const icon_image = require('./../assets/icon_image.svg');
const icon_video = require('./../assets/icon_video.svg');
const icon_design = require('./../assets/icon_design.svg');
const icon_text = require('./../assets/icon_text.svg');

export function date_to_str(date){
	return date.toLocaleString('en-us', {month:'short'})+'. '+date.getDate()+', '+date.getFullYear();
}

export function getFormatFromMimeType(mimeType) {
	if (mimeType && mimeType.includes('image'))
		return 'Image';
	else if (mimeType && mimeType.includes('video'))
		return 'Video';
	else if(mimeType && mimeType.match(/(powerpoint)/g))
		return 'Design';
	else
		return 'Document';
}

export function getImageBasedOnMimeType(imageType) {
	if (imageType && imageType.includes('image'))
		return icon_image;
	else if (imageType && imageType.includes('video'))
		return icon_video;
	else if(imageType && imageType.match(/(powerpoint)/g))
		return icon_design;
	else
		return icon_text;
}
