import React from 'react';
import InputFieldBlock from '../BasicInputs/InputFieldBlock';
import ClipWeek from '../ClipWeek';

const TUESDAY = 2;
const WEDNESDAY = 3;
const THURSDAY = 4;
export default class ClipsCalculator extends React.Component{
    constructor(props){
        super(props);
        this.state={currentTime:'', inputtedDate:new Date(), step1Date:null,step2Date:null,step3Date:null};
    }


    calculateDates=()=>{
        let timeStringSplit=this.state.currentTime.split('-');
        let inputDate=new Date(timeStringSplit[0],timeStringSplit[1]-1,timeStringSplit[2]);
        this.setState({inputtedDate: inputDate}, ()=> this.setDateForEachStep());
    }

    getClosestDesiredDayBeforeTheCurrentDay(desired_day_of_week,date){
        let cur_day_of_week = date.getDay(); // integer value from 0(sunday)-6(saturday)
        let number_of_days_needed_to_subtract=0 // how many days to go back to get to the desired_day_of_week
        while(cur_day_of_week !== desired_day_of_week){
            number_of_days_needed_to_subtract-=1;
            cur_day_of_week=cur_day_of_week-1;
            if(cur_day_of_week<0){
                cur_day_of_week=6;
            }
        }
        date.setDate(date.getDate() + number_of_days_needed_to_subtract);
    }

    setDateForEachStep(){
        let inputDate=this.state.inputtedDate;
        let step1Date=new Date(inputDate.getFullYear(),inputDate.getMonth(), inputDate.getDate() - (7*13));
        this.getClosestDesiredDayBeforeTheCurrentDay(TUESDAY,step1Date);
        let step2Date=new Date(inputDate.getFullYear(),inputDate.getMonth(), inputDate.getDate() - (7*8));
        this.getClosestDesiredDayBeforeTheCurrentDay(WEDNESDAY,step2Date);
        let step3Date=new Date(inputDate.getFullYear(),inputDate.getMonth(), inputDate.getDate() - (7*4));
        this.getClosestDesiredDayBeforeTheCurrentDay(THURSDAY,step3Date);
        this.setState({ inputDate,step1Date,step2Date,step3Date});
    }

	handleInputChange = event => {
        this.setState({currentTime:event.currentTarget.value});
    };
    
    render(){
        return (
            <div className='ClipsCalculator'>
                <div className='clips-header'>
                    <span className='clips-header-text'>CLIPS Quick Date Calculator</span>
                </div>
                <InputFieldBlock
                    fieldType='date'
                    title='Renewal Date'
                    name='renewal-date'
                    fieldName='renewal-date'
                    callback={this.handleInputChange}
                    max='9999'
                    fieldHint='yyyy-mm-dd'
                />
                <button className='calculate' onClick={this.calculateDates}>Calculate</button>
                <div className='new-time-wrapper'>
                    <ClipWeek step='1' weeks='13' stepDate={this.state.step1Date?.toDateString()}/>
                    <ClipWeek step='2' weeks='8' stepDate={this.state.step2Date?.toDateString()}/>
                    <ClipWeek step='3' weeks='4' stepDate={this.state.step3Date?.toDateString()}/>
                </div>
            </div>
        );
    }
}