/*
CatTagLabelList - a complete widget for category and tag labels

A category and tag label list consists of (from left to right and top to bottom)
	CategoryLabel - a label for the category of this object
	TagLabelList - a list of tag labels for this object
*/


import React from 'react';
import PropTypes from 'prop-types';

import CategoryLabel from './CategoryLabel.jsx';
import TagLabelList from './TagLabelList.jsx';

/*
props (component-level arguments):
	category: the category to display
	tags: the list of tag to display
*/
class CatTagLabelList extends React.Component {
	render() {
		return (
			<div className='CatTagLabelList'>
				{this.props.category && <CategoryLabel category={this.props.category} />}
				{(this.props.category && this.props.tags && (this.props.tags.length>0)) && <span className='cat-and-tag'>|</span>}
				{(this.props.tags && (this.props.tags.length>0)) && <TagLabelList tags={this.props.tags} />}
			</div>
		);
	}
}

CatTagLabelList.defaultProps={
	category: '',
	tags: [],
};

CatTagLabelList.propTypes = {
	category: PropTypes.string,
	tags: PropTypes.array,
};

export default CatTagLabelList;

