/*
FlexTable - A Flex table is a responsive table that uses display flex on mobile to fit to the screen better than a normal table element.
*/

import React from 'react';
import PropTypes from 'prop-types';
import {date_to_str} from '../lib/libformat.jsx';
import { isAFunction } from '../shared-components/src/proj-libs/helperFunctions';

/*
props (component-level arguments):
	tableTitle: A string or react component to display prior to the table content
	tableData: An object that consists of a headerData object and a rows array that consists of row data objects.
*/
class FlexTable extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			tableData: this.props.tableData
		};

		this.renderTableTitle=this.renderTableTitle.bind(this);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		return {
			tableData: nextProps.tableData,
		};
	}

	sortTable(e, sortKey) {

		// Check what the target's current sort order is
		// If asc, sort a-0 > z-9, else z-9 < a-0
		if(e.currentTarget.dataset.sortorder === 'asc') {
			this.setState({
				tableData: {
					'headerData': this.state.tableData.headerData,
					'rows': this.state.tableData.rows.sort((a,b) => (a[sortKey]>b[sortKey])?1:((a[sortKey]<b[sortKey])?-1:0))
				}
			});
			e.currentTarget.dataset.sortorder = 'desc';
		} else {
			this.setState({
				tableData: {
					'headerData': this.state.tableData.headerData,
					'rows': this.state.tableData.rows.sort((a,b) => (a[sortKey]<b[sortKey])?1:((a[sortKey]>b[sortKey])?-1:0))
				}
			});
			e.currentTarget.dataset.sortorder = 'asc';
		}

		// Reset all other sort orders, so first click is always a-0 > z-9
		Array.prototype.slice.call(document.querySelectorAll('.FlexTable thead tr .item.sortable')).forEach(function(x) {
			if(x !== e.currentTarget) {
				x.dataset.sortorder = 'asc';
			}
		});
	}

	renderTableTitle(){
		if(this.props.tableTitle!==undefined && this.props.tableTitle!==null){
			if((typeof this.props.tableTitle)==='string'){
				return (<div className='table-title-cont'>
					<div className='grid-x grid-padding-x'>
						<div className='small-12 cell'>
							<h1 className='table-title'>
								{this.props.tableTitle}
							</h1>
						</div>
					</div>
				</div>);
			}
			return (<div className='table-title-cont'>
				{this.props.tableTitle}
			</div>);
		}
		return '';
	}

	render(){
		return(
			<div className='FlexTable'>
				{this.renderTableTitle()}
				<div className='table-wrapper'>
					<table>
						{this.state.tableData.headerData && <thead>
							<tr>
								{this.state.tableData.headerData.map((colHdr,hdrKey) => {
									if(colHdr.hasOwnProperty('sortable') && colHdr.sortable!==true) {
										return(<th key={'FlexTable-header-'+hdrKey} className={`item ${colHdr.customClass?colHdr.customClass:''}`}><span className='header-text'>{colHdr.title}</span></th>);
									} else {
										return(
										<th
											key={'FlexTable-header-'+hdrKey}
											className={`item sortable ${colHdr.customClass?colHdr.customClass:''}`}
											data-sortorder='asc'
											onClick={(e) => {
												if (isAFunction(this.props.sort)) {
													this.props.sort(e, colHdr.title);
												}
												else {
													if(colHdr.sortField){
														this.sortTable(e, colHdr.sortField);
													}else{
														this.sortTable(e, colHdr.field);
													}

												}

											}}>

												<span className='header-text'>{colHdr.title}</span>
										</th>);
									}
								})}
							</tr>
						</thead>}
						{this.state.tableData.rows && <tbody>
							{this.state.tableData.rows.map( (row, rowIdx) => {
								return(<tr key={'FlexTable-item-'+rowIdx} className="row">
										{this.state.tableData.headerData.map((hdr, fieldIdx) => {
											//get a unique table cell identifier
											let key='FlexTable-item-'+rowIdx+'-content-'+fieldIdx;
											//if the row does not contain any information for this field (requested by the header data given)
											if(!row.hasOwnProperty(hdr.field)){
												//then display an empty cell to maintain table alignment
												return(<td key={key}></td>);
											}

											//get any custom css classes that were specified
											let cssClasses='';
											let cssClassList=[];
											if((row.hasOwnProperty('cssClasses')) && (row['cssClasses'].hasOwnProperty(hdr.field))){
												cssClasses=row['cssClasses'][hdr.field];
												cssClassList=cssClasses.split(' ');
											}

											//get any javascript hooks that were specified
											let onClick=null;
											if(hdr.hasOwnProperty('onClick')){
												if(row.hasOwnProperty(hdr['onClick'])){
													onClick=row[hdr['onClick']];
												}
											}

											//finally, get the actual value of the cell data
											let val=row[hdr.field];
											if(hdr.isLink && row[hdr.field]){
												val = <a href={`${row[hdr.field]}`} target="_blank" rel="noopener noreferrer">Portal</a>;
											}
											if(hdr.title.toLowerCase().includes('email')){
												val = <a href={`mailto:${row[hdr.field]}`}>{row[hdr.field]}</a>;
											}

											if(hdr.title.toLowerCase().includes('address')){
												val = <a href={`http://maps.google.com/?q=${row[hdr.field]}`} target="_blank" rel="noopener noreferrer">{row[hdr.field]}</a>;
											}

											//special case action event button handling and formatting
											if(onClick!==null){
												return(<td key={key} className={`item table-action-item ${cssClasses}`}>
													<button onClick={onClick}>
														<i className={`${val}`}></i>
													</button>
												</td>);
											//special case tag array formatting
											}else if(cssClassList.includes('tag-array') && (val instanceof Array)) {
												return(<td key={key} className={`item ${cssClasses}`}>{val.join(', ')}</td>);
											//special case image formatting
											} else if(cssClassList.includes('has-img')) {
												return(<td key={key} className={`item ${cssClasses}`} dangerouslySetInnerHTML={{__html: val}} />);
											} else if(cssClassList.includes('iso-date') && (val!==null)){
												let date=new Date(val);
												return(<td key={key} className={`item ${cssClasses}`}><time dateTime={date.toISOString()}>{date_to_str(date)}</time></td>);
											}

											return(<td key={key} className={`item ${cssClasses}`}>{val}</td>);
										})}
								</tr>);
							})}
						</tbody>}
					</table>
				</div>
			</div>
		);
	}
}

FlexTable.propTypes = {
	tableData: PropTypes.object.isRequired
};

FlexTable.defaultProps = {
	tableData: {}
};

export default FlexTable;
