import React, { useContext, useState } from 'react';
import { InputFieldBlock } from '../../../../BasicInputs/InputFieldBlock';
import { InputCheckbox } from '../../../../BasicInputs/InputCheckbox';
import { Question } from './Question';
import { FormContext } from './FormContext';
import { AddQuestionMenu } from './AddQuestionMenu';
export const Option = ({
	question,
	questionIndex,
	optionIndex,
	removeOption,
	alreadyHasFollowUp,
}) => {
	const context = useContext(FormContext);
	const [isFollowUp, setFollowUp] = useState(
		question.answer_options[optionIndex].question
	);
	const checkChange = () => {
		if (isFollowUp) {
			question.answer_options[optionIndex].question = null;
		}
		setFollowUp((prev) => !prev);
		context.reRender();
	};
	return (
		<>
			<div className={`FieldSelectOption`}>
				<div className="grid-x option-row">
					<div className={`cell small-9`}>
						<InputFieldBlock
							fieldName={`${optionIndex}`}
							fieldRequired={true}
							fieldType="text"
							title=""
							fieldValue={
								question.answer_options[optionIndex].title
							}
							id={`${optionIndex}`}
							callback={(e) =>
								(question.answer_options[optionIndex].title =
									e.currentTarget.value)
							}
							inputBlockClass={'option'}
							fieldHint={'Enter answer option'}
						/>
					</div>
					<div
						className={`cell small-${
							alreadyHasFollowUp ? 2 : 3
						} has-follow-up`}
					>
						{!alreadyHasFollowUp &&
							question.question_type !== 'dropdown' && (
								<InputCheckbox
									onCheckChange={checkChange}
									required={false}
									defaultChecked={isFollowUp}
									inputId={`option-number-${optionIndex}-q-${questionIndex}`}
									id="follow-up"
									desc="Has follow-up question"
								/>
							)}
						<i
							className="far fa-trash-alt"
							onClick={() => removeOption(question, optionIndex)}
						></i>
					</div>
				</div>
				{isFollowUp &&
					!question.answer_options[optionIndex].question && (
						<AddQuestionMenu
							isFollowUp={true}
							answerOption={question.answer_options[optionIndex]}
						/>
					)}
			</div>
			{question.answer_options[optionIndex].question && (
				<Question
					answer_option={question.answer_options[optionIndex]}
					questions={null}
					isFollowUp={true}
					question={question.answer_options[optionIndex].question}
				/>
			)}
		</>
	);
};
