import { INLINE_IMAGE_CLASSNAME } from '../constants';
import config from '../config';
import { toast } from 'react-toastify';

export function InlineImagesFilePicker(callback, value, meta, shortid, component) {
    if (meta.filetype === 'image') {
        var input = document.getElementById('my-file');
        input.click();
        input.onchange = () => {
            var file = input.files[0];
            var reader = new FileReader();
            reader.onload = (e) => {
                let key = `post/${component.state.postBucketId}/${shortid.generate()}_${file.name}`;
                let inlineImageIsUploading = component.state.inlineImageIsUploading;
                inlineImageIsUploading = true;
                component.setState({ inlineImageIsUploading }, () => {
                    component.aws.uploadFileRawKey(config.bucket_name, key, file, err => {
                        toast.error('Error uploading file to S3');
                        inlineImageIsUploading = false;
                        component.setState({ inlineImageIsUploading });
                    }, success => {
                        // callback will fill the upload ui with whatever is passed to it.
                        // it will call urlconverter_callback once you hit save.
                        let key = success.key;
                        if (success.hasOwnProperty('Key')) {
                            key = success.Key;
                        }
                        component.aws.getSignedUrl(config.bucket_name, key, error => {
                            toast.error('Error getting signed url for newly uploaded file.');
                            inlineImageIsUploading = false;
                            component.setState({ inlineImageIsUploading });
                        }, success => {
                            callback(`${success}`, { alt: file.name });
                            inlineImageIsUploading = false;
                            component.setState({ inlineImageIsUploading });
                        });
                    });
                });
            };
            reader.readAsDataURL(file);
        };
    }
}

export function parseForInlineImages(aws) {
    let images = document.getElementsByClassName(INLINE_IMAGE_CLASSNAME);
    if (images) {
        for (let i = 0; i < images.length; i++) {
            let src = images[i].src;
            if (src.startsWith(config.aws_url)) {
                src = decodeURIComponent(src, 'UTF-8');
                src = src.split('/');
                src = src.slice(3);
                src = src.join('/');
                let key = src.split('?')[0];
                aws.getSignedUrl(config.bucket_name, key, error => {
                    toast.error('Error getting signed url for newly uploaded file.');
                }, success => {
                    images[i].src = success;
                });
            }
        }
    }
}

export function getTinyMceInitSettings(component, shortid) {
    return (
        {
            plugins: ['advlist autolink lists link charmap print preview anchor advcode powerpaste media mediaembed formatpainter', 'image imagetools'],
            height: 256,
            toolbar: 'image | undo redo | bold italic | alignleft aligncenter alignright | code formatpainter',
            file_browser_callback_types: 'image',
            urlconverter_callback: function (url, node, on_save, name) {
                return url;
            },
            image_class_list: [// the default value here is required for inline-images
                //we can expand it at a later time if the client wants to further customize
                { title: 'None', value: INLINE_IMAGE_CLASSNAME }
            ],
            file_picker_callback: (callback, value, meta) => {
                InlineImagesFilePicker(callback, value, meta, shortid, component);
            },
        }
    );
}