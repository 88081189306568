/*
BriefViewTitle - a title associated with an object for use with a BriefView display

A brief view title is a base-level component which contains no subcomponents
*/

import React from 'react';
import PropTypes from 'prop-types';

/*
props (component-level arguments):
	text: the text to render, aka the title to display
	tag: which tag to use for the title, the default is h3
*/
class BriefViewTitle extends React.Component {
	render() {
		return (
			<this.props.tag className='BriefViewTitle'>
				{this.props.text}
			</this.props.tag>
		);
	}
}

BriefViewTitle.defaultProps={
	text:'',
	tag: 'h3'
};

BriefViewTitle.propTypes={
	text:PropTypes.string.isRequired,
	tag:PropTypes.string,
};

export default BriefViewTitle;

