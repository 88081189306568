/*
	NewFormView - the flyout form when creating a new form
*/

import React from 'react';
import InputFieldBlock from '../../../BasicInputs/InputFieldBlock';
import AddFieldController from './AddFieldController';
import { parse_cookies } from '../../../../react-utils/src/libformat';
import { xml_send_info } from '../../../../react-utils/src/libajax';
import { SESSION_ID } from '../../../../constants';
import FormEditView from './FormEditView';
import styled from 'styled-components';
import IconButton from '../../../IconButton';
/*
states (component-level variables):
	questions: a list of question objects retrieved from children components
	inputs.title: the title of this form

props (component-level arguments):
	onSuccessCreate: callback function that reupdates the table when a form is created
	closeFlyoutCallback: callback function that closes the flyout menu
	formBeingViewed: object that holds all information on a form being edited 
*/
class NewFormView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			questions: [],
			copy_form_taker:false,
			email: [''],
			inputs: {
				title: '',
			}
		};
		this.handleInputChange = this.handleInputChange.bind(this);
		this.addQuestion = this.addQuestion.bind(this);
		this.handleQuestionLabelChange = this.handleQuestionLabelChange.bind(this);
		this.handleOptionLabelChange = this.handleOptionLabelChange.bind(this);
		this.addQuestionOption = this.addQuestionOption.bind(this);
		this.addFollowUpQuestion = this.addFollowUpQuestion.bind(this);
		this.addFollowUpQuestionOption = this.addFollowUpQuestionOption.bind(this);
		this.reformatDependsOn = this.reformatDependsOn.bind(this);
		this.removeQuestion = this.removeQuestion.bind(this);
		this.removeQuestionOption = this.removeQuestionOption.bind(this);
	}

	handleInputChange = event => {
		let inputs = this.state.inputs;
		inputs[event.currentTarget.id] = event.currentTarget.value;
		this.setState({
			inputs: inputs
		});
	};

	removeQuestion(question_idx, follow_up = false, option_idx = null) {
		let questions = this.state.questions;
		if (follow_up) {
			delete questions[question_idx].answer_options[option_idx].follow_up_question;
		} else {
			questions[question_idx]=null; // set to null and not splice so data doesnt have to be shuffled around
		}
		this.setState({questions});
	}

	removeQuestionOption(question_idx, option_idx, follow_up = false, follow_up_option_idx = null){
		let questions = this.state.questions;
		if(follow_up){
			delete questions[question_idx].answer_options[option_idx].follow_up_question.answer_options[follow_up_option_idx];
		}else{
			questions[question_idx].answer_options[option_idx]=null;
		}
		this.setState({questions});
	}

	addQuestion(question, follow_up = false) {
		if (follow_up) {
			this.addFollowUpQuestion(question);
		} else {
			let questions = this.state.questions;
			questions.push(question);
			this.setState({ questions });
		}
	}

	addFollowUpQuestion(follow_up_question) {
		let questions = this.state.questions;
		questions[follow_up_question.question_idx].answer_options[follow_up_question.option_idx].follow_up_question = follow_up_question;
		this.setState({ questions });
	}

	addQuestionOption(question_option, follow_up = false) {
		let questions = this.state.questions;
		if (follow_up) {
			this.addFollowUpQuestionOption(question_option);
		} else {
			let questions = this.state.questions;
			for (let i in questions) {
				if(questions[i]===null){
					continue;
				}
				if (questions[i].question_idx === question_option.question_idx) {
					if (questions[i].answer_options === undefined) {
						questions[i].answer_options = [];
						questions[i].answer_options[0] = question_option;
					} else {
						questions[i].answer_options.push(question_option);
					}
				}
			}
		}
		this.setState({ questions });
	}

	addFollowUpQuestionOption(question_option) {
		let questions = this.state.questions;
		for (let i in questions) {
			if (questions[i].question_idx === question_option.question_idx) {
				if(!questions[i].answer_options[question_option.option_idx].follow_up_question.answer_options) {
					questions[i].answer_options[question_option.option_idx].follow_up_question.answer_options = [];
					questions[i].answer_options[question_option.option_idx].follow_up_question.answer_options[0] = question_option;
				} else {
					questions[i].answer_options[question_option.option_idx].follow_up_question.answer_options.push(question_option);
				}
			}
		}
		this.setState({ questions });
	}

	handleQuestionLabelChange(label_title, question_idx, follow_up = false, option_idx = 0) {
		let questions = this.state.questions;
		if (follow_up) {
			questions[question_idx].answer_options[option_idx].follow_up_question.title = label_title;
		} else {
			questions[question_idx].title = label_title;
		}
		this.setState({ questions });
	}

	handleOptionLabelChange(label_title, question_idx, option_idx, follow_up = false, follow_up_option_idx = 0) {
		let questions = this.state.questions;
		if (follow_up) {
			questions[question_idx].answer_options[option_idx].follow_up_question.answer_options[follow_up_option_idx].title = label_title;
		} else {
			questions[question_idx].answer_options[option_idx].title = label_title;
		}
		this.setState({ questions });
	}

	// need to account for the nulls from deleting questions/options
	// data is saved in the format: question: answer_options[ { title, follow_up_question: question:answer_options[ {title} ] } ]
	reformatDependsOn() {
		let questions = this.state.questions;
		let new_questions = [];
		for (let q in questions) {
			if(questions[q] === null){
				continue;
			}
			if (questions[q].answer_options) {
				let answer_options = questions[q].answer_options;
				let cur_question = questions[q];
				let new_answer_options = [];
				for (let item in answer_options) {
					if(answer_options[item] === null){
						continue;
					}
					new_answer_options.push(answer_options[item].title);
				}
				let question = {
					title: cur_question.title,
					question_type: cur_question.question_type,
					answer_options: new_answer_options
				};
				new_questions.push(question);
				for (let j in answer_options) {
					if(answer_options[j] === null){
						continue;
					}
					if (answer_options[j].follow_up_question) {
						let new_answer_options = [];
						for (let item in answer_options[j].follow_up_question.answer_options) {
							if(answer_options[j].follow_up_question.answer_options[item] === null){
								continue;
							}
							new_answer_options.push(answer_options[j].follow_up_question.answer_options[item].title);
						}
						let question = {
							title: answer_options[j].follow_up_question.title,
							question_type: answer_options[j].follow_up_question.question_type,
							answer_options: new_answer_options,
							depends_on: {
								[parseInt(q)]: answer_options[j].title
							}
						};
						new_questions.push(question);
					}
				}
			} else {
				let cur_question = questions[q];
				let question = {
					title: cur_question.title,
					question_type: cur_question.question_type
				};
				new_questions.push(question);
			}
		}
		return new_questions;
	}

	parseInput() {
		const cookies = parse_cookies();
		this.reformatDependsOn();
		let data = {
			title: this.state.inputs.title,
			description: 'Form Description',
			completion_title: 'Thank you for completing the form.',
			completion_subtitle: '',
			questions: this.reformatDependsOn(),
			copy_form_taker: this.state.copy_form_taker,
			email: this.state.email
		};
		xml_send_info(
			'/form',
			JSON.stringify(data),
			xhr => {
				this.props.onSuccessCreate();
			},
			'POST',
			{ Authorization: cookies[SESSION_ID] },
			error => {
				console.log(error.response);
			}
		);
	}

	submitForm() {
		return event => {
			event.preventDefault();
			this.parseInput();
			this.props.closeFlyoutCallback();
			return false;
		};
	}

	handleCheckboxChange=()=>{
		this.setState({copy_form_taker: !this.state.copy_form_taker});
	}

	addEmail=()=>{
		this.setState({email:[...this.state.email,'']});
	}
	removeEmail=(index)=>{
		let oldEmails = [...this.state.email];
		oldEmails.splice(index,1);
		this.setState({email:oldEmails});
	}

	render() {
		if (this.props.formBeingViewed) {
			return <FormEditView closeFlyoutCallback={this.props.closeFlyoutCallback} formBeingViewed={this.props.formBeingViewed} />;
		}
		return (
			<div className={'NewFormView flyout-form'}>
				<form onSubmit={this.submitForm()}>
					<div className="save-form-button">
						<button className="button save-form" type="submit">
							<i className="fa fa-check"></i>Save Form
						</button>
					</div>
					<h2 className="flyout-title">Create a New Form</h2>
					<div className="main-form-field-wrapper">
						<div className="grid-container full">
							<div className="grid-x">
								<div className="cell small-12">
									<InputFieldBlock title="Title your form" fieldName="title" fieldRequired={true} fieldType="text" id="title" callback={this.handleInputChange} fieldValue={this.state.inputs.title} />
								</div>
								<div className="email-container">
								<div className="grid-x">
										<div className="cell small-6">
											<InputWrapper>
												{this.state.email.map((item,index)=>{
													return (
														<InputButtonWrapper key={`email-${index}`} >
															<InputFieldBlock inputBlockClass="email" title="Email results will get sent to" fieldName="email" fieldType="email" callback={(e)=>this.handleEmailInputChange(e,index)} fieldValue={this.state.email[index]} />
															{index > 0 && 
																<IconButton
																	buttonColorType='color-blue'
																	foregroundIcon='fas fa-trash'
																	backgroundIcon='fa fa-square color-light-gray3'
																	extraClasses='icon-button-click'
																	callback={(e)=>{e.preventDefault();this.removeEmail(index);}}
																/>
															}
														</InputButtonWrapper>
													);
												})}
												
												<button className='button save-form' type='button' onClick={()=>this.addEmail()}>Add Email</button>
											</InputWrapper>
										</div>
										<div className="cell small-6">
											<div className="save-email-button-container">
												<div className='form-checkbox-container'>
													<input type='checkbox' name='target' id='input-target' checked={this.state.copy_form_taker} onChange={this.handleCheckboxChange} />
												<label htmlFor='input-target'>CC Form Taker On Submission</label>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="add-form-fields">Add Questions Below</div>
							</div>
						</div>
					</div>
					<AddFieldController
						addQuestionOption={this.addQuestionOption}
						questions={this.state.questions}
						addQuestionCallback={this.addQuestion}
						handleQuestionLabelChange={this.handleQuestionLabelChange}
						handleOptionLabelChange={this.handleOptionLabelChange}
						handleInputChange={this.handleInputChange}
						removeQuestion={this.removeQuestion} removeQuestionOption={this.removeQuestionOption}
					/>
				</form>
			</div>
		);
	}
}

export default NewFormView;

const InputWrapper = styled.div`
	button:last-child {
		margin-top:1rem;
	}
`;

export const InputButtonWrapper = styled.div`
	display:flex;
	align-items:center;
	justify-content:flex-start;
	button.save-form{
		margin-top:30px;
	}
`;