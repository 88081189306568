/*
S3Image - An S3Image is a image that will take in a s3 path and return image from an s3 server.
*/

import React from 'react';
import PropTypes from 'prop-types';
import config from '../config';
/*
props (component-level arguments):
	s3_path: the s3 path of this image (for which a signed_url will be generated)
	additionalClasses: Additional Classes for style purposes
	placeholderImageURL: A place holder image URL. Must be a full valid path.
	altText: alternative text for screen readers and to display if the image couldn't load

state (component-level arguments):
	signed_url: the full url generated for this s3 object
*/
class S3Image extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			signed_url: null,
		};
		this.handleError=this.handleError.bind(this);
		this.handleSuccess=this.handleSuccess.bind(this);
		this.getSignedURL=this.getSignedURL.bind(this);
	}

	componentDidMount() {
		this.getSignedURL(this.props.s3_path);
	}

	getSignedURL(s3_path) {
		if (s3_path) {
			let aws = null;
			if (this.props.aws_object) {
				aws = this.props.aws_object;
				aws.getSignedUrl(config.bucket_name, s3_path, this.handleError, this.handleSuccess);
			} else {
				this.setState({signed_url: null});
			}
		} else {
			this.setState({signed_url: null});
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.s3_path !==prevProps.s3_path || (!prevProps.aws_object && this.props.aws_object)) {
			this.getSignedURL(this.props.s3_path);
		}
	}

	handleError(error) {
		// console.log(`Error getting signed url. ${error}`);
	}

	handleSuccess(url) {
		this.setState({
			signed_url: url
		});
	}

	render() {
		return (
			<div
				className={`S3Image Thumbnail ${this.props.additionalClasses} ${(this.state.signed_url || this.props.placeholderImageURL) ? '' : 'no-image'}`}>
				{(this.state.signed_url || this.props.placeholderImageURL) &&
				<img src={this.state.signed_url ? this.state.signed_url : this.props.placeholderImageURL}
					 alt={this.props.altText}/>}
			</div>
		);
	}
}

S3Image.defaultProps = {
	additionalClasses: '',
};

S3Image.propTypes = {
	s3_path: PropTypes.string,
	additionalClasses: PropTypes.string,
	placeholderImageURL: PropTypes.string,
	altText: PropTypes.string,
};

export default S3Image;
