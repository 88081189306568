import React from 'react';
import config from '../config.js';

class ThemeableContainer extends React.Component {
	//---------------------------------------------------------------------
	// BEGIN Public Methods
	//---------------------------------------------------------------------
	
	constructor(props) {
		super(props);
		this.state={
			components:{},
		};
	}
	
	async loadThemeStyles(){
		await this.loadStyles(config.theme);
	}
	
	getThemeComponent(componentName,fallback){
		if(this.hasOwnProperty('state')){
			if(this.state.hasOwnProperty('components')){
				if(this.state.components.hasOwnProperty(componentName)){
					return this.state.components[componentName];
				}
			}
		}
		return fallback;
	}
	
	async loadThemeComponent(componentName){
		if(config.hasOwnProperty('theme')){
			let themeComponent=(await this.loadComponent(config.theme,componentName));
			if(themeComponent!==null){
				let components=this.state.components;
				components[componentName]=themeComponent;
				
				//NOTE: because of the asynchronous nature of this method,
				//we have to run a setState call in order to force a re-render after theme components load
				//if we just set a global or a class variable that isn't part of state,
				//then no re-render occurs and we are left with the un-themed version from the first render
				//until a later re-render occurs
				this.setState({
					components:components,
				});
			}
		}
	}

	//---------------------------------------------------------------------
	// END Public Methods
	//---------------------------------------------------------------------

	//---------------------------------------------------------------------
	// BEGIN Private Methods
	//---------------------------------------------------------------------

	async loadStyles (theme){
		return new Promise(resolve => {
			import('../themes/'+theme+'/styles.css')
				.then((style) => {
					resolve(style);
				})
				.catch(error => {
					console.error('Warning: no styles found for theme "'+theme+'"');
					resolve(null);
				});
		});
	}

	async loadComponent (theme,componentPath){
		return new Promise(resolve => {
			//NOTE: the .jsx file extension is assumed for all components
			import('../themes/'+theme+'/components/'+componentPath+'.jsx')
				.then(function (component) {
					resolve(component.default);
				})
				.catch(error => {
//					console.error('Warning: "'+componentPath+'" component not found for theme "'+theme+'"');
					resolve(null);
				});
		});
	}
	
	//---------------------------------------------------------------------
	// END Private Methods
	//---------------------------------------------------------------------
}

export default ThemeableContainer;


