/*
 FieldQuestionLabel - a question to display: has a title, question_type, and possibly options

*/

import React from 'react';
import FieldSelectOption from './FieldSelectOption';
import TiledButton from './TiledButton';
import InputFieldBlock from '../../../../BasicInputs/InputFieldBlock';
/*
props (component-level arguments):
	handleOptionLabelChange: callback function - changes the title of an option
	questions: a list of all questions
	addQuestionCallback: callback function - adds a question
	addQuestionOption: callback function - adds a question option
	question_idx: the index of the current question the follow up question is on
	option_number_owner: if this is a follow up question then this holds the option number holding this question
	handleQuestionLabelChange:
	follow_up_enabled: if true then this is a follow up question
	label_number: the question id of this label
	field_type: the type of question
*/
class FieldQuestionLabel extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			option_count: 0,
			options: [],
			option_titles: [],
			inputs: {
				title: ''
			}
		};
		this.increaseOptions = this.increaseOptions.bind(this);
		this.addOption=this.addOption.bind(this);
		this.deleteOption=this.deleteOption.bind(this);
	}

	componentDidMount() {
		if (this.props.field_type === 'single-select' || this.props.field_type === 'multi-select' || this.props.field_type === 'dropdown-select') {
			this.increaseOptions();
		}
	}

	render() {
		if (this.props.field_type === 'text' || this.props.field_type === 'textarea') {
			return this.renderText();
		} else if (this.props.field_type === 'single-select' || this.props.field_type === 'multi-select' || this.props.field_type === 'dropdown-select') {
			let multi = (this.props.field_type === 'multi-select');
			return this.renderSelect(multi);
		}
	}

	handleOptionInputChange = event => {
		let option_titles = this.state.option_titles;
		option_titles[event.currentTarget.id] = event.currentTarget.value;
		this.setState({
			option_titles: option_titles
		});
		if (this.props.follow_up_enabled) {
			this.props.handleOptionLabelChange(event.currentTarget.value, this.props.last_label_number, this.props.option_number_owner, true, parseInt(event.currentTarget.id));
		} else {
			this.props.handleOptionLabelChange(event.currentTarget.value, this.props.question_idx, parseInt(event.currentTarget.id));
		}
	};

	handleLabelInputChange = event => {
		let inputs = this.state.inputs;
		inputs['title'] = event.currentTarget.value;
		this.setState({
			inputs: inputs
		});
		if (this.props.follow_up_enabled) {
			this.props.handleQuestionLabelChange(event.currentTarget.value, this.props.last_label_number, true, this.props.option_number_owner);
		} else {
			this.props.handleQuestionLabelChange(event.currentTarget.value, this.props.question_idx);
		}
	};

	deleteOption(e,option_number){
		e.preventDefault();
		if(this.state.options.length <= 1){
			return null;
		}
		if(this.props.follow_up_enabled){
			this.props.removeQuestionOption(this.props.last_label_number, this.props.option_number_owner,true,option_number);
		}else{
			this.props.removeQuestionOption(this.props.label_number,option_number);
		}
		let options = this.state.options;
		options[option_number] = null;
		this.setState({options});
	}
	
	addOption(){
		let options = this.state.options;
		options.push(
			<FieldSelectOption
				addQuestionOption={this.props.addQuestionOption}
				questions={this.props.questions}
				question_idx={this.props.question_idx}
				addQuestionCallback={this.props.addQuestionCallback}
				option_number_owner={this.props.option_number_owner}
				handleInputChange={this.handleOptionInputChange}
				handleOptionLabelChange={this.props.handleOptionLabelChange}
				handleQuestionLabelChange={this.props.handleQuestionLabelChange}
				label_number={this.props.label_number} follow_up_label={this.props.last_label_number}
				follow_up_enabled={this.props.follow_up_enabled}
				removeQuestion={this.props.removeQuestion} removeQuestionOption={this.props.removeQuestionOption} deleteOption={this.deleteOption}
				option_number={this.state.options.length}
			/>
		);
		if (this.props.follow_up_enabled) {
			this.props.addQuestionOption({
				question_idx: this.props.last_label_number,
				title: '',
				option_idx: this.props.option_number_owner,
				follow_up_option_idx: this.state.options.length - 1
			},true);
		} else {
			this.props.addQuestionOption({
				question_idx: this.props.question_idx,
				title: '',
				option_idx: this.state.options.length - 1
			});
		}
		this.setState({ options });
	}

	increaseOptions() {
		if (this.state.options.length < 25) {
			this.addOption();
		}
	}

	renderText() {
		return (
			<div className={'FieldQuestionLabel text'}>
				<div className="fa-label-container">
					<i className="fa fa-grip-vertical"></i>
				</div>
				<div className="field-label-content text">
					<div className="field-label-title">
						{this.props.field_type === 'text' ? 'Text' : 'Textarea'}
						<i className="fas fa-text-width textarea"> </i>
					</div>
					<InputFieldBlock
						title="Question Title"
						callback={this.handleLabelInputChange}
						fieldRequired={true}
						fieldType="text"
						fieldName={`text-q-${this.props.question_idx}-${this.props.option_number_owner}`}
						fieldHint={'Enter the title of this question...'}
					/>
				</div>
				<div className="fa-label-container">
					<i className="far fa-trash-alt" onClick={e=>this.props.remove_question_from_controller(e,this.props.label_number)}></i>
				</div>
			</div>
		);
	}

	renderSelect(multi = false) {
		return (
			<div className={'FieldQuestionLabel single-select'}>
				<div className="fa-label-container">
					<i className="fa fa-grip-vertical"></i>
				</div>
				<div className="field-label-content text">
					<div className="field-label-title">
						{this.props.field_type === 'dropdown-select'?
						'Dropdown': multi ? 'Multi-Select' : 'Single-select'}
						<i className="fas fa-text-width textarea"> </i>
					</div>
					<InputFieldBlock
						title="Question Title"
						fieldRequired={true}
						fieldType="text"
						fieldName={`select-q-${this.props.question_idx}-${this.props.option_number_owner}`}
						callback={this.handleLabelInputChange}
						fieldValue={this.state.inputs.title}
						fieldHint={'Enter the title of this question...'}
					/>
					{this.state.options.map((item,idx) =>{
						return <div key={'question-'+this.props.question_idx+'item-'+idx+'follow-up'+this.props.option_number_owner}> {item} </div>;
					})}
					{this.state.options.length < 25 && <TiledButton faClass="fa fa-plus" onClickEvent={this.increaseOptions} tileClass="add-option" tileText="Add Option" />}
				</div>
				<div className="fa-label-container">
					<i className="far fa-trash-alt" onClick={e=>this.props.remove_question_from_controller(e,this.props.label_number)}></i>
				</div>
			</div>
		);
	}
}

FieldQuestionLabel.defaultProps = {
	follow_up_enabled: false
};
export default FieldQuestionLabel;
