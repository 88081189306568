/*
ToggleSwitch - A ToggleSwitch is a fancy way to view a checkbox, and it's handling is enforced by it's parent.
*/

import React from 'react';
import PropTypes from 'prop-types';

/*
props (component-level arguments):
	handleChangeCallback: A callback function that handles what happens when this switch is pressed. Required
	toggleLabel: The label to place beside the switch to denote what you are switching
	value: The defaultValue of the check box
*/
class ToggleSwitch extends React.Component {
	constructor(props) {
		super(props);
		this.toggleInput = this.toggleInput.bind(this);
	}
	
	toggleInput() {
		this.props.handleChangeCallback(!this.props.value);
	}
	
	render() {
		return(
			<div className='ToggleSwitch'>
				{this.props.toggleLabel && <p className='ToggleSwitch-title'>{this.props.toggleLabel}</p>}
				<label className='switch'>
					<input type='checkbox' checked={this.props.value} onChange={this.toggleInput}/>
					<span className='slider-toggle'></span>
				</label>
			</div>
		);
	}
}

ToggleSwitch.propTypes = {
	handleChangeCallback: PropTypes.func.isRequired,
	value: PropTypes.bool,
	toggleLabel: PropTypes.string
};

ToggleSwitch.defaultProps = {
	value: false
};

export default ToggleSwitch;
