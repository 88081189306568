/*
FormsAndDownloads - A Forms and Downloads Page contains lnks to download forms and other information
*/

import React from 'react';

import { xml_fetch_info } from '../../react-utils/src/libajax.js';
import { parse_cookies } from '../../react-utils/src/libformat';
import { ERROR_RETRIEVING_FILES, SESSION_ID, TRY_AGAIN_LATER, FILTER_DEPARTMENT} from '../../constants';
import {processError} from '../../projlibs/cookie-management';
import { getFormatFromMimeType, getImageBasedOnMimeType, date_to_str } from '../../lib/libformat';
import { openSignedURLFile } from '../../lib/libdownloadFiles';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

import Filters from '../Filters';
import FilteredComponent from '../FilteredComponent.jsx';
import S3Image from '../S3Image.jsx';
import { PageController } from '../PageController';
import { LoadingIndicator } from '../LoadingIndicator';
import { FlexTableController } from '../../shared-components/src/components/Shared/FlexTableController';
import { table_enums, updateSorting } from '../../projlibs/pagination-functions.js';

const headerInformationForFormsAndDownloads = [
	{'title':'Title','field':'title'},
	{'title':'Date','field':'date'},
	{'title':'Format','field':'format'},
	// {'title':'Tags','field':'tags','sortable':false},
	{'title':'','field':'actionIcon','onClick':'actionEvent','sortable':false}
];

class FormsAndDownloads extends FilteredComponent {
	constructor(props) {
		super(props);

		this.state = {
			filters:{
				[FILTER_DEPARTMENT]:'',
				tags:'',
			},
			fileInfo: {},
			numPages:1,
			currentPage: 0,
			isLoading:true,
			order_by:'&order_by=title&order_dir=asc',
		};
		this.renderImageLoader=this.renderImageLoader.bind(this);
		this.getFilteredData=this.getFilteredData.bind(this);
		this.netCallSuccess=this.netCallSuccess.bind(this);
		this.netCallFailure=this.netCallFailure.bind(this);
	}
	
	checkForDepartmentProps(item){
		if(item.location.department){
			return item.location.department;
		}
		
		return false;
	}

	UNSAFE_componentWillReceiveProps(newProps){
		if(this.checkForDepartmentProps(this.props)!==this.checkForDepartmentProps(newProps)){
			let filters=this.state.filters;
			if(this.checkForDepartmentProps(newProps)) {
				filters[FILTER_DEPARTMENT]=this.checkForDepartmentProps(newProps);
				this.setState({
					filters:filters,
				},() => {
					this.getFilteredData();
				});
			} else {
				filters[FILTER_DEPARTMENT]='';
				this.setState({
					filters:filters,
				},() => {
					this.getFilteredData();
				});
			}
		}
	}

	renderImageLoader(fileItem) {
		return (<>
			<S3Image additionalClasses={`${fileItem.img_s3_path ? '' : 'default-backup-image' }`} s3_path={fileItem.img_s3_path} placeholderImageURL={getImageBasedOnMimeType(fileItem.mime_type)} altText={fileItem.title} />
			{fileItem.title}
		</>);
	}

	getFilteredData() {
		const tag=this.state.filters.tags;
		const department=this.state.filters[FILTER_DEPARTMENT];

		const cookies = parse_cookies();
		let url_path='/search/?table=Post&type=file&limit=1000';

		if(department!==''&&department!==undefined){
			url_path+=`&department=${encodeURIComponent(department)}`;
		}

		if(tag!==''&&tag!==undefined){
			url_path+=`&tag={${tag}}`;
		}
		url_path+=`&page=${this.state.currentPage}&page_size=10&${this.state.order_by}`;
		// then we add landing page filtering
		if(this.props.match.params.fileDept){
			url_path+=`&landing_page=${encodeURIComponent(this.props.match.params.fileDept)}`;
		}
		this.setState({isLoading:true},()=>{
			xml_fetch_info(url_path,
				this.netCallSuccess,
				{
					'Content-Type': 'application/json',
					Authorization: cookies[SESSION_ID]
				},
				this.netCallFailure
			);
		});
	}

	netCallSuccess(xhr){
		this.setState({isLoading:false});
		const response = JSON.parse(xhr.response);
		if(response.hasOwnProperty('File')){
			// const sortedResponse = response.File.sort((a,b) => (a['modified_at']<b['modified_at'])?1:((a['modified_at']>b['modified_at'])?-1:0));
			
			let filesInfo=[];
			for(let idx=0;idx<response.File.length;idx++){
				let fileItem=response.File[idx];
				if(fileItem.is_valid===false){
					continue;
				}
			
				if(fileItem.s3_path===null || fileItem.s3_path===undefined || fileItem.s3_path==='') {
					continue;
				}
			
				if(fileItem.tags===null){
					fileItem.tags=[];
				}
				
				filesInfo.push(
					{
						'title': this.renderImageLoader(fileItem),
						'date': moment.unix(fileItem.published_at).format('YYYY-MM-DD'),
						'format': getFormatFromMimeType(fileItem.mime_type),
						'tags': fileItem.tags,
						'actionIcon': 'fas fa-arrow-down',
						'actionEvent':() => this.downloadFile(fileItem.s3_path,fileItem.title),
						'cssClasses':{'title':'title title-square-thumbnail','tags':'tag-array','actionIcon':'action-button no-circle-icon circle-border','date':'iso-date'}
					}
				);
			}
			
			this.setState({
				numPages: response.page_count, fileInfo: {'headerData': headerInformationForFormsAndDownloads, 'rows': filesInfo}
			});
		} else {
			//if no results were found then there's nothing to display
			this.setState({
				numPages: response.page_count, fileInfo:{'headerData': headerInformationForFormsAndDownloads, 'rows': []},
			});
		}
	}

	netCallFailure(error){
		this.setState({isLoading:false});
		if(processError(error)){
			return false;
		}else{
			toast.error(ERROR_RETRIEVING_FILES + ' ' + TRY_AGAIN_LATER);
		}
	}

	componentDidMount() {
		if(this.checkForDepartmentProps(this.props)) {
			let filters=this.state.filters;
			filters[FILTER_DEPARTMENT]=this.checkForDepartmentProps(this.props);
			this.setState({
				filters:filters,
			},() => {
				this.getFilteredData();
			});
		} else {
			this.getFilteredData();
		}
	}

	componentDidUpdate(prevProps, prevState){
		if(prevState.currentPage !== this.state.currentPage || prevState.order_by !== this.state.order_by){
			this.getFilteredData();
		}
		if(prevProps.match.params.fileDept !== this.props.match.params.fileDept){
			this.resetFilterData();
		}
	}

	downloadFile(url,postTitle) {
		openSignedURLFile(url,postTitle);
	}

	filterData=(value,index)=>{
		let filters = this.state.filters;
		filters[index]=value;
		this.setState({currentPage:0,filters}, ()=>this.getFilteredData());
	}

	resetFilterData = () => {
		this.setState({
			currentPage:0,filters:{
				[FILTER_DEPARTMENT]:'',
				tags:'',
				currentPage:0
			}
		},()=>this.getFilteredData());
	};

	render() {
		let filterOptions=null;
		if(this.props.tags && this.props.departments){
			filterOptions={tags: this.genFilterOptions(this.props.tags, 'Tag'), departments: this.genFilterOptions(this.props.departments, 'Department')};
		}
		
		if(!filterOptions){
			filterOptions=[];
		}
		
		return(
			<div className={'FormsAndDownloads'}>
				<Filters
					filterValues={[
						this.state.filters['tags'],
						this.state.filters[FILTER_DEPARTMENT],
					]}
					filterChangeCallback={this.filterData}
					filters={[
						{
							title:'Tag Filter',
							filterName:'tags',
							filterOptions:filterOptions.tags,
						},
						{
							title: 'Department Filter',
							filterName:FILTER_DEPARTMENT,
							filterOptions:filterOptions.departments,
						}
					]}
					resetCallbackHandler={this.resetFilterData}
				/>
				<FlexTableController hideSortIcon={true} tableData={SetupTableData(this.state.fileInfo)} sort={(e, title) => updateSorting(e, title, this, table_enums.files)} />
				<>{this.state.isLoading &&<LoadingIndicator isLoading={this.state.isLoading}/>}</>
				
				<PageController pageNumber={this.state.currentPage} setPageNumber={(page)=>{
					this.setState({currentPage:page});
				}} numPages={this.state.numPages} />
			</div> 
		);
	}
}

export default FormsAndDownloads;

function SetupTableData(fileInfo, download){
	if(fileInfo.rows){
		let titles = ['Title', 'Date', 'Format', ''];
		let rows = fileInfo.rows.map( (item, index)=>{
			let date=new Date(item.date);
			let formatted_date=<time dateTime={date.toISOString()}>{date_to_str(date)}</time>;
			return [
                {
                    sortValue: item.title,
                    element: item.title,
					tdClass:'name title title-square-thumbnail'
                },
				{
                    sortValue: item.date,
                    element: formatted_date,
					tdClass: 'item iso-date'
                },
				{
                    sortValue: item.format,
                    element: item.format
                },
				{
					tdClass: 'item table-action-item action-button no-circle-icon circle-border',
					element:(
						<button onClick={item.actionEvent}><i className='fas fa-arrow-down'/></button>
					)
				}
			];
		});
		return {titles:titles, rows:rows};
	}
}

