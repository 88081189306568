/*
FlyoutMenu - A drawer style menu


*/

import React from 'react';
import PropTypes from 'prop-types';

/*
props (component-level arguments):
	child: A default prop that is used to allow any code to be inserted into the FlyoutMenu
	buttonText: The text of the button to open the menu
	cancelIcon: The (Font Awesome) icon that is used on in the cancel/close bar, default is the x icon
	haveOpenButton: Notes whether the menu requires a open button, ususaly used for making a new instance of the data that the table is rendering
*/
const FLYOUT_OPEN_CLASS='flyout-menu-is-open';
class FlyoutMenu extends React.Component {
	
	componentDidUpdate(prevProps){
		let body=document.getElementsByTagName('body')[0];
		if(!prevProps.menuOpen && this.props.menuOpen){
			body.classList.add(FLYOUT_OPEN_CLASS);
		}else if(prevProps.menuOpen && !this.props.menuOpen){
			body.classList.remove(FLYOUT_OPEN_CLASS);
		}
	}
	render() {
		return(
			<div id={this.props.id} className={'FlyoutMenu '+(this.props.menuOpen?'is-open':'is-closed')}>
				{
					this.props.haveOpenButton && <button className='button button-small button-icon new-item-button' onClick={() => this.props.openMenu()} dangerouslySetInnerHTML={{__html: this.props.buttonText}}></button>
				}

				<div className={`menu ${this.props.menuOpen ? 'open' : 'closed'}`}>
					<button className='button cancel-button' onClick={() => this.props.closeMenu()}>
						<span className={`fa ${this.props.cancelIcon}`}></span> {this.props.closeButtonText ? this.props.closeButtonText : 'Cancel'}
					</button>
					<div className='flyout-content'>
						<div className='grid-x grid-padding-x grid-padding-y'>
							<div className='cell small-12'>
								{this.props.children}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

FlyoutMenu.propTypes = {
	buttonText: PropTypes.string,
	haveOpenButton: PropTypes.bool,
};

FlyoutMenu.defaultProps = {
	buttonText: 'Open Menu',
	id: 'flyoutID',
	cancelIcon: 'fa-times',
	haveOpenButton: false
};

export default FlyoutMenu;

