/*
InputRadio, a radio button that takes user input

An InputRadio item is a base level component and has no subcomponents
*/

import React from 'react';
import PropTypes from 'prop-types';

/*
props (component level arguments):
	value: the value of the radio button
	desc: the description of the radio button
	radioName: the name of the radio button
	callback: The onchange event handler
*/

export class InputRadio extends React.Component {

	render() {
		return (
			<div className="InputFormOptionContainer">
                <input type="radio"
					onClick={this.props.onClick?this.props.onClick:null}
					disabled={this.props.disabled}
                    id={`radio_${this.props.radioName}_${this.props.value}_${this.props.radioId}`}
					checked={this.props.value}
                    name={this.props.radioName}
                    onChange={this.props.callback}
                />
				<label htmlFor={`radio_${this.props.radioName}_${this.props.value}_${this.props.radioId}`}><span>{this.props.desc}</span></label>
			</div>
		);
	}
}

InputRadio.propTypes = {
	value: PropTypes.bool.isRequired,
	desc: PropTypes.string.isRequired,
	radioId: PropTypes.string.isRequired,
	radioName: PropTypes.string.isRequired,
	callback: PropTypes.func
};

export default InputRadio;
