/*
App.js is the main entry point for React

The highest top-level layout is done in this file.

For debugging and review purposes, this currently contains a "demo" display and doesn't contain real data yet.

*/

import React from 'react';
//eslint-disable-next-line no-unused-vars
import {BrowserRouter as Router, Route, Link, withRouter, Switch} from 'react-router-dom'; // Even tho Router is not actually used a tag, it is needed for the component to show up.
import {ToastContainer} from 'react-toastify';
import './styles.css';
import { xml_fetch_info } from './react-utils/src/libajax.js';
import { parse_cookies } from './react-utils/src/libformat';
import {processError, hasSession} from './projlibs/cookie-management';

import {IS_ADMIN, PATH_NEWS, PATH_EVENTS, PATH_DOWNLOADS,
	PATH_EMPLOYEE_DIRECTORY, DEPARTMENT, LOCATION_NAME, PATH_CHAT, NAV_NEWS, NAV_DOWNLOADS, NAV_EVENTS,
	NAV_ADMIN, NAV_COMPANY_DIR, NAV_LOGIN, PATH_ADMIN_DIR_MANAGER_EMPLOYEE,
	PATH_ADMIN_DIR_MANAGER_LOCATION, PATH_EMPLOYEE_SINGLE_DIRECTORY, MS_PER_SECOND, SECONDS_PER_MINUTE,
	MINUTES_PER_HOUR, HOURS_PER_DAY, ERROR_RETRIEVING_EVENTS, ERROR_RETRIEVING_POSITIONS,
	TRY_AGAIN_LATER, SESSION_ID, PATH_ADMIN_DIR_MANAGER_COMPANY, PATH_ADMIN_NEWS_MANAGER, PATH_ADMIN_EVENTS_MANAGER, PATH_ADMIN_FILE_MANAGER,
	PATH_DOWNLOADS_ACCOUNTING, PATH_DOWNLOADS_COMMUNICATIONS,PATH_DOWNLOADS_IT, POST_TYPE_EVENTS, POST_TYPE_FILES, PATH_DOWNLOADS_HR,
	POST_TYPE_NEWS, NAV_QUICK_LINK, PATH_ADMIN_QUICK_LINK_MANAGER, PATH_ADMIN_DIR_MANAGER_EMPLOYEE_REGEX, PATH_ADMIN_DIR_MANAGER_LOCATION_REGEX, PATH_ADMIN_DIR_MANAGER_COMPANY_REGEX,
	PATH_ADMIN, PATH_ADMIN_FORM_MANAGER, NAV_FORMS, PATH_FORMS, USER_IMAGE, PATH_DOWNLOADS_TRAINING_DEVELOPMENT, NAV_CLIPS_CALCULATOR} from './constants.js';

import ThemeableContainer from './components/ThemeableContainer';

import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CurrentDate from './components/CurrentDate';
import Calendar from './components/Calendar/Calendar';
import Dashboard from './components/Pages/Dashboard';
import Events from './components/Pages/Events';
import News from './components/Pages/News';
import Login from './components/Pages/Login';
import NewsPost from './components/Pages/NewsPost';
import EventPost from './components/Pages/EventPost';
import FormsAndDownloads from './components/Pages/FormsAndDownloads';
import Forms from './components/Pages/FormTaker/Forms';
import CompanyDirectory from './components/Pages/CompanyDirectory';
import QuickLink from './components/Pages/QuickLink';
import Greeting from './components/Greeting';
import MainLogo from './components/MainLogo';
import GlobalSearch from './components/GlobalSearch';
import {UpcomingEvents} from './components/UpcomingEvents';
import ProfileSettingsBar from './components/ProfileSettingsBar';

import AdminNewsManager from './components/Admin/Pages/AdminNewsManager';
import AdminEventManager from './components/Admin/Pages/AdminEventManager';
import AdminFileManager from './components/Admin/Pages/AdminFileManager';
import AdminEmployeeManager from './components/Admin/Pages/AdminEmployeeManager';
import AdminLocationManager from './components/Admin/Pages/AdminLocationManager';
import AdminCompanyManager from './components/Admin/Pages/AdminCompanyManager';
import FormManager from './components/Admin/Pages/Forms/FormManager';
import AdminQuickLinkManager from './components/Admin/Pages/AdminQuickLinkManager';
import {getTagsForPostType} from './lib/networking/tagnetworking';
import {getCatsForPostType} from './lib/networking/categorynetworking';
import {getEvents} from './lib/networking/eventnetworking';
import {getDepartments} from './lib/networking/departmentnetworking';
import ToggleSwitch from './components/ToggleSwitch';
import {
    NAV_RESET,
    RESET_PASS_ENDPOINT,
    PATH_DOWNLOADS_HEALTH_DEVELOPMENT,
    PATH_LOCATION_SINGLE_DIRECTORY,
    PATH_COMPANY_SINGLE_DIRECTORY
} from './constants';
//eslint-disable-next-line
import HerdRAAWSUtils from './projlibs/HerdRAAWSUtils';
import ClipsCalculator from './components/Pages/ClipsCalculator';

const SIDEBAR_OPEN_CLASS='sidebar-is-open';

class App extends ThemeableContainer {
	constructor(props) {
		super(props);
		this.state = {
			components: {},
			selectedNavItem: this.props.location.pathname,
			userInfo: null,
			searchPlaceholderText: 'news, events, and files',
			eventsInfo: [],
			newsCatsFromServer: [],
			newsTagsFromServer: [],
			eventCatsFromServer: [],
			eventTagsFromServer: [],
			fileTagsFromServer: [],
			departmentsFromServer: [],
			industriesFromServer: [],
			positionsFromServer: [],
			allEvents: [],
			citiesFromServer: [],
			employeeCitiesFromServer: [],
			statusFromServer: [],
			formatsFromServer: [],
			searchInFocus: false,
			directoryManagerMenuToggle: false,
			toggleDepartmentMenus: [],
			s3_profile:null,
			pageClass: '',
			isAdminView: (window.location.pathname.indexOf('/admin')===0),
			adminUpdate:false
		};

		this.setUserInfo=this.setUserInfo.bind(this);
		this.handleSearchFocus=this.handleSearchFocus.bind(this);
		this.toggleDirectoryManagerMenu=this.toggleDirectoryManagerMenu.bind(this);
		this.formatEventsForCal=this.formatEventsForCal.bind(this);
		this.getTagCatInfo=this.getTagCatInfo.bind(this);
		this.renderUserDepartmentNav=this.renderUserDepartmentNav.bind(this);
		this.toggleAdminView=this.toggleAdminView.bind(this);
		this.appInitNetworkRequests=this.appInitNetworkRequests.bind(this);

		if(this.props.location.pathname !== `/${NAV_LOGIN}` && window.location.pathname !== '/'+RESET_PASS_ENDPOINT) {
			hasSession();
		}
	}

	appInitNetworkRequests(){
		let cookies=parse_cookies();
		this.getTagCatInfo();

		xml_fetch_info('/positions/',
			xhr => {
				const response = JSON.parse(xhr.response);
				let posInfo = response.Position;

				this.setState({
					positionsFromServer:posInfo
				});
			},
			{
				'Content-Type': 'application/json',
				Authorization: cookies[SESSION_ID]
			},
			function (error) {
				if(processError(error)){
					return false;
				}else{
					toast.error(ERROR_RETRIEVING_POSITIONS + ' ' + TRY_AGAIN_LATER);
				}
			}
		);

		getDepartments(xhr => {
			this.setState({departmentsFromServer: JSON.parse(xhr.response)['Department']});
		});

		getEvents(xhr => {
			const response = JSON.parse(xhr.response);
			let eventsInfo = [];
			let allEvents = [];
			allEvents = response.Event;
			allEvents = allEvents.filter(item=>item.is_valid===true);
			const eventSorted = response.Event.sort((a,b) => (a['start']>b['start'])?1:((a['start']<b['start'])?-1:0));
			let valids=0;
			for(let idx=0;idx<eventSorted.length;idx++){
				let eventItem=eventSorted[idx];
				if(eventItem.is_valid===false){

					continue;
				}
				if(eventItem.start < Math.floor(Date.now() / 1000)) {
					continue;
				}
				if(eventItem.category===null){
					eventItem.category='';
				}
				if(eventItem.tags===null){
					eventItem.tags=[];
				}
				valids+=1;
				eventsInfo.push(eventItem);
				if(valids>=3){
					break;
				}
			}
			this.setState({ eventsInfo, allEvents });
		},null, {'limit':1000});

		xml_fetch_info('/directory-filters/',
			xhr => {
				const response = JSON.parse(xhr.response);
				const cityInfo = response.City;
				const industryInfo = response.Industry;
				const statusInfo = response.Status;
				const employeeCityInfo = response.Employee_City;
				this.setState({
					citiesFromServer: cityInfo,
					industriesFromServer: industryInfo,
					statusFromServer: statusInfo,
					employeeCitiesFromServer: employeeCityInfo,
				});
			},
			{
				'Content-Type': 'application/json',
				Authorization: cookies[SESSION_ID]
			},
			function (error) {
				if(processError(error)) {
					return false;
				} else {
					toast.error(ERROR_RETRIEVING_EVENTS + ' ' + TRY_AGAIN_LATER);
				}
			}
		);
	}

	async componentDidMount(){
		this.setSearchParams();
		await this.loadThemeStyles();

		await this.loadThemeComponent('Pages/Dashboard');
		await this.loadThemeComponent('Pages/Events');
		await this.loadThemeComponent('Pages/EventPost');
		await this.loadThemeComponent('Pages/News');
		await this.loadThemeComponent('Pages/NewsPost');
		await this.loadThemeComponent('Pages/FormsAndDownloads');
		await this.loadThemeComponent('Pages/CompanyDirectory');
		await this.loadThemeComponent('Pages/ClipsCalculator');
		await this.loadThemeComponent('Pages/Login');

		window.addEventListener('resize', this.closeSidebar);

		const cookies = parse_cookies();

		// if the user is already logged in
		if(cookies.hasOwnProperty(SESSION_ID)){
			this.appInitNetworkRequests();
			this.aws = new HerdRAAWSUtils();
			this.setState({s3_profile: cookies[USER_IMAGE]});

			// log the user data event in google analytics
			let ga_user_data={
				'event':'userData',
				'dept':cookies[DEPARTMENT],
				'branchName':cookies[LOCATION_NAME],
			};
			window.dataLayer.push(ga_user_data);
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			this.setState({
				selectedNavItem: this.props.location.pathname,
				isAdminView: (window.location.pathname.indexOf('/admin')===0)
			}, () => {
				this.setSearchParams();
			});
			this.closeSidebar();
		}
	}

	setSearchParams() {
		let cur_nav=this.state.selectedNavItem;
		if(this.state.selectedNavItem.match(PATH_NEWS)) {
			this.setState({searchPlaceholderText: 'news', pageClass: ''});
		} else if(cur_nav.match(PATH_DOWNLOADS_HR)){
			this.setState({searchPlaceholderText: 'HR files and downloads', pageClass: ''});
		}else if(cur_nav.match(PATH_DOWNLOADS_HEALTH_DEVELOPMENT)){
			this.setState({searchPlaceholderText: 'Health and Safety files & downloads', pageClass: ''});
		}else if(cur_nav.match(PATH_DOWNLOADS_TRAINING_DEVELOPMENT)){
			this.setState({searchPlaceholderText: 'Training and Development files & downloads', pageClass: ''});
		}else if(cur_nav.match(PATH_DOWNLOADS_ACCOUNTING)){
			this.setState({searchPlaceholderText: 'Accounting files & downloads', pageClass: ''});
		}else if(cur_nav.match(PATH_DOWNLOADS_COMMUNICATIONS)){
			this.setState({searchPlaceholderText: 'Communications files & downloads', pageClass: ''});
		}else if(cur_nav.match(PATH_DOWNLOADS_IT)){
			this.setState({searchPlaceholderText: 'IT files & downloads', pageClass: ''});
		}else if(this.state.selectedNavItem.match(PATH_EVENTS)) {
			this.setState({searchPlaceholderText: 'events & happenings', pageClass: ''});
		} else if(this.state.selectedNavItem.match(PATH_DOWNLOADS)) {
			this.setState({searchPlaceholderText: 'files & downloads', pageClass: ''});
		} else if(this.state.selectedNavItem.match(PATH_EMPLOYEE_SINGLE_DIRECTORY)) {
			this.setState({searchPlaceholderText: 'employees', pageClass: ''});
		} else if(this.state.selectedNavItem.match(PATH_LOCATION_SINGLE_DIRECTORY)) {
			this.setState({searchPlaceholderText: 'branch locations', pageClass: ''});
		} else if(this.state.selectedNavItem.match(PATH_COMPANY_SINGLE_DIRECTORY)) {
			this.setState({searchPlaceholderText: 'companies', pageClass: ''});
		} else if(this.state.selectedNavItem === PATH_CHAT) {
			this.setState({searchPlaceholderText: 'chat', pageClass: 'chat-page-cell'});
		} else {
			this.setState({searchPlaceholderText: 'news, events, and files', pageClass: ''});
		}
	}

	toggleSidebar() {
		let sidebar=document.getElementById('sidebar');
		if(sidebar!==null){
			sidebar.classList.toggle('open');
		}
		document.getElementsByTagName('body')[0].classList.toggle(SIDEBAR_OPEN_CLASS);
	}

	closeSidebar() {
		let sidebar=document.getElementById('sidebar');
		if(sidebar!==null){
			sidebar.classList.remove('open');
		}
		document.getElementsByTagName('body')[0].classList.remove(SIDEBAR_OPEN_CLASS);
	}

	getTagCatInfo() {
		getTagsForPostType(POST_TYPE_NEWS, (res) => {this.setState({newsTagsFromServer: JSON.parse(res.response).Tag.filter(tag=>tag!=='')});});
		getCatsForPostType(POST_TYPE_NEWS, (res) => {this.setState({newsCatsFromServer: JSON.parse(res.response).Category.filter(category=>category!=='')});});

		getTagsForPostType(POST_TYPE_EVENTS, (res) => {this.setState({eventTagsFromServer: JSON.parse(res.response).Tag.filter(tag=>tag!=='')});});
		getCatsForPostType(POST_TYPE_EVENTS, (res) => {this.setState({eventCatsFromServer: JSON.parse(res.response).Category.filter(category=>category!=='')});});

		getTagsForPostType(POST_TYPE_FILES, (res) => {this.setState({fileTagsFromServer: JSON.parse(res.response).Tag.filter(tag=>tag!=='')});});
		getCatsForPostType(POST_TYPE_FILES, (res) => {this.setState({fileCatsFromServer: JSON.parse(res.response).Category.filter(category=>category!=='')});});
	}

	handleSearchFocus(focusState) {
		this.setState({
			searchInFocus: focusState
		});
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.closeSidebar);
	}

	setUserInfo(userInfo){
		let cookies=parse_cookies();
		this.setState({
			userInfo:userInfo,s3_profile: cookies[USER_IMAGE]
		});
		this.appInitNetworkRequests();
		this.aws = new HerdRAAWSUtils();
	}

	formatEventsForCal(){
		let events={};
		const events_info=this.state.allEvents;
		for(let ev_idx=0;ev_idx<events_info.length;ev_idx++){
			let ev_start=(new Date(events_info[ev_idx].start*MS_PER_SECOND));
			let ev_end=(new Date(events_info[ev_idx].end*MS_PER_SECOND));
			let ev_date=ev_start;
			while(ev_date<=ev_end){
				let date_key=(ev_date.getFullYear()+'-'+((ev_date.getMonth()+1)+'').padStart(2,'0')+'-'+((ev_date.getDate()+'')).padStart(2,'0'));
				if(!events.hasOwnProperty(date_key)){
					events[date_key]=[];
				}
				events[date_key].push(events_info[ev_idx]);

				ev_date=new Date(ev_date.getTime()+(MS_PER_SECOND*SECONDS_PER_MINUTE*MINUTES_PER_HOUR*HOURS_PER_DAY));
			}
		}
		return events;
	}

	renderUserDepartmentNav(userDepartments) {
		let departmentNav=[];
		for(let usrDep = 0; usrDep<userDepartments.length;usrDep++) {
			const deptActiveClass=(userDepartments[usrDep] && this.props.location.department === userDepartments[usrDep]) ? 'is-active' : '';
			departmentNav.push(<div key={usrDep} className='group-nav'>
				{<button onClick={()=>this.toggleDepartmentMenus(userDepartments[usrDep])} className={'nav-item nav-toggle'}>{userDepartments[usrDep]} <span className='sub-menu-toggle'><i className='fas fa-caret-down'></i></span></button>}
				<ul className={`sub-menu ${this.state.toggleDepartmentMenus[userDepartments[usrDep]] ? 'menu-open' : ''}`}>
					<li><Link className={`nav-item ${(this.state.selectedNavItem === '/' ? deptActiveClass : '')}`} to={ {pathname: '/', department:userDepartments[usrDep]} } >Dashboard</Link></li>

					<li><Link className={`nav-item ${(this.state.selectedNavItem.match(PATH_NEWS) ? deptActiveClass : '')}`} to={ {pathname: `/${NAV_NEWS}`, department: userDepartments[usrDep]} }>News</Link></li>
					<li><Link className={`nav-item ${(this.state.selectedNavItem.match(PATH_DOWNLOADS) ? deptActiveClass : '')}`} to={ {pathname: `/${NAV_DOWNLOADS}`, department: userDepartments[usrDep]} }>Files & Downloads</Link></li>

					<li><Link className={`nav-item ${(this.state.selectedNavItem.match(PATH_EMPLOYEE_DIRECTORY) ? deptActiveClass : '')}`} to={ {pathname: `${PATH_EMPLOYEE_DIRECTORY}`, department: userDepartments[usrDep]} }>Company Directory</Link></li>
				</ul>
			</div>);
		}
		return departmentNav;
	}

	toggleDirectoryManagerMenu() {
		this.setState({
			directoryManagerMenuToggle: !this.state.directoryManagerMenuToggle
		});
	}

	toggleDepartmentMenus(dept) {
		let deptMenu = this.state.toggleDepartmentMenus;
		deptMenu[dept] = deptMenu.hasOwnProperty(dept) ? !deptMenu[dept] : true;
		this.setState({
			toggleDepartmentMenus: deptMenu
		});
	}

	toggleAdminView(val) {
		let path = this.props.location.pathname.split('/').filter(a => a !== '');
		if(val) {
			// if downloads in url then we want to just replace the path with admin/downloads
			// this is because there are non admin views that filter based off a third item
			// ex HR, Accounting etc..
			if(path.includes('downloads')){
				path=['admin','downloads'];
			}else{
				//not in downloads page so just add admin to the front of path
				path.unshift('admin');
			}
		} else {
			path.shift();
		}
		this.setState({adminUpdate:!this.state.adminUpdate}, ()=>{
			this.getTagCatInfo();
		});
		this.props.history.push('/'+path.join('/'));
	}

	render() {
		const ComponentDashboard=this.getThemeComponent('Pages/Dashboard',Dashboard);
		const ComponentEvents=this.getThemeComponent('Pages/Events',Events);
		const ComponentEventPost=this.getThemeComponent('Pages/EventPost',EventPost);
		const ComponentNews=this.getThemeComponent('Pages/News',News);
		const ComponentNewsPost=this.getThemeComponent('Pages/NewsPost',NewsPost);
		const ComponentClipsCalculator=this.getThemeComponent('Pages/ClipsCalculator', ClipsCalculator);
		const ComponentFormsAndDownloads=this.getThemeComponent('Pages/FormsAndDownloads',FormsAndDownloads);
		const ComponentCompanyDirectory=this.getThemeComponent('Pages/CompanyDirectory',CompanyDirectory);

		const ComponentLogin=this.getThemeComponent('Pages/Login',Login);

		const ComponentAdminDashboard=this.getThemeComponent('Admin/Pages/AdminDashboard',Dashboard);
		const ComponentAdminEventManager=this.getThemeComponent('Admin/Pages/AdminEventManager',AdminEventManager);
		const ComponentAdminFileManager=this.getThemeComponent('Admin/Pages/AdminFileManager',AdminFileManager);
		const ComponentAdminNewsManager=this.getThemeComponent('Admin/Pages/AdminNewsManager',AdminNewsManager);
		const ComponentAdminEmployeeManager=this.getThemeComponent('Admin/Pages/AdminEmployeeManager',AdminEmployeeManager);
		const ComponentAdminLocationManager=this.getThemeComponent('Admin/Pages/AdminLocationManager',AdminLocationManager);
		const ComponentAdminCompanyManager = this.getThemeComponent('Admin/Pages/AdminCompanyManager', AdminCompanyManager);
		const ComponentAdminFormManager=this.getThemeComponent('Forms/FormManager',FormManager);
		const ComponentAdminQuickLinkManager = this.getThemeComponent('Admin/Pages/AdminQuickLinkManager', AdminQuickLinkManager);

		const cookies=parse_cookies();
		const isAdminUser=(cookies.hasOwnProperty(IS_ADMIN) && cookies[IS_ADMIN]==='true');
		const hasDepartments=(cookies.hasOwnProperty(DEPARTMENT) && cookies[DEPARTMENT]!=='null');
		const userDepartments=cookies.hasOwnProperty(DEPARTMENT) ? cookies[DEPARTMENT].split(',') : null;
		const isSplashPage=this.props.location.pathname==='/login/' || this.props.location.pathname==='/login' || window.location.pathname === '/'+RESET_PASS_ENDPOINT;
		const isDashboard=(
			this.props.location.pathname==='/' ||
			this.props.location.pathname===`/${NAV_QUICK_LINK}` ||
			this.props.location.pathname.match(PATH_NEWS) ||
			this.props.location.pathname.match(PATH_EVENTS) ||
			this.props.location.pathname.match(NAV_CLIPS_CALCULATOR) ||
			(isAdminUser && this.state.selectedNavItem.match(PATH_ADMIN))
		);
		const isLoginScreen=((this.props.location.pathname === '/' + NAV_LOGIN) || this.props.location.pathname==='reset' || window.location.pathname === '/'+RESET_PASS_ENDPOINT);
		return (
			<div className="App">
				<ToastContainer />
				{(!isSplashPage) && <>
					<div className={'HeaderBar'}>
						<button type="button" className="sidebar-hamburger" onClick={() => this.toggleSidebar()}>
							<span className="show-for-sr">toggle navigation</span>
							<span className="sidebar-hamburger-icon"></span>
						</button>
						<ProfileSettingsBar profile_path={this.state.s3_profile}/>
					</div>
					<div className='overlay-background' onClick={() => this.closeSidebar()}></div>
					<aside id='sidebar' className={'side-bar'}>
						<div className='sidebar-wrapper'>
							<MainLogo />
							<Greeting userInfo={this.state.userInfo} />
							{(isAdminUser && this.state.isAdminView)?
								<ul className='navigation'>
									<li><Link className={`nav-item ${this.state.selectedNavItem.match(PATH_ADMIN) ? 'is-active' : ''}`} to={`/${NAV_ADMIN}`}>Administrator Dashboard</Link></li>
									<li>
										{this.state.directoryManagerMenuToggle && <Link className={'nav-item'} to={PATH_ADMIN_DIR_MANAGER_EMPLOYEE}>Directory Manager <button className='sub-menu-toggle' onClick={this.toggleDirectoryManagerMenu}><i className='fas fa-caret-down'></i></button></Link>}
										{!this.state.directoryManagerMenuToggle && <button onClick={this.toggleDirectoryManagerMenu} className={`nav-item ${this.state.selectedNavItem.match(/\/admin\/directory-manager\/(employees|locations|companies)/g) ? 'is-active' : ''}`}>Directory Manager <span className='sub-menu-toggle'><i className='fas fa-caret-down'></i></span></button>}
										<ul className={`sub-menu ${this.state.directoryManagerMenuToggle ? 'menu-open' : ''}`}>
											<li><Link className={`nav-item ${this.state.selectedNavItem.match(PATH_ADMIN_DIR_MANAGER_EMPLOYEE_REGEX) ? 'is-active' : ''}`} to={PATH_ADMIN_DIR_MANAGER_EMPLOYEE}>Employees</Link></li>
											<li><Link className={`nav-item ${this.state.selectedNavItem.match(PATH_ADMIN_DIR_MANAGER_LOCATION_REGEX) ? 'is-active' : ''}`} to={PATH_ADMIN_DIR_MANAGER_LOCATION}>Locations</Link></li>
											<li><Link className={`nav-item ${this.state.selectedNavItem.match(PATH_ADMIN_DIR_MANAGER_COMPANY_REGEX) ? 'is-active' : ''}`} to={PATH_ADMIN_DIR_MANAGER_COMPANY}>Companies</Link></li>
										</ul>
									</li>

									<li><Link className={`nav-item ${this.state.selectedNavItem.match(PATH_ADMIN_NEWS_MANAGER) ? 'is-active' : ''}`} to={`/${NAV_ADMIN}/${NAV_NEWS}/`}>News Manager</Link></li>
									<li><Link className={`nav-item ${this.state.selectedNavItem.match(PATH_ADMIN_EVENTS_MANAGER) ? 'is-active' : ''}`} to={`/${NAV_ADMIN}/${NAV_EVENTS}/`}>Event Manager</Link></li>
									<li><Link className={`nav-item ${this.state.selectedNavItem.match(PATH_ADMIN_FILE_MANAGER) ? 'is-active' : ''}`} to={`/${NAV_ADMIN}/${NAV_DOWNLOADS}`}>File Manager</Link></li>
									<li><Link className={`nav-item ${this.state.selectedNavItem.match(PATH_ADMIN_FORM_MANAGER) ? 'is-active' : ''}`} to={`/${NAV_ADMIN}/${NAV_FORMS}`}>Form Manager</Link></li>
									<li><Link className={`nav-item ${this.state.selectedNavItem.match(PATH_ADMIN_QUICK_LINK_MANAGER) ? 'is-active' : ''}`} to={`${PATH_ADMIN_QUICK_LINK_MANAGER}`}>Quick Link Manager</Link></li>
									<li><Link className={`nav-item ${this.state.selectedNavItem === '/'+NAV_LOGIN ? 'is-active' : ''}`} to={`/${NAV_LOGIN}`}>Logout</Link></li>
								</ul>
							:
								<>
									<ul className='navigation'>
										<li><Link className={`nav-item ${this.state.selectedNavItem === '/' ? 'is-active' : ''}`} to='/'>Dashboard</Link></li>
										<li><Link className={`nav-item ${this.state.selectedNavItem.match(PATH_NEWS) ? 'is-active' : ''}`} to={`/${NAV_NEWS}/`}>News</Link></li>
										<li><Link className={`nav-item ${this.state.selectedNavItem.match(PATH_EVENTS) ? 'is-active' : ''}`} to={`/${NAV_EVENTS}/`}>Events & Happenings</Link></li>
										<li><Link className={`nav-item ${this.state.selectedNavItem.match(PATH_DOWNLOADS) ? 'is-active' : ''}`} to={`/${NAV_DOWNLOADS}/`}>Files & Downloads</Link></li>
										<li><Link className={`nav-item ${this.state.selectedNavItem.match(PATH_FORMS) ? 'is-active' : ''}`} to={`/${NAV_FORMS}/`}>Forms</Link></li>
										<li><Link className={`nav-item ${this.state.selectedNavItem.match(PATH_DOWNLOADS_HR) ? 'is-active' : ''}`} to={`${PATH_DOWNLOADS_HR}`}>HR</Link></li>
										<li><Link className={`nav-item ${this.state.selectedNavItem.match(PATH_DOWNLOADS_HEALTH_DEVELOPMENT) ? 'is-active' : ''}`} to={`${PATH_DOWNLOADS_HEALTH_DEVELOPMENT}`}>Health & Safety</Link></li>
										<li><Link className={`nav-item ${this.state.selectedNavItem.match(PATH_DOWNLOADS_TRAINING_DEVELOPMENT) ? 'is-active' : ''}`} to={`${PATH_DOWNLOADS_TRAINING_DEVELOPMENT}`}>Training & Development</Link></li>
										<li><Link className={`nav-item ${this.state.selectedNavItem.match(PATH_DOWNLOADS_ACCOUNTING) ? 'is-active' : ''}`} to={`${PATH_DOWNLOADS_ACCOUNTING}`}>Accounting</Link></li>
										<li><Link className={`nav-item ${this.state.selectedNavItem.match(PATH_DOWNLOADS_COMMUNICATIONS) ? 'is-active' : ''}`} to={`${PATH_DOWNLOADS_COMMUNICATIONS}`}>Communications</Link></li>
										<li><Link className={`nav-item ${this.state.selectedNavItem.match(PATH_DOWNLOADS_IT) ? 'is-active' : ''}`} to={`${PATH_DOWNLOADS_IT}`}>IT</Link></li>
										
										<li><Link className={`nav-item ${this.state.selectedNavItem.includes(NAV_COMPANY_DIR) ? 'is-active' : ''}`} to={`${PATH_EMPLOYEE_DIRECTORY}`}>Company Directory</Link></li>
										<li><Link className={`nav-item ${this.state.selectedNavItem.includes(NAV_CLIPS_CALCULATOR) ? 'is-active' : ''}`} to={`/${NAV_CLIPS_CALCULATOR}`}>CLIPS Date Calculator</Link></li>
										<li><Link className={`nav-item ${this.state.selectedNavItem === '/'+NAV_LOGIN+'/' ? 'is-active' : ''}`} to={`/${NAV_LOGIN}/`}>Logout</Link></li>
										{hasDepartments && <>
										<h2 className='departments-menu-title'>Departments</h2>
										{this.renderUserDepartmentNav(userDepartments)}
										</>}
									</ul>
								</>
							}
							<div className='cell shrink hide-for-large'>
								{isAdminUser && <ToggleSwitch value={this.state.isAdminView} toggleLabel='Admin Mode' handleChangeCallback={this.toggleAdminView} />}

							</div>

						</div>
					</aside>
				</>
				}
				<div className={`main-cell grid-x ${isLoginScreen ? 'login-screen' : isDashboard ? 'is-dashboard-page' : ''} ${this.state.isAdminView ? 'is-admin-page' : ''}`}>
					{!isSplashPage && (
						<div className={`cell small-12 top-bar-cell ${this.state.searchInFocus ? 'search-focus' : ''}`}>
							<div className='grid-x'>
								<div className="cell auto">
									<GlobalSearch {...this.props} selectedNavItem={this.state.selectedNavItem} searchParam={this.state.searchPlaceholderText} focusCallback={this.handleSearchFocus} />
								</div>
								{!this.state.searchInFocus && (
									<div className='cell shrink show-for-large navigation-bar-controllers'>
										{isAdminUser && <ToggleSwitch value={this.state.isAdminView} toggleLabel='Admin Mode' handleChangeCallback={this.toggleAdminView} />}
										<ProfileSettingsBar profile_path={this.state.s3_profile}/>
									</div>
								)}
							</div>
						</div>
					)}
					<div className="cell small-12">
						<div className="grid-x">
							<div className={`cell auto page-cell ${this.state.pageClass}`}>
								<Switch>
									<Route path='/' exact component={ComponentDashboard} />
									<Route path={`/${NAV_NEWS}`} exact render={(props) => <ComponentNews {...props} cats={this.state.newsCatsFromServer} tags={this.state.newsTagsFromServer} />} />
									<Route path={`/${NAV_NEWS}/:post_id?`} component={ComponentNewsPost} />
									<Route path={`/${NAV_EVENTS}`} exact render={(props) => <ComponentEvents {...props} cats={this.state.eventCatsFromServer} tags={this.state.eventTagsFromServer} />} />
									<Route path={`/${NAV_EVENTS}/:event_id`} component={ComponentEventPost} />
									<Route path={`/${NAV_DOWNLOADS}`} exact render={(props) => <ComponentFormsAndDownloads {...props} departments={this.state.departmentsFromServer} tags={this.state.fileTagsFromServer} />} />
									<Route path={`/${NAV_DOWNLOADS}/:fileDept/`} render={(props) => <ComponentFormsAndDownloads {...props} departments={this.state.departmentsFromServer} tags={this.state.fileTagsFromServer} />} />
									<Route path={`/${NAV_COMPANY_DIR}/:dirType(employee|location|company)/:directoryId?`} render={(props) => <ComponentCompanyDirectory {...props} departments={this.state.departmentsFromServer} positions={this.state.positionsFromServer} cities={this.state.citiesFromServer} industries={this.state.industriesFromServer} employeeCities={this.state.employeeCitiesFromServer} />} />
									<Route path={`/${NAV_LOGIN}`} exact render={(props) => <ComponentLogin {...props} setUserInfoCallback={this.setUserInfo} />} />
									<Route path={`/${NAV_RESET}`} render={(props) => <ComponentLogin {...props} setUserInfoCallback={this.setUserInfo} />} />
									<Route path={`/${NAV_FORMS}`} component={Forms} />
									<Route path={`/${NAV_QUICK_LINK}`} component={QuickLink} />
									<Route path={`/${NAV_CLIPS_CALCULATOR}`} exact component={ComponentClipsCalculator} />
									{isAdminUser && <Route path={`/${NAV_ADMIN}`} exact component={ComponentAdminDashboard} />}
									{isAdminUser && <Route path={`/${NAV_ADMIN}/${NAV_NEWS}/:post_id?`} component={ComponentAdminNewsManager} />}
									{isAdminUser && <Route path={`/${NAV_ADMIN}/${NAV_EVENTS}/:event_id?`} component={ComponentAdminEventManager} />}
									{isAdminUser && <Route path={`/${NAV_ADMIN}/${NAV_DOWNLOADS}/:file_id?`} component={ComponentAdminFileManager} />}
									{isAdminUser && <Route path={`/${NAV_ADMIN}/${NAV_FORMS}/:formId?`} component={ComponentAdminFormManager} />}

									{isAdminUser && (
										<Route
											path={`${PATH_ADMIN_DIR_MANAGER_EMPLOYEE}`}
											exact
											render={props => <ComponentAdminEmployeeManager {...props} departments={this.state.departmentsFromServer} positions={this.state.positionsFromServer} />}
										/>
									)}

									{isAdminUser && (
										<Route
											path={`${PATH_ADMIN_DIR_MANAGER_EMPLOYEE}/:user_id?`}
											render={props => <ComponentAdminEmployeeManager {...props} departments={this.state.departmentsFromServer} positions={this.state.positionsFromServer} />}
										/>
									)}
									{/* {isAdminUser && <Route path={`${PATH_ADMIN_DIR_MANAGER_LOCATION}`} exact render={props => <ComponentAdminLocationManager {...props} />} />} */}
									{isAdminUser && <Route path={`${PATH_ADMIN_DIR_MANAGER_LOCATION}/:location_id?`} render={props => <ComponentAdminLocationManager {...props} />} />}
									{/* {isAdminUser && <Route path={`${PATH_ADMIN_DIR_MANAGER_COMPANY}`} exact render={props => <ComponentAdminCompanyManager {...props} />} />} */}
									{isAdminUser && <Route path={`${PATH_ADMIN_DIR_MANAGER_COMPANY}/:company_id?`} render={props => <ComponentAdminCompanyManager {...props} />} />}
									{isAdminUser && <Route path={`${PATH_ADMIN_QUICK_LINK_MANAGER}`} exact render={props => <ComponentAdminQuickLinkManager {...props} />} />}
								</Switch>
							</div>
							{isDashboard && (
								<div className="sidebar-dashboard cell shrink show-for-xxlarge">
									{/* add a profile heading display here that shows the icon for the logged in user and a setting cog that can open an options menu */}
									{/* add the calendar display iff (if and only if) this is a dashboard view */}
									<div className="dash-calendar-cont">
										<CurrentDate />
										<Calendar events={this.formatEventsForCal()} />
									</div>
									<UpcomingEvents eventsInfo={this.state.eventsInfo} />
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(props => <App {...props} />);
