/*
				 - a list of the next 3 upcoming events for the logged in user

A UpcomingEvents view consists of (from left to right and top to bottom)
	A title, directly in this component
		One or more BriefView components; one for each item

*/

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import BriefView from './BriefView/BriefView.jsx';
import HerdRAAWSUtils from '../projlibs/HerdRAAWSUtils';
const dummy_excerpt='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque et tellus vitae elit consectetur accumsan. Integer fermentum augue et massa dictum, eget ullamcorper ex feugiat. Integer eros ipsum, porta ac tempus ut, vehicula egestas turpis. Aenean sed maximus ipsum. Donec id egestas quam. Integer lectus sem, porttitor eu fermentum nec, mollis id magna. Curabitur libero eros, dapibus a facilisis in, euismod sed massa. ';

/*
props (component-level arguments):
	events_items: a list of events objects to be displayed as brief views
*/
class UpcomingEvents extends React.Component {
	constructor(props){
		super(props);
		this.aws = new HerdRAAWSUtils();
	}
	render() {
		return (
			<div className='UpcomingEvents'>
				<h2 className='UpcomingEvents-title'>Events & Happenings</h2>
				{this.props.eventsInfo.map( (object, idx)=> {
					return (
						<Link to={`/events/${object.event_id}`} key={idx}>
							<BriefView aws_object={this.aws} key={idx} object={object} display_type='upcoming_events' />
						</Link>
					);
				})}
				<div className='view-all-cont'>
					<Link to='/events' className='view-all-link'>View All</Link>
				</div>
			</div>
		);
	}
}

UpcomingEvents.defaultProps={
	eventsInfo:[],
};

UpcomingEvents.propTypes={
	eventsInfo:PropTypes.array,
};



export {UpcomingEvents, dummy_excerpt};

