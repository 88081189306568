/*
Dashboard - A Dashboard is the primary and default screen on the Intranet webiste. It contains links to and condensed versions of important information
*/

import React from 'react';

import { xml_fetch_info } from '../../react-utils/src/libajax.js';
import { parse_cookies } from '../../react-utils/src/libformat';
import {ERROR_RETRIEVING_NEWS, TRY_AGAIN_LATER, SESSION_ID, ERROR_RETRIEVING_LINKS} from '../../constants';
import {processError} from '../../projlibs/cookie-management';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {LatestNews} from '../LatestNews.jsx';
import ImageCarousel from '../ImageCarousel/ImageCarousel.jsx';
import QuickLinkBar from '../QuickLinkBar.jsx';
import config from '../../config';
import { setCarouselData, getEvents, getHeaders } from '../../projlibs/HelperNetworkCalls.js';
import { getRecentMessages } from '../../projlibs/RecentMessagesNetworking.js';
import HerdRAAWSUtils from '../../projlibs/HerdRAAWSUtils';
import FeaturedCompanies from '../FeaturedCompanies.jsx';
import {getStartofDayTimeStamp} from './Events';
import { sortLinks } from './QuickLink.jsx';
class Dashboard extends React.Component {
	constructor(props) {
		super(props);
		var carouselData = [];
		
		const downloadsData = [
			{title: 'Test Download 1', downloadLink: '#', downloadImageType: 'icon', image: 'https://image.flaticon.com/icons/svg/1697/1697389.svg'},
			{title: 'Test Download 2', downloadLink: '#', downloadImageType: 'image', image: 'https://picsum.photos/400/400'},
			{title: 'Test Download 3', downloadLink: '#', downloadImageType: 'video', image: 'https://picsum.photos/400/400'},
			{title: 'Test Download 4', downloadLink: '#', downloadImageType: 'icon', image: 'https://image.flaticon.com/icons/svg/1697/1697389.svg'},
		];

		this.aws = new HerdRAAWSUtils();

		this.state = {
			newsInfo: [],
			eventsInfo:[],
			carouselData:carouselData,
			downloadsData:downloadsData,
			quickLinkInfo: [],
			recentMessages:null,
			getRecentMessagesFailed:false
		};
		
		this.renderImageCarousel=this.renderImageCarousel.bind(this);
		this.fetchNews=this.fetchNews.bind(this);
	}

	renderQuickLinks() {
		return <QuickLinkBar links={this.state.quickLinkInfo} />;
	}

	componentDidUpdate(prevProps){
		if(prevProps.location.department !== this.props.location.department){
			this.fetchNews();
		}
	}

	componentDidMount() {
		let cookies=parse_cookies();
		getRecentMessages( (res)=> this.setState({recentMessages:res}), (err)=>this.setState({getRecentMessagesFailed:true}));
		if(cookies.hasOwnProperty(SESSION_ID)){
			this.fetchNews();
			fetchFeaturedPosts(this,'news');
			this.fetchQuickLinks();
		}
	}


	
	fetchNews() {
		let cookies = parse_cookies();
		let news_url = '/news/';
		if(this.props.location.department){
			news_url+=`?limit=1000&department=${this.props.location.department}`;
		}else{
			news_url = '/news?limit=1000';
		}
		xml_fetch_info(news_url,
			xhr => {
				let response = JSON.parse(xhr.response);
				let newsInfo=[];
				let valids=0;
				for(let idx=0;idx<response.Post.length;idx++){
					let newsItem=response.Post[idx];
					if(newsItem.is_valid===false){
						continue;
					}
					if(newsItem.category===null){
						newsItem.category='';
					}
					if(newsItem.tags===null){
						newsItem.tags=[];
					}
					if(newsItem.direct_file && newsItem.file_s3_path!==undefined && newsItem.file_s3_path.length>0){
						newsItem.getSignedUrlError=function(error){
							console.log(error);
						};
						newsItem.getSignedUrlSuccess=function(url){
							newsItem.signed_PDF_link=url;
						};
						this.aws.getSignedUrl(config.bucket_name,newsItem.file_s3_path[0],newsItem.getSignedUrlError,newsItem.getSignedUrlSuccess);
					}
					valids+=1;
					newsInfo.push(newsItem);
					newsInfo.getSignedUrlSuccess=function(url){
						newsInfo.carasoulBack=url;
					};
					if(valids>=5){
						break;
					}
				}
				this.setState({
					newsInfo,
				});
			},
			{
				'Content-Type': 'application/json',
				Authorization: cookies[SESSION_ID]
			},
			function (error) {
				if(processError(error)){
					return false;
				}else{
					toast.error(ERROR_RETRIEVING_NEWS + ' ' + TRY_AGAIN_LATER);
				}
			}
		);
	}

	setCarouselDataHandler=(carouselData)=>{
		this.setState({carouselData}, ()=>this.fetchEventsFeatured());// set state is async but has an optional second arg to run after it is done
	}

	fetchEventsFeatured(){
		getEvents(this.getEventsSuccessHandler, this.getEventsError, `min_time=${getStartofDayTimeStamp()}`);
	}

	getEventsSuccessHandler=(response)=>{
		setCarouselData(response,this.successSetEventsCarousel);
	}

	getEventsError=(error)=>{
		console.log(error);
	}

	successSetEventsCarousel=carouselData=>{
		carouselData=carouselData.sort( (a,b)=>{
			return a.published_at-b.published_at;
		});
		this.setState({eventsInfo:carouselData?carouselData[0]:carouselData},()=>this.mergeCarouselData());
	}
	
	fetchQuickLinks() {
		let cookies = parse_cookies();
		const max_unfeatured_links=5;
		xml_fetch_info('/link/',
			xhr => {
				let response = JSON.parse(xhr.response);
				let quickLinkInfo=[];
				let unFeaturedLinks=[];
				for(let idx=0;idx<response.Link.length;idx++){
					let quickLinkItem=response.Link[idx];
					if(quickLinkItem.is_valid === false){
						continue;
					}
					
					if(quickLinkItem.is_featured !== true){
						if(unFeaturedLinks.length<max_unfeatured_links){
							unFeaturedLinks.push(quickLinkItem);
						}
						continue;
					}

					quickLinkInfo.push(quickLinkItem);
				}
				if(quickLinkInfo.length===0){
					quickLinkInfo=unFeaturedLinks;
				}
				sortLinks(quickLinkInfo);
				this.setState({
					quickLinkInfo,
				});
			},
			{
				'Content-Type': 'application/json',
				Authorization: cookies[SESSION_ID]
			},
			function (error) {
				if(processError(error)){
					return false;
				}else{
					toast.error(ERROR_RETRIEVING_LINKS + ' ' + TRY_AGAIN_LATER);
				}
			}
		);
	}


	mergeCarouselData=()=>{
		let events = this.state.eventsInfo;
		let carouselData=this.state.carouselData;
		carouselData=carouselData.concat(events);
		let compare=(a,b)=>{
			if(a.published_at < b.published_at){// for events published_at is equal to start here
				return 1;
			} else if(a.published_at>b.published_at){
				return -1;
			}else{
				return 0;
			}
		};
		carouselData.sort(compare);
		this.setState({carouselData});

	}

	renderImageCarousel() {
		return <ImageCarousel dashboard={true} aws_object={this.aws} slideArray={this.state.carouselData} />;
	}

	render() {
		return(
			<div className={'Dashboard'}>
				<div className='grid-x'>
					<div className='cell small-12'>
						{this.renderImageCarousel()}
					</div>
				</div>
				<div className="grid-x">
					<div className='small-12 cell'>
						{this.renderQuickLinks()}
					</div>
					<div className='small-12 large-8 cell'>
						<LatestNews aws_object={this.aws} newsInfo={this.state.newsInfo} />
						{/* <DownloadContainer department={this.props.location.department} preTitle='New!' title='Forms &amp; Downloads' /> */}
					</div>
					<div className="small-12 large-4 cell">
						<div className='hide-for-large'>
							<br />
						</div>
						<FeaturedCompanies/>
					</div>
				</div>
			</div>
		);
	}
}

export default Dashboard;


export function fetchFeaturedPosts(component,base_url='news'){
	let url = '';
	if(component.props.location.department){
		url=`/${base_url}/?limit=1000&department=${component.props.location.department}&featured=true`;
	}else{
		url = `/${base_url}?limit=1000&featured=true`;
	}
	xml_fetch_info(url,xhr=>{
		setCarouselData(JSON.parse(xhr.response).Post, component.setCarouselDataHandler);
	},getHeaders(),function (error) {
		if(processError(error)){
			return false;
		}
	});
}