/*
 FieldSelectOption - an answer option to a single select or multi select question
*/

import React from 'react';
import InputFieldBlock from '../../../../BasicInputs/InputFieldBlock';
import InputCheckbox from '../../../../BasicInputs/InputCheckbox';
import AddFieldController from '../AddFieldController';
/*
state:
	checked: if true then inputcheckbox is checked and an addfieldcontroller is added to allow for follow up questions

props (component-level arguments):
	follow_up_enabled: if true then this is a follow up question option
	option_number: the option number of this option from 0 to the last option for the question this option is a part of
	handleInputChange: a callback function that sends input back up to parent
	handleOptionLabelChange: a callback function passed down to addfieldcontroller from parent components. used for follow up questions
	label_number: the label number from 0 to the number of labels (questions) that this option belongs to 
	addQuestionOption: a callback function that adds an option to a question or follow up question 
	questions: an array of all current question objects passed down from NewFormView
	addQuestionCallback: a callback function passed down (down if this is a follow up question) to addfieldcontroller to add questions.
	follow_up_label: if this is a follow up field then this is the label number of the question owning the follow up
	option_number_owner: if this is a follow up field this is the option number that owns this follow up question
*/
class FieldSelectOption extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked: false,
		};
		this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
	}

	handleCheckBoxChange() {
		if(this.state.checked){
			this.setState({checked:false});
			this.props.removeQuestion(this.props.label_number, true, this.props.option_number);
		}else{
			this.setState({
				checked: true
			});
		}
	}

	render() {
		let input_field_size = '8';
		let follow_up_size = '3';
		if (this.props.follow_up_enabled) {
			input_field_size = '10';
			follow_up_size = '1';
		}
		return (
			<div className={`FieldSelectOption`}>
				<div className="grid-x option-row">
					{/* enter option  */}
					<div className="fa-container">
						<i className="fa fa-grip-vertical"></i>
					</div>
					<div className={`cell small-${input_field_size}`}>
						<InputFieldBlock
							fieldName={`${this.props.option_number}`}
							fieldRequired={true}
							fieldType="text"
							title=""
							id={`${this.props.option_number}`}
							callback={this.props.handleInputChange}
							inputBlockClass={'option'}
							fieldHint={'Enter answer option'}
						/>
					</div>
					<div className={`cell small-${follow_up_size} has-follow-up`}>
						{(!this.props.follow_up_enabled && this.props.questions[this.props.question_idx].question_type!=='dropdown') && (
							<InputCheckbox onCheckChange={this.handleCheckBoxChange} required={false} inputId={`option-number:${this.props.option_number}+label_number:${this.props.label_number}`} value="false" id="follow-up" desc="Has follow-up question" />
						)}
						<i className="far fa-trash-alt" onClick={e => this.props.deleteOption(e,this.props.option_number)}></i>
					</div>
				</div>
				{this.state.checked && (
					<AddFieldController
						addQuestionOption={this.props.addQuestionOption}
						questions={this.props.questions}
						addQuestionCallback={this.props.addQuestionCallback}
						handleQuestionLabelChange={this.props.handleQuestionLabelChange}
						handleOptionLabelChange={this.props.handleOptionLabelChange}
						label_number={this.props.label_number}
						last_label_number={this.props.label_number}
						handleInputChange={this.props.handleInputChange}
						follow_up_enabled={this.state.checked}
						option_number_owner={this.props.option_number}
						removeQuestion={this.props.removeQuestion} removeQuestionOption={this.props.removeQuestionOption}
					/>
				)}
			</div>
		);
	}
}

export default FieldSelectOption;
