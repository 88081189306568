import React from 'react';
import { InputFieldBlock } from '../../../../BasicInputs/InputFieldBlock';

export const FormTitle = ({ title, setTitle }) => (
	<div className="cell small-12">
		<InputFieldBlock
			title="Title your form"
			fieldName="title"
			fieldRequired={true}
			fieldType="text"
			id="title"
			callback={(e) => setTitle(e.currentTarget.value)}
			fieldValue={title}
		/>
	</div>
);
