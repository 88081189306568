import React from 'react';
import styled, { css } from 'styled-components';
import { StyledBase } from './StyledBase';
import { Color, FontSize } from '../../constants/style-constants';
import { breakpoint } from '../../proj-libs/breakpoints';

// Dropdown: Displays a list of options when pressed.
// Props:
// All StyledBase props are available.
// options: array of strings that the Dropdown will display
// placeholder: text that will be displayed if no option is selected.
// selectedOption: option that should be selected.
// onChange: callback when option is selected. Event.target.value will hold the selected option.
const DropdownView = styled.div`
	${(props) => css`
		label {
			display: block;
			color: ${Color.nile50};
			font-size: ${FontSize.body2};
		}
		flex:${props.flex};
		margin-top: ${props.marginTop};
		margin-right: ${props.marginRight};
		margin-bottom: ${props.marginBottom};
		margin-left: ${props.marginLeft};
	`}
`;

const SelectDiv = styled.div`
	position: relative;
	${(props) => css`
		&::after {
			content: '${props.iconUnicode ? props.iconUnicode : ''}';
			position: absolute;
			right: 8px;
			top: 50%;
			transform: translateY(-50%);
			font-family: 'Font Awesome 5 Free';
			font-weight: 900;
			color: ${props.iconColor ? props.iconColor : Color.nile};
			pointer-events: none;
		}
	`}
`;

const StyledSelect = styled.select`
	${(props) => css`
		${StyledBase}
		border:${props.border};
		height: ${props.divHeight};
		display: block;
		position: relative;
		margin: ${props.margin};
		border-radius: ${props.borderRadius};
		flex: ${props.flex};
		width: ${props.width};
		${breakpoint('small only')}{
			width:${props.smallWidth};
		}
		&:hover,
		&:focus,
		&:active {
			transform: ${props.hoverTransform};
			filter: ${props.hoverFilter};
			box-shadow: ${props.hoverBoxShadow};
			background-color: ${props.hoverBackground};
			outline: none;
			/* color: ${props.hoverColor}; */
			border-color: ${props.hoverBorderColor};
			text-decoration: ${props.hoverTextDecorationLine};
			text-decoration-color: ${props.hoverTextDecorationColor};
			text-decoration-style: ${props.hoverTextDecorationStyle};
			opacity: ${props.hoverOpacity};
			border: ${props.hoverBorder};
		}
	`}
`;


export const Dropdown = (props) => (
	<DropdownView {...props}>
		{props.label && <label>{props.label}</label>}
		<SelectDiv {...props}>
			<StyledSelect
				required={props.required}
				defaultValue={
					props.noDefault ? undefined : props.selectedOption ? props.selectedOption : (props.placeholder ? (props.placeholderValue ? props.placeholderValue : 'placeholder') : 'placeholder')
				}
				{...props}
			>
				{props.placeholder && (
					<option value={props.placeholderValue ? props.placeholderValue : ''}>{props.placeholder}</option>
				)}
				{props.options &&
					Object.keys(props.options).map((key) => (
						<option key={key} value={props.values ? props.values[key] : key}>
							{props.options[key]}
						</option>
					))}
			</StyledSelect>
		</SelectDiv>
	</DropdownView>
);

Dropdown.defaultProps = {
	className: 'Dropdown',
	width: 'fit-content',
	minWidth: '100%',
	color: Color.nile,
	fontSize: FontSize.body2,
	backgroundColor: Color.white,
	borderColor: Color.nile15,
	borderRadius: '5px',
	padding: '8px 40px 8px 8px',
	appearance: 'none',
	cursor: 'pointer',
	hoverFilter: 'invert(0.1)',
};

export const FilterDropdown = styled(Dropdown)``;

FilterDropdown.defaultProps = {
	className: 'FilterDropdown',
	color: Color.nile50,
	fontSize: FontSize.body1,
	backgroundColor: Color.nile5,
	borderColor: 'transparent',
	hoverBorderColor: Color.zest,
};
