import React from 'react';
import MetaData from '../MetaData';

export const DateAuthor = ({ created_at, author, flexDirection = "column" }) => {
    return (
        <div className={`metadata-wrapper ${flexDirection}`}>
            <MetaData additionalClass={'calendar'} icon='far fa-calendar-alt' text={created_at} />
            {author &&
                <div className="author-wrapper">
                    <MetaData additionalClass={'calendar'} icon='far fa-user' text={author} />
                </div>
            }
        </div>
    )
};