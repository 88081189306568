import config from '../config';
import {toast} from 'react-toastify';
import {
	TrimInputFields,
	getAWSKey,
	removeDuplicateTags,
} from './HelperFunctions';
import S3Image from '../components/S3Image';
import React from 'react';
import Post from '../models/Post';
import { get_form_data } from './libform';
const imagePlaceholder = require('../assets/icon_image.svg');

export function savePreLoadedAttachments(component,file_s3_array){
    for(let i=0;i<file_s3_array.length;i++){
        let fileName=file_s3_array[i];
        component.aws.getObject(config.bucket_name,file_s3_array[i],error=>{
            toast.error('Error retrieving file information');
        },success=>{
            let preloadedFiles=component.state.preloadedFiles;
            preloadedFiles.push({
                name:fileName,
                fileInfo: success
            });
            if(preloadedFiles.length===file_s3_array.length){
                component.setState({donePreloading:true,preloadedFiles});
            }else{
                component.setState({preloadedFiles});
            }
        });
    }
}
 
export function uploadFileAttachments(component, netPost){
    let fileAttachments=component.state.fileAttachments;
    component.setState({submitting:true}, ()=>{
        if(fileAttachments.length===0){
            component.savePostNetworkRequest(copyPostObject(netPost,netPost['type']));
        }
        for(let i=0;i<fileAttachments.length;i++){
            let file=fileAttachments[i];
            component.aws.uploadFile(config.bucket_name, file.name, file, (error)=>{
                toast.error('There was an error uploading one or more of the attached files.');
            }, (success)=>{
                let key = getAWSKey(success);
                let fileAttachmentKeys=component.state.fileAttachmentKeys;
                fileAttachmentKeys.push(key);
                component.setState({fileAttachmentKeys},()=>{
                    if(fileAttachmentKeys.length===component.state.fileAttachments.length){
                        // then we are done uploading
                        component.setState({submitting:false});
                        netPost.file_s3_path=fileAttachmentKeys;
                        // note the copyPostObject function is neccesary here because of how JS callbacks are behaving here.
                        // it is adding aws variables into the netPost argument. not good as it has unneccsary
                        // data that would add more traffic to the network request and also has a circular reference
                        let newNetPost=copyPostObject(netPost,netPost['type']);
                        component.savePostNetworkRequest(newNetPost);
                    }
                });
            });
        }
        
    });
}

export function stripPostUiFields(post) {
    //remove any fields that are only valid in the front end
    let uiFields = ['formattedTitle', 'formattedTags', 'editIcon', 'editAction', 'deleteIcon', 'deleteAction', 'cssClasses'];
    for (let i = 0; i < uiFields.length; i++) {
        if (post.hasOwnProperty(uiFields[i])) {
            delete post[uiFields[i]];
        }
    }
    return post;
}

export function setFilteredPostInfo(component) {
    //start with all data
    let filteredPostInfo = component.state.postInfo;
    let filteredCat = component.state.filteredCat;
    let filteredTag = component.state.filteredTag;

    //apply any category filters that are present
    if (filteredCat !== '') {
        filteredPostInfo = filteredPostInfo.filter(post => post.category === filteredCat);
    }

    //apply any tag filters that are present
    if (filteredTag !== '') {
        filteredPostInfo = filteredPostInfo.filter(post => post.tags.includes(filteredTag));
    }

    //update the state to reflect the newly-applied filters
    component.setState({
        filteredPostInfo: filteredPostInfo
    });
}

export function genFormattedTableData(component, isEvents=false) {
    let tableData = {
        headerData: [
            { title: 'Post Title', field: 'formattedTitle', sortField:'title' },
            { title: isEvents?'Start Date':'Date Posted', field: 'formattedPublishedAt' },
            { title: 'Type', field: 'type', sortable: false },
            { title: 'Category', field: 'category', sortable: false },
            { title: 'Tags', field: 'tags', sortable: false },
            { title: '', field: 'editIcon', sortable: false, onClick: 'editAction' },
            { title: '', field: 'deleteIcon', sortable: false, onClick: 'deleteAction' }
        ],
        rows: []
    };
    for (var eventIdx = 0; eventIdx < component.state.filteredPostInfo.length; eventIdx++) {
        let post = component.state.filteredPostInfo[eventIdx];
        post['formattedTitle'] = renderImageLoader(component,post.img_s3_path, `${post.title}`);
        if(isEvents){
            post['formattedPublishedAt'] = new Date(post['start'] * 1000);
        }else{
            post['formattedPublishedAt'] = new Date(post['published_at'] * 1000);
        }
        post['formattedTags'] = post.tags === null ? '' : post.tags.join(', ');
        post['editIcon'] = 'fas fa-edit';
        post['editAction'] = function() {
            component.openEditForm(post);
        };
        post['deleteIcon'] = 'fas fa-trash';
        post['deleteAction'] = function() {
            component.deletePost(post);
        };
        post['cssClasses'] = {
            formattedTitle: 'title',
            formattedPublishedAt: 'iso-date',
            tags: 'tag-array',
            editIcon: 'circle-border action-button secondary-color-icon',
            deleteIcon: 'action-button'
        };
        tableData['rows'].push(post);
    }
    return tableData;
}

export function renderImageLoader(component,image, title) {
    return (
        <>
            <S3Image aws_object={component.aws} s3_path={image} placeholderImageURL={imagePlaceholder} />
            {title}
        </>
    );
}

export function sortPostsByDate(posts) {
    posts.sort((a, b) => (a['published_at'] < b['published_at'] ? 1 : a['published_at'] > b['published_at'] ? -1 : 0));
    return posts;
}

export function savePost(current_target, component, postType='post') {
    if(!TrimInputFields('admin-manager-form')){
        return false;
    }
    let form_data = get_form_data(current_target);
    //NOTE: when editing existing posts there will be a post_id value set
    //when creating a new post there will not be a post_id value set
    //because it allows us to distinguish those cases
    let post = component.state.editingPost;
    if (post === null) {
        post = new Post(null);
    }
    post.title = form_data['title'];
    post.is_featured = form_data['is_featured'] === 'true';
    post.category = form_data['category'];
    post.content = component.state.editorContent;
    post.file_s3_path = component.state.file_s3_path;
    post.img_s3_path = component.state.detailedPhoto;
    //NOTE: to generate the short description from the content we need to take out html tags.
    //because the WYSIWYG field allows them for content, but we can't allow them here
    //(if we did then they might have ending tags cut off due to the substring operation)
    let div_tmp = document.createElement('DIV');
    div_tmp.innerHTML = post.content;
    post.desc = div_tmp.innerText;
    //for the post object that goes over the network
    //we must remove any fields that are only valid in the front end
    //so do that here
    let netPost = stripPostUiFields(post);
    netPost.tags = component.state.selectedTags;
    netPost.tags = removeDuplicateTags(netPost.tags);
    netPost.category = component.state.selectedCategory;
    netPost['type'] = postType;
    netPost.departments = component.state.selectedDepartments;
    if (component.state.date){
        netPost.published_at = component.state.date.getTime()/1000;
    }
    netPost.is_featured=component.state.is_featured;
    if(postType==='event'){
        netPost.address=form_data['address'];
        netPost.contact_phone=form_data['contact_phone'];
        netPost.contact_name=form_data['contact_name'];
        netPost.start=component.state.dateStart.getTime() / 1000;
        netPost.end=component.state.dateEnd.getTime() / 1000;
        uploadFileAttachments(component,netPost);
    }else{
        netPost.author_name=form_data["author_name"];
        if(!component.state.pdfOnly){// then there are multiple file attachments
            // upload the multiple attachments
            // the network request will be made inside the function uploadFileAttachments when all files have been uploaded.
            // if all files are not uploaded then this will throw a toast terror message and will not make the network request 
            // done to help avoid uploading files that have been deleted
            uploadFileAttachments(component,netPost);
        }else{
            netPost.direct_file=true;
            // is a pdf post so there are no multiple attachments. 
            //the network request can be made here because the files are already uploaded
            component.savePostNetworkRequest(netPost);
        }
    }
    return false;
}

export function copyPostObject(netPost, postType='post'){
    let a={};
    a.title=netPost.title;
    a.is_featured=netPost.is_featured;
    a.category=netPost.category;
    a.content=netPost.content;
    a.file_s3_path=netPost.file_s3_path;
    a.img_s3_path=netPost.img_s3_path;
    a.desc=netPost.desc;
    a.tags=netPost.tags;
    a.category=netPost.category;
    a['type']=postType;
    a.departments=netPost.departments;
    a.published_at=netPost.published_at;
    if(netPost.hasOwnProperty('post_id')){
        a.post_id=netPost.post_id;
    }
    if(postType==='event'){
        a.address=netPost.address;
        a.contact_phone=netPost.contact_phone;
        a.contact_name=netPost.contact_name;
        a.end=netPost.end;
        a.start=netPost.start;
    }else if(postType==="post"){
        a.author_name=netPost.author_name;
    }
    return a;
}
