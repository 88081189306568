/*

QuestionContentRow - a row representing one multiplechoice or true/false option

*/
import React from 'react';
import InputRadio from '../../BasicInputs/InputRadio';
import InputCheckbox from '../../BasicInputs/InputCheckbox';

/*
props ( component level arguments):
	highlighted: if true then this row has been clicked and so it will have visual changes until another row is clicked
	question_content: the text content of this option

*/
class QuestionContentRow extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			selected_question: null,
		};
	}

	updateRow=()=>{
		let selected = this.props.selected_row === this.props.display_order;
		if(this.props.multi_select){
			selected = this.props.selected_row.includes(this.props.display_order.toString());
		}
		let questions = this.props.questions;
		let dependent_question_options=[];
		let dependent_question_option=null;
		for (let item in questions) {
			const question = questions[item];
			if (question.depends_on) {
				let keys = Object.keys(question.depends_on)[0];// get the question index for the question that depends on this
				if (questions[keys] === this.props.question_owner) {// if the question this question depends on is question owner
					if (question.depends_on[keys] === this.props.title) {// if the title of this question is the same as the question owner answer option
						dependent_question_option=question;
					}
					dependent_question_options.push(question);
				}
			}
		}
		return this.props.questionChangeCallback(this.props.title,this.props.question_owner,selected,dependent_question_options,dependent_question_option);// this callback will show the question
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevProps.selected_row !== this.props.selected_row || this.props.selected_row.length!==prevProps.selected_row.length){
			this.updateRow();
		}
	}

	clickCallback = () => { // this will change which row is selected
		if(this.props.multi_select){
			this.props.clickCallback(this.props.display_order,true);
		}else{
			if(this.props.display_order!==undefined && this.props.display_order === this.props.selected_row){
				this.props.clickCallback(null);
			}else{
				this.props.clickCallback(this.props.display_order);
			}
		}
	}


	renderMultiSelect(){
		let selected = null;
		if(this.props.multi_select){
			let selected_row=this.props.selected_row.split(',');// this is done because componentdidupdate only does a shallow compare, it cannot check for array updates
			if(selected_row.length>0){
				selected=selected_row.includes(this.props.display_order.toString());
			}else{
				selected=false;
			}
		}
		return (
			<div className="QuestionContentRow">
				<InputCheckbox 
					disabled={this.props.disabled}
					checked={selected===true}
					required={false}
					onCheckChange={this.clickCallback}
					inputId={`checkbox-${this.props.question_owner.form_question_id}-${this.props.title}`}
					value="false"
					desc={this.props.title} />
			</div>
		);
	}

	renderSingleSelect(){
		let selected = this.props.selected_row === this.props.display_order;
		return (
			<div className="QuestionContentRow">
				<InputRadio
					disabled={this.props.disabled}
					radioId={this.props.title}
					required={false}
					onClick={this.clickCallback}
					radioName={`${this.props.question_owner.title}-${this.props.question_owner.form_question_id.toString()}`}
					value={selected===true} 
					callback={this.clickCallback} 
					desc={this.props.title} 
				/>
			</div>
		);
	}

	render() {
		if(this.props.multi_select){
			return this.renderMultiSelect();
		}else{
			return this.renderSingleSelect();
		}
	}
}
export default QuestionContentRow;
