import React, { useContext } from 'react';
import { QUESTION_TYPE_FILE_UPLOAD } from '../../../../../constants';
import SelectFieldButton from '../FieldLabel/SelectFieldButton';
import FieldText from '../FieldText';
import { FormContext } from './FormContext';

export const AddQuestionMenu = ({
	isFollowUp = false,
	questions,
	answerOption = null,
	option = null,
}) => {
	const context = useContext(FormContext);
	const addQuestion = (questionType) => {
		let answerOptions = null;
		if (questionType !== 'text' && questionType !== 'textarea' && questionType!==QUESTION_TYPE_FILE_UPLOAD) {
			answerOptions = [
				{
					title: '',
					question: null,
				},
			];
		}
		if (!isFollowUp) {
			questions.push({
				question_type: questionType,
				title: '',
				answer_options: answerOptions,
				valid: true,
			});
		} else {
			answerOption.question = {
				question_type: questionType,
				title: '',
				answer_options: answerOptions,
				isFollowUp: true,
				valid: true,
			};
		}
		context.reRender((prev) => !prev);
	};
	return (
		<>
			<div
				className={`${
					isFollowUp
						? 'AddFieldController follow-up'
						: 'AddFieldController'
				}`}
			>
				{questions && questions.length === 0 && (
					<FieldText follow_up_enabled={isFollowUp} />
				)}
				<SelectFieldButton
					classIdentifier={
						questions && questions.length === 0 ? '' : 'bottom-left'
					}
					addLabelHandler={addQuestion}
				/>
			</div>
		</>
	);
};
