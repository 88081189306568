import React from 'react';
import S3Image from './S3Image.jsx';
import { parse_cookies } from './../react-utils/src/libformat.js';
import { set_cookie }  from '../projlibs/cookie-management.js';
import HerdRAAWSUtils from '../projlibs/HerdRAAWSUtils';
import FlyoutMenu from './FlyoutMenu';
import { InnerEmployees } from './FlyoutMenuInner';
import { xml_fetch_info } from '../react-utils/src/libajax';
import {
	USER_ID,
	USER_IMAGE,
	SAVE_AVATAR_SUCCESS,
	SAVE_AVATAR_FAIL,
	TRY_AGAIN_LATER
} from '../constants';
import { getHeaders } from '../projlibs/HelperNetworkCalls';
import { toast } from 'react-toastify';
import { xml_send_info } from '../lib/networking/herdajax';
import { CircularProgress } from '@material-ui/core';
const cookies = parse_cookies();
class ProfileSettingsBar extends React.Component {
	constructor(props) {
		super(props);
		this.aws = new HerdRAAWSUtils();
		this.state = {
			menuOpen: false,
			dibv: {},
			photo_s3_path: false,
			loading: true,
			hovering: false
		};
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevState.menuOpen !== this.state.menuOpen) {
			let cookies = parse_cookies();
			this.setState({"loading":true},()=>{
				xml_fetch_info(`/employee/${cookies[USER_ID]}`, xhr => {
					let res = JSON.parse(xhr.response).Employee.User;
					this.setState({ dibv: res, loading: false, photo_s3_path: res.photo_s3_path });
				}, getHeaders(), err => {
					toast.error(err);
					this.setState({ loading: false });
				});
			});

		}
	}
	profilePathChanged = (key) => {
		let dibv = this.state.dibv;
		dibv.photo_s3_path = key;
		this.setState({ photo_s3_path: key, dibv: {...dibv}} );
	};

	updatePreview = (preview) => {
		this.setState({ preview: preview });
	};

	handleImageClick = () => {
		this.setState({ menuOpen: !this.state.menuOpen });
	};
	closeMenu = () => {
		this.setState({ menuOpen: false });
	};
	saveAvatar = () => {
		let user_id = parse_cookies()[USER_ID];

		if (user_id && !this.state.loading) {
			this.setState({"loading":true}, ()=>{
				xml_send_info('/employee/', JSON.stringify({
					user_id: user_id,
					photo_s3_path: this.state.photo_s3_path
				}), xhr => {
					toast.success(SAVE_AVATAR_SUCCESS);
					//NOTE: we know that user_id is the USER_ID from cookies
					//because it was set just above this
					//so this section is only ever used for editing your own avatar, and is separate from the admin view
					let response_obj=JSON.parse(xhr.response);
					if(response_obj.hasOwnProperty('Employee') && response_obj.Employee.hasOwnProperty('User') && response_obj.Employee.User.hasOwnProperty('photo_s3_path')){
						//update the associated cookie for the photo right away
						//so the user doesn't need to log in again to see the change
						set_cookie(USER_IMAGE, response_obj.Employee.User.photo_s3_path);
						this.setState({"photo_s3_path":response_obj.Employee.User.photo_s3_path,"loading":false});
					}else{
						this.setState({"loading":false});
					}
				}, 'PUT', getHeaders(), err => {
					toast.error(SAVE_AVATAR_FAIL + ' ' + TRY_AGAIN_LATER);
					this.setState({"loading":false});
				});
			});

		}
	};

	profilePath = () => {
		if (this.state.photo_s3_path && this.props.profile_path !== this.state.photo_s3_path) {
			return this.state.photo_s3_path;
		} else {
			return this.props.profile_path;
		}
	};
	render() {
		return (
			<div className='ProfileSettingsBar'>
				<div
					onClick={this.handleImageClick} className='profile-thumbnail'
				>
					<S3Image aws_object={this.aws} additionalClasses='image-content circle' s3_path={this.profilePath()} altText={cookies.title} />
				</div>
				{this.state.menuOpen &&
					<FlyoutMenu closeMenu={this.closeMenu} menuOpen={this.state.menuOpen}>
						{this.state.loading ?
							<div className='loading-wrapper-flex'>
								<CircularProgress id='network-loading' thickness={6} size={80} />
							</div>
							:
							<>
								<InnerEmployees updatePreview={this.updatePreview} profilePathChanged={this.profilePathChanged} allowUpload={true} dibv={this.state.dibv} />
								<div className="small-12 cell button-holder">
									<button type="button" disabled={this.state.loading} className="button save-form" onClick={this.saveAvatar}>Save</button>
								</div>
							</>
						}
					</FlyoutMenu>
				}
			</div>
		);
	}
}

export default ProfileSettingsBar;
