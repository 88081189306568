import React, { useEffect, useState } from 'react';
import UploadWidget from '../../UploadWidget/UploadWidget';
import { ACCEPTED_FILE_TYPE_ARRAY } from '../../../constants';
import HerdRAAWSUtils from '../../../projlibs/HerdRAAWSUtils';
import config from '../../../config';
import { toast } from 'react-toastify';
import styled from 'styled-components';
export const QuestionFileUpload = ({
	fileLabel = 'Upload File',
	handleFileInputChange,
	idString,
	response,
	viewingResults,
}) => {
	const [file, setFile] = useState(null);
	const [aws] = useState(new HerdRAAWSUtils());
	const [signedUrl, setSignedUrl] = useState('');

	useEffect(() => {
		aws.getSignedUrl(
			config.bucket_name,
			response,
			(err) => {
				toast.error('Error downloading users file response');
			},
			(success) => {
				setSignedUrl(success);
			}
		);
	}, [aws, response]);
	const saveFile = (file) => {
		setFile(file);
		handleFileInputChange(idString, file);
	};
	if (viewingResults) {
		return (
			<TextRowWrapper>
				<label className='question-title'>{fileLabel}: </label>
				{response ? (
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={signedUrl}
					>
						Link To File
					</a>
				) : (
					<label className='input-title'>User did not upload a file.</label>
				)}
			</TextRowWrapper>
		);
	}
	return (
		<UploadWidget
			fileCategory={fileLabel}
			acceptedFileTypes={ACCEPTED_FILE_TYPE_ARRAY}
			saveFile={saveFile}
			allowAllFileSizes={false}
			includeUploadTitle={false}
			showPreview={true}
			allowableFileSize={10}
			idUniqueIdentifier={'file-upload'}
			classIdentifier={'upload-detailed-photo'}
			showRequirements={true}
			fileObject={file}
			fileId={`upload-file-${idString}`}
		/>
	);
};


const TextRowWrapper=styled.div``;