/*
AdminLocationManager - the admin page for viewing, modifying, creating, and deleting location information

*/

import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import {get_form_data} from '../../../projlibs/libform.jsx';
import {xml_fetch_info,xml_send_info} from '../../../react-utils/src/libajax.js';
import config from '../../../config';
import {parse_cookies} from '../../../react-utils/src/libformat.js';
import { ERROR_RETRIEVING_LOCATIONS, ERROR_LOCATION_NOT_SAVED, SUCCESS_LOCATION_SAVED, TRY_AGAIN_LATER, SESSION_ID, PATH_SEARCH, GENERIC_UPLOAD_ERROR_MESSAGE, UPLOAD_IMAGE_FILE_TYPES } from '../../../constants.js';
import Multiselect from 'react-widgets/lib/Multiselect';
import {processError} from '../../../projlibs/cookie-management.js';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import FlyoutMenu from '../../FlyoutMenu.jsx';
import FlexTable from '../../FlexTable.jsx';
import S3Image from '../../S3Image.jsx';
import AdminManagerAbstract from '../AdminManagerAbstract.jsx';
import { getDepartments } from '../../../projlibs/HelperNetworkCalls.js';
import UploadWidget from '../../UploadWidget/UploadWidget.jsx';
import Location from '../../../models/Location.js';
import { TrimInputFields } from '../../../projlibs/HelperFunctions.js';
import HerdRAAWSUtils from '../../../projlibs/HerdRAAWSUtils';
import {TINY_MCE_BASE_PLUGINS} from "../../../constants";
import { getAWSKey } from '../../../projlibs/HelperFunctions';
import { PageController } from '../../PageController';
import { LoadingIndicator } from '../../LoadingIndicator';
import { table_enums, updateSorting } from '../../../projlibs/pagination-functions.js';
const IMG_PLACEHOLDER = require('../../../assets/icon_image.svg');

const LOCATIONS_TABLE_HEADERS = [
	{'title':'Location Name','field':'formatted_name'},
	{'title':'Address','field':'formatted_address'},
	{'title':'Office Phone #','field':'contact_phone','sortable':false},
	{'title':'Office Email','field':'contact_email','sortable':false},
	{'title':'','field':'editIcon','onClick':'editAction','sortable':false},
	{'title':'','field':'deleteIcon','onClick':'deleteAction','sortable':false},
];

/*
props (component-level arguments):
	departments: a list of departments for filtering purposes
	positions: a list of positions for filtering purposes
	match: url path information for path-based routing arguments

state (component-level globals):
	table_rows: an ordered list of data for display in a FlexTable
	filtered_city: the city to filter on
	edit_view_open, data_to_edit: see AdminManagerAbstract.jsx
*/
class AdminLocationManager extends AdminManagerAbstract {
	constructor(props) {
		super(props);
		this.state = {
			table_rows: [],
			filtered_city: '',
			edit_view_open: false,
			data_to_edit: null,
			departments: [],
			selectedDepartments: [],
			editorContent: '',
			uploadingDetailedPhoto: null,
			detailedPhotoObject: null,
			detailedPhoto: null,
			submitting:false,
			location_id: '',
			numPages:1,
			currentPage: 0,
			isLoading:true,
			order_by:'&order_by=created_at&order_dir=desc'
		};
		this.aws = new HerdRAAWSUtils();
		this.getFilteredLocInfo = this.getFilteredLocInfo.bind(this);
		this.formatLocInfo = this.formatLocInfo.bind(this);
		this.netCallSuccess = this.netCallSuccess.bind(this);
		this.netCallFailure = this.netCallFailure.bind(this);
		this.netActionSuccess = this.netActionSuccess.bind(this);
		this.netActionFailure = this.netActionFailure.bind(this);

		this.stripLocUiFields = this.stripLocUiFields.bind(this);
		this.saveLoc = this.saveLoc.bind(this);
		this.deleteLoc = this.deleteLoc.bind(this);

		this.renderEditForm = this.renderEditForm.bind(this);
		this.renderTableTitle = this.renderTableTitle.bind(this);
		this.renderImageLoader = this.renderImageLoader.bind(this);
	}

	componentDidMount() {
		getDepartments(this.successGetDepartments, this.failGetTags);
	}
	getDetailedPhotoObjectSuccess = obj => {
		this.setState({ detailedPhotoObject: obj });
	};

	getObjectFail = err => {
		console.log(err);
	};

	componentDidUpdate(prevProps, prevState) {
		if(prevState.location_id !== prevProps.match.params.location_id) {
			this.setState({
				location_id: prevProps.match.params.location_id
			}, () => this.getFilteredLocInfo());
		}
		if(prevState.currentPage !== this.state.currentPage || prevState.order_by !== this.state.order_by){
			this.getFilteredLocInfo();
		}
		
		//then editing a new post
		if (prevState.data_to_edit !== this.state.data_to_edit && this.state.data_to_edit) {
			let departments = [];
			for (let item in this.state.data_to_edit.departments) {
				if (this.state.data_to_edit.departments[item].name) {
					departments.push(this.state.data_to_edit.departments[item].name.toLowerCase());
				}
			}
			this.setState({
				selectedDepartments: departments,
				detailedPhoto: this.state.data_to_edit.photo_s3_path,
				editorContent: this.state.data_to_edit.desc
			});
			this.aws.getObject(config.bucket_name, this.state.data_to_edit.photo_s3_path, this.getObjectFail, this.getDetailedPhotoObjectSuccess);
		} else if (!this.state.data_to_edit && prevState.data_to_edit) {
			this.setState({
				selectedDepartments: [],
				edit_view_open: false,
				data_to_edit: null,
				detailedPhoto: '',
				uploadingDetailedPhoto: false,
				editorContent: ''
			});
		}
	}

	successGetDepartments = departments => {
		this.setState({ departments });
	};

	failGetTags = error => {
		toast.error(error);
	};

	formatLocInfo(loc_info) {
		loc_info['formatted_name'] = this.renderImageLoader(loc_info.photo_s3_path, loc_info.name);
		loc_info['formatted_address'] = this.formatAddress(loc_info);
		loc_info['editIcon'] = 'fas fa-edit';
		loc_info['deleteIcon'] = 'fas fa-trash';
		loc_info['editAction'] = function() {
			this.openEditForm(loc_info);
		}.bind(this);
		loc_info['deleteAction'] = function() {
			this.deleteLoc(loc_info);
		}.bind(this);
		loc_info['cssClasses'] = {
			formatted_name: 'name title title-circle-thumbnail',
			editIcon: 'circle-border action-button secondary-color-icon',
			deleteIcon: 'action-button'
		};
		return loc_info;
	}

	formatAddress(directoryItem) {
		if(directoryItem.address) {
			let formattedAddress = [directoryItem.address, directoryItem.city, directoryItem.state_prov, directoryItem.postal_code, directoryItem.country].filter(elm=>(elm !== null && elm !== undefined && elm !== '')).join(', ');
			return formattedAddress;
		}

		return '';
	}


	handleWYSIWYGInputChange=(content, editor) =>{
		this.setState({
			editorContent: content
		});
	}

	getFilteredLocInfo() {
		const city = this.state.filtered_city;

		//make a network request to get the time-filtered data
		const cookies = parse_cookies();
		let url_path = PATH_SEARCH + '?table=Location';
		if (city !== '') {
			url_path += '&city=' + encodeURIComponent(city);
		}
		url_path+=`&page=${this.state.currentPage}&page_size=0&${this.state.order_by}&company_id=${config.main_company_id?config.main_company_id:1}`;
		this.setState({isLoading:true}, ()=>{
			xml_fetch_info(
				url_path,
				this.netCallSuccess,
				{
					'Content-Type': 'application/json',
					Authorization: cookies[SESSION_ID]
				},
				this.netCallFailure
			);
		});

	}

	netCallSuccess(xhr) {
		const response = JSON.parse(xhr.response);
		if (response.hasOwnProperty('Location')) {
			let info = [];
			let directory_info = response['Location'];
			if(this.state.location_id) {
				directory_info = directory_info.filter(data => data.location_id === parseInt(this.state.location_id));
			}
			for (let idx = 0; idx < directory_info.length; idx++) {
				const directory_item = directory_info[idx];
				if(directory_item.company_id===1){
					info.push(this.formatLocInfo(directory_item));
				}
			}
			//filter out invalid or disabled items
			info = info.filter(loc => loc.is_valid);

			this.setState({
				table_rows: info,
				isLoading:false, numPages: response.page_count
			});
		} else {
			this.setState({
				table_rows: [],
				isLoading:false, numPages: response.page_count
			});
		}
		
	}

	uploadDetailedPhotoError = err => {
		toast.error(GENERIC_UPLOAD_ERROR_MESSAGE + ' ' + TRY_AGAIN_LATER);
		this.setState({ uploadingDetailedPhoto: false });
	};

	setDetailedPhotoSuccess = data => {
		let key = getAWSKey(data);
		this.setState({ detailedPhoto: key, uploadingDetailedPhoto: false });
	};

	saveDetailedPhoto = file => {
		this.setState({ detailedPhotoObject: null, uploadingDetailedPhoto: true }, () => this.aws.uploadFile(config.bucket_name, file.name, file, this.uploadDetailedPhotoError, this.setDetailedPhotoSuccess));
	};

	netCallFailure(error) {
		this.setState({isLoading:false});
		if (processError(error)) {
			return false;
		} else {
			toast.error(ERROR_RETRIEVING_LOCATIONS + ' ' + TRY_AGAIN_LATER);
		}
	}

	netActionSuccess(xhr) {
		let data = JSON.parse(xhr.responseText)['Location'];
		let table_rows = this.state.table_rows;
		//look through all existing users to see if this is an update to a previously-existing user
		let idx = 0;
		for (idx = 0; idx < table_rows.length; idx++) {
			//if this is a match
			if (table_rows[idx]['location_id'] - 0 === data['location_id'] - 0) {
				//then update the existing data
				if (data.is_valid===false) {
					table_rows.splice(idx, 1);
					continue;
				}
				table_rows[idx] = this.formatLocInfo(data);
				//and stop looking
				break;
			}
		}
		//if we iterated through all existing posts and didn't find a matching id
		//then this is a new user and we should add it to the end of the list
		if (idx >= table_rows.length) {
			if(data.is_valid === true){
				table_rows.push(this.formatLocInfo(data));
			}
			
		}

		//sort by date, newest first
		table_rows.sort((a, b) => (a['modified_at'] < b['modified_at'] ? 1 : a['modified_at'] > b['modified_at'] ? -1 : 0));

		this.setState(
			{
				table_rows: table_rows,
				filtered_city: '',
				edit_view_open: false,
				data_to_edit: null
			},
			() => {
				toast.success(SUCCESS_LOCATION_SAVED);
			}
		);
	}

	netActionFailure(xhr) {
		this.setState({submitting: false});
		toast.error(ERROR_LOCATION_NOT_SAVED + ' ' + TRY_AGAIN_LATER);
	}

	stripLocUiFields(user) {
		//remove any fields that are only valid in the front end
		let uiFields = ['formatted_name', 'formatted_address', 'editIcon', 'editAction', 'deleteIcon', 'deleteAction', 'cssClasses'];
		for (let i = 0; i < uiFields.length; i++) {
			if (user.hasOwnProperty(uiFields[i])) {
				delete user[uiFields[i]];
			}
		}
		return user;
	}

	saveLoc(current_target) {
		if(!TrimInputFields('admin-manager-form')){
			return false;
		}
		let cookies = parse_cookies();

		let form_data = get_form_data(current_target);

		//NOTE: when editing existing locationss there will be a location_id value set
		//when creating a new user there will not be a user_id value set
		//this is intentional and it allows us to distinguish those cases
		let loc = this.state.data_to_edit;
		if (loc === null) {
			loc = new Location(null);
		}

		loc.name = form_data['name'];
		loc.address = form_data['address'];
		loc.contact_phone = form_data['contact_phone'];
		loc.contact_email = form_data['contact_email'];
		loc.manager_name = form_data['manager_name'];
		loc.postal_code = form_data['postal_code'];
		loc.state_prov = form_data['state_prov'];
		loc.city = form_data['city'];
		loc.country = 'Canada';

		//for the user object that goes over the network
		//we must remove any fields that are only valid in the front end
		//so do that here
		let netLoc = this.stripLocUiFields(loc);
		delete netLoc.created_at;
		delete netLoc.modified_at;
		netLoc.desc=this.state.editorContent;
		netLoc.department_names = this.state.selectedDepartments;
		if(this.state.detailedPhoto){
			netLoc.photo_s3_path = this.state.detailedPhoto;
		}
		xml_send_info('/location', JSON.stringify(netLoc), this.netActionSuccess, loc.hasOwnProperty('location_id') ? 'PUT' : 'POST', { Authorization: cookies[SESSION_ID] }, this.netActionFailure);

		return false;
	}

	deleteLoc(loc) {
		let cookies = parse_cookies();
		let data = {
			location_id: loc.location_id,
			company_id:loc.company_id,
			is_valid:false

		};
		xml_send_info('/location', JSON.stringify(data), this.netActionSuccess, 'PUT', { Authorization: cookies[SESSION_ID] }, this.netActionFailure);
	}

	handleCreateDepartment = department => {
		let departments = [...this.state.departments];
		let selectedDepartments = [...this.state.selectedDepartments];
		selectedDepartments.push(department);
		departments.push(department);
		this.setState({ departments, selectedDepartments });
	};

	changeDepartments = value => {
		this.setState({ selectedDepartments: value });
	};

	renderEditForm() {
		let dflts =
			this.state.data_to_edit !== null
				? this.state.data_to_edit
				: {
						name: '',
						address: '',
						contact_phone: '',
						contact_email: '',
						manager_name:'',
						state_prov:'',
						city:'',
						postal_code:''

				  };
		return (
			<FlyoutMenu id="edit-user-flyout" menuOpen={this.state.edit_view_open} buttonText="New Location" closeButtonText="Cancel" openMenu={this.openEditForm} closeMenu={this.closeEditForm} haveOpenButton={true}>
				{this.state.edit_view_open && (
					<form onSubmit={e=>{
						e.preventDefault();
						let current_target=e.currentTarget;
						this.setState({submitting:true},()=>{this.saveLoc(current_target);});
					}}id="admin-manager-form">
						<div className="grid-x grid-padding-x grid-padding-y">
							<div className="small-12 cell">
								<h2 className="flyout-title">{this.state.data_to_edit === null ? 'Create a New Location' : 'Editing Location ' + this.state.data_to_edit.name}</h2>
							</div>
							<div className="small-12 medium-8 large-7 cell">
								<div className="grid-x grid-padding-x grid-padding-y">
									<div className="small-12 medium-12 cell">
										<label htmlFor="input-name">Location Name</label>
										<input type="text" name="name" id="input-name" placeholder="..." required={true} defaultValue={dflts['name']} />
									</div>
									<div className="small-12 medium-12 cell">
										<label htmlFor="input-address">Location Address</label>
										<textarea name="address" id="input-address" placeholder="..." required={true} defaultValue={dflts['address']} />
									</div>

									<div className="small-12 medium-6 cell">
										<label htmlFor="input-state_prov">State/Province</label>
										<textarea name="state_prov" id="input-state_prov" placeholder="..." required={false} defaultValue={dflts['state_prov']} />
									</div>
									<div className="small-12 medium-6 cell">
										<label htmlFor="input-city">City</label>
										<textarea name="city" id="input-city" placeholder="..." required={false} defaultValue={dflts['city']} />
									</div>
									<div className="small-12 medium-6 cell">
										<label htmlFor="input-postal_code">Postal Code</label>
										<textarea name="postal_code" id="input-postal_code" placeholder="..." required={false} defaultValue={dflts['postal_code']} />
									</div>

									<div className="small-12 medium-6 cell">
										<label htmlFor="input-contact-phone">Contact Phone #</label>
										<input type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" name="contact_phone" id="input-contact-phone" placeholder="..." required={true} defaultValue={dflts['contact_phone']} />
									</div>
									<div className="small-12 medium-6 cell">
										<label htmlFor="input-contact-email">Contact Email</label>
										<input type="email" name="contact_email" id="input-contact-email" placeholder="..." required={true} defaultValue={dflts['contact_email']} />
									</div>

									<div className="small-12 medium-6 cell">
										<label htmlFor="input-manager_name">Manager Name</label>
										<input type="text" name="manager_name" id="input-manager_name" placeholder="..." required={true} defaultValue={dflts['manager_name']} />
									</div>
									<div className="small-12 medium-6 cell">
										<label htmlFor="input-tags">Departments</label>
										<Multiselect
											data={this.state.departments}
											value={this.state.selectedDepartments}
											onChange={this.changeDepartments}
											onCreate={department => this.handleCreateDepartment(department)}
											allowCreate="onFilter"
											textField="tags"
											placeholder="Select Departments..."
										/>
									</div>
									<div className="small-12 cell">
										<label htmlFor="input-content">Location Description</label>
										<Editor
											apiKey={config.tinymce_key}
											value={this.state.editorContent}
											id="input-content"
											name="content"
											init={{
												plugins: TINY_MCE_BASE_PLUGINS,
												height: 256,
												toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code formatpainter'
											}}
											onEditorChange={this.handleWYSIWYGInputChange}
										/>
									</div>
									<div className="small-12 cell">
										<input disabled={this.state.uploadingDetailedPhoto || this.state.submiting} className="show-for-medium" type="submit" value="Submit" />
									</div>
								</div>
							</div>
							<div className="upload-widget-container">
								<UploadWidget
									fileCategory="Detailed Photo"
									acceptedFileTypes={UPLOAD_IMAGE_FILE_TYPES}
									saveFile={this.saveDetailedPhoto}
									idUniqueIdentifier={'detailed-photo'}
									classIdentifier={'upload-detailed-photo'}
									showRequirements={false}
									fileObject={this.state.detailedPhotoObject}
									fileId={'upload-detailed-photo'}
									uploading={this.state.uploadingDetailedPhoto}
									preview={this.state.detailedPhoto}
								/>
							</div>
							<div className="small-12 medium-4 large-5 cell">
								<input disabled={this.state.uploadingDetailedPhoto || this.state.submiting} className="hide-for-medium" type="submit" value="Submit" />
							</div>
						</div>
					</form>
				)}
			</FlyoutMenu>
		);
	}

	renderTableTitle() {
		return (
			<div className="grid-x grid-padding-x">
				<div className="small-12 medium-8 cell">
					<h1 className="table-title">Locations</h1>
				</div>
				<div className="small-12 medium-4 cell">
					<div className="new-item-button-cont">{this.renderEditForm()}</div>
				</div>
			</div>
		);
	}

	renderImageLoader(image, title) {
		return (
			<>
				<S3Image aws_object={this.aws} s3_path={image} placeholderImageURL={IMG_PLACEHOLDER} />
				{title}
			</>
		);
	}

	render() {
		return (
			<div className='AdminLocationManager'>
				<FlexTable tableData={{ headerData: LOCATIONS_TABLE_HEADERS, rows: this.state.table_rows }} 
				tableTitle={this.renderTableTitle()}
				sort={(e, title) => updateSorting(e, title, this, table_enums.location)}
				/>
				<>{this.state.isLoading &&<LoadingIndicator isLoading={this.state.isLoading}/>}</>
				
				<PageController pageNumber={this.state.currentPage} setPageNumber={(page)=>{
					this.setState({currentPage:page});
				}} numPages={this.state.numPages} />
			</div>
		);
	}
}

AdminLocationManager.propTypes={
	cities:PropTypes.array,
};

export default AdminLocationManager;