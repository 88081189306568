/*
Current Date - Displays the current date

A Current Date is a base level component, and contains no subcomponents
*/

import React from 'react';
import moment from 'moment';
class CurrentDate extends React.Component {
	render() {
		return(
			<div className={`CurrentDate `}>
				<p className='today-text'>Today</p>
				<p className='date-text'>{moment().format("MMMM D, YYYY")}</p>
				<p className='day-of-week-text'>{moment().format("dddd")}</p>
			</div>
		);
	}
}

export default CurrentDate;
