/*
MetaData - a display for a piece of metadata associated with an object

Meta Data is a base-level component, and contains no subcomponents
*/


import React from 'react';
import PropTypes from 'prop-types';

/*
props (component-level arguments):
	icon: the icon to use for this meta data (optional)
	text: the text to display
*/
class MetaData extends React.Component {
	render() {
		if(!this.props.text){
			return null;
		}
		return (
			<div className={`MetaData ${this.props.additionalClass}`}>
				{this.props.icon!==null?
					<div className='icon metadata-icon'>
						<span className={this.props.icon} />
					</div>
				:''}
				<p className='metadata-text'>
					{this.props.text}
				</p>
			</div>
		);
	}
}

MetaData.defaultProps={
	icon: null,
	text: '',
};

MetaData.propTypes={
	icon: PropTypes.string,
	text: PropTypes.string.isRequired,
};

export default MetaData;

