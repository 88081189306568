class BaseModel {
	constructor(created_at) {
		// if created_at is passed, it will use that value, otherwise, new Date();
		// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Details_of_the_Object_Model#JavaScript_4
		this.created_at = created_at || new Date().toISOString();
		
		//NOTE: these internal variables must be in snake case rather than lower camel case
		//because they are used as the serialization fields that get sent to the server
		
		this.modified_at = this.created_at;
		this.valid = true;
	}

	get_created_at() {
		return new Date(this.created_at);
	}

	get_modified_at() {
		return new Date(this.modified_at);
	}

	set_modified_at(modified_at) {
		this.modified_at = modified_at.toISOString();
	}

	get_valid() {
		return this.valid;
	}

	set_valid(is_valid) {
		this.valid = is_valid;
	}

	serialize(omit_fields=[],date_fields=['created_at','modified_at']) {
		let serial_obj={};
		let keys=Object.keys(this);
		for(let i=0;i<keys.length;i++){
			if(omit_fields.includes(keys[i])){
				continue;
			}
			if(date_fields.includes(keys[i])){
				serial_obj[keys[i]]=Math.floor((new Date(this[keys[i]])).getTime()/1000.0);
			}else{
				serial_obj[keys[i]]=this[keys[i]];
			}
		}
		return JSON.stringify(serial_obj);
	}

	deserialize(object_type, serialized_data) {
		let data = JSON.parse(serialized_data);
		let deserialized_data = Object.setPrototypeOf(data, object_type.prototype);
		deserialized_data.created_at = new Date(deserialized_data.get_created_at()).toISOString();
		deserialized_data.modified_at = new Date(deserialized_data.get_modified_at()).toISOString();
		return deserialized_data;
	}

	type_string() {
		return "string";
	}

	type_bool() {
		return "boolean";
	}

	type_number() {
		return "number";
	}

	type_array() {
		return "array";
	}

	type_date() {
		return "date";
	}

	type_object() {
		return "object";
	}

	type_function() {
		return "function";
	}

	type_check(value, expected_type) {
		return (typeof value === expected_type);
	}

	/** This function sets the given property within the object state; it returns true on success, false on failure (including failure due to type difference)
	 *
	 * @param child: the Model that called this function
	 * @param property: the name of the property
	 * @param value: the value to be set
	 * @param expectedType: the data type expected from the 'value' parameter
	 * @returns {boolean}: true if the given value is successfully set with the correct data type, false otherwise.
	 */
	generic_setter(child, property, value, expected_type) {
		// check type then set value, if wrong type -> return false;
		if (this.type_check(value, expected_type)) {
			child[property] = value;
			return true;
		} else if (value instanceof Date && expected_type === this.type_date()) {
			// checking type of Date separately because 'typeof' doesn't work with Date
			child[property] = value.toISOString();
			return true;
		} else if (value instanceof Array && expected_type === this.type_array()) {
			// checking type of Array separately because 'typeof' doesn't work with Array
			child[property] = value;
			return true;
		}
		return false;
	}
}

export default BaseModel;

