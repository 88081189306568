/*
NewsPost - A NewsPost page is a detaield view for a news article that uses the post ID params to get the information from an API

A NewsPost Page consists of a Search and CatTagLabelList components
*/

import React from 'react';
import { Link } from 'react-router-dom';

import { xml_fetch_info } from '../../react-utils/src/libajax.js';
import { parse_cookies } from '../../react-utils/src/libformat';
import { ERROR_RETRIEVING_NEWS, TRY_AGAIN_LATER, SESSION_ID } from '../../constants';
import { processError } from '../../projlibs/cookie-management';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CatTagLabelList from '../CatTagLabelList';
import moment from 'moment';
import MetaData from '../MetaData';

import S3Image from '../S3Image.jsx';
import config from '../../config';
import FileAttachment from '../PostComponents/FileAttachment.jsx';
import HerdRAAWSUtils from '../../projlibs/HerdRAAWSUtils';
/*
props (component-level arguments):
	No Props

params: 
	Post_id: The ID of the post, used to fetch from the API
*/
import { parseForInlineImages } from '../../projlibs/InlineImageFunctions';
import { DateAuthor } from '../PostMetaData/DateAuthor.jsx';
class NewsPost extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			post_id: 0,
			newsInfo: {},
			newsInfoContent:'',
			fileAttachmentUrls: [],
			fileAttachment: null
		};
		this.updateInfo = this.updateInfo.bind(this);
		this.aws = new HerdRAAWSUtils();

		//NOTE: we can't set the window title in the constructor or in componentDidMount
		//because we don't have the data loaded at that point
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.post_id !== this.props.match.params.post_id) {
			this.setState({
				post_id: this.props.match.params.post_id
			}, () => this.updateInfo());
		}
		if(prevState.newsInfoContent !== this.state.newsInfoContent){
			parseForInlineImages(this.aws);
		}
	}

	updateInfo() {
		const cookies = parse_cookies();
		const { match: { params } } = this.props;
		xml_fetch_info('/news/' + params.post_id,
			xhr => {
				const response = JSON.parse(xhr.response);
				let newsInfo = [response.Post][0];
				let newsInfoContent = newsInfo.content;
				if (newsInfo.file_s3_path) {
					newsInfo.fileNames = [];
					for (let i = 0; i < newsInfo.file_s3_path.length; i++) {
						newsInfo.fileNames[i] = {
							fileName: newsInfo.file_s3_path[i]
						};
						newsInfo.fileNames[i].getSignedUrlSuccess = (url) => {
							newsInfo.fileNames[i].signedUrl = url;
							this.forceUpdate();
						};
					}
					newsInfo.getSignedUrlError = function (error) {
						console.log(error);
					};
					for (let i = 0; i < newsInfo.fileNames.length; i++) {
						this.aws.getSignedUrl(config.bucket_name, newsInfo.fileNames[i].fileName, newsInfo.getSignedUrlError, newsInfo.fileNames[i].getSignedUrlSuccess);
					}
				}
				this.setState({ newsInfo, newsInfoContent }, () => {
					//log a view of this post which includes the title information for the post
					let ga_event={
						'event':'custom-pageview',
						'url':window.location.pathname,
						'title':'News - '+(this.state?.newsInfo?.title),
						'category':'news',
					};
					window.dataLayer.push(ga_event);
				});
			},
			{
				'Content-Type': 'application/json',
				Authorization: cookies[SESSION_ID]
			},
			function (error) {
				if (processError(error)) {
					return false;
				} else {
					toast.error(ERROR_RETRIEVING_NEWS + ' ' + TRY_AGAIN_LATER);
				}
			}
		);
	}

	renderHeader() {
		if (this.state.newsInfo.direct_file) {
			return null; // TODO: open the pdf
		} else if (this.state.newsInfo.img_s3_path) {
			return (
				<header>
					<S3Image aws_object={this.aws} additionalClasses='full' s3_path={this.state.newsInfo.img_s3_path} />
				</header>
			);
		} else {
			return (
				null
			);
		}
	}

	render() {
		return (
			<div className={'NewsPost '}>
				<div className='meta-row'>
					<Link className={'caret-left-link'} to='/news'>Back to News</Link>
					<CatTagLabelList category={this.state.newsInfo.category} tags={this.state.newsInfo.tags} />
				</div>
				<div className='post-content'>
					{this.renderHeader()}
					<div className='content-wrapper'>
						{this.state.newsInfo.title && <h1>{this.state.newsInfo.title}</h1>}
						<DateAuthor author={this.state.newsInfo.author_name} created_at={moment.unix(this.state.newsInfo.created_at).format('MMM. DD, YYYY')}/>
						{this.state.newsInfo.fileNames && this.state.newsInfo.fileNames.map((item, index) => {
							return <FileAttachment fileName={item.fileName} fileAttachmentLink={item.signedUrl} />;
						})}
						{/* This might use something else, I'm not sure what author_id is actually going to present. */}
						{this.state.newsInfo.author_id && <MetaData icon='fas fa-user' text={this.state.newsInfo.author_id} />}
						{this.state.newsInfoContent &&
							<div>
								<hr />
								<div className='content' dangerouslySetInnerHTML={{ __html: this.state.newsInfoContent }} />
							</div>
						}
					</div>
				</div>
			</div>
		);
	}
}

export default NewsPost;
