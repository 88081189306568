/*

	AdminEmployeeManager - the admin page for viewing, modifying, creating, and deleting employee information.

*/

import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import config from '../../../config';
import { get_form_data } from '../../../projlibs/libform.jsx';
import { xml_fetch_info, xml_send_info } from '../../../react-utils/src/libajax.js';
import { parse_cookies } from '../../../react-utils/src/libformat.js';
import { ERROR_RETRIEVING_EMPLOYEES, ERROR_USER_NOT_SAVED,ERROR_SAME_EMAIL, SUCCESS_USER_SAVED, SUCCESS_USER_DELETED, TRY_AGAIN_LATER, SESSION_ID, PATH_SEARCH, GENERIC_UPLOAD_ERROR_MESSAGE, UPLOAD_IMAGE_FILE_TYPES, EMPLOYEE_INFORMATION, EMPLOYEE_FORM_RESULTS } from '../../../constants.js';
import { processError } from '../../../projlibs/cookie-management.js';
import Multiselect from 'react-widgets/lib/Multiselect';
import InputDropdownBlock from '../../BasicInputs/InputDropdownBlock.jsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import FlyoutMenu from '../../FlyoutMenu.jsx';
import FlexTable from '../../FlexTable.jsx';
import S3Image from '../../S3Image.jsx';
import AdminManagerAbstract from '../AdminManagerAbstract.jsx';

import User from '../../../models/User.js';
import UploadWidget from '../../UploadWidget/UploadWidget.jsx';
import { getDepartments, getLocation } from '../../../projlibs/HelperNetworkCalls.js';
import TabSelectView from './Forms/TabSelectView';
import UserFormResults from './Forms/UserFormResults';
import { getUserFormResults } from '../../../projlibs/form-networking';
import { TrimInputFields, getAWSKey } from '../../../projlibs/HelperFunctions';
import HerdRAAWSUtils from '../../../projlibs/HerdRAAWSUtils';
import {TINY_MCE_BASE_PLUGINS} from "../../../constants";
import { LoadingIndicator } from '../../LoadingIndicator';
import { PageController } from '../../PageController';
import { table_enums, updateSorting } from '../../../projlibs/pagination-functions';

const IMG_PLACEHOLDER = require('../../../assets/icon_image.svg');

const EMPLOYEES_TABLE_HEADERS = [
	{ title: 'Name', field: 'formatted_name' },
	{ title: 'Department', field: 'formatted_department' },
	{ title: 'Office' , 'field': 'office_name'},
	{ title: 'Position', field: 'position' },
	{ title: 'Phone #', field: 'work_phone', sortable: false },
	{ title: 'Email', field: 'email', sortable: false },
	{ title: '', field: 'editIcon', onClick: 'editAction', sortable: false },
	{ title: '', field: 'deleteIcon', onClick: 'deleteAction', sortable: false }
];

/*
props (component-level arguments):
	departments: a list of departments for filtering purposes
	positions: a list of positions for filtering purposes
	match: url path information for path-based routing arguments

state (component-level globals):
	table_rows: an ordered list of data for display in a FlexTable
	filtered_dept: the department to filter on
	filtered_pos: the position to filter on
	edit_view_open, data_to_edit: see AdminManagerAbstract.jsx
*/
class AdminEmployeeManager extends AdminManagerAbstract {
	constructor(props) {
		super(props);
		this.state = {
			table_rows: [],
			filtered_dept: '',
			filtered_pos: '',
			edit_view_open: false,
			data_to_edit: null,
			departments: [],
			allAddresses: [],
			allAddressesDict: {},
			selectedDepartments: [],
			editorContent: '',
			uploadingDetailedPhoto: '',
			detailedPhotoObject: null,
			detailedPhoto: '',
			user_id: '',
			formView: EMPLOYEE_INFORMATION,
			userFormResults:[],
			selectedForm:null,
			submitting: false,
			inputs: {
				officeId: ''
			},
			numPages:1,
			currentPage: 0,
			isLoading:true,
			order_by:'&order_by=created_at&order_dir=desc'
		};
		this.aws = new HerdRAAWSUtils();
		this.getFilteredEmployeeInfo = this.getFilteredEmployeeInfo.bind(this);
		this.formatEmployeeInfo = this.formatEmployeeInfo.bind(this);
		this.handleUpdatedEmployeeInfo = this.handleUpdatedEmployeeInfo.bind(this);
		this.netCallSuccess = this.netCallSuccess.bind(this);
		this.netCallFailure = this.netCallFailure.bind(this);
		this.netActionSuccess = this.netActionSuccess.bind(this);
		this.netActionFailure = this.netActionFailure.bind(this);

		this.stripUserUiFields = this.stripUserUiFields.bind(this);
		this.saveUser = this.saveUser.bind(this);
		this.deleteUser = this.deleteUser.bind(this);
		this.openEditForm = this.openEditForm.bind(this);
		this.closeEditForm = this.closeEditForm.bind(this);

		this.renderEditForm = this.renderEditForm.bind(this);
		this.renderTableTitle = this.renderTableTitle.bind(this);
		this.renderImageLoader = this.renderImageLoader.bind(this);
		this.renderForm = this.renderForm.bind(this);
		this.renderSurveyResults=this.renderSurveyResults.bind(this);
		this.handleWYSIWYGInputChange = this.handleWYSIWYGInputChange.bind(this);
		this.resetFormState=this.resetFormState.bind(this);
	}


	componentDidMount() {
		getDepartments(this.successGetDepartments, this.failGetTags);
		getLocation(this.successGetLocations, this.failGetTags);
		getLocation( (res)=>{this.setState({ allAddressesDict: res });}, this.failGetTags, true);
	}

	resetFormState(){
		this.setState({
			edit_view_open: false,
			data_to_edit: null,
			selectedDepartments: [],
			editorContent: '',
			uploadingDetailedPhoto: '',
			detailedPhotoObject: null,
			detailedPhoto: '',
			formView: EMPLOYEE_INFORMATION,
			userFormResults:[],
			selectedForm:null,
			inputs: {
				officeId: ''
			}
		});
	}

	getDetailedPhotoObjectSuccess = obj => {

		this.setState({ detailedPhotoObject: obj });
	};

	getObjectFail = err => {
		console.log(err);
	};


	componentDidUpdate(prevProps, prevState) {
		if(prevState.user_id !== prevProps.match.params.user_id) {
			this.setState({
				user_id: prevProps.match.params.user_id
			}, () => this.getFilteredEmployeeInfo());
		}
		if(prevState.currentPage !== this.state.currentPage || prevState.order_by !== this.state.order_by){
			this.getFilteredEmployeeInfo();
		}
		
		//then editing a new post
		if (prevState.data_to_edit !== this.state.data_to_edit && this.state.data_to_edit) {
			let departments = [];
			for (let item in this.state.data_to_edit.user_departments) {
				if (this.state.data_to_edit.user_departments[item].Department.name) {
					departments.push(this.state.data_to_edit.user_departments[item].Department.name.toLowerCase());
				}
			}
			this.setState({
				selectedCategory: this.state.data_to_edit.category ? this.state.data_to_edit.category : null,
				selectedDepartments: departments,
				detailedPhoto: this.state.data_to_edit.photo_s3_path,
				editorContent: this.state.data_to_edit.desc,
				inputs: {
					officeId: this.state.data_to_edit.location_id ? parseInt(this.state.data_to_edit.location_id) : '',
				}
			});
			this.aws.getObject(config.bucket_name, this.state.data_to_edit.photo_s3_path, this.getObjectFail, this.getDetailedPhotoObjectSuccess);
		} else if (!this.state.data_to_edit && prevState.data_to_edit) {
			this.resetFormState();
		}
	}

	getDetailedPhotoObjectSuccess = obj => {
		this.setState({ detailedPhotoObject: obj });
	};

	getObjectFail = err => {
		console.log(err);
	};

	successGetLocations = locations => {
		this.setState({ allAddresses: locations });
	};

	successGetDepartments = departments => {
		this.setState({ departments });
	};

	failGetTags = error => {
		toast.error(error);
	};

	handleCreateDepartment = department => {
		let departments = [...this.state.departments];
		let selectedDepartments = [...this.state.selectedDepartments];
		selectedDepartments.push(department);
		departments.push(department);
		this.setState({ departments, selectedDepartments });
	};

	changeDepartments = value => {
		this.setState({ selectedDepartments: value });
	};

	formatEmployeeInfo(employee_info) {
		employee_info['formatted_name'] = this.renderImageLoader(employee_info.photo_s3_path, employee_info.first_name + ' ' + employee_info.last_name);
		employee_info['editIcon'] = 'fas fa-edit';
		employee_info['deleteIcon'] = 'fas fa-trash';
		employee_info['editAction'] = function() {
			this.openEditForm(employee_info);
		}.bind(this);
		employee_info['deleteAction'] = function() {
			this.deleteUser(employee_info);
		}.bind(this);
		employee_info['office_name']= this.state.allAddressesDict[employee_info.location_id];
		employee_info['cssClasses'] = {
			formatted_name: 'name title title-circle-thumbnail',
			editIcon: 'circle-border action-button secondary-color-icon',
			deleteIcon: 'action-button'
		};

		employee_info['formatted_department'] = '-';
		if (employee_info.hasOwnProperty('user_departments') && employee_info['user_departments'].length > 0) {
			employee_info['formatted_department'] = employee_info['user_departments'][0]['Department']['name'];
		}
		return employee_info;
	}

	//handle an employee update result
	//wher user_id is the user identifier for the user which was updated
	//and res is the result object
	handleUpdatedEmployeeInfo(res) {
		let info = [];
		let users = res['User'];
		let directory_info = users;
		if(!Array.isArray(directory_info)){
			directory_info=[directory_info];
		}
		for (let idx = 0; idx < directory_info.length; idx++) {
			const directory_item = directory_info[idx];
			info.push(this.formatEmployeeInfo(directory_item));
		}
		info = info.filter(user => user.is_enabled);

		this.setState({
			table_rows: info,
			isLoading: false, numPages: res.page_count
		});
	}


	getFilteredEmployeeInfo() {
		const dept = this.state.filtered_dept;
		const pos = this.state.filtered_pos;

		//make a network request to get the time-filtered data
		const cookies = parse_cookies();
		let url_path = PATH_SEARCH + '?table=User&user_type=employee';
		if (dept !== '') {
			url_path += '&department=' + encodeURIComponent(dept);
		}
		if (pos !== '') {
			url_path += '&position=' + encodeURIComponent(pos);
		}
		url_path+=`&page=${this.state.currentPage}&page_size=0&${this.state.order_by}`;
		if(this.state.user_id){
			url_path=`/employee/${this.state.user_id}`;
		}
		this.setState({isLoading:true},()=>{
			xml_fetch_info(
				url_path,
				this.netCallSuccess,
				{
					'Content-Type': 'application/json',
					Authorization: cookies[SESSION_ID]
				},
				this.netCallFailure
			);
		});

	}

	netCallSuccess(xhr) {
		this.setState({isLoading:false});
		let response = JSON.parse(xhr.response);
		if(response.hasOwnProperty('Employee')){
			response = response.Employee;
		}
		
		if (response.hasOwnProperty('User')) {
			this.handleUpdatedEmployeeInfo(response);
		} else {
			this.setState({
				table_rows: [],
				isLoading: false, numPages: response.page_count
			});
		}
	}

	netCallFailure(error) {
		if (processError(error)) {
			return false;
		} else {
			toast.error(ERROR_RETRIEVING_EMPLOYEES + ' ' + TRY_AGAIN_LATER);
		}
	}

	netActionSuccess(xhr) {
		let data = JSON.parse(xhr.responseText)['Employee']['User'];
		let table_rows = this.state.table_rows;

		//look through all existing users to see if this is an update to a previously-existing user
		let idx = 0;
		for (idx = 0; idx < table_rows.length; idx++) {
			//if this is a match
			if (table_rows[idx]['user_id'] - 0 === data['user_id'] - 0) {
				//then update the existing data
				table_rows[idx] = this.formatEmployeeInfo(data);
				//and stop looking
				break;
			}
		}
		//if we iterated through all existing posts and didn't find a matching id
		//then this is a new user and we should add it to the end of the list
		if (idx >= table_rows.length) {
			table_rows.push(this.formatEmployeeInfo(data));
		}

		//sort by date, newest first
		table_rows.sort((a, b) => (a['modified_at'] < b['modified_at'] ? 1 : a['modified_at'] > b['modified_at'] ? -1 : 0));

		//filter out disabled items
		table_rows = table_rows.filter(user => user.is_enabled);

		this.resetFormState();
		if (data.is_enabled) {
			toast.success(SUCCESS_USER_SAVED);
		} else {
			toast.success(SUCCESS_USER_DELETED);
		}
	}

	netActionFailure(xhr) {
		this.setState({submitting: false});
		if(xhr.status===409){
			toast.error(ERROR_SAME_EMAIL);
		}else{
			toast.error(ERROR_USER_NOT_SAVED + ' ' + TRY_AGAIN_LATER);
		}
	}

	stripUserUiFields(user) {
		//remove any fields that are only valid in the front end
		let uiFields = ['formatted_name', 'formatted_department', 'editIcon', 'editAction', 'deleteIcon', 'deleteAction', 'cssClasses'];
		for (let i = 0; i < uiFields.length; i++) {
			if (user.hasOwnProperty(uiFields[i])) {
				delete user[uiFields[i]];
			}
		}
		return user;
	}

	handleWYSIWYGInputChange(content, editor) {
		this.setState({
			editorContent: content
		});
	}

	saveUser(current_target) {
		if(!TrimInputFields('admin-manager-form')){
			return false;
		}
		let cookies = parse_cookies();

		let form_data = get_form_data(current_target);

		//NOTE: when editing existing users there will be a user_id value set
		//when creating a new user there will not be a user_id value set
		//this is intentional and it allows us to distinguish those cases
		let user = this.state.data_to_edit;
		if (user === null) {
			user = new User(null);
		}

		user.first_name = form_data['first_name'];
		user.last_name = form_data['last_name'];
		user.email = form_data['email'];
		user.supervisor = form_data['supervisor'];
		user.position = form_data['position'];
		user.personal_phone = form_data['personal_phone'];
		user.work_phone = form_data['work_phone'];

		if (this.state.detailedPhoto) {
			user.photo_s3_path = this.state.detailedPhoto;
		}
		user.department_names = this.state.selectedDepartments;
		user.desc = this.state.editorContent;
		user.location_id = parseInt(this.state.inputs.officeId);
		//for the user object that goes over the network
		//we must remove any fields that are only valid in the front end
		//so do that here
		let netUser = this.stripUserUiFields(user);
		delete netUser.employee_since;
		if (this.state.data_to_edit) {
			delete netUser.password;
		}
		netUser.location = xml_send_info('/employee', JSON.stringify(netUser), this.netActionSuccess, user.hasOwnProperty('user_id') ? 'PUT' : 'POST', { Authorization: cookies[SESSION_ID] }, this.netActionFailure);

		return false;
	}

	deleteUser(user) {
		let cookies = parse_cookies();

		//set the user to be disabled

		//remove any UI fields from what we send over the network
		let data = { user_id: user.user_id, is_enabled: false };
		xml_send_info('/employee', JSON.stringify(data), this.netActionSuccess, 'PUT', { Authorization: cookies[SESSION_ID] }, this.netActionFailure);
	}

	viewFormResults=(form)=>{
		this.setState({selectedForm:form});
	}

	openEditForm(data_to_edit = null) {
		let user_id=null;
		if(data_to_edit){
			user_id = data_to_edit.user_id;
		}
		this.setState({
			edit_view_open: true,
			formView: EMPLOYEE_INFORMATION,
			data_to_edit: data_to_edit,
			selectedForm:null,
			submitting:false
		},()=>getUserFormResults( user_id, (res)=>{this.setState({userFormResults:res});}, (err)=>{console.log(err);}, this.viewFormResults, true) );
	}

	closeEditForm() {
		this.resetFormState();
	}

	handleDropdownChange = event => {
		event.preventDefault();
		let inputs = { ...this.state.inputs };
		if (typeof event !== 'undefined') {
			inputs[event.currentTarget.id] = event.currentTarget.value;
			inputs.officeId = event.currentTarget.value;
			this.setState({
				inputs: inputs
			});
		}
	};

	uploadDetailedPhotoError = err => {
		toast.error(GENERIC_UPLOAD_ERROR_MESSAGE + ' ' + TRY_AGAIN_LATER);
		this.setState({ uploadingDetailedPhoto: false });
	};

	setDetailedPhotoSuccess = data => {
		let key = getAWSKey(data);
		this.setState({ detailedPhoto: key, uploadingDetailedPhoto: false });
	};

	saveDetailedPhoto = file => {
		this.setState({ detailedPhotoObject: null, uploadingDetailedPhoto: true }, () => this.aws.uploadFile(config.bucket_name, file.name, file, this.uploadDetailedPhotoError, this.setDetailedPhotoSuccess));
	};


	renderTabHeader(){
		if(this.state.data_to_edit){
			return(
				<TabSelectView
					selected={this.state.formView}
					tabs={[{name:'Employee Information', clickCallback:()=>this.setState({formView:EMPLOYEE_INFORMATION})},
					{name:'View Form Results', clickCallback:()=>{
						if(this.state.formView===EMPLOYEE_FORM_RESULTS){
							this.setState({selectedForm:null});
						}else{
							this.setState({formView:EMPLOYEE_FORM_RESULTS});
						}
					}}]}
				/>
			);
		}
	}
	renderForm(){
		let dflts =
			this.state.data_to_edit !== null
				? this.state.data_to_edit
				: {
						first_name: '',
						last_name: '',
						email: '',
						work_phone: '',
						personal_phone: '',
						position: '',
						supervisor: '',
						content: ''
				  };
		return(
			<form onSubmit={e=>{
				e.preventDefault();
				let current_target=e.currentTarget;
				this.setState({submitting:true},()=>{this.saveUser(current_target);});
			}}id="admin-manager-form">
				<div className="grid-x grid-padding-x grid-padding-y">
					<div className="small-12 cell">
						<h2 className="flyout-title">{this.state.data_to_edit === null ? 'Create a New Employee' : this.state.data_to_edit.first_name + ' ' + this.state.data_to_edit.last_name}</h2>
						{this.renderTabHeader()}
					</div>
					
					<div className="small-12 medium-8 large-7 cell">
						<div className="grid-x grid-padding-x grid-padding-y">

							<div className="small-12 medium-6 cell">
								<label htmlFor="input-first-name">Employee First Name</label>
								<input type="text" name="first_name" id="input-first-name" placeholder="..." required={true} defaultValue={dflts['first_name']} />
							</div>
							<div className="small-12 medium-6 cell">
								<label htmlFor="input-last-name">Employee Last Name</label>
								<input type="text" name="last_name" id="input-last-name" placeholder="..." required={true} defaultValue={dflts['last_name']} />
							</div>

							<div className="small-12 medium-6 cell">
								<label htmlFor="input-position">Position</label>
								<input type="text" name="position" id="input-position" placeholder="..." required={true} defaultValue={dflts['position']} />
							</div>
							{/* <div className="small-12 medium-6 cell">
								<label htmlFor="input-superv fisor">Supervisor</label>
								<input type="text" name="supervisor" id="input-supervisor" placeholder="..." required={false} defaultValue={dflts['supervisor']} />
							</div> */}

							<div className="small-12 medium-6 cell">
								<label htmlFor="input-work_phone">Office Phone</label>
								<input type="text" name="work_phone" id="input-work_phone" placeholder="..." defaultValue={dflts['work_phone']} />
							</div>
							<div className="small-12 medium-6 cell">
								<label htmlFor="input-personal_phone">Mobile Phone</label>
								<input type="text" name="personal_phone" id="input-personal_phone" placeholder="..." required={false} defaultValue={dflts['personal_phone']} />
							</div>

							<div className="small-12 medium-6 cell">
								<label htmlFor="input-tags">Departments</label>
								<Multiselect
									data={this.state.departments}
									value={this.state.selectedDepartments}
									onChange={this.changeDepartments}
									onCreate={department => this.handleCreateDepartment(department)}
									allowCreate="onFilter"
									textField="tags"
									placeholder="Select Departments..."
								/>
							</div>
							<div className="small-12 medium-6 cell">
								<InputDropdownBlock
									value={`${this.state.inputs.officeId}`}
									dropdownId={'office'}
									title="Office"
									callback={this.handleDropdownChange}
									defaultSelectText="- Select -"
									optionData={this.state.allAddresses}
									fieldName="office"
									key="office"
								/>
							</div>

							<div className="small-12 cell">
								<label htmlFor="input-email">Email</label>
								<input type="email" name="email" id="input-email" placeholder="..." required={true} defaultValue={dflts['email']} />
							</div>
							<div className="small-12 cell">
								<label htmlFor="input-content">Vendor Description</label>
								<Editor
									apiKey={config.tinymce_key}
									value={this.state.editorContent}
									id="input-content"
									name="content"
									init={{
										plugins: TINY_MCE_BASE_PLUGINS,
										height: 256,
										toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code formatpainter'
									}}
									onEditorChange={this.handleWYSIWYGInputChange}
								/>
							</div>
							<div className="small-12 cell">
								<input disabled={this.state.uploadingDetailedPhoto || this.state.submitting} className="show-for-medium" type="submit" value="Submit" />
							</div>
						</div>
					</div>
					<div className="small-12 medium-4 large-5 cell">
						<div className="upload-widget-container">
							<UploadWidget
								fileCategory="Detailed Photo"
								acceptedFileTypes={UPLOAD_IMAGE_FILE_TYPES}
								saveFile={this.saveDetailedPhoto}
								idUniqueIdentifier={'detailed-photo'}
								classIdentifier={'upload-detailed-photo'}
								showRequirements={false}
								fileObject={this.state.detailedPhotoObject}
								fileId={'upload-detailed-photo'}
								uploading={this.state.uploadingDetailedPhoto}
								preview={this.state.detailedPhoto}
							/>
						</div>
						<input disabled={this.state.uploadingDetailedPhoto || this.state.submitting} className="hide-for-medium" type="submit" value="Submit" />
					</div>
				</div>
			</form>
		);
	}

	renderSurveyResults(){
		return(
			<div className="grid-x grid-padding-x grid-padding-y">
				<div className="small-12 cell">
					<h2 className="flyout-title">{this.state.data_to_edit === null ? 'Create a New Employee' : this.state.data_to_edit.first_name + ' ' + this.state.data_to_edit.last_name}</h2>
					{this.renderTabHeader()}
					{this.state.userFormResults.length>0 && <UserFormResults
						selectedForm={this.state.selectedForm}
						userFormResults={this.state.userFormResults}
						goBackCallback={()=>{this.setState({
							formView:EMPLOYEE_FORM_RESULTS,
							selectedForm:null
						});}}
					/>}
				</div>
			</div>
		);
	}

	renderEditForm() {
		let showEmployeeInfo = this.state.formView === EMPLOYEE_INFORMATION;

		return (
			<FlyoutMenu id="edit-user-flyout" menuOpen={this.state.edit_view_open} buttonText="New Employee" closeButtonText="Cancel" openMenu={this.openEditForm} closeMenu={this.closeEditForm} haveOpenButton={true}>
				{this.state.edit_view_open && showEmployeeInfo ? this.renderForm(): this.renderSurveyResults()}
			</FlyoutMenu>
		);
	}

	renderTableTitle() {
		return (
			<div className="grid-x grid-padding-x">
				<div className="small-12 medium-8 cell">
					<h1 className="table-title">Employees</h1>
				</div>
				<div className="small-12 medium-4 cell">
					<div className="new-item-button-cont">
						{this.renderEditForm()}
					</div>
				</div>
			</div>
		);
	}

	renderImageLoader(image, title) {
		return (
			<>
				<S3Image aws_object={this.aws} s3_path={image} placeholderImageURL={IMG_PLACEHOLDER} />
				{title}
			</>
		);
	}

	render() {
		return (
			<div className='AdminEmployeeManager'>
				<FlexTable 
					tableData={{ headerData: EMPLOYEES_TABLE_HEADERS, rows: this.state.table_rows }} 
					tableTitle={this.renderTableTitle()} 
					sort={(e, title) => updateSorting(e, title, this, table_enums.employee)}
				/>
				<>{this.state.isLoading &&<LoadingIndicator isLoading={this.state.isLoading}/>}</>
				
				<PageController pageNumber={this.state.currentPage} setPageNumber={(page)=>{
					this.setState({currentPage:page});
				}} numPages={this.state.numPages} />
			</div>
		);
	}
}

AdminEmployeeManager.propTypes = {
	departments: PropTypes.array,
	positions: PropTypes.array
};

export default AdminEmployeeManager;
