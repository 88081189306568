/*
Login - An event page shows Event Information
*/

import React from 'react';
import { xml_send_info } from '../../react-utils/src/libajax.js';
import { sessionLogout, set_cookie }  from '../../projlibs/cookie-management.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { parse_cookies } from '../../react-utils/src/libformat';
import {
    AWS_TOKEN,
    AWS_IDENTITY_ID,
    SESSION_ID,
    DEPARTMENT,
    USER_ID,
    USER_IMAGE,
    IS_ADMIN,
    EMAIL,
    FIRST_NAME,
    LAST_NAME,
    LOCATION_NAME,
    ERROR_INVALID_USER_OR_PASS,
    ERROR_SIGNING_IN,
    RESET_PASS_ENDPOINT,
    ERROR_PASSWORDS_DONT_MATCH,
    MIN_PASSWORD_LENGTH,
    PASSWORD_TOO_SHORT,
    INVALID_RESET_LINK,
    SUCCESSFULLY_CHANGED_PASSWORD,
    RESET_PASS_EMAIL_SENT,
    NEW_PASSWORD_INVALID,
	ACCOUNT_DISABLED,
    EMAIL_DOES_NOT_EXIST
} from '../../constants';
import { getHeaders } from '../../projlibs/HelperNetworkCalls';
import { xml_rqst_info } from '../../react-utils/src/libajax';
import HerdRAAWSUtils from "../../projlibs/HerdRAAWSUtils";
const logo = require('../../assets/primary_white.svg');

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.submitLoginRequest=this.submitLoginRequest.bind(this);
		this.state={
			forgotPassword:false, // can be forgot password
			submitDisabled:false
		};

		this.aws = new HerdRAAWSUtils();

		this.forgotPasswordClickHandler=this.forgotPasswordClickHandler.bind(this);
		this.renderLoginForms=this.renderLoginForms.bind(this);
		this.renderLoginFields=this.renderLoginFields.bind(this);
		this.renderForgotPasswordFields=this.renderForgotPasswordFields.bind(this);
		this.renderResetPasswordFields=this.renderResetPasswordFields.bind(this);
		this.submitForgotPasswordRequest=this.submitForgotPasswordRequest.bind(this);
	}

	componentDidMount() {
		const cookies = parse_cookies();
		if(cookies.hasOwnProperty(SESSION_ID)){
			sessionLogout();
		}
	}

	forgotPasswordClickHandler(){
		this.setState({forgotPassword:!this.state.forgotPassword});
	}

	submitForgotPasswordRequest(){
		this.setState({submitDisabled:true});
		xml_send_info('/request-reset',JSON.stringify({email:document.getElementById('email').value}),xhr=>{
			this.setState({forgotPassword:false});
			toast.success(RESET_PASS_EMAIL_SENT);
			this.setState({submitDisabled:false});
		},'POST',getHeaders(),error=>{
			let response = JSON.parse(error.response);
			this.setState({submitDisabled:false});
			toast.error(`${error.status}: ${response.message}`);
		},noData=>{
			toast.error(EMAIL_DOES_NOT_EXIST);
			this.setState({submitDisabled:false});
		});
	}

	resetTokenIsValidRequest(resetToken){
		let password = document.getElementById('newPassword')?.value;
		let confirmedPassword = document.getElementById('confirmedPassword')?.value;
		if(password !== confirmedPassword){
			toast.error(ERROR_PASSWORDS_DONT_MATCH);
			return false;
		}
		if(password.length<MIN_PASSWORD_LENGTH){
			toast.error(PASSWORD_TOO_SHORT);
			return false;
		}else{
			let englishLetters = /^[A-Za-z0-9]*$/;
			let uppercase=0,digits=0,specialChars=0;;
			for (let i = 0; i < password.length; i++) {
				let char=password.charAt(i);
				if(!isNaN(char)){
					digits+=1;
				}else if(englishLetters.test(char)){
					if(char === char.toUpperCase()){
						uppercase+=1;
					}
				}else{
					specialChars+=1;
				}
			}
			if(uppercase===0||digits===0||specialChars===0){
				toast.error(NEW_PASSWORD_INVALID,{autoClose: 15000});
				return false;
			}
		}
		xml_send_info('/reset',JSON.stringify({password1:password,password2:confirmedPassword,reset_code:resetToken}),xhr=>{
			toast.success(SUCCESSFULLY_CHANGED_PASSWORD);
			this.setState({submitDisabled:true});
			setTimeout(function () {
				window.location.href = '/login';
			}, 5000);
		},'POST',getHeaders(),error=>{
			console.log(error);
			let response = JSON.parse(error.response);
			toast.error(`${error.status}: ${response.message}`);
		});
	}

	submitPasswordResetRequest(){
		this.setState({submitDisabled:true});
		let resetToken = new URLSearchParams(window.location.search);
		resetToken = resetToken.get('code');
		xml_rqst_info(`/reset?code=${resetToken}`,xhr=>{
			this.resetTokenIsValidRequest(resetToken);
			this.setState({submitDisabled:false});
		},'GET',{},getHeaders(),error=>{
			toast.error(INVALID_RESET_LINK);
			this.setState({submitDisabled:false});
		});
	}

	submitLoginRequest() {
		let data = {
			email: document.getElementById('email').value,
			password: document.getElementById('password').value,
			user_type: 'user'
		};

		xml_send_info(
			'/login?full=true',
			JSON.stringify(data),
			function(xhr) {
				let response = JSON.parse(xhr.response);
				let old_cookies=parse_cookies();

				// Set the users cookies when they sign in succesfully
				set_cookie(AWS_TOKEN, response?.AWSToken?.Token);
				set_cookie(AWS_IDENTITY_ID, response?.AWSToken?.IdentityId);
				set_cookie(SESSION_ID,response.Session.session_id);
				set_cookie(USER_ID,response.Session.user_id);
				set_cookie(EMAIL,response.User.email);
				set_cookie(FIRST_NAME,response.User.first_name);
				set_cookie(LAST_NAME,response.User.last_name);
				set_cookie(IS_ADMIN,response.User.is_admin);
				set_cookie(USER_IMAGE, response.User.photo_s3_path);

				if(response.User.user_departments && response.User.user_departments.length>0) {
					set_cookie(DEPARTMENT,response.User.user_departments.map(usr_dep => usr_dep.Department.name));
				} else {
					set_cookie(DEPARTMENT,null);
				}
				set_cookie(LOCATION_NAME,response.User?.Location?.name);

				//if this user was previously logged in, but not as the user they just logged in as
				if((old_cookies.hasOwnProperty('email')) && response.User.email!==old_cookies['email']){
					//reinitialize google analytics for the new user
					//so that the userData event is interpreted correctly
					//(this ends the current session; a new session is then created when the userData event is pushed)
					window.ga('send','pageview',{'sessionControl': 'end'});
				}

				// log the user data event in google analytics
				let ga_user_data={
					'event':'userData',
					'dept':response.User?.user_departments[0]?.Department?.name,
					'branchName':response.User?.Location?.name,
				};
				window.dataLayer.push(ga_user_data);

				if((typeof this.props.setUserInfoCallback)==='function'){
					this.props.setUserInfoCallback(response.User);
				}

				this.aws.initAWSInstance(response.AWSToken.IdentityId, response.AWSToken.Token).then(
					() => this.props.history.push('/')
				);
			}.bind(this),
			'POST',
			{ 'Content-Type': 'application/json' },
			function(error) {
				console.log(error);
				let options = {
					autoClose: 5000,
					type: toast.TYPE.ERROR,
					position: toast.POSITION.TOP_RIGHT
				};
				if(error.status===401){
					toast.error(
						<div className="toast-content">{ERROR_INVALID_USER_OR_PASS}</div>,
						options
					);
				}else if(error.status===460){
					toast.error(ACCOUNT_DISABLED);
				}else{
					toast.error(
						<div className="toast-content">{ERROR_SIGNING_IN}</div>,
						options
					);
				}
			}
		);
	}

	//TODO: implement this further later when models are ready in a separate branch and navigation and routing is set up.
	onLoginSubmit() {
		return event => {
			event.preventDefault();
			if(window.location.pathname==='/'+RESET_PASS_ENDPOINT){
				this.submitPasswordResetRequest();
			}else if(this.state.forgotPassword){
				this.submitForgotPasswordRequest();
			}else{
				this.submitLoginRequest();
			}
			return false;
		};
	}

	renderForgotPasswordFields(){
		return(
			<div className='input-text-fields'>
				<div className='email-wraper'>
					<label className={'input-title'} htmlFor={'email'}>Username</label>
					<input className={'input-field'} autoComplete='user-name' id={'email'} name={'email'} type={'text'} placeholder={'....'} required={true}/>
					<button type="button" onClick={this.forgotPasswordClickHandler} className='forgot-password-button'>Go Back</button>
				</div>
			</div>
		);
	}
	renderLoginFields(){
		return(
			<div className='input-text-fields'>
				<div className='email-wraper'>
					<label className={'input-title'} htmlFor={'email'}>Email</label>
					<input className={'input-field'} autoComplete='user-name' id={'email'} name={'email'} type={'text'} placeholder={'....'} required={true}/>
				</div>
				<div className='password-wrapper'>
					<label className={'input-title'} htmlFor={'password'}>Password</label>
					<input className={'input-field'} id={'password'} autoComplete='current-password' name={'password'} type={'password'} placeholder={'***********'} required={true}/>
					<button type="button" onClick={this.forgotPasswordClickHandler} className='forgot-password-button'>Reset Password</button>
				</div>
			</div>
		);
	}

	renderResetPasswordFields(){
		return(
			<div className='input-text-fields'>
				<div className='password-wrapper'>
					<label className={'input-title'} htmlFor={'newPassword'}>New Password</label>
					<input className={'input-field'} id={'newPassword'} autoComplete='new-password' name={'newPassword'} type={'password'} placeholder={'***********'} required={true}/>
				</div>
				<div className='password-wrapper'>
					<label className={'input-title'} htmlFor={'confirmedPassword'}>Confirm New Password</label>
					<input className={'input-field'} id={'confirmedPassword'} autoComplete='confirmedPassword' name={'confirmedPassword'} type={'password'} placeholder={'***********'} required={true}/>
					<p className='forgot-password-requirements'>Password must contain 10 characters including:</p>
					<ul className='forgot-password-requirements-list'>
						<li>An uppercase character</li>
						<li>A digit</li>
						<li>A special character</li>
					</ul>
				</div>
			</div>
		);
	}

	renderLoginForms(){
		if(window.location.pathname==='/'+RESET_PASS_ENDPOINT){
			return this.renderResetPasswordFields();
		}else if(this.state.forgotPassword){
			return this.renderForgotPasswordFields();
		}else{
			return this.renderLoginFields();
		}
	}

	render() {
		let loginText='Portal Login';
		let buttonText='login';
		if(window.location.pathname === '/'+RESET_PASS_ENDPOINT){
			loginText='Enter New Password';
			buttonText='submit';
		}else if(this.state.forgotPassword){
			loginText='Reset Password';
			buttonText='submit';
		}
		return(
			<div className={`Login`}>
				<div className='grid-x'>
					<div className='cell small-12 large-5 logo-image-container'>
						{logo && <img className="login-page-logo" alt="Project Logo" src={logo} />}
					</div>
					<div className='cell small-12 large-7 login-form-container'>
						<div className='grid-x'>
							<div className='cell small-12 large-10 large-offset-1'>
							<form className='login-form' onSubmit={this.onLoginSubmit()}>
								<h1 className='title'>{loginText}</h1>
								<div className='form-fields'>
									{this.renderLoginForms()}
								</div>

								<button className='login-button' type='submit' disabled={this.state.submitDisabled}>
									{buttonText}
								</button>
							</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Login;
