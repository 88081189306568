import { getHeaders } from './HelperNetworkCalls';
import { xml_send_info } from '../react-utils/src/libajax';
import { parse_cookies } from '../react-utils/src/libformat';
import { EMAIL, USER_ID } from '../constants';

export function getRecentMessages(successCallback,failCallback=function(){}){
	let cookies=parse_cookies();
	xml_send_info('/recent-messages',JSON.stringify({ 'email':cookies[EMAIL] }), xhr=>{
		let response=JSON.parse(xhr.response).RecentMessages;
		let formattedRecentMessages=[];
		if(response.length){
			for(let i=0;i<response.length;i++){
				let sendBirdData=JSON.parse(response[i].data);
				let senderData=sendBirdData[0];
				if(sendBirdData[0].id===cookies[USER_ID]){
					senderData=sendBirdData[1];
				}
				formattedRecentMessages.push({
					from_name:senderData['name'],
					from_photo:senderData['photo'],
					date:response[i].created_at,
					content:response[i].message
				});
			}
		}
		successCallback(formattedRecentMessages);
	}, 'POST', getHeaders(), error=>{
		failCallback(JSON.parse(error.response));
	},xhr=>{failCallback();});
}
