var config = {
        backend_host:'https://api.elaine.rhinoactive.com',
        //no theme (core only)
        //theme:null,
        //mcfarlan rowlands theme
        theme:'mcfr-theme',

        tinymce_key:'pmuv4c1ejzh8j599umt5c2v1qjl3qdlvicqkxq9nqkpx4u0u',
        bucket_name:'mcfr-elaine',
        IDENTITY_POOL_ID:'ca-central-1:c0125c0c-2543-4601-8a78-d113c06c623e',

        aws_host:'',
        aws_region_id:'ca-central-1',
        aws_service:'execute-api',
	aws_url: 'https://mcfr-elaine.s3.ca-central-1.amazonaws.com',

        // these keys can be empty, since session keys are acquired at login
        aws_access_key_id:'',
        aws_secret_access_key:'',

        sendbird_app_id:'6E37D0EE-45CB-4D28-974D-D3B43060695D',
	sendbird_delay_per_get_message_request:200, // in ms

	maxFileSizeMB:15,// the maximum file size allowed to be uploaded for the upload components.
	slider_speed_ms: 6000,
};

export default config;
