/*
ImageSlide - An Image Slide is a carousel for information to provide a brief view into pages and events at a glane

An Image Slide consists of a MetaData component
*/

import React from 'react';

import PropTypes from 'prop-types';
import MetaData from '../MetaData.jsx';
import DateTile from '../DateTile';
import S3Image from '../S3Image';

/*
props (component-level arguments):
	linkUrl: An optional link for the slide
	imageUrl: An optional background image
	title: An optional title
	metaData: An Optional array of meta data
*/
class ImageSlide extends React.Component {
	showMetaData() {
		let metaDataArray = [];
		for (let index = 0; index < this.props.metaData.length; index++) {
			let data = this.props.metaData[index];
			metaDataArray.push(<MetaData key={index} icon={data['icon']} text={data['data']} />);
		}
		return <div className='slide-meta-information'>{metaDataArray}</div>;
	}



	showSlideContent() {
		const SlideContainer = this.props.newPath ? 'a' : 'div' ;
		return (
			<SlideContainer className='slide-container' href={this.props.newPath}>
				<div className='slide-content-wrapper'>
					{this.props.date && <DateTile date={this.props.date} />}
					<h2 className={`slide-title ${this.props.date ? 'has-ellipsis' : ''}`}>{this.props.title}</h2>
					{this.showMetaData()}
				</div>
			</SlideContainer>
		);
	}

	render() {
		return (
			<div className={'ImageSlide '}>
				<S3Image aws_object={this.props.aws_object} s3_path={this.props.imageUrl} additionalClasses={'carousel'}/>
				{this.showSlideContent()}
			</div>
		);
	}
}

ImageSlide.propTypes = {
	linkUrl: PropTypes.string,
	imageUrl: PropTypes.string,
	title: PropTypes.string,
	metaData: PropTypes.array
};

ImageSlide.defaultProps = {
	title: '',
	metaData: []
};

export default ImageSlide;
