import React from 'react';
import { InputFieldBlock } from '../../../../BasicInputs/InputFieldBlock';
export const TextQuestion = ({ question, questionIndex, removeQuestion, isFollowUp, questionTypeLabel='Text', questionTypeFA='fa-text-width' }) => (
	<div className={`FieldQuestionLabel text ${isFollowUp?'follow-up':''}`}>
		<div className="field-label-content text">
			<div className="field-label-title">
				{questionTypeLabel}
				<i className={`fas ${questionTypeFA} textarea`}> </i>
			</div>
			<InputFieldBlock
				title="Question Title"
				callback={(e) => (question.title = e.currentTarget.value)}
				fieldValue={question.title}
				fieldRequired={true}
				fieldType="text"
				fieldName={`text-q-${questionIndex}`}
				fieldHint={'Enter the title of this question...'}
			/>
		</div>
		<div className="fa-label-container">
			<i
				className="far fa-trash-alt"
				onClick={() => removeQuestion()}
			></i>
		</div>
	</div>
);
