/*
	AdminEventManager - the admin page for creating, modifying, and deleting new events
*/

import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import config from '../../../config';

import FlexTable from '../../FlexTable.jsx';
import FlyoutMenu from '../../FlyoutMenu.jsx';
import FilteredComponent from '../../FilteredComponent.jsx';

import { xml_fetch_info, xml_send_info } from '../../../react-utils/src/libajax.js';
import { parse_cookies } from '../../../react-utils/src/libformat.js';

import { SESSION_ID, ERROR_RETRIEVING_POSTS, ERROR_POST_NOT_SAVED, TRY_AGAIN_LATER, SUCCESS_POST_SAVED, SUCCESS_POST_DELETED, GENERIC_UPLOAD_ERROR_MESSAGE, 
	ACCEPTED_FILE_TYPE_ARRAY, UPLOAD_IMAGE_FILE_TYPES } from '../../../constants.js';
	
import UploadWidget from '../../UploadWidget/UploadWidget.jsx';

import DateTimePicker from 'react-datetime-picker';
import 'react-widgets/dist/css/react-widgets.css';

import Multiselect from 'react-widgets/lib/Multiselect';
import { getTags, getCategories, getDepartments } from '../../../projlibs/HelperNetworkCalls.js';

import { processError } from '../../../projlibs/cookie-management';
import HerdRAAWSUtils from '../../../projlibs/HerdRAAWSUtils';
import MultiUploadWidget from '../../UploadWidget/MultiUploadWidget';
import { stripPostUiFields, savePreLoadedAttachments, genFormattedTableData, sortPostsByDate, savePost } from '../../../projlibs/PostHelperFunctions';
import {
    parseForInlineImages,
	getTinyMceInitSettings
} from '../../../projlibs/InlineImageFunctions';
import { TINY_MCE_NAME, TINY_MCE_ID, SHORT_ID_CHARACTERS } from '../../../constants';
import { getAWSKey } from '../../../projlibs/HelperFunctions';
import { LoadingIndicator } from '../../LoadingIndicator';
import { PageController } from '../../PageController';

const shortid = require('shortid');
shortid.characters(SHORT_ID_CHARACTERS); //  remove _ because it was making it hard to parse

/*
props (component-level arguments):
	none

state (component-global variables):
	postInfo: an ordered list of posts
	filteredPostInfo: a subset of postInfo filtered based off of the set filter parameters
	filteredCat: the string to use to filter by category
	filteredTag: the string to use to filter by tag
	editPostOpen: whether or not the new/edit post menu is open
	editingPost: the post being edited (null for a new post)
	editorContent: the WYSIWYG editor content field value
*/

class AdminEventManager extends FilteredComponent {
	constructor(props) {
		super(props);

		this.state = {
			date: new Date(),
			dateStart: new Date(),
			dateEnd: new Date(),
			tags: [],
			file_s3_path:'',
			selectedTags: [],
			departments: [],
			selectedDepartments: [],
			categories: [],
			selectedCategory: null,
			postInfo: [],
			filteredPostInfo: [],
			filteredCat: '',
			filteredTag: '',
			editPostOpen: false,
			editingPost: null,
			thumbnail: '',
			pdfOnly:false,
			uploadingThumbnail: false,
			detailedPhoto: '',
			uploadingDetailedPhoto: false,
			thumbnailObject: null,
			detailedPhotoObject: null,
			fileAttachmentKeys:[],
			fileAttachments:[],
			preloadedFiles:[],
			donePreloading:false,
			postBucketId:shortid.generate(),
			inlineImageIsUploading:false,
			submitting: false,
			inputs: {
				category: '',
				types: '',
				sendTimestamp: 0
			},
			numPages:1,
			currentPage: 0,
			isLoading:true,
			order_by:'&order_by=created_at&order_dir=desc',
			tableData:[],

			//NOTE: editorContent must be separate from editingPost.content
			//to handle the case of a newly-created post
			editorContent: ''
		};
		this.handleWYSIWYGInputChange = this.handleWYSIWYGInputChange.bind(this);
		this.deletePost = this.deletePost.bind(this);
		this.openEditForm = this.openEditForm.bind(this);
		this.closeEditForm = this.closeEditForm.bind(this);
		this.renderEditForm = this.renderEditForm.bind(this);
		this.renderTableTitle = this.renderTableTitle.bind(this);
		this.aws = new HerdRAAWSUtils();
	}

	componentDidMount() {
		this.fetchPostInfo();
		getTags(res=>{this.setState({ tags:res });}, this.failGetTags);
		getCategories(res=>{this.setState({ categories:res });}, this.failGetTags, 'event');
		getDepartments(res=>{this.setState({ departments:res });}, this.failGetTags);
	}

	componentDidUpdate(prevProps, prevState){
		if(prevState.currentPage !== this.state.currentPage || prevState.order_by !== this.state.order_by){
			this.fetchPostInfo();
		}
	}

	failGetTags = error => {
		toast.error(error);
	};

	fetchPostInfo() {
		let cookies = parse_cookies();
		//NOTE: although this says it's looking for "news" the server returns all post data of type 'post' at that endpoint
		//regardless of if it's in the News category or not
		let url='/search/?table=Post&type=event';
		if (this.props.match.params.event_id) {
			url = `/events/${this.props.match.params.event_id}`;
		}else{
			url+=`&page=${this.state.currentPage}&page_size=0&${this.state.order_by}`;
		}
		this.setState({isLoading:true}, ()=>{
			xml_fetch_info(
				url,
				xhr => {
					let data = JSON.parse(xhr.responseText);
					let posts = [];
					for (let idx = 0; idx < data['Event'].length; idx++) {
						if (data['Event'][idx].is_valid === false) {
							continue;
						}
						posts.push(data['Event'][idx]);
					}
					if (data['Event'].length === undefined) {
						// then it is a single file
						if (data['Event'].is_valid) {
							posts.push(data['Event']);
						}
					}

					posts = sortPostsByDate(posts);
					this.setState({
						postInfo: posts,
						filteredPostInfo: posts,
						filteredCat: '',
						filteredTag: '',
						isLoading:false,
						numPages:data.page_count
					});
				},
				{
					'Content-Type': 'application/json',
					Authorization: cookies[SESSION_ID]
				},
				(error)=> {
					this.setState({isLoading:false});
					if (processError(error)) {
						return false;
					} else {
						toast.error(ERROR_RETRIEVING_POSTS + ' ' + TRY_AGAIN_LATER);
					}
				}
			);
		});
	}

	handleWYSIWYGInputChange(content, editor) {
		this.setState({
			editorContent: content
		});
	}

	deletePost(post) {
		let cookies = parse_cookies();

		//set the post to be invalid
		post.is_valid = false;

		//remove any UI fields from what we send over the network
		let netPost = stripPostUiFields(post);
		let request_delete_data = {
			event_id: netPost.event_id,
			post_id: netPost.post_id,
			is_valid: false
		};
		xml_send_info(
			'/events',
			JSON.stringify(request_delete_data),
			xhr => {
				let data = JSON.parse(xhr.responseText)['Event'];
				let postInfo = this.state.postInfo;
				let newPostInfo = [];

				//look through all existing posts to see if this is an update to a previously-existing post
				let idx = 0;
				for (idx = 0; idx < postInfo.length; idx++) {
					//if this is a match
					if (postInfo[idx]['post_id'] - 0 === data['post_id'] - 0) {
						//then update the existing data
						postInfo[idx] = data;
					}
					//if this is invalid (as the new post should be if it saved correctly)
					//then don't include it in the new list
					if (postInfo[idx].is_valid === false) {
						continue;
					}
					newPostInfo.push(postInfo[idx]);
				}

				//sort by date, newest first
				newPostInfo = sortPostsByDate(newPostInfo);

				this.setState(
					{
						postInfo: newPostInfo,
						filteredPostInfo: newPostInfo,
						filteredTag: '',
						filteredCat: '',
						editPostOpen: false,
						editingPost: null,
						editorContent: ''
					},
					() => {
						toast.success(SUCCESS_POST_DELETED);
					}
				);
			},
			'PUT',
			{ Authorization: cookies[SESSION_ID] },
			xhr => {
				console.log(xhr.responseText);
				toast.error(ERROR_POST_NOT_SAVED + ' ' + TRY_AGAIN_LATER);
			}
		);
	}

	openEditForm(editingPost = null) {
		this.setState({
			editPostOpen: true,
			editingPost: editingPost,
			editorContent: editingPost !== null ? editingPost.content : '',
			postBucketId:shortid.generate(),
			inlineImageIsUploading:false,
			submitting:false,
		});
		if(editingPost){
			let departments = [];
			for (let item in editingPost.departments) {
				if (editingPost.departments[item].name) {
					departments.push(editingPost.departments[item].name.toLowerCase());
				}
			}
			this.setState({
				selectedTags: editingPost.tags,
				selectedCategory: editingPost.category ? editingPost.category : null,
				selectedDepartments: departments,
				date: new Date(editingPost.published_at * 1000),
				dateStart: new Date(editingPost.start * 1000),
				dateEnd: new Date(editingPost.end * 1000),
				detailedPhoto: editingPost.img_s3_path,
				thumbnail: editingPost.file_s3_path,
				pdfOnly:false,
				is_featured:editingPost.is_featured,
				editPostOpen: true,
				editingPost: editingPost,
				editorContent: editingPost !== null ? editingPost.content : '',
				donePreloading:false,
				preloadedFiles:[],
				fileAttachmentKeys:[],
				uploadingDetailedPhoto: false,
				detailedPhotoObject: null,
				postBucketId:shortid.generate(),
				inlineImageIsUploading:false,
				submitting:false,
			},()=>{
				if(editingPost.img_s3_path && editingPost.img_s3_path.length>0){
					this.aws.getObject(config.bucket_name, editingPost.img_s3_path, err=>{console.log(err);}, success=>{
						this.setState({ detailedPhotoObject: success });
					});
				}
				if(editingPost.file_s3_path && editingPost.file_s3_path[0]?.length>0){
					savePreLoadedAttachments(this,editingPost.file_s3_path);
				}
				parseForInlineImages(this.aws);
			});
		}
	}

	savePostNetworkRequest=(netPost)=>{
		let url = '/events';
		let cookies=parse_cookies();
		xml_send_info(
			url,
			JSON.stringify(netPost),
			xhr => {
				let data = JSON.parse(xhr.responseText)['Event'];
				let postInfo = this.state.postInfo;

				//look through all existing posts to see if this is an update to a previously-existing post
				let idx = 0;
				for (idx = 0; idx < postInfo.length; idx++) {
					//if this is a match
					if (postInfo[idx]['post_id'] - 0 === data['post_id'] - 0) {
						//then update the existing data
						postInfo[idx] = data;
						//and stop looking
						break;
					}
				}
				//if we iterated through all existing posts and didn't find a matching id
				//then this is a new post and we should add it to the end of the list
				if (idx >= postInfo.length) {
					postInfo.push(data);
				}

				//sort by date, newest first
				postInfo = sortPostsByDate(postInfo);

				this.setState(
					{
						postInfo: postInfo,
						filteredPostInfo: postInfo,
						filteredTag: '',
						filteredCat: '',
						editPostOpen: false,
						date: new Date(),
						dateStart: new Date(),
						dateEnd: new Date(),
						selectedTags: [],
						selectedDepartments: [],
						selectedCategory: null,
						editingPost: null,
						thumbnail: '',
						uploadingThumbnail: false,
						detailedPhoto: '',
						uploadingDetailedPhoto: false,
						pdfOnly:false,
						editorContent: '',
					},
					() => {
						toast.success(SUCCESS_POST_SAVED);
					}
				);
			},
			netPost.hasOwnProperty('post_id') ? 'PUT' : 'POST',
			{ Authorization: cookies[SESSION_ID] },
			xhr => {
				this.setState({submitting: false});
				toast.error(ERROR_POST_NOT_SAVED + ' ' + TRY_AGAIN_LATER);
			}
		);
	}

	closeEditForm() {
		this.setState({
			editPostOpen: false,
			editingPost: null,
			editorContent: '',
			pdfOnly:false,
			filteredTag: '',
			filteredCat: '',
			date: new Date(),
			dateStart: new Date(),
			dateEnd: new Date(),
			selectedTags: [],
			selectedDepartments: [],
			selectedCategory: null,
			thumbnail: '',
			uploadingThumbnail: false,
			detailedPhoto: '',
			uploadingDetailedPhoto: false,
			donePreloading:false,
			preloadedFiles:[],
			submitting:false,
			is_featured:false,
			file_s3_path:''
		});
	}

	uploadThumbnailError = err => {
		toast.error(GENERIC_UPLOAD_ERROR_MESSAGE + ' ' + TRY_AGAIN_LATER);
		this.setState({ uploadingThumbnail: false });
		console.log(err);
	};

	uploadDetailedPhotoError = err => {
		toast.error(GENERIC_UPLOAD_ERROR_MESSAGE + ' ' + TRY_AGAIN_LATER);
		this.setState({ uploadingDetailedPhoto: false });
	};

	setThumbnailSuccess = data => {
		let key = getAWSKey(data);
		this.setState({ thumbnail: key, uploadingThumbnail: false });
	};

	setDetailedPhotoSuccess = data => {
		let key = getAWSKey(data);
		this.setState({ detailedPhoto: key, uploadingDetailedPhoto: false });
	};

	saveFileAttachments=files=>{
		let file_s3_path=this.state.file_s3_path;
		if(!files){
			file_s3_path='';
		}
		files = files?files:[];
		this.setState({fileAttachments:files, file_s3_path:file_s3_path});
	}

	saveDetailedPhoto = file => {
		this.setState({ detailedPhotoObject: null, uploadingDetailedPhoto: true }, () => this.aws.uploadFile(config.bucket_name, file.name, file, this.uploadDetailedPhotoError, this.setDetailedPhotoSuccess));
	};

	onChangeDateStart = date => {
		let minutes = new Date(date);
		minutes = minutes.getMinutes();
		if (minutes < 1) {
			const minutesInput = document.getElementsByClassName('react-datetime-picker__inputGroup__minute')[0];
			minutesInput.value = 0;
		}
		this.setState({ dateStart: date });
	};

	onChangeDateEnd = date => {
		let minutes = new Date(date);
		minutes = minutes.getMinutes();
		if (minutes < 1) {
			const minutesInput = document.getElementsByClassName('react-datetime-picker__inputGroup__minute')[0];
			minutesInput.value = 0;
		}
		this.setState({ dateEnd: date });
	};

	changeDepartments = value => {
		this.setState({ selectedDepartments: value });
	};

	handleCreateCategory = category => {
		let categories = [...this.state.categories];
		categories.push(category);
		this.setState({ categories, selectedCategory: category });
	};

	changeCategory = value => {
		this.setState({ selectedCategory: value[value.length - 1] });
	};

	handleCreateDepartment = department => {
		let departments = [...this.state.departments];
		let selectedDepartments = [...this.state.selectedDepartments];
		selectedDepartments.push(department);
		departments.push(department);
		this.setState({ departments, selectedDepartments });
	};

	changeTags = value => {
		this.setState({ selectedTags: value });
	};

	handleCreateTag = tag => {
		let tags = [...this.state.tags];
		let selectedTags = [...this.state.selectedTags];
		selectedTags.push(tag);
		tags.push(tag);
		this.setState({ tags, selectedTags });
	};

	handleDropdownChange = event => {
		event.preventDefault();
		let inputs = { ...this.state.inputs };
		if (typeof event !== 'undefined') {
			inputs[event.currentTarget.id] = event.currentTarget.value;
			this.setState({
				inputs: inputs
			});
		}
	};

	renderUploadWidgets=()=>{
		return(
			<div className="upload-widget-container">
				<UploadWidget
					fileCategory="Featured Image"
					acceptedFileTypes={UPLOAD_IMAGE_FILE_TYPES}
					saveFile={this.saveDetailedPhoto}
					fileObject={this.state.detailedPhotoObject}
					idUniqueIdentifier={'detailed-photo'}
					classIdentifier={'upload-detailed-photo'}
					showRequirements={true}
					fileId={'upload-detailed-photo'}
					uploading={this.state.uploadingDetailedPhoto}
					preview={this.state.detailedPhoto}
				/>
				<MultiUploadWidget
					fileCategory="Attachment"
					acceptedFileTypes={ACCEPTED_FILE_TYPE_ARRAY}
					saveFile={this.saveFileAttachments}
					idUniqueIdentifier={'thumbnail'}
					classIdentifier={'upload-thumbnail'}
					fileId={'upload-thumbnail'}
					allowAllFileSizes={true}
					preloadedFiles={this.state.preloadedFiles}
					donePreloading={this.state.donePreloading}
				/>
			</div>
		);
	}

	handlePDFCheckbox=()=>{
		this.setState({
			thumbnail:null,
			uploadingThumbnail:null,
			thumbnailObject: null,
			detailedPhoto:null,
			uploadingDetailedPhoto:null,
			detailedPhotoObject: null,
			pdfOnly: !this.state.pdfOnly,
		});
	}
	handleFeaturedCheck=()=>{
		this.setState({is_featured: !this.state.is_featured});
	}
	renderEditForm() {
		let dflts =
			this.state.editingPost !== null
				? this.state.editingPost
				: {
						title: '',
						is_featured: false,
						category: 'News',
						tags: [],
						content: '',
						contact_name: '',
						contact_phone: '',
						address: '',
						selectedDepartments: '',
						selectedCategory: '',
						selectedTags: '',
						dateStart: new Date(),
						dateEnd: new Date()
				  };
		let multiSelectCategory = [];
		if(this.state.selectedCategory){
			multiSelectCategory.push(this.state.selectedCategory);
		}
		return (
			<FlyoutMenu id="edit-post-flyout" menuOpen={this.state.editPostOpen} buttonText="New Event" closeButtonText="Cancel" openMenu={this.openEditForm} closeMenu={this.closeEditForm} haveOpenButton={true}>
				{this.state.editPostOpen && (
					<form onSubmit={e=>{
						e.preventDefault();
						let current_target=e.currentTarget;
						this.setState({submitting:true},()=>{savePost(current_target,this, 'event');});
					}}id="admin-manager-form">
						<div className="grid-x grid-padding-x grid-padding-y">
							<div className="small-12 cell title-cell">
								<h2 className="flyout-title">{this.state.editingPost === null ? 'Create a New Event' : 'Editing Event ' + this.state.editingPost.title}</h2>
							</div>
							<div className="small-12 medium-8 large-7 cell content-cell">
								<div className="grid-x grid-margin-x grid-padding-y">

									<div className="small-6 cell is-featured-cell">
											<label className="input-checkbox-cont" htmlFor="input-is-featured">
											<input type="checkbox" id="input-is-featured" name="is_featured" onChange={this.handleFeaturedCheck} checked={this.state.is_featured} defaultChecked={dflts['is_featured']} />
												Featured Post
											</label>
										</div>

									<div className="small-12 cell title-cell">
										<label htmlFor="input-title">Title</label>
										<input type="text" name="title" id="input-title" placeholder="..." required={true} defaultValue={dflts['title']} />
									</div>

									<div className="small-12 cell location-cell">
										<label htmlFor="input-address">Location / Address</label>
										<input type="text" name="address" id="input-category" placeholder="..." defaultValue={dflts['address']} />
									</div>

									<div className="small-12 cell contact_name-cell">
										<label htmlFor="input-contact_name">Contact Name</label>
										<input type="text" name="contact_name" id="input-contact_name" placeholder="..." defaultValue={dflts['contact_name']} />
									</div>

									<div className="small-12 cell contact_phone-cell">
										<label htmlFor="input-contact_phone">Contact Information</label>
										<input type="text" name="contact_phone" id="input-contact_phone" placeholder="..." defaultValue={dflts['contact_phone']} />
									</div>

									<div className="small-12 cell datetime-cell">
										<label htmlFor="input-title">Start Time</label>
										<DateTimePicker
											onChange={this.onChangeDateStart}
											value={this.state.dateStart}
											disableClock={true}
											// showLeadingZeros={false}
										/>
									</div>

									<div className="small-12 cell datetime-cell">
										<label htmlFor="input-title">End Time</label>
										<DateTimePicker
											onChange={this.onChangeDateEnd}
											value={this.state.dateEnd}
											disableClock={true}
											// showLeadingZeros={false}
										/>
									</div>

									<div className="small-12 cell">
										<label htmlFor="input-tags">Tags</label>
										<Multiselect data={this.state.tags} value={this.state.selectedTags} onChange={this.changeTags} onCreate={tag => this.handleCreateTag(tag)} allowCreate="onFilter" textField="tags" placeholder="Select Tags..." />
									</div>

									<div className="cell small-12 medium-6 large-6">
										<label htmlFor="input-title">Category</label>
										<Multiselect
											data={this.state.categories}
											value={multiSelectCategory}
											onChange={this.changeCategory}
											onCreate={category => this.handleCreateCategory(category)}
											allowCreate="onFilter"
											textField="Category"
											placeholder="Select Category"
										/>
									</div>
									<div className="small-12 cell">
										<label htmlFor="input-tags">Departments</label>
										<Multiselect
											data={this.state.departments}
											value={this.state.selectedDepartments}
											onChange={this.changeDepartments}
											onCreate={department => this.handleCreateDepartment(department)}
											allowCreate="onFilter"
											textField="tags"
											placeholder="Select Departments..."
										/>
									</div>
									{!this.state.pdfOnly &&
										<div className="small-12 cell">
											<label htmlFor="input-content">Content</label>
											<input className="invisible-fileId" id='my-file' type='file'></input>
											<Editor
												apiKey={config.tinymce_key}
												value={dflts['content']}
												id={TINY_MCE_ID}
												name={TINY_MCE_NAME}
												init={getTinyMceInitSettings(this,shortid)}
												onEditorChange={this.handleWYSIWYGInputChange}
											/>
										</div>
									}
									<div className="small-12 cell">
										<input className="show-for-medium" disabled={this.state.submitting || this.state.uploadingDetailedPhoto} type="submit" value="Submit" />
									</div>
								</div>
							</div>
							<div className="small-12 medium-4 cell upload-cell">
								{this.renderUploadWidgets()}
								<input className="hide-for-medium" disabled={this.state.submitting || this.state.uploadingDetailedPhoto} type="submit" value="Submit" />
							</div>
						</div>
					</form>
				)}
			</FlyoutMenu>
		);
	}

	renderTableTitle() {
		return (
			<div className="grid-x grid-padding-x">
				<div className="small-12 medium-8 cell">
					<h1 className="table-title">Event Manager</h1>
				</div>
				<div className="small-12 medium-4 cell">
					<div className="new-item-button-cont">{this.renderEditForm()}</div>
				</div>
			</div>
		);
	}

	render() {
		let tableData = genFormattedTableData(this, true);
		return (
			<div className="AdminPostManager">
				<FlexTable tableData={tableData} tableTitle={this.renderTableTitle()} />
				<>{this.state.isLoading &&<LoadingIndicator isLoading={this.state.isLoading}/>}</>
				
				<PageController pageNumber={this.state.currentPage} setPageNumber={(page)=>{
					this.setState({currentPage:page});
				}} numPages={this.state.numPages} />
			</div>
		);
	}
}

export default AdminEventManager;