/*
 FileAttachment - an attachment icon that writes out the file name inside and opens the attached file when clicked


*/

import React from 'react';
import pdf_icon from '../../assets/icon_PDF_brand_gold.svg';
/*
props (component-level arguments):

	fileName: string - the name of the file to display
	fileAttachmentLink: a a signed url to the file to display/download

*/
import { openAlreadySignedURLFile, stripShortIDAndBucket } from '../../lib/libdownloadFiles';
class FileAttachment extends React.Component {
	openPDF = e => {
		e.preventDefault();
		openAlreadySignedURLFile(this.props.fileAttachmentLink,this.props.fileName,null);
	};

	render() {
		if(!this.props.fileAttachmentLink){
			return null;
		}
		return (
			<div className={'FileAttachment'} onClick={e => this.openPDF(e)}>
				{pdf_icon && <img className='pdf-post' alt='pdf post' src={pdf_icon} />}
				<span className='file-attachment-name'>{this.props.fileName?stripShortIDAndBucket(this.props.fileName):'No-name'}</span>
			</div>
		);
	}
}

export default FileAttachment;
