/*
IconButton - a specially formatted button that uses a font awesome icon instead of text

A Icon Button is a base level component and has no subcomponents
*/

import React from 'react';
import PropTypes from 'prop-types';

/*
props (component-level arguments):
	backgroundIcon: The FontAwesome class for the foreground icon
	foregroundIcon: The FontAwesome class for the background icon
	iconSize: A linear scaling class that increases the em value of the icons fontSize
	buttonColorType: Either Primary or Danger. Depicting whether the default color is primary or the hover is the alert/danger color
	disabled: Denotes whether the component has the disabled attribute
	callback: the callback function for when the IconButton is activated
	extraClasses: additional classes for display purposes
*/
class IconButton extends React.Component {

	render() {
		return(
			<button disabled={this.props.disabled} className={`IconButton ${this.props.extraClasses}`} onClick={this.props.callback}>
				{/* New in FontAwesome 5, The fa-stack class adds a set height/width and the position:relative style
				The fa-stack-2x makes the icon twice the size as normal, so it treats it as a background element that is not reliant on a height/width of it's parent. */}
				<div className={`fa-stack ${this.props.iconSize}`}>
					<i className={`${this.props.backgroundIcon} fa-stack-2x`}></i>
					<i className={`${this.props.foregroundIcon} ${this.props.buttonColorType} fa-stack-1x fa-inverse`}></i>
				</div>
			</button>
		);
	}
}

IconButton.propTypes = {
	backgroundIcon: PropTypes.string,
	foregroundIcon: PropTypes.string.isRequired,
	iconSize: PropTypes.string,
	buttonColorType: PropTypes.string,
	disabled: PropTypes.bool,
	callback: PropTypes.func,
	extraClasses: PropTypes.string
}

IconButton.defaultProps = {
	backgroundIcon: 'fas fa-circle',
	foregroundIcon: 'fa',
	iconSize: 'fa-1x',
	buttonColorType: '',
	disabled: false,
	callback:PropTypes.func,
	extraClasses: ''
}

export default IconButton;
