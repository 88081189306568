export function updateSorting(e, title, component, table) {
	component.setState({currentPage:0, order_by: setColumns(e, title, table)});
	e.currentTarget.dataset.sortorder = e.currentTarget.dataset.sortorder === 'asc' ? 'desc' : 'asc';
};

function setColumns(e, title, table) {
	switch(table){
		case table_enums.forms:
			switch(title) {
				case 'Form title':
					return `order_by=title&order_dir=${e.currentTarget.dataset.sortorder}`;
				case 'Date Created':
					return `order_by=created_at&order_dir=${e.currentTarget.dataset.sortorder}`;
				default:
					return '';
			}
		case table_enums.company:
			switch(title) {
				case 'Company Name':
					return `order_by=name&order_dir=${e.currentTarget.dataset.sortorder}`;
				case 'Department':
					return `order_by=department&order_dir=${e.currentTarget.dataset.sortorder}`;
				case 'Office':
					return `order_by=location&order_dir=${e.currentTarget.dataset.sortorder}`;
				case 'Position':
					return `order_by=position&order_dir=${e.currentTarget.dataset.sortorder}`;
				default:
					return '';
			}
		case table_enums.location:
			switch(title) {
				case 'Location Name':
					return `order_by=name&order_dir=${e.currentTarget.dataset.sortorder}`;
				case 'Address':
					return `order_by=address&order_dir=${e.currentTarget.dataset.sortorder}`;
				case 'Office':
					return `order_by=location&order_dir=${e.currentTarget.dataset.sortorder}`;
				case 'City':
					return `order_by=city&order_dir=${e.currentTarget.dataset.sortorder}`;
				case 'Position':
					return `order_by=position&order_dir=${e.currentTarget.dataset.sortorder}`;
				default:
					return '';
			}
		case table_enums.employee:
			switch(title) {
				case 'Name':
					return `order_by=name&order_dir=${e.currentTarget.dataset.sortorder}`;
				case 'Department':
					return `order_by=department&order_dir=${e.currentTarget.dataset.sortorder}`;
				case 'Office':
					return `order_by=location&order_dir=${e.currentTarget.dataset.sortorder}`;
				case 'Position':
					return `order_by=position&order_dir=${e.currentTarget.dataset.sortorder}`;
				default:
					return '';
			}
		case table_enums.files:
			switch(title) {
				case 'Post Title':
					return `order_by=title&order_dir=${e.currentTarget.dataset.sortorder}`;
				case 'Date Posted':
					return `order_by=published_at&order_dir=${e.currentTarget.dataset.sortorder}`;
				case 'Title':
					return `order_by=title&order_dir=${e.currentTarget.dataset.sortorder}`;
				case 'Date':
					return `order_by=published_at&order_dir=${e.currentTarget.dataset.sortorder}`;
				case 'Format':
					return `order_by=mime_type&order_dir=${e.currentTarget.dataset.sortorder}`;
				default:
					return '';
			}
		case table_enums.news:
			switch(title) {
				case 'Post Title':
					return `order_by=title&order_dir=${e.currentTarget.dataset.sortorder}`;
				case 'Date Posted':
					return `order_by=published_at&order_dir=${e.currentTarget.dataset.sortorder}`;
				case 'Start Date':
					return `order_by=start&order_dir=${e.currentTarget.dataset.sortorder}`;
				default:
					return '';
			}
		case table_enums.events:
			switch(title){
				case 'Post Title':
					return `order_by=title&order_dir=${e.currentTarget.dataset.sortorder}`;
				case 'Date Posted':
					return `order_by=published_at&order_dir=${e.currentTarget.dataset.sortorder}`;
				default:
					return '';
			}
		default:
			return '';
	}
};
export const table_enums = {
	company:'company',
	location: 'location',
	employee: 'employee',
	files: 'files',
	news: 'news',
	events: 'events',
	forms: 'forms'
};
