/*

	Forms - the view for employees to take forms

*/

import React from 'react';
import FlexTable from '../../FlexTable';
import { getForms } from '../../../projlibs/form-networking';
import Form from '../../../models/Form';
import { processError } from '../../../projlibs/cookie-management';
import { toast } from 'react-toastify';
import { ERROR_RETRIEVING_FORMS, TRY_AGAIN_LATER } from '../../../constants';
import FlyoutMenu from '../../FlyoutMenu';
import FormQuestions from './FormQuestions';
import { FormFilter } from '../../FormFilter';
import {table_enums, updateSorting} from "../../../projlibs/pagination-functions";

/*
props (component-level arguments):

*/

const FORM_MANAGER_TABLE_HEADERS = [
	{ title: 'Form title', field: 'title' },
	{ title: 'Date Created', field: 'prettyCreatedDate', sortField: 'created_at'},
	{ title: '', field: 'editIcon', onClick: 'openAction', sortable: false },
];

class Forms extends React.Component {
    constructor(props){
        super(props);
		this.state = {
			forms: [],
			table_rows:[],
			formOpen: false,
			current_form:null,
			currentTag: '',
			order_by:'order_by=title&order_dir=asc'

		};
		this.populateTableSuccessHandler = this.populateTableSuccessHandler.bind(this);
		this.populateTableErrorHandler = this.populateTableErrorHandler.bind(this);
		this.getAllForms = this.getAllForms.bind(this);
    }

    componentDidMount() {
        getForms(this.populateTableSuccessHandler, this.populateTableErrorHandler, '', this.state.order_by);
    }
	getAllForms=(tag='')=>{
		this.setState({currentTag: tag}, ()=>{
			getForms(this.populateTableSuccessHandler, this.populateTableErrorHandler, tag, '', this.state.order_by);
		});
	}
	componentDidUpdate(prevProps, prevState) {
		console.log('componentDidUpdate');
		if(prevState.order_by !== this.state.order_by){
			getForms(this.populateTableSuccessHandler, this.populateTableErrorHandler, '', this.state.order_by);
		}
	}

	populateTableSuccessHandler = response => {
		response = JSON.parse(response).Form;
		let forms = [];
		for (let item in response) {
			let form = new Form();
			form.setValues(response[item]);
			if(form.valid){
                form['openAction'] = function() {
                    this.openForm(form);
				}.bind(this);
				form['cssClasses'] = {
					// formattedTitle: 'title',
					editIcon: 'circle-border action-button secondary-color-icon'
				};
                forms.push(form);
			}
		}
		this.setState({forms});
    };

	populateTableErrorHandler = error => {
		if (processError(error)) {
			return false;
		} else {
			toast.error(ERROR_RETRIEVING_FORMS + ' ' + TRY_AGAIN_LATER);
		}
    };

	renderFlyout() {
		return (
			<FlyoutMenu id="edit-user-flyout" menuOpen={this.state.formOpen} closeButtonText="Cancel" openMenu={this.openForm} closeMenu={this.closeForm} haveOpenButton={false}>
				{this.state.formOpen&&<FormQuestions closeForm={this.closeForm} forms={this.state.current_form}/>}
            </FlyoutMenu>
		);
	}

	renderTableTitle() {
		return (
			<div className="grid-x grid-padding-x">
				<div className="small-12 medium-8 cell">
					<span className="table-title">Forms</span>
                    {this.renderFlyout()}
				</div>
			</div>
		);
    }

    openForm=(form)=>{
	//log a view of this post which includes the title information for the post
	let ga_event={
		'event':'ga-event',
		'action':'view',
		'category':'form',
		'label':form.title+' ('+form.form_id+')',
	};
	window.dataLayer.push(ga_event);
        this.setState({formOpen:true, current_form:form});
    }

    closeForm=()=>{
        this.setState({formOpen:false, current_form:null},()=>getForms(this.populateTableSuccessHandler, this.populateTableErrorHandler, this.state.currentTag));
    }

	render() {
		return (
			<div className={'Forms'}>
				<FormFilter tagChangeCallback={this.getAllForms}/>
				<FlexTable tableData={{ headerData: FORM_MANAGER_TABLE_HEADERS, rows: this.state.forms }}
						   tableTitle={this.renderTableTitle()}
						   sort={(e, title) => updateSorting(e, title, this, table_enums.forms)}/>
			</div>
		);
	}
}

export default Forms;
