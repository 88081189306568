/*
Brief View - a summary view such as for an event list or news list

A Brief View consists of (from left to right and top to bottom)
	Thumbnail - an image for the object
	MetaData - metadata for the object, in this case the date, along with an associated icon
	BriefViewTitle - the title for this view
	Excerpt - the primary text content for this view

An Extended Brief View (a type of brief view) consists of (from left to right and top to bottom)
	DateView - an abbreviated display showing the month and day of month
	BriefViewTitle - the title for this view
	MetaData - metdata for the object, consisting of start_time and end_time
	MetaData - metdata for the object consisting of address
	Excerpt - the primary text content for this view
	CatTagLabelList - the labels for the category and tags for the object
	Thumbnail - an image for the object
*/

import React from 'react';
import PropTypes from 'prop-types';

import S3Image from '../S3Image.jsx';
import MetaData from '../MetaData.jsx';
import BriefViewTitle from './BriefViewTitle.jsx';
import Excerpt from '../Excerpt.jsx';
import DateView from '../DateView.jsx';
import CatTagLabelList from '../CatTagLabelList.jsx';
import moment from 'moment';
import { applyMaxSummaryLength } from '../../projlibs/HelperFunctions';
import { DateAuthor } from '../PostMetaData/DateAuthor.jsx';

/*
props (component-level arguments):
	object: the object for which to display a brief view
		this object is expected to contain the following required fields:
			title: the title which we will display
			created_at: the timestamp when this field was made as a UTC Unix timestamp integer
			thumbnail: the thumbnail image which we will display
			excerpt: the excerpt text which we will display
		if an extended display is used, then this object is expected to ALSO contain the following fields:
			date: the date of the event, in iso 8601 format
			start_time: the time at which the event starts, for an event
			end_time: the time at which the event ends, for an event
			address: the address this object is associated with
			category: the category name, as a human-readable string
			tags: the list of tags, each entry of which is a human-readable string
	display_type: the type of display, as a strong (current options are regular and extended)
		the "extended" display moves the thumbnail to the right side and adds date, category, and tag information
*/
class BriefView extends React.Component {
	render_regular(object) {
		const dateMoment = moment.unix(object.created_at);
		
		if(object.direct_file){
			return (
				<div className='BriefView regular'>
					<div className="BriefView-flex-cont">
						<S3Image aws_object={this.props.aws_object} additionalClasses='image-content' s3_path={object.img_s3_path} altText={object.title} />
						<div className="content-wrapper">
							<MetaData icon='far fa-calendar-alt' text={dateMoment.format('MMM. DD, YYYY')} />
							<BriefViewTitle text={object.title} />
						</div>
					</div>
				</div>
			);
		}else{
			let content=applyMaxSummaryLength(object.desc);
			// because content.length was being cut off previously in the admin manager posts were set to have 128 characters despite being cut off
			// the equal to sign will readd the ... for posts that were cut off here
			return (
				<div className='BriefView regular'>
					<div className="BriefView-flex-cont">
						<S3Image aws_object={this.props.aws_object} additionalClasses='image-content' s3_path={object.img_s3_path} altText={object.title} />
						<div className="content-wrapper">
							<DateAuthor author={object.author_name} created_at={dateMoment.format('MMM. DD, YYYY')}/>
							<BriefViewTitle text={object.title} />
							<Excerpt text={content} />
						</div>
					</div>
				</div>
			);
		}

	}

	render_extended(object) {
		let content=applyMaxSummaryLength(object.desc);
		return (
			<div className='BriefView extended BriefView-extended'>
				<div className="grid-x">
					<div className="small-12 medium-2 large-1 cell date-cell">
						{object.date && <DateView date={(new Date(object.date))} />}
					</div>
					<div className="small-12 medium-7 large-8 cell excerpt-cell">
						<BriefViewTitle text={object.title} />
						<MetaData icon='far fa-clock' text={object.start_time+' - '+object.end_time} />
						<MetaData icon='fas fa-map-marker-alt' text={object.address} />
						<Excerpt text={content} />
						<CatTagLabelList category={object.category} tags={object.tags} />
					</div>
					<div className="small-12 medium-3 large-3 cell thumbnail-cell">
						<S3Image aws_object={this.props.aws_object} s3_path={object.img_s3_path} altText={object.title} />
					</div>
				</div>
			</div>
		);
	}

	render_upcoming_events(object) {
		const timeStartMoment = moment.unix(object.start);
		const timeEndMoment = moment.unix(object.end);
		return (
			<div className='BriefView extended Briefview-upcoming-evnets'>
				<div className="grid-x">
					<div className="small-12 medium-7 large-8 cell excerpt-cell">
						<BriefViewTitle text={object.title} />
						<MetaData additionalClass='meta-date' text={timeStartMoment.format('MMM. DD, YYYY')} />
						<MetaData additionalClass='meta-time' text={`${timeStartMoment.format('h:mma')} - ${timeEndMoment.format('hh:mma')}`} />
					</div>
				</div>
			</div>
		);
	}
	
	render_pdf_news_archive(object){
		const dateMoment = moment.unix(object.published_at);
		return (
			<div className='BriefView extended BriefView-archive BriefView-news-archive'>
				<div className="thumbnail-cell">
					<S3Image aws_object={this.props.aws_object} s3_path={object.img_s3_path} altText={object.title} />
				</div>
				<div className="content-cont">
					<div className="excerpt-cell">
						<BriefViewTitle text={object.title} />
						<DateAuthor author={object.author_name} flexDirection="row" created_at={dateMoment.format('MMM. DD, YYYY')}/>
						{(object.category || object.tags) && <CatTagLabelList category={object.category} tags={object.tags} />}
					</div>
				</div>
			</div>
		);
	}
	render_news_archive(object) {
		const dateMoment = moment.unix(object.published_at);
		let content=applyMaxSummaryLength(object.desc);
		return (
			<div className='BriefView extended BriefView-archive BriefView-news-archive'>
				<div className="thumbnail-cell">
					<S3Image aws_object={this.props.aws_object} s3_path={object.img_s3_path} altText={object.title} />
				</div>
				<div className="content-cont">
					<div className="excerpt-cell">
						<BriefViewTitle text={object.title} />
						<DateAuthor author={object.author_name} flexDirection="row" created_at={dateMoment.format('MMM. DD, YYYY')}/>
						<Excerpt text={content} />
						{(object.category || object.tags) && <CatTagLabelList category={object.category} tags={object.tags} />}
					</div>
				</div>
			</div>
		);
	}
	
	render_pdf_events_archive(object){
		const timeStartMoment = moment.unix(object.start);
		const timeEndMoment = moment.unix(object.end);
		const dateMoment = moment.unix(object.start);
		const dateMomentEnd = moment.unix(object.end);
		let content=applyMaxSummaryLength(object.desc);
		return (
			<div className='BriefView extended BriefView-archive BriefView-events-archive pdf'>
				<div className="thumbnail-cell">
					<S3Image aws_object={this.props.aws_object} s3_path={object.img_s3_path} altText={object.title} />
				</div>
				<div className="content-cont">
					<div className="date-cell">
						{dateMoment && <DateView endDate={new Date(dateMomentEnd)} date={(new Date(dateMoment))} />}
					</div>
					<div className="excerpt-cell">
						<BriefViewTitle text={object.title} />
						<MetaData icon='far fa-clock' text={`${timeStartMoment.format('hh:mma')} - ${timeEndMoment.format('hh:mma')}`} />
						<MetaData icon='fas fa-map-marker-alt' text={`${object.address}`} />
						<Excerpt text={content} />
						{(object.category || object.tags) && <CatTagLabelList category={object.category} tags={object.tags} />}
					</div>
				</div>
			</div>
		);
	}
	render_events_archive(object) {
		const timeStartMoment = moment.unix(object.start);
		const timeEndMoment = moment.unix(object.end);
		const dateMoment = moment.unix(object.start);
		const dateMomentEnd = moment.unix(object.end);
		let content=applyMaxSummaryLength(object.desc);
		return (
			<div className='BriefView extended BriefView-archive BriefView-events-archive'>
				<div className="thumbnail-cell">
					<S3Image aws_object={this.props.aws_object} s3_path={object.img_s3_path} altText={object.title} />
				</div>
				<div className="content-cont">
					<div className="date-cell">
						{dateMoment && <DateView endDate={new Date(dateMomentEnd)} date={(new Date(dateMoment))} />}
					</div>
					<div className="excerpt-cell">
						<BriefViewTitle text={object.title} />
						<MetaData icon='far fa-clock' text={`${timeStartMoment.format('hh:mma')} - ${timeEndMoment.format('hh:mma')}`} />
						<MetaData icon='fas fa-map-marker-alt' text={`${object.address}`} />
						<Excerpt text={content} />
						{(object.category || object.tags) && <CatTagLabelList category={object.category} tags={object.tags} />}
					</div>
				</div>
			</div>
		);
	}

	render() {
		let object=this.props.object;
		
		if(object===null){
			return (
				<div className='BriefView'>
				</div>
			);
		}

		//if we were asked to render an extended view then do that
		if(this.props.display_type===BriefView.DISPLAY_TYPES.EXTENDED){
			return this.render_extended(object);
		}
		
		if(this.props.display_type===BriefView.DISPLAY_TYPES.NEWS_ARCHIVE){
			return this.render_news_archive(object);
		}

		if(this.props.display_type === BriefView.DISPLAY_TYPES.NEWS_PDF_ARCHIVE){
			return this.render_pdf_news_archive(object);
		}

		if(this.props.display_type === BriefView.DISPLAY_TYPES.EVENTS_PDF_ARCHIVE){
			return this.render_pdf_events_archive(object);
		}
		if(this.props.display_type===BriefView.DISPLAY_TYPES.EVENTS_ARCHIVE){
			return this.render_events_archive(object);
		}
		
		if(this.props.display_type===BriefView.DISPLAY_TYPES.UPCOMING_EVENTS){
			return this.render_upcoming_events(object);
		}

		//if we got here and didn't return then fall back to the regular rendering
		return this.render_regular(object);
	}
}

BriefView.defaultProps={
	object:null,
	display_type:'regular',
};

BriefView.propTypes={
	object:PropTypes.object.isRequired,
	display_type:PropTypes.string.isRequired,
};

BriefView.DISPLAY_TYPES={
	EXTENDED:'extended',
	NEWS_ARCHIVE:'news_archive',
	EVENTS_ARCHIVE:'events_archive',
	UPCOMING_EVENTS:'upcoming_events',
	NEWS_PDF_ARCHIVE: 'news_archive_pdf',
	EVENTS_PDF_ARCHIVE: 'events_archive_pdf'
};


export default BriefView;


