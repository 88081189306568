/*
Calendar Arrows - Left and Right arrows for controlling the month the calendar is on.

A Calendar Arrows is a base level component, and contains no subcomponents
*/

import React from 'react';
import PropTypes from 'prop-types';

const DIRECTION_PAST=-1;
const DIRECTION_FUTURE=1;

/*
props (component-level arguments):
	monthChangeCallback: the function to call when a button is clicked
		the argument passed to this function will be -1 for "left" and +1 for "right"
*/
class CalendarArrows extends React.Component {
	constructor(props){
		super(props);
		
		this.handleMonthChange=this.handleMonthChange.bind(this);
	}
	
	handleMonthChange(direction){
		if(this.props.monthChangeCallback!==undefined && ((typeof this.props.monthChangeCallback)==='function')){
			this.props.monthChangeCallback(direction);
		}
	}
	
	render() {
		return(
			<div className={`CalendarArrows `}>
				<button className='calendar-arrow-left' onClick={() => this.handleMonthChange(DIRECTION_PAST)}>
					<span className="fa fa-caret-left"></span>
				</button>
				<button className='calendar-arrow-right' onClick={() => this.handleMonthChange(DIRECTION_FUTURE)}>
					<span className="fa fa-caret-right"></span>
				</button>
			</div>
		);
	}
}

CalendarArrows.propTypes={
	monthChangeCallback:PropTypes.func,
};

export default CalendarArrows;
