/*
ButtonRow - A ButtonRow is a row of buttons that are all contained in a single container

A ButtonRow contains an array of buttons.
*/

import React from 'react';
import PropTypes from 'prop-types';

/*
props (component-level arguments):
	buttonList: A List of objects to filter with, each of which consists of the following:
		text: the text of the button to display
		callback: the callback that triggers when this button is clicked
	selectedButtonIndex: The index of the currently-selected button
	changeSelectCallback: A callback function for when the button selection is changed
*/
class ButtonRow extends React.Component {
	buttonOnChange(index) {
		this.props.changeSelectCallback(index);
		if(this.props.buttonList.length>index){
			if(this.props.buttonList[index].hasOwnProperty('callback')){
				this.props.buttonList[index].callback();
			}
		}
	}

	getButtonList() {
		let buttonArray = [];

		for(let index = 0; index < this.props.buttonList.length; index++) {
			buttonArray.push(<button onClick={() => this.buttonOnChange(index)} key={index} className={this.props.selectedButtonIndex === index ? 'selected' : ''}>{this.props.buttonList[index]['text']}</button>);
		}

		return buttonArray;
	}

	render() {
		return(
			<div className={`ButtonRow `}>
				{this.getButtonList()}
			</div>
		);
	}
}

ButtonRow.propTypes = {
	buttonList: PropTypes.array,
	selectedButtonIndex: PropTypes.number.isRequired,
	changeSelectCallback: PropTypes.func.isRequired,
}

ButtonRow.defaultProps = {
	buttonList: [],
}

export default ButtonRow;
