import { MAX_SUMMARY_LENGTH } from '../constants';

export function applyMaxSummaryLength(content){
    if(content.length>=MAX_SUMMARY_LENGTH){
        return content.substring(0,MAX_SUMMARY_LENGTH)+'...';
    }
    return content;
}

// this function will be used to remove leading and trailing white spaces for all input fields inside of a form with an id equal to the id supplied
export function TrimInputFields(formId){
    let form = document.getElementById(formId);
    let FormInputValid=true;
    if(form && form.elements){
        for (var i = 0; i < form.elements.length; i++) {
            let element = form.elements[i];
            if(element.type==='text'){
                element.value = element.value.trim();
                if(element.required && element.value === ''){
                    FormInputValid=false;
                }
            }
        }
    }
    return FormInputValid;
}

export function checkSimiliarMonth(startMonth, endMonth){
    if(startMonth===endMonth){
        endMonth='';
    }
    return endMonth;
}

export function checkSimiliarDay(startDay, endDay, startMonth, endMonth){
    if(startDay===endDay && startMonth!==endMonth){
        endDay='';
    }
    return endDay;
}

/*
    If the file size is large and has to be uploaded in chunks then the s3 path is stored in a variable called Key
    otherwise its stored in a variable called key
*/
export function getAWSKey(data){
    if(data.hasOwnProperty('Key')){
        return data.Key;
    }return data.key;
}

export function removeDuplicateTags(tags) {
    let uniqueTagHashMap = {};
    let uniqueTags = [];
    for (let tag in tags){
        if(tags[tag] in uniqueTagHashMap){
            continue;
        }else{
            uniqueTagHashMap[tags[tag]]=tags[tag];
            uniqueTags.push(tags[tag]);
        }
    }
    return uniqueTags;
}
