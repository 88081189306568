/*
CompanyDirectory - 


*/

import React from 'react';

import { xml_fetch_info } from '../../react-utils/src/libajax.js';
import { parse_cookies } from '../../react-utils/src/libformat';
import {ERROR_RETRIEVING_DIRECTORY, TRY_AGAIN_LATER, SESSION_ID} from '../../constants';
import {processError} from '../../projlibs/cookie-management';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import FilteredComponent from '../FilteredComponent.jsx';
import FlexTable from '../FlexTable.jsx';
import Filters from '../Filters.jsx';
import FlyoutMenu from '../FlyoutMenu.jsx';
import BarStylePageMenu from '../BarStylePageMenu.jsx';
import S3Image from '../S3Image.jsx';
import { InnerEmployees, InnerLocations, InnerCompanies } from '../FlyoutMenuInner.jsx';
import { getLocation } from '../../projlibs/HelperNetworkCalls.js';
import HerdRAAWSUtils from "../../projlibs/HerdRAAWSUtils";
import { LoadingIndicator } from '../LoadingIndicator';
import { PageController } from '../PageController';
import { table_enums, updateSorting } from '../../projlibs/pagination-functions.js';
import config from '../../config.js';
const imagePlaceholder = require('../../assets/icon_image.svg');

/*
	props (component-level arguments):	
*/

const headerInformationEmployees = [
	{'title':'Name','field':'name'},
	{'title':'Department','field':'department'},
	{'title': 'Office' , 'field':'office_name'},
	{'title':'Position','field':'position'},
	{'title':'Phone #','field':'phone','sortable':false},
	{'title':'Email','field':'email','sortable':false},
	{'title':'','field':'actionIcon','onClick':'actionEvent','sortable':false}
];

const headerInformationLocations = [
	{'title':'Location Name','field':'name'},
	{'title':'City','field':'city'},
	{'title':'Address','field':'address','sortable':false},
	{'title':'Office Phone #','field':'phone','sortable':false},
	{'title':'Office Email','field':'email','sortable':false},
	{'title':'','field':'actionIcon','onClick':'actionEvent','sortable':false}
];

const headerInformationCompanies = [
	{'title':'Company Name','field':'name'},
	{'title':'Portal','field':'portal_url','sortable':false, 'isLink':true},
	{'title':'Address','field':'address','sortable':false},
	{'title':'Phone #','field':'phone','sortable':false, 'customClass':'customMinWidth'},
	{'title':'','field':'actionIcon','onClick':'actionEvent','sortable':false}
];


class CompanyDirectory extends FilteredComponent {
	constructor(props) {
		super(props);
		this.state = {
			filters:[],
			tableData: {},
			locationDictionary:{},
			dirType: '',
			directoryDetailedInformationOpen: false,
			directoryInformationBeingViewed: null,
			numPages:1,
			currentPage: 0,
			isLoading:true,
			order_by:'&order_by=created_at&order_dir=desc',
		};

		this.aws = new HerdRAAWSUtils();

		this.loadHeaderData = this.loadHeaderData.bind(this);
		this.handleUpdatedDirectory = this.handleUpdatedDirectory.bind(this);
		this.openDirectoryDetailedInformation = this.openDirectoryDetailedInformation.bind(this);
		this.closeDirectoryDetailedInformation = this.closeDirectoryDetailedInformation.bind(this);
		this.renderInformation = this.renderInformation.bind(this);
		this.getDirectoryName = this.getDirectoryName.bind(this);
		this.getDirectoryArray = this.getDirectoryArray.bind(this);
		this.renderImageLoader = this.renderImageLoader.bind(this);
		this.getFilteredData=this.getFilteredData.bind(this);
		this.netCallSuccess=this.netCallSuccess.bind(this);
		this.netCallFailure=this.netCallFailure.bind(this);
	}

	getDirectoryName() {
		if (this.props.match.params.dirType === 'employee') {
			return 'Employee';
		} else if (this.props.match.params.dirType === 'location') {
			return 'Location';
		} else if (this.props.match.params.dirType === 'company') {
			return 'Company';
		} else {
			return 'Company Directory';
		}
	}

	getDirectoryArray() {
		if (this.props.match.params.dirType === 'employee') {
			return 'User';
		} else if (this.props.match.params.dirType === 'location') {
			return 'Company';
		} else if (this.props.match.params.dirType === 'company') {
			return 'Company';
		} else {
			return null;
		}
	}
	
	getFlyoutMenuInner() {
		const dibv = this.state.directoryInformationBeingViewed;
		
		let dirType=this.props.match.params.dirType;

		if (dirType === 'employee') {
			return <InnerEmployees dibv={dibv} />;
		} else if (dirType === 'location') {
			return <InnerLocations dibv={dibv} />;
		} else if (dirType === 'company') {
			return <InnerCompanies dibv={dibv} />;
		} else {
			return null;
		}
	}

	loadHeaderData() {
		if (this.props.match.params.dirType === 'employee') {
			return headerInformationEmployees;
		} else if (this.props.match.params.dirType === 'location') {
			return headerInformationLocations;
		} else if (this.props.match.params.dirType === 'company') {
			return headerInformationCompanies;
		} else {
			return [
				{'title':'Fake','field':'name'},
				{'title':'Fake','field':'department'},
				{'title':'Fake','field':'position'},
				{'title':'Fake','field':'phone','sortable':false},
				{'title':'Fake','field':'email','sortable':false},
				{'title':'','field':'actionIcon','onClick':'actionEvent','sortable':false}
			];
		}
	}

	handleUpdatedDirectory(res, id) {
		var directoryInfo;
		var info=[];
		if(this.props.match.params.dirType.toLowerCase() === 'employee') {
			directoryInfo = res;
			for(let idx=0;idx<directoryInfo.length;idx++){
				const directoryItem = directoryInfo[idx];
				if( directoryItem.is_enabled && ( id === null || parseInt(id)===directoryItem.user_id)){
					const officeName = directoryItem.Location ? directoryItem.Location.name : '';
					const employeeInfo = {
						'name':this.renderImageLoader(directoryItem.photo_s3_path, `${directoryItem.first_name} ${directoryItem.last_name}`),
						'fullName': `${directoryItem.first_name} ${directoryItem.last_name}`,
						'position': directoryItem.position,
						'bio': directoryItem.desc,
						'phone': directoryItem.work_phone,
						'mobile_phone': directoryItem.personal_phone,
						'email': directoryItem.email,
						'department': directoryItem.user_departments.map(dep => dep.Department.name),
						'photo_s3_path': directoryItem.photo_s3_path,
						'location_id': directoryItem.location_id,
						'office': officeName,
						'actionIcon': 'fas fa-angle-right',
						'actionEvent':() => this.openDirectoryDetailedInformation(employeeInfo),
						'cssClasses':{'name':'name title title-circle-thumbnail','actionIcon':'action-button no-circle-icon circle-border'},
						'office_name': this.state.locationDictionary[directoryItem.location_id]
					};
					info.push(employeeInfo);
				}
			}
		} else if (this.props.match.params.dirType.toLowerCase() === 'location') {
			directoryInfo = res;
			for(let parent_idx=0;parent_idx<directoryInfo.length;parent_idx++) {
				const directoryParent = directoryInfo[parent_idx];
				const directoryChildren = directoryParent.hasOwnProperty('locations')?(directoryParent.locations):[directoryParent];
				for(let child_idx=0;child_idx<directoryChildren.length;child_idx++) {
					const directoryItem = directoryChildren[child_idx];
					if(!directoryItem.is_valid || directoryItem.company_id!==1){
						continue;
					}
					if( id !== null && parseInt(id)!==directoryItem.location_id ) {
						continue;
					}
					const locationInfo = {
						'name': this.renderImageLoader(directoryItem.photo_s3_path, `${directoryItem.name}`),
						'fullName': directoryItem.name,
						'city': directoryItem.city,
						'address': `${directoryItem.address}, ${directoryItem.city}, ${directoryItem.postal_code}, ${directoryItem.country}`,
						'phone': directoryItem.contact_phone,
						'description': directoryParent.desc,
						'supervisor': directoryItem.manager_name,
						'photo_s3_path': directoryItem.photo_s3_path,
						'departments': [
							{
								'name': 'design'
							},
							{
								'name': 'development'
							}
						],
						'email': directoryItem.contact_email,
						'actionIcon': 'fas fa-angle-right',
						'actionEvent':() => this.openDirectoryDetailedInformation(locationInfo),
						'cssClasses':{'name':'name title title-square-thumbnail','actionIcon':'action-button no-circle-icon circle-border'}
					};

					info.push(locationInfo);
				}
			}
		} else if (this.props.match.params.dirType.toLowerCase() === 'company') {
			directoryInfo = res;
			for(let parent_idx=0; parent_idx<directoryInfo.length;parent_idx++) {
				const directoryParent = directoryInfo[parent_idx];
				if(directoryParent.valid){
					const directoryChildren = directoryParent.locations;
					for(let child_idx=0;child_idx<directoryChildren.length;child_idx++) {
						const directoryItem = directoryChildren[child_idx];
						if(directoryItem.is_primary && ( id === null || parseInt(id)===directoryItem.company_id)){
							const clientInfo = {
								'name': this.renderImageLoader(directoryItem.photo_s3_path, `${directoryItem.name}`),
								'companyName': directoryItem.name,
								'industry': directoryParent.industry,
								'address': this.formatAddress(directoryItem),
								'phone': directoryItem.contact_phone,
								'email': directoryItem.contact_email,
								'status': directoryParent.status,
								'description': directoryParent.desc,
								'photo_s3_path': directoryItem.photo_s3_path,
								'public_website': directoryParent.public_website,
								'portal_url': directoryParent.portal_url,
								'phone_fax': directoryItem.phone_fax,
								'actionIcon': 'fas fa-angle-right',
								'actionEvent':() => this.openDirectoryDetailedInformation(clientInfo),
								'cssClasses':{'name':'name title title-square-thumbnail','actionIcon':'action-button no-circle-icon circle-border', 'status':`status-cell ${directoryParent.status}`}
							};
							info.push(clientInfo);
						}
					}
				}
			}
		}
		this.setState({
			tableData: {'headerData': this.loadHeaderData(), 'rows': info}
		});
	}

	formatAddress(directoryItem) {
		if(directoryItem.address) {
			let formattedAddress = [directoryItem.address, directoryItem.city, directoryItem.state_prov, directoryItem.postal_code, directoryItem.country].filter(elm=>(elm !== null && elm !== undefined && elm !== '')).join(', ');
			return formattedAddress;
		}

		return '';
	}

	renderImageLoader(image, title) {
		return (<>
			<S3Image aws_object={this.aws} s3_path={image} placeholderImageURL={imagePlaceholder} />
			{title}
		</>);
	}

	getDepartmentFilter=()=>{
		let filters=this.state.filters;
		if(this.props.location.department) {
			filters['department']=this.props.location.department;
		} else {
			filters['department']='';
		}
		return filters;
	}

	UNSAFE_componentWillMount(){
		if(this.props.match.params.dirType === 'employee'){
			let filters = this.getDepartmentFilter();
			this.setState({
				filters:filters,
				dirType:this.props.match.params.dirType,
				directoryId: this.props.match.params.directoryId
		},() => {this.getFilteredData();});	
		}
	}

	componentDidMount() {
		getLocation((res) => this.setState({locationDictionary: res}), (err) => console.log(err), true);
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevState.dirType !== prevProps.match.params.dirType) {
			this.setState({
				dirType: prevProps.match.params.dirType
			});
			this.resetFilterData();
		} else if(prevState.directoryId !== prevProps.match.params.directoryId) {
			this.setState({
				directoryId: prevProps.match.params.directoryId
			}, () => this.getFilteredData());
		}
		if(prevProps.location.department !== this.props.location.department){
			let filters=this.state.filters;
			if(this.props.location.department) {
				filters['department']=this.props.location.department;
			} else {
				filters['department']='';
			}
			this.setState({filters:filters},() => {this.getFilteredData();});
		}
		if(prevState.currentPage !== this.state.currentPage || prevState.order_by !== this.state.order_by){
			this.getFilteredData();
		}
	}

	getFilteredData() {
		const department=this.state.filters.department;
		const position=this.state.filters.position;
		const city=this.state.filters.city;
		const cityLocations=this.state.filters.cityLocations;
		const industry=this.state.filters.industry;

		const cookies = parse_cookies();
		let url_path='/search/?table=';

		if(this.props.match.params.dirType === 'employee') {
			url_path+='User';
			if(department!==''&&department!==undefined){
				url_path+=`&department=${department}`;
			}
			if(position!==''&&position!==undefined){
				url_path+=`&position=${position}`;
			}
		} else if(this.props.match.params.dirType === 'location') {
			url_path+='Location';
		} else if(this.props.match.params.dirType === 'company') {
			url_path+='Company&company_type=vendor';
			
			if(industry!==''&&industry!==undefined){
				url_path+=`&industry=${industry}`;
			}
		}

		if((this.props.match.params.dirType === 'employee')) {
			if(city!==''&&city!==undefined){
				url_path+=`&city=${city}`;
			}
		}
		if(this.props.match.params.dirType==='location'){
			if(cityLocations!==''&&cityLocations!==undefined){
				url_path+=`&city=${cityLocations}`;
				
			}
			url_path+=`&company_id=${config.main_company_id?config.main_company_id:1}`;
		}
		url_path+=`&page=${this.state.currentPage}&page_size=0&${this.state.order_by}`;
		this.setState({isLoading:true}, ()=>{
			xml_fetch_info(url_path,
				this.netCallSuccess,
				{
					'Content-Type': 'application/json',
					Authorization: cookies[SESSION_ID]
				},
				this.netCallFailure
			);
		});

	}

	netCallSuccess(xhr){
		const response = JSON.parse(xhr.response);
		this.setState({isLoading: false, numPages: response.page_count,});
		let id = null;
		if(this.props.match.params.directoryId){
			id = this.props.match.params.directoryId;
		}
		
		const capDirType = this.props.match.params.dirType.charAt(0).toUpperCase() + this.props.match.params.dirType.slice(1);
		let arrayInner = '';
		if(this.props.match.params.dirType === 'employee') {
			arrayInner = 'User';
		} else {
			arrayInner = 'Company';
		}
		
		if(response.hasOwnProperty(capDirType)){
			if(response[capDirType].hasOwnProperty(arrayInner)) {
				this.handleUpdatedDirectory(response[capDirType][arrayInner],id);
			} else {
				this.handleUpdatedDirectory(response[capDirType],id);
			}
		} else if(response.hasOwnProperty(arrayInner)) {
			this.handleUpdatedDirectory(response[arrayInner],id);
		} else {
			this.handleUpdatedDirectory([], null);
		}
	}

	netCallFailure(error){
		this.setState({isLoading: false});
		if(processError(error)){
			return false;
		}else{
			toast.error(ERROR_RETRIEVING_DIRECTORY + ' ' + TRY_AGAIN_LATER);
		}
	}

	openDirectoryDetailedInformation(directoryInformationBeingViewed=null) {
		const dibv = directoryInformationBeingViewed;
		
		if(dibv!==null){
			let dirType=this.props.match.params.dirType;

			//log a view of this post which includes the title information for the post
			let ga_event={
				'event':'ga-event',
				'category':dirType,
				'action':'view',
			};
			
			if (dirType === 'employee') {
				ga_event['label']=dibv.fullName+' ('+dibv.email+')';
			} else if (dirType === 'location') {
				ga_event['label']=dibv.city+' ('+dibv.address+')';
			} else if (dirType === 'company') {
				ga_event['label']=dibv.companyName+' ('+dibv.address+')';
			}
			window.dataLayer.push(ga_event);
		}
		
		this.setState({
			directoryDetailedInformationOpen:true,
			directoryInformationBeingViewed
		});
	}
	
	closeDirectoryDetailedInformation() {
		this.setState({
			directoryDetailedInformationOpen:false
		});
	}

	renderInformation() {
		return (
			<FlyoutMenu id='directory-detailed-information-flyout' menuOpen={this.state.directoryDetailedInformationOpen} closeButtonText={`Back To ${this.getDirectoryName()}`} openMenu={this.openDirectoryDetailedInformation} closeMenu={this.closeDirectoryDetailedInformation} cancelIcon='fa-caret-left'>
				{(this.state.directoryDetailedInformationOpen) &&
					this.getFlyoutMenuInner()
				}
			</FlyoutMenu>
		);
	}

	checkForFilters = () => {
		let filters = this.state.filters;
		for (let item in filters) {
			if (filters[item] === '') {
				continue;
			} else {
				return true;
			}
		}
		return false;
	};

	filterData = (value, index) => {
		let filters = this.state.filters;
		filters[index] = value;
		this.setState({currentPage:0, filters}, () => this.getFilteredData());
	};

	resetFilterData = () => {
		let filters=[];
		if(this.props.location.department && this.props.match.params.dirType === 'employee'){
			filters['department']=this.props.location.department;
		}
		this.setState({currentPage:0, filters: filters}, () => this.getFilteredData());
	};


	render() {
		let filterOptions=[];
		let filterValues=[];
		//employee-only filters
		if (this.props.match.params.dirType === 'employee') {
			if(this.props.departments && this.props.departments.length>0){
				filterOptions.push({
					filterName:'department',
					filterOptions:this.genFilterOptions(this.props.departments, 'Departments'),
				});
				filterValues.push(this.state.filters['department']);
			}
			if(this.props.positions && this.props.positions.length>0){
				filterOptions.push({
					filterName:'position',
					filterOptions:this.genFilterOptions(this.props.positions, 'Positions'),
				});
				filterValues.push(this.state.filters['position']);
			}
			if(this.props.employeeCities && this.props.employeeCities.length>0){
				filterOptions.push({
					filterName:'city',
					filterOptions:this.genFilterOptions(this.props.employeeCities, 'Offices'),
				});
				filterValues.push(this.state.filters['city']);
			}
		//location-only filters
		} else if (this.props.match.params.dirType === 'location') {
			//put any filters which ONLY apply to location here
			if(this.props.cities && this.props.cities.length>0){
				filterOptions.push({
					filterName:'cityLocations',
					filterOptions:this.genFilterOptions(this.props.employeeCities, 'Offices'),
				});
				filterValues.push(this.state.filters['city']);
			}
		//company-only filters
		} else if (this.props.match.params.dirType === 'company') {
			if(this.props.industries && this.props.industries.length>0){
				filterOptions=[{
					filterName:'industry',
					filterOptions:this.genFilterOptions(this.props.industries, 'Industries'),
				}];
				filterValues.push(this.state.filters['industry']);
			}
		} else {
			filterOptions=[];
		}
		return(
			<div className={'CompanyDirectory'}>
				<BarStylePageMenu department={this.props.location.department} pages={[{'link':'employee', 'title': 'Employees'}, {'link':'location', 'title': 'Branch Locations'}, {'link':'company', 'title': 'Companies'}]} active={this.props.match.params.dirType} />
				{ (this.props.match.params.dirType === 'employee') &&
					<Filters
						filterValues={filterValues}
						key='employee'
						filterChangeCallback={this.filterData}
						filters={filterOptions}
						resetCallbackHandler={this.resetFilterData}
					/>
				}
				{ (this.props.match.params.dirType === 'location') &&
					<Filters
						filterValues={filterValues}
						key='location'
						filterChangeCallback={this.filterData}
						filters={filterOptions}
						resetCallbackHandler={this.resetFilterData}
					/>
				}
				{ (this.props.match.params.dirType === 'company') &&
					<Filters
						filterValues={filterValues}
						key='company'
						filterChangeCallback={this.filterData}
						filters={filterOptions}
						resetCallbackHandler={this.resetFilterData}
					/>
				}
				<FlexTable sort={(e, title) => updateSorting(e, title, this, table_enums[this.props.match.params.dirType])} tableData={this.state.tableData} />
				{this.renderInformation()}
				<>{this.state.isLoading &&<LoadingIndicator isLoading={this.state.isLoading}/>}</>
				
				<PageController pageNumber={this.state.currentPage} setPageNumber={(page)=>{
					this.setState({currentPage:page});
				}} numPages={this.state.numPages} />
			</div>
		);
	}
}

export default CompanyDirectory;