import React, { useContext } from 'react';
import TiledButton from '../FieldLabel/TiledButton';
import { Option } from './Option';
import { FormContext } from './FormContext';
export const ListOfOptions = ({
	questions,
	question,
	questionIndex,
	isFollowUp,
}) => {
	const context = useContext(FormContext);
	const addOption = () => {
		question.answer_options.push({
			title: '',
			question: null,
		});
		context.reRender((prev) => !prev);
	};
	const removeOption = (question, optionIndex) => {
		question.answer_options.splice(optionIndex, 1);
		context.reRender((prev) => !prev);
	};
	return (
		<>
			{question.answer_options.map((item, idx) => {
				return (
					<div key={`question-${questionIndex}-option-${idx}`}>
						<Option
							removeOption={removeOption}
							optionIndex={idx}
							questionIndex={questionIndex}
							question={question}
							isFollowUp={isFollowUp}
							alreadyHasFollowUp={questions ? false : true}
						/>
					</div>
				);
			})}
			{question.answer_options.length < 25 && (
				<TiledButton
					faClass="fa fa-plus"
					onClickEvent={addOption}
					tileClass="add-option"
					tileText="Add Option"
				/>
			)}
		</>
	);
};
