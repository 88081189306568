import BaseModel from './BaseModel.js';

class Post extends BaseModel {
	constructor(created_at) {
		super(created_at);
		
		//NOTE: the validity field for this class is different from the validity field for the base model
		//because the server names these validity fields inconsistently in its data model
		delete this.valid;
		this.is_valid=true;
		
		//NOTE: these internal variables must be in snake case and not lower camel case
		//because they are used as the serialization fields that get sent to the server
		
		this.title='';
		this.is_featured=false;
		this.desc='';
		this.content='';
		this.modified_at=this.created_at;
		this.type='post';
		this.tags=[];
		this.published_at=this.created_at;
	}
	
	set_title(title) {
		return this.generic_setter(this,'title',title,this.type_string());
	}
	get_title() {
		return this.title;
	}
	
	set_is_featured(is_featured){
		return this.generic_setter(this,'is_featured',is_featured,this.type_bool());
	}
	
	get_is_featured(){
		return this.is_featured;
	}

	set_desc(desc) {
		return this.generic_setter(this,'desc',desc,this.type_string());
	}
	get_desc() {
		return this.desc;
	}

	set_content(content) {
		return this.generic_setter(this,'content',content,this.type_string());
	}
	get_content() {
		return this.content;
	}
	
	set_tags(tags){
		return this.generic_setter(this,'tags',tags,this.type_array());
	}
	get_tags(){
		return this.tags;
	}
	
	set_published_at(published_at){
		return this.generic_setter(this,'published_at',published_at,this.type_date());
	}
	get_published_at(){
		return this.published_at;
	}

}
export default Post;

