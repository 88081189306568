/*
AddFieldController - the controller to add new labels 


*/

import React from 'react';
import FieldText from './FieldText';
import FieldQuestionLabel from './FieldLabel/FieldQuestionLabel';
import SelectFieldButton from './FieldLabel/SelectFieldButton';

/*
props (component-level arguments):
	addQuestionOption: callback function - adds a question option to a question
	questions: array - a list of all questions
	addQuestionCallback: callback function - adds a question to the array of quests
	handleQuestionLabelChange: callback function - changes the title of a question
	handleOptionLabelChange: callback function - changes the title of an option
	label_number: the question id that this button belongs to
	option_number_owner: if this is a follow up question then this holds the option number holding this question
	follow_up_enabled: if true then this is a follow-up-question AddFieldController and is created by FieldSelectOption
	handleInputChange: callback function passed down to FieldQuestionLabel
	last_label_number: if this is a followe up AddFieldController then this points to the question id of the original question
*/
class AddFieldController extends React.Component {
	constructor(props){
		super(props);
		this.state={
			fields:[]
		};
		this.addLabelHandler=this.addLabelHandler.bind(this);
		this.reformatQuestionTypes=this.reformatQuestionTypes.bind(this);
		this.deleteQuestion=this.deleteQuestion.bind(this);
	}

	reformatQuestionTypes(label_type){
		if(label_type === 'text' || label_type === 'textarea'){
			return 'open_ended';
		}else if (label_type === 'single_select'){
			return 'single_select';
		}else if (label_type === 'multi-select'){
			return 'multi_select';
		}else if (label_type === 'dropdown-select'){
			return 'dropdown';
		}
	}

	deleteQuestion(e,label_number){
		e.preventDefault();
		let fields = this.state.fields;
		if(this.props.follow_up_enabled){
			this.props.removeQuestion(this.props.last_label_number, true, this.props.option_number_owner);
		}else{
			this.props.removeQuestion(label_number);
			
		}
		fields[label_number] = null; // dont want to actually remove the index, this way we dont have to reshuffle questions from parent
		this.setState({fields});
	}

	addLabelHandler(label_type){
		let fields=this.state.fields;
		fields.push(
			<div key={this.state.fields.length}>
				<FieldQuestionLabel 
					handleOptionLabelChange={this.props.handleOptionLabelChange} questions={this.props.questions} addQuestionCallback={this.props.addQuestionCallback} 
					addQuestionOption={this.props.addQuestionOption} question_idx={this.props.questions.length}
					removeQuestion={this.props.removeQuestion} removeQuestionOption={this.props.removeQuestionOption} remove_question_from_controller={this.deleteQuestion}
					option_number_owner={this.props.option_number_owner} handleInputChange={this.props.handleInputChange} handleQuestionLabelChange={this.props.handleQuestionLabelChange}
					follow_up_enabled={this.props.follow_up_enabled} last_label_number={this.props.last_label_number} label_number={fields.length} field_type={label_type}
				/>
			</div>
		);
		this.setState(fields);
		let value = this.props.questions.length;
		if(this.props.follow_up_enabled){
			const question_type = this.reformatQuestionTypes(label_type);
			this.props.addQuestionCallback({
				question_type:question_type,
				title:'',
				question_idx:this.props.label_number, 
				option_idx: this.props.option_number_owner},
			true);
		}else{
			const question_type = this.reformatQuestionTypes(label_type);
			this.props.addQuestionCallback({
				question_type:question_type,
				title:'',
				question_idx:value
			});
		}
	}

	render() {
		const SHOW_ADD_FIELD_CONTROLLER_IN_BOTTOM_LEFT_WITHOUT_BLUE_BG = !this.props.follow_up_enabled;
		let addFieldClassName = '';
		if(SHOW_ADD_FIELD_CONTROLLER_IN_BOTTOM_LEFT_WITHOUT_BLUE_BG){
			addFieldClassName = 'AddFieldController';
		}else{
			addFieldClassName = 'AddFieldController follow-up';
		}
		return(
			<div className={`${addFieldClassName}`}>
				{this.state.fields.length === 0 && <FieldText follow_up_enabled={this.props.follow_up_enabled} addLabelHandler={this.addLabelHandler} />}
				<div className="fields-container">{this.state.fields.map(function (item,idx){
					return <div key={'item-'+idx}> {item} </div>;})}
				</div>
				<SelectFieldButton classIdentifier={this.state.fields.length === 0 ? '' : 'bottom-left'} addLabelHandler={this.addLabelHandler}/>
			</div>
		);
	}
}
AddFieldController.defaultProps = {
	follow_up_enabled: false,
};
export default AddFieldController;
