import BaseModel from './BaseModel.js';

class QuickLink extends BaseModel {
	constructor() {
		super();
		
		//NOTE: the validity field for this class is different from the validity field for the base model
		//because the server names these validity fields inconsistently in its data model
		delete this.valid;
		this.is_valid=true;
		
		//NOTE: these internal variables must be in snake case and not lower camel case
		//because they are used as the serialization fields that get sent to the server
		
		this.title='';
		this.url='';
		this.target='';
		this.is_featured=false;

		this.prepareForNetworkRequest();
		this.update();
	}
	
	set_title(title) {
		return this.generic_setter(this,'title',title,this.type_string());
	}
	get_title() {
		return this.title;
	}
	
	set_is_featured(is_featured){
		return this.generic_setter(this,'is_featured',is_featured,this.type_bool());
	}
	
	get_is_featured(){
		return this.is_featured;
	}

	set_url(url) {
		return this.generic_setter(this,'url',url,this.type_string());
	}
	get_url() {
		return this.url;
	}
	
	set_target(target) {
		return this.generic_setter(this,'target',target,this.type_string());
	}
	get_target() {
		return this.target;
	}

	prepareForNetworkRequest() {
		delete this.created_at;
		delete this.modified_at;
	}

	update() {
		this.prepareForNetworkRequest();
	}
}
export default QuickLink;

