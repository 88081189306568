// constants for styles
import { createGlobalStyle, css } from 'styled-components';

export const Color = {
	athens: '#fafbfc',

	nile: 'rgba(22, 45, 76, 1)',
	nile90: 'rgba(22, 45, 76, 0.9)',
	nile70: 'rgba(22, 45, 76, 0.7)',
	nile50: 'rgba(22, 45, 76, 0.5)',
	nile25: 'rgba(22, 45, 76, 0.25)',
	nile18: 'rgba(22, 45, 76, 0.18)',
	nile15: 'rgba(22, 45, 76, 0.15)',
	nile10: 'rgba(22, 45, 76, 0.1)',
	nile5: 'rgba(22, 45, 76, 0.05)',

	regent: '#8a94a6',
	ghost: '#c5cad2',
	mischka: '#dcdfe5',
	porcelain: '#e7e9ed',
	silver: '#f3f4f6',

	poppy: '#c62828',
	meadow: '#03b866',
	nova: '#ffd008',

	lilac: '#9d62e6',
	heart: '#773ebf',
	charmed: '#ae1ebf',

	wategos: '#63d0e6',
	picton: '#32bcf6',
	blizzard: '#b7e5fa',

	orchid: '#cb47b3',
	hot: '#ff54b3',
	daisy: '#ff86c9',

	banana: '#ffe34e',
	cheeto: '#ff8824',
	sun: '#ffb725',

	marine: '#3d8bd3',
	bay: '#2a33ab',
	peri: '#8a97e8',

	mantis: '#7adc77',
	forest: '#27bf2b',
	vida: '#aeff6b',

	downy: '#66cbc4',
	coral: '#ff2e3e',
	grapefruit: '#ff837f',

	zest: '#e48b2b',
	fuel: '#f2b72b',

	white: '#ffffff',
	black: '#000000',

	transparent: 'transparent',

	darkBlue: '#172B4D',

	liunaBlack: '#020303',
	inputBG: '#FAFAFA',
	inputBorder: '#CFD3D3',
	formTitleBlack: '#001414',
	signUpTitleBlack: '#1E3131',
	buttonTextOrange: '#F7921E',
	underLineColor: '#F8A84B',
	bgWhite: '#F4F4F4',
	steelix: '#6B7777',
	mamba: '#001414'

};

export const FontSize = {
	header1: '40px',
	header2: '33px',
	header3: '27px',
	header4: '23px',
	header5: '19px',
	body1: '16px',
	body2: '13px',
	caption1: '11px',
	caption2: '9px'
};

export const FontWeight = {
	light: '100',
	normal: '400',
	medium: '500',
	semibold: '600',
	bold: '700'
};

export const FontFamily = 'Source Sans Pro';

// Global styles are defined here.
// Any non-general styles should be defined in the related styled component.
export const GlobalStyle = createGlobalStyle`
	${props => css`
		body {
			margin: 0;
			overflow: 'visible';
		}
		* {
			font-family: ${FontFamily}, sans-serif;
			font-weight: ${FontWeight.semibold};
		}

		h1 {
			font-size: 2.5rem;
		}
		h2 {
			font-size: 2.0625rem;
		}
		h3 {
			font-size: 1.6875rem;
		}
		h4 {
			font-size: 1.4375rem;
		}
		h5 {
			font-size: 1.1875rem;
		}
		body {
			font-size: 1rem;
			background-color: ${Color.bgWhite};
		}
	`}
`;