/*
FlyoutMenuInner - The inner information for the flyout menu to render.
	Note this is loaded as the children of the Flyout menu and not inside the Flyout menu itself.


*/

import React from 'react';
import S3Image from './S3Image';
import HerdRAAWSUtils from '../projlibs/HerdRAAWSUtils';
import UploadWidget from './UploadWidget/UploadWidget';
import { UPLOAD_IMAGE_FILE_TYPES } from '../constants';
import config from '../config';
import { getAWSKey } from '../projlibs/HelperFunctions';

/*
props (component-level arguments):
	dibv: An acronym for "directoryInformationBeingViewed", this is the data coming from the server for a specific company directory.
		this value is a json object
*/
export class InnerEmployees extends React.Component {
	constructor(props) {
		super(props);
		this.aws = new HerdRAAWSUtils();
		this.state = {
			avatar: null,
			avatarObject: null,
			uploading: false
		};
	}


	componentDidMount(){
		if (this.props.allowUpload){
			this.getAvatarObject();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.allowUpload && prevProps.dibv.photo_s3_path !== this.props.dibv.photo_s3_path) {
			this.getAvatarObject();
		}
	}

	getAvatarObject=()=>{
		this.setState({ uploading: true }, () => {
			this.aws.getObject(config.bucket_name, this.props.dibv.photo_s3_path, () => (this.setState({ uploading: false })), successResponse => {
				this.setState({ avatarObject: successResponse, uploading: false });
			});
		});
	}

	saveAvatar = (file) => {
		this.setState({ avatarObject: null, uploading: true },
			() => this.aws.uploadFile(config.bucket_name, file.name, file, () => (this.setState({ uploading: false })), successResponse => {
				this.setState({ avatarObject: successResponse, upload: false });
				let key = getAWSKey(successResponse);
				this.props.profilePathChanged(key);
				this.getAvatarObject();
			}));
	};

	render() {
		return (
			<div className={'InnerEmployees '}>
				<div className='grid-x'>
					<div className='cell small-12 large-7 bio-col'>
						<div className='header-wrapper'>
							{this.props.allowUpload ?
								<div className="upload-widget-container">
									<UploadWidget
										isAvatar={true}
										fileCategory="Profile Avatar"
										acceptedFileTypes={UPLOAD_IMAGE_FILE_TYPES}
										saveFile={this.saveAvatar}
										idUniqueIdentifier={'avatar-upload'}
										classIdentifier={'avatar-upload'}
										showRequirements={false}
										fileObject={this.state.avatarObject}
										fileId={'avatar-upload'}
										uploading={this.state.uploading}
										preview={this.state.avatar}
									/>
								</div>
								:
								<div className='thumbnail-wrapper rounded'>
									<S3Image aws_object={this.aws} additionalClasses='header-thumbnail' s3_path={this.props.dibv.photo_s3_path} altText={`${this.props.dibv.first_name} ${this.props.dibv.last_name}`} />
								</div>
							}
							<div className='header-text-wrapper'>
								<h2 className='header-name'>{this.props.dibv.fullName}</h2>
								<p className='header-position'>{this.props.dibv.position}</p>
							</div>
						</div>
						{
							this.props.dibv.bio && <div className='bio'>
								<h3 className='info-title'>Personal Information:</h3>
								{this.props.dibv.bio && <div className='info html-wrapper' dangerouslySetInnerHTML={{ __html: this.props.dibv.bio }}></div>}
							</div>
						}
					</div>
					<div className='cell small-12 large-5 contact-col'>
						<div className='contact-information'>
							<h3 className='info-title'>Contact Information:</h3>
							{this.props.dibv.phone && <p className='info'>Office Tel: <a href={`tel:${this.props.dibv.phone}`} className='info-inner'>{this.props.dibv.phone}</a></p>}
							{this.props.dibv.mobile_phone && <p className='info'>Mobile Tel: <a href={`tel:${this.props.dibv.mobile_phone}`} className='info-inner'>{this.props.dibv.mobile_phone}</a></p>}
							{this.props.dibv.email && <p className='info'>Email: <a href={`mailto:${this.props.dibv.email}`} className='info-inner'>{this.props.dibv.email}</a></p>}
						</div>
						<hr />
						<div className='work-information'>
							<h3 className='info-title'>Work Information:</h3>
							{this.props.dibv.department && <p className='info'>Departments: <span className='info-inner'>{this.props.dibv.department}</span></p>}
							{this.props.dibv.office && <p className='info'>Office: <span className='info-inner'>{this.props.dibv.office}</span></p>}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export class InnerLocations extends React.Component {
	render() {
		return (
			<div className={'InnerLocations '}>
				<div className='grid-x'>
					<div className='cell small-12 large-7 bio-col'>
						<div className='header-wrapper'>
							{/* TODO: Change image to be photo_s3_path to match the object from server */}
							<div className='thumbnail-wrapper'>
								<S3Image additionalClasses='header-thumbnail' s3_path={this.props.dibv.photo_s3_path} altText={`${this.props.dibv.fullName}`} />
							</div>
							<div className='header-text-wrapper'>
								<h2 className='header-name'>{this.props.dibv.fullName}</h2>
								<a className='arrow-link' rel="noopener noreferrer" target="_blank" href={'https://www.google.ca/maps/dir/?api=1&destination=' + this.props.dibv.address}>Get Directions</a>
							</div>
						</div>
						{
							this.props.dibv.description && <div className='bio'>
								{this.props.dibv.description && <p className='info'>{this.props.dibv.description}</p>}
							</div>
						}
					</div>
					<div className='cell small-12 large-5 contact-col'>
						<div className='contact-information'>
							<h3 className='info-title'>Contact Information:</h3>
							{this.props.dibv.phone && <p className='info'>Office Tel: <a href={`tel:${this.props.dibv.phone}`} className='info-inner'>{this.props.dibv.phone}</a></p>}
							{this.props.dibv.email && <p className='info'>Email: <a href={`mailto:${this.props.dibv.email}`} className='info-inner'>{this.props.dibv.email}</a></p>}
							{this.props.dibv.address && <p className='info'>Address: <a className='info-inner' target="_blank" rel="noopener noreferrer" href={'https://www.google.ca/maps/dir/?api=1&destination=' + this.props.dibv.address}>{this.props.dibv.address}</a></p>}
						</div>
						<hr />
						<div className='work-information'>
							<h3 className='info-title'>Location Information:</h3>
							{this.props.dibv.supervisor && <p className='info'>Supervisor: <span className='info-inner'>{this.props.dibv.supervisor}</span></p>}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export class InnerCompanies extends React.Component {
	formatWebsite(url) {
		if (url.match(/(http)s?:\/\/.*/g)) {
			return url;
		}
		return 'http://' + url;
	}

	render() {
		return (
			<div className={'InnerCompanies '}>
				<div className='grid-x'>
					<div className='cell small-12 large-7 bio-col'>
						<div className='header-wrapper'>
							{/* TODO: Change image to be photo_s3_path to match the object from server */}
							<div className='thumbnail-wrapper'>
								<S3Image additionalClasses='header-thumbnail' s3_path={this.props.dibv.photo_s3_path} altText={`${this.props.dibv.companyName}`} />
							</div>
							<div className='header-text-wrapper'>
								<h2 className='header-name'>{this.props.dibv.companyName}</h2>
								<a className='arrow-link' target="_blank" rel="noopener noreferrer" href={'https://www.google.ca/maps/dir/?api=1&destination=' + this.props.dibv.address}>Get Directions</a>
								{this.props.dibv.industry && <p className='info'>Industry: <span className='info-inner'>{this.props.dibv.industry}</span></p>}
							</div>
						</div>
						{
							this.props.dibv.description && <div className='bio'>
								{this.props.dibv.description && <p className='info' dangerouslySetInnerHTML={{ __html: this.props.dibv.description }}></p>}
							</div>
						}
					</div>
					<div className='cell small-12 large-5 contact-col'>
						<div className='contact-information'>
							<h3 className='info-title'>Contact Information:</h3>
							{this.props.dibv.phone && <p className='info'>Office Tel: <a href={`tel:${this.props.dibv.phone}`} className='info-inner'>{this.props.dibv.phone}</a></p>}
							{this.props.dibv.phone_fax && <p className='info'>Fax Tel: <a href={`tel:${this.props.dibv.phone_fax}`} className='info-inner'>{this.props.dibv.phone_fax}</a></p>}
							{this.props.dibv.email && <p className='info'>Email: <a href={`mailto:${this.props.dibv.email}`} className='info-inner'>{this.props.dibv.email}</a></p>}
							{this.props.dibv.address && <p className='info'>Address: <a className='info-inner' target="_blank" rel="noopener noreferrer" href={'https://www.google.ca/maps/dir/?api=1&destination=' + this.props.dibv.address}>{this.props.dibv.address}</a></p>}
							{this.props.dibv.public_website && <p className='info'>Website: <a href={this.formatWebsite(this.props.dibv.public_website)} target='_blank' without rel="noopener noreferrer" className='info-inner'>{this.props.dibv.public_website}</a></p>}
							{this.props.dibv.portal_url && <p className='info'>Portal: <a href={this.formatWebsite(this.props.dibv.portal_url)} target='_blank' without rel="noopener noreferrer" className='info-inner'>{this.props.dibv.portal_url}</a></p>}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
