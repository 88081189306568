import React, { useContext } from 'react';
import { OptionsQuestion } from './OptionsQuestion';
import { TextQuestion } from './TextQuestion';
import { FormContext } from './FormContext';
import { Draggable } from 'react-beautiful-dnd';
import reactDom from 'react-dom';
import { QUESTION_TYPE_FILE_UPLOAD } from '../../../../../constants';
export const Question = ({
	questions,
	question,
	questionIndex,
	isFollowUp,
	answer_option,
	portal
}) => {
	const context = useContext(FormContext);
	if('valid' in question && question.valid===false){
		return <></>;
	}
	const removeQuestion = () => {
		if (answer_option) {
			answer_option.question = null;
		} else {
			context.setQuestions(prev=>prev.filter(element=>element!==question));
		}
		context.reRender();
	};
	const getQuestion=()=>{
		switch (question.question_type) {
			case 'text':
			case 'textarea':
			case 'open_ended':
				return (
					<TextQuestion
						question={question}
						questionTypeLabel={'Text'}
						questionIndex={questionIndex}
						isFollowUp={isFollowUp}
						removeQuestion={removeQuestion}
					/>
				);
			case QUESTION_TYPE_FILE_UPLOAD:
				return (
					<TextQuestion
						question={question}
						questionTypeFA='fa-file'
						questionTypeLabel={'File'}
						questionIndex={questionIndex}
						isFollowUp={isFollowUp}
						removeQuestion={removeQuestion}
					/>
				);
			default:
				return (
					<OptionsQuestion
						question={question}
						questions={questions}
						questionIndex={questionIndex}
						isFollowUp={isFollowUp}
						removeQuestion={removeQuestion}
					/>
				);
		}
	};
	if(isFollowUp){
		return getQuestion();
	}else{
		return (
			<Draggable isDragDisabled={false} draggableId={`q-${question.title}`} index={questionIndex}>
			{(draggableProvided, draggableSnapshot) => (
			  <PortalAwareItem
				question={getQuestion()}
				provided={draggableProvided}
				snapshot={draggableSnapshot}
				portal={portal}
			  />
			)}
		  </Draggable>
		);
	}
};

function PortalAwareItem({ provided, snapshot, question, portal }) {
	const usePortal = snapshot.isDragging;
  
	const child = (
	  <div
		className="bg-light p-3 mb-1"
		ref={provided.innerRef}
		{...provided.draggableProps}
		{...provided.dragHandleProps}
	  >
		{question}
	  </div>
	);
  
	if (!usePortal) {
	  return child;
	}
  
	// if dragging - put the item in a portal
	return reactDom.createPortal(child, portal);
  }