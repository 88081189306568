import {DEPARTMENT_URL, ERROR_RETRIEVING_DEPARTMENTS, TRY_AGAIN_LATER} from '../../constants';
import {processError} from '../../projlibs/cookie-management';
import {toast} from 'react-toastify';
import {add_query_parameters, fetch_info} from './herdajax';

export function getDepartments(successCallback, errorCallback = null, parameters = {}) {
	if (errorCallback === undefined || errorCallback === null) {
		errorCallback = defaultErrorCallback;
	}

	let url = add_query_parameters(DEPARTMENT_URL, parameters);
	fetch_info(url, successCallback, errorCallback, {});
}

// Likely should be overridden.
export function defaultErrorCallback(error) {
	if (processError(error)) {
		return false;
	} else {
		toast.error(ERROR_RETRIEVING_DEPARTMENTS + ' ' + TRY_AGAIN_LATER);
	}
}
