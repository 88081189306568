/*
	AdminNewsManager - A News Manager allows an administrator to view, modify, and create news
*/

import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import config from '../../../config';

import FlexTable from '../../FlexTable.jsx';
import FlyoutMenu from '../../FlyoutMenu.jsx';
import FilteredComponent from '../../FilteredComponent.jsx';

import { xml_fetch_info, xml_send_info } from '../../../react-utils/src/libajax.js';
import { parse_cookies } from '../../../react-utils/src/libformat.js';
import { SESSION_ID, ERROR_RETRIEVING_POSTS, ERROR_POST_NOT_SAVED, TRY_AGAIN_LATER, SUCCESS_POST_SAVED, SUCCESS_POST_DELETED, GENERIC_UPLOAD_ERROR_MESSAGE, 
	ACCEPTED_FILE_TYPE_ARRAY, UPLOAD_IMAGE_FILE_TYPES, UPLOAD_PDF_FILE_TYPE, TINY_MCE_NAME, FIRST_NAME, LAST_NAME } from '../../../constants.js';
import UploadWidget from '../../UploadWidget/UploadWidget.jsx';
import DateTimePicker from 'react-datetime-picker';
import 'react-widgets/dist/css/react-widgets.css';
import Multiselect from 'react-widgets/lib/Multiselect';
import { getTags, getCategories, getDepartments, getHeaders } from '../../../projlibs/HelperNetworkCalls.js';
import { processError } from '../../../projlibs/cookie-management';
import HerdRAAWSUtils from '../../../projlibs/HerdRAAWSUtils';
import { TINY_MCE_ID, SHORT_ID_CHARACTERS } from '../../../constants';
import MultiUploadWidget from '../../UploadWidget/MultiUploadWidget.jsx';
import {
    genFormattedTableData,
    sortPostsByDate,
    savePost,
	savePreLoadedAttachments
} from '../../../projlibs/PostHelperFunctions';
import {
    parseForInlineImages,
	getTinyMceInitSettings
} from '../../../projlibs/InlineImageFunctions';
import { getAWSKey } from '../../../projlibs/HelperFunctions';
import { LoadingIndicator } from '../../LoadingIndicator';
import { PageController } from '../../PageController';
const shortid = require('shortid');
shortid.characters(SHORT_ID_CHARACTERS); //  remove _ because it was making it hard to parse
/*
props (component-level arguments):
	none

state (component-global variables):
	postInfo: an ordered list of posts
	filteredPostInfo: a subset of postInfo filtered based off of the set filter parameters
	filteredCat: the string to use to filter by category
	filteredTag: the string to use to filter by tag
	editPostOpen: whether or not the new/edit post menu is open
	editingPost: the post being edited (null for a new post)
	editorContent: the WYSIWYG editor content field value
*/

class AdminNewsManager extends FilteredComponent {
	constructor(props) {
		super(props);

		this.state = {
			date: new Date(),
			tags: [],
			selectedTags: [],
			departments: [],
			selectedDepartments: [],
			categories: [],
			is_featured:false,
			selectedCategory: null,
			postInfo: [],
			filteredPostInfo: [],
			filteredCat: '',
			filteredTag: '',
			editPostOpen: false,
			editingPost: null,
			uploadingPDF: false,
			detailedPhoto: '',
			pdfOnly:false,
			uploadingDetailedPhoto: false,
			showDatePicker: false,
			PDFObject: '',
			detailedPhotoObject: '',
			AWSrefreshed:false,
			postBucketId:shortid.generate(),
			fileAttachmentKeys:[],
			fileAttachments:[],
			preloadedFiles:[],
			donePreloading:false,
			submitting:false,
			inlineImageIsUploading:false,
			inputs: {
				category: '',
				sendTimestamp: 0,
			},
			carouselData:[],
			numPages:1,
			currentPage: 0,
			isLoading:true,
			order_by:'&order_by=created_at&order_dir=desc',

			//NOTE: editorContent must be separate from editingPost.content
			//to handle the case of a newly-created post
			editorContent: '',
		};
		this.aws = new HerdRAAWSUtils();
		this.handleWYSIWYGInputChange = this.handleWYSIWYGInputChange.bind(this);
		this.deletePost = this.deletePost.bind(this);
		this.openEditForm = this.openEditForm.bind(this);
		this.closeEditForm = this.closeEditForm.bind(this);
		this.renderEditForm = this.renderEditForm.bind(this);
		this.renderTableTitle = this.renderTableTitle.bind(this);
	}

	componentDidMount() {
		this.fetchPostInfo();
		getTags(successResponse=>{this.setState({tags:successResponse});}, this.genericNetworkFailHandler);
		getCategories(successResponse=>{this.setState({categories:successResponse});}, this.genericNetworkFailHandler);
		getDepartments(successResponse=>{this.setState({departments:successResponse});}, this.genericNetworkFailHandler);
	}

	componentDidUpdate(prevProps, prevState){
		if(prevState.currentPage !== this.state.currentPage || prevState.order_by !== this.state.order_by){
			this.fetchPostInfo();
		}
	}

	getObjectFail = err => {
		console.log(err);
	};

	genericNetworkFailHandler = error => {
		toast.error(error);
	};

	fetchPostInfo() {
		//NOTE: although this says it's looking for "news" the server returns all post data of type 'post' at that endpoint
		//regardless of if it's in the News category or not
		let url='/search/?table=Post&type=post';
		if (this.props.match.params.post_id) {
			url = `/news/${this.props.match.params.post_id}`;
		}else{
			url+=`&page=${this.state.currentPage}&page_size=0&${this.state.order_by}`;
		}
		this.setState({isLoading:true}, ()=>{
			xml_fetch_info(url,
				xhr => {
					let data = JSON.parse(xhr.responseText);
					let posts = [];
					for (let idx = 0; idx < data['Post'].length; idx++) {
						if (data['Post'][idx].is_valid === false) {
							continue;
						}
						posts.push(data['Post'][idx]);
					}
					if (data['Post'].length === undefined) {
						// then it is a single file
						if (data['Post'].is_valid) {
							posts.push(data['Post']);
						}
					}
					//sort by date, newest first
					posts = sortPostsByDate(posts);
					this.setState({numPages:data.page_count , isLoading:false, postInfo: posts,filteredPostInfo: posts,filteredCat: '',filteredTag: ''});
			},getHeaders(),(error)=> {
				this.setState({isLoading:false});
				if (processError(error)) {
					return false;
				} else {
					toast.error(ERROR_RETRIEVING_POSTS + ' ' + TRY_AGAIN_LATER);
				}
			});
		});
	}

	handleWYSIWYGInputChange(content, editor) {
		this.setState({
			editorContent: content
		});
	}

	savePostNetworkRequest=(netPost)=>{
		let url = '/news';
		xml_send_info(
			url,
			JSON.stringify(netPost),
			xhr => {
				let data = JSON.parse(xhr.responseText)['Post'];
				let postInfo = this.state.postInfo;
				//look through all existing posts to see if this is an update to a previously-existing post
				let idx = 0;
				for (idx = 0; idx < postInfo.length; idx++) {
					//if this is a match
					if (postInfo[idx]['post_id'] - 0 === data['post_id'] - 0) {
						//then update the existing data
						postInfo[idx] = data;
						//and stop looking
						break;
					}
				}
				//if we iterated through all existing posts and didn't find a matching id
				//then this is a new post and we should add it to the end of the list
				if (idx >= postInfo.length) {
					postInfo.push(data);
				}
				//sort by date, newest first
				postInfo = sortPostsByDate(postInfo);
				this.setState(
					{
						postInfo: postInfo,
						filteredPostInfo: postInfo,
						filteredTag: '',
						filteredCat: '',
						editPostOpen: false,
						date: new Date(),
						selectedTags: [],
						selectedDepartments: [],
						selectedCategory: null,
						pdfOnly:false,
						editingPost: null,
						editorContent: ''
					},
					() => {
						toast.success(SUCCESS_POST_SAVED);
					}
				);
			},
			netPost.hasOwnProperty('post_id') ? 'PUT' : 'POST',getHeaders(),
			xhr => {
				this.setState({submitting: false});
				toast.error(ERROR_POST_NOT_SAVED + ' ' + TRY_AGAIN_LATER);
			}
		);
	}

	deletePost(post) {
		const cookies = parse_cookies();
		let request_delete_data = { post_id: post.post_id, is_valid: false };
		xml_send_info(
			'/news',
			JSON.stringify(request_delete_data),
			xhr => {
				let data = JSON.parse(xhr.responseText)['Post'];
				let postInfo = this.state.postInfo;
				let newPostInfo = [];

				//look through all existing posts to see if this is an update to a previously-existing post
				let idx = 0;
				for (idx = 0; idx < postInfo.length; idx++) {
					//if this is a match
					if (postInfo[idx]['post_id'] - 0 === data['post_id'] - 0) {
						//then update the existing data
						postInfo[idx] = data;
					}
					//if this is invalid (as the new post should be if it saved correctly)
					//then don't include it in the new list
					if (postInfo[idx].is_valid === false) {
						continue;
					}
					newPostInfo.push(postInfo[idx]);
				}

				//sort by date, newest first
				newPostInfo = sortPostsByDate(newPostInfo);

				this.setState(
					{
						postInfo: newPostInfo,
						filteredPostInfo: newPostInfo,
						filteredTag: '',
						filteredCat: '',
						editPostOpen: false,
						date: new Date(),
						selectedTags: [],
						selectedDepartments: [],
						selectedCategory: null,
						pdfOnly:false,
						editingPost: null,
						editorContent: ''
					},
					() => {
						toast.success(SUCCESS_POST_DELETED);
					}
				);
			},
			'PUT',
			{ Authorization: cookies[SESSION_ID] },
			xhr => {
				toast.error(ERROR_POST_NOT_SAVED + ' ' + TRY_AGAIN_LATER);
			}
		);
	}

	resetState=()=>{
		this.setState({
			date: new Date(),
			selectedTags: [],
			selectedDepartments: [],
			selectedCategory: null,
			editingPost: null,
			uploadingPDF: false,
			detailedPhoto: '',
			pdfOnly:false,
			uploadingDetailedPhoto: false,
			showDatePicker: false,
			PDFObject: '',
			detailedPhotoObject: '',
			AWSrefreshed:false,
			postBucketId:shortid.generate(),
			fileAttachmentKeys:[],
			fileAttachments:[],
			preloadedFiles:[],
			donePreloading:false,
			inlineImageIsUploading:false,
			submitting:false,
			is_featured:false,
			file_s3_path: '', //going forward pdfs will be stored in here
			inputs: {
				category: '',
				sendTimestamp: 0,
			},

			//NOTE: editorContent must be separate from editingPost.content
			//to handle the case of a newly-created post
			editorContent: ''
		});
	}

	openEditForm(editingPost = null) {
		let departments = [];
		if(!editingPost){
			this.setState({editPostOpen:true},()=>this.resetState());
		}else{
			for (let item in editingPost.departments) {
				if (editingPost.departments[item].name) {
					departments.push(editingPost.departments[item].name.toLowerCase());
				}
			}
			let isPDF=editingPost.direct_file;
			this.setState({
				selectedTags: editingPost.tags,
				postBucketId:shortid.generate(),
				selectedCategory: editingPost.category ? editingPost.category : null,
				selectedDepartments: departments,
				date: new Date(editingPost.published_at * 1000),
				detailedPhoto: editingPost.img_s3_path,
				file_s3_path: editingPost.file_s3_path, //going forward pdfs will be stored in here
				pdfOnly:isPDF,
				editPostOpen: true,
				editingPost: editingPost,
				editorContent: editingPost !== null ? editingPost.content : '',
				donePreloading:false,
				uploadingDetailedPhoto: false,
				preloadedFiles:[],
				fileAttachmentKeys:[],
				detailedPhotoObject: '',
				PDFObject:null,
				inlineImageIsUploading:false,
				submitting:false,
				is_featured:editingPost.is_featured
			},()=>{
				if(editingPost.img_s3_path){
					this.aws.getObject(config.bucket_name, editingPost.img_s3_path, this.getObjectFail, successResponse=>{this.setState({ detailedPhotoObject: successResponse });});
				}
				if(isPDF && editingPost.file_s3_path[0].length>0){
					this.aws.getObject(config.bucket_name, editingPost.file_s3_path[0], this.getObjectFail, successResponse=>{this.setState({ PDFObject: successResponse });});
				}else{
					if(editingPost.file_s3_path){
						savePreLoadedAttachments(this,editingPost.file_s3_path);
					}
				}
				parseForInlineImages(this.aws);
			});
		}
	}

	closeEditForm() {
		this.setState({
			editPostOpen: false,
			pdfOnly:false,
			date: new Date(),
			selectedTags: [],
			selectedDepartments: [],
			selectedCategory: null,
			editingPost: null,
			editorContent: '',
			donePreloading:false,
			detailedPhotoObject: '',
			preloadedFiles:[]
		});
	}

	uploadPDFError = err => {
		toast.error(GENERIC_UPLOAD_ERROR_MESSAGE + ' ' + TRY_AGAIN_LATER);
		this.setState({ uploadingPDF: false });
		console.log(err);
	};

	uploadDetailedPhotoError = err => {
		toast.error(GENERIC_UPLOAD_ERROR_MESSAGE + ' ' + TRY_AGAIN_LATER);
		this.setState({ uploadingDetailedPhoto: false });
	};

	setPDFSuccess = data => {
		let key = getAWSKey(data);
		this.setState({ file_s3_path: [key], uploadingPDF: false });
	};

	setDetailedPhotoSuccess = data => {
		let key = getAWSKey(data);
		this.setState({ detailedPhoto: key, uploadingDetailedPhoto: false });
	};

	savePDF = file => {
		this.setState({ PDFObject: null, uploadingPDF: true }, () => this.aws.uploadFile(config.bucket_name, file.name, file, this.uploadPDFError, this.setPDFSuccess));
	};

	saveFileAttachments=files=>{
		this.setState({fileAttachments:files, file_s3_path:files});
	}

	saveDetailedPhoto = file => {
		this.setState({ detailedPhotoObject: null, uploadingDetailedPhoto: true }, () => this.aws.uploadFile(config.bucket_name, file.name, file, this.uploadDetailedPhotoError, this.setDetailedPhotoSuccess));
	};

	onChangeDate = date => {
		let minutes = new Date(date);
		minutes = minutes.getMinutes();
		if (minutes < 1) {
			const minutesInput = document.getElementsByClassName('react-datetime-picker__inputGroup__minute')[0];
			minutesInput.value = 0;
		}
		this.setState({ date });
	};

	changeDepartments = value => {
		this.setState({ selectedDepartments: value });
	};

	handleCreateCategory = category => {
		let categories = [...this.state.categories];
		categories.push(category);
		this.setState({ categories, selectedCategory: category });
	};

	changeCategory = value => {
		this.setState({ selectedCategory: value[value.length - 1] });
	};

	handleCreateDepartment = department => {
		let departments = [...this.state.departments];
		let selectedDepartments = [...this.state.selectedDepartments];
		selectedDepartments.push(department);
		departments.push(department);
		this.setState({ departments, selectedDepartments });
	};

	changeTags = value => {
		this.setState({ selectedTags: value });
	};

	handleCreateTag = tag => {
		let tags = [...this.state.tags];
		let selectedTags = [...this.state.selectedTags];
		selectedTags.push(tag);
		tags.push(tag);
		this.setState({ tags, selectedTags });
	};

	handleDropdownChange = event => {
		event.preventDefault();
		let inputs = { ...this.state.inputs };
		if (typeof event !== 'undefined') {
			inputs[event.currentTarget.id] = event.currentTarget.value;
			this.setState({
				inputs: inputs
			});
		}
	};
	renderUploadWidgets=()=>{
		if(this.state.pdfOnly){
			let preview=this.state.file_s3_path;
			if(preview){
				preview=this.state.file_s3_path[0];
			}
			return(
				<div className="upload-widget-container">
					<UploadWidget
						fileCategory="PDF"
						acceptedFileTypes={UPLOAD_PDF_FILE_TYPE}
						saveFile={this.savePDF}
						fileObject={this.state.PDFObject}
						idUniqueIdentifier={'detailed-photo'}
						classIdentifier={'upload-detailed-photo'}
						allowAllFileSizes={true}
						showRequirements={true}
						fileId={'upload-detailed-photo'}
						uploading={this.state.uploadingPDF}
						preview={preview}
					/>
					<UploadWidget
						fileCategory="Thumbnail"
						acceptedFileTypes={UPLOAD_IMAGE_FILE_TYPES}
						saveFile={this.saveDetailedPhoto}
						fileObject={this.state.detailedPhotoObject}
						idUniqueIdentifier={'thumbnail'}
						classIdentifier={'upload-thumbnail'}
						fileId={'upload-thumbnail'}
						uploading={this.state.uploadingDetailedPhoto}
						preview={this.state.detailedPhoto}
					/>
				</div>
			);
		}else{
			return(
				<div className="upload-widget-container">
					<UploadWidget
						fileCategory="Featured Image"
						acceptedFileTypes={UPLOAD_IMAGE_FILE_TYPES}
						saveFile={this.saveDetailedPhoto}
						fileObject={this.state.detailedPhotoObject}
						idUniqueIdentifier={'detailed-photo'}
						classIdentifier={'upload-detailed-photo'}
						showRequirements={true}
						fileId={'upload-detailed-photo'}
						uploading={this.state.uploadingDetailedPhoto}
						preview={this.state.detailedPhoto}
					/>
					<MultiUploadWidget
						fileCategory="Attachment"
						acceptedFileTypes={ACCEPTED_FILE_TYPE_ARRAY}
						saveFile={this.saveFileAttachments}
						idUniqueIdentifier={'thumbnail'}
						classIdentifier={'upload-thumbnail'}
						allowAllFileSizes={true}
						fileId={'upload-thumbnail'}
						preloadedFiles={this.state.preloadedFiles}
						donePreloading={this.state.donePreloading}
					/>
				</div>
			);
		}
	}
	handlePDFCheckbox=()=>{
		this.setState({
			PDF:null,
			uploadingPDF:null,
			PDFObject: null,
			detailedPhoto:null,
			uploadingDetailedPhoto:null,
			detailedPhotoObject: null,
			pdfOnly: !this.state.pdfOnly,
		});
	}
	handleFeaturedCheck=()=>{
		this.setState({is_featured: !this.state.is_featured});
	}
	renderEditForm() {
		const cookies=parse_cookies();
		const author_name = `${cookies[FIRST_NAME]} ${cookies[LAST_NAME]}`;
		let dflts =
			this.state.editingPost !== null
				? this.state.editingPost
				: {
						title: '',
						is_featured: false,
						category: 'News',
						author_name: author_name,
						tags: [],
						content: '',
						selectedDepartments: '',
						selectedTags: '',
						selectedCategory: '',
						dateStart: new Date(),
						dateEnd: new Date()
				  };
		let multiSelectCategory = [];
		if(this.state.selectedCategory){
			multiSelectCategory.push(this.state.selectedCategory);
		}
		let disable_submit = false;
		if(this.state.pdfOnly){
			disable_submit = this.state.uploadingDetailedPhoto || this.state.uploadingPDF;
		}else{
			disable_submit = this.state.uploadingDetailedPhoto;
		}
		return (
			<FlyoutMenu id="edit-post-flyout" menuOpen={this.state.editPostOpen} buttonText="New Post" closeButtonText="Cancel" openMenu={this.openEditForm} closeMenu={this.closeEditForm} haveOpenButton={true}>
				{this.state.editPostOpen && (
					<form onSubmit={e=>{
						e.preventDefault();
						let current_target=e.currentTarget;
						this.setState({submitting:true},()=>{savePost(current_target,this);});
					}}id="admin-manager-form">

						<div className="grid-x grid-padding-x grid-padding-y">
							<div className="small-12 cell title-cell">
								<h2 className="flyout-title">{this.state.editingPost === null ? 'Create a New Post' : 'Editing ' + this.state.editingPost.title}</h2>
							</div>
							<div className="small-12 medium-8 large-7 cell content-cell">
								<div className="grid-x grid-margin-x grid-padding-y">

								<div className="small-6 cell is-featured-cell">
									<label className="input-checkbox-cont" htmlFor="input-is-featured">
										<input type="checkbox" id="input-is-featured" name="is_featured" onChange={this.handleFeaturedCheck} checked={this.state.is_featured} defaultChecked={dflts['is_featured']} />
										Featured Post
									</label>
									<label className="input-checkbox-cont" htmlFor="input-is_pdf">
										<input type="checkbox" disabled={this.state.editingPost!==null}onChange={this.handlePDFCheckbox} checked={this.state.pdfOnly} id="input-is_pdf" name="is_pdf" defaultChecked={dflts['is_pdf']} />
										PDF Only
									</label>
								</div>

									<div className="small-12 cell title-cell">
										<label htmlFor="input-title">Title</label>
										<input type="text" name="title" id="input-title" placeholder="..." required={true} defaultValue={dflts['title']} />
									</div>
									<div className="small-6 cell title-cell">
										<label htmlFor="input-author">Author</label>
										<input type="text" name="author_name" id="input-author" placeholder="..." required={false} defaultValue={dflts['author_name']} />
									</div>

									<div className="small-12 cell datetime-cell">
										<label htmlFor="input-time">Time</label>
										<DateTimePicker
											onChange={this.onChangeDate}
											value={this.state.date}
											disableClock={true}
											// showLeadingZeros={false}
										/>
									</div>

									<div className="small-12 cell">
										<label htmlFor="input-tags">Tags</label>
										<Multiselect data={this.state.tags} value={this.state.selectedTags} onChange={this.changeTags} onCreate={tag => this.handleCreateTag(tag)} allowCreate="onFilter" textField="tags" placeholder="Select Tags..." />
									</div>

									<div className="cell small-12">
										<label htmlFor="input-category">Category</label>
										<Multiselect
											data={this.state.categories}
											value={multiSelectCategory}
											onChange={this.changeCategory}
											onCreate={category => this.handleCreateCategory(category)}
											allowCreate="onFilter"
											textField="Category"
											placeholder="Select Category"
										/>
									</div>
									<div className="small-12 cell">
										<label htmlFor="input-tags">Departments</label>
										<Multiselect
											data={this.state.departments}
											value={this.state.selectedDepartments}
											onChange={this.changeDepartments}
											onCreate={department => this.handleCreateDepartment(department)}
											allowCreate="onFilter"
											textField="tags"
											placeholder="Select Departments..."
										/>
									</div>
									{!this.state.pdfOnly &&
										<div className="small-12 cell">
											<label htmlFor="input-content">Content</label>
										<input className='invisible-fileId' id='my-file' type='file'></input>
											<Editor
												apiKey={config.tinymce_key}
												value={dflts['content']}
												id={TINY_MCE_ID}
												name={TINY_MCE_NAME}
												init={getTinyMceInitSettings(this,shortid)}
												onEditorChange={this.handleWYSIWYGInputChange}
											/>
										</div>
									}
									<div className="small-12 cell">
										<input disabled={disable_submit || this.state.submitting} className="show-for-medium"type="submit" value="Submit" />
									</div>
								</div>
							</div>
							<div className="small-12 medium-4 cell upload-cell">
							{this.renderUploadWidgets()}
								<input disabled={disable_submit || this.state.submitting} className="hide-for-medium" type="submit" value="Submit" />
							</div>
						</div>
					</form>
				)}
			</FlyoutMenu>
		);
	}

	renderTableTitle() {
		return (
			<div className="grid-x grid-padding-x">
				<div className="small-12 medium-8 cell">
					<h1 className="table-title">News Manager</h1>
				</div>
				<div className="small-12 medium-4 cell">
					<div className="new-item-button-cont">{this.renderEditForm()}</div>
				</div>
			</div>
		);
	}

	render() {
		let tableData = genFormattedTableData(this);

		return (
			<div className="AdminPostManager">
				<FlexTable 
					tableData={tableData} tableTitle={this.renderTableTitle()} 
				/>
				<>{this.state.isLoading &&<LoadingIndicator isLoading={this.state.isLoading}/>}</>
				
				<PageController pageNumber={this.state.currentPage} setPageNumber={(page)=>{
					this.setState({currentPage:page});
				}} numPages={this.state.numPages} />
			</div>
		);
	}
}

export default AdminNewsManager;