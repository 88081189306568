import {ERROR_RETRIEVING_EVENTS, EVENT_URL, TRY_AGAIN_LATER} from "../../constants";
import {processError} from "../../projlibs/cookie-management";
import {toast} from "react-toastify";
import {add_query_parameters, fetch_info} from "./herdajax";

export function getEvents(successCallback, errorCallback = null, parameters = {}) {
    if (errorCallback === undefined || errorCallback === null) {
        errorCallback = defaultErrorCallback;
    }

    let url = add_query_parameters(EVENT_URL, parameters);
    fetch_info(url,successCallback, errorCallback, {});
}

// Likely should be overridden.
export function defaultErrorCallback(error) {
    if (processError(error)) {
        return false;
    } else {
        toast.error(ERROR_RETRIEVING_EVENTS + ' ' + TRY_AGAIN_LATER);
    }
}