import BaseModel from './BaseModel.js';

class User extends BaseModel {
	constructor(created_at) {
		super(created_at);
		
		//NOTE: the validity field for this class is different from the validity field for the base model
		//because the server names these validity fields inconsistently in its data model
		delete this.valid;
		this.is_enabled=true;
		
		//NOTE: these internal variables must be in snake case and not lower camel case
		//because they are used as the serialization fields that get sent to the server
		
		this.first_name='';
		this.last_name='';
		this.email='';
		this.work_phone='';
		this.personal_phone='';
		this.supervisor='';
		this.position='';
		this.user_type='employee';
	}
	
	set_first_name(first_name) {
		return this.generic_setter(this,'first_name',first_name,this.type_string());
	}
	get_first_name() {
		return this.first_name;
	}

	set_last_name(last_name) {
		return this.generic_setter(this,'last_name',last_name,this.type_string());
	}
	get_last_name() {
		return this.last_name;
	}

	set_email(email) {
		return this.generic_setter(this,'email',email,this.type_string());
	}
	get_email() {
		return this.email;
	}

	set_work_phone(work_phone) {
		return this.generic_setter(this,'work_phone',work_phone,this.type_string());
	}
	get_work_phone() {
		return this.work_phone;
	}

	set_personal_phone(personal_phone) {
		return this.generic_setter(this,'personal_phone',personal_phone,this.type_string());
	}
	get_personal_phone() {
		return this.personal_phone;
	}

	set_supervisor(supervisor) {
		return this.generic_setter(this,'supervisor',supervisor,this.type_string());
	}
	get_supervisor() {
		return this.supervisor;
	}

	set_position(position) {
		return this.generic_setter(this,'position',position,this.type_string());
	}
	get_position() {
		return this.position;
	}
}
export default User;

