/*
Grid Cell - A single cell in the CalendarGrid that houses event data.

A GridCell is a base level component, and contains no subcomponents
*/
import React from 'react';
import PropTypes from 'prop-types';

/*
props (component-level arguments):
	text - The text to show on the cell
	events - what events are associated with this cell
	dayType - What type of day this is, (pastDay, dayRemainingThisMonth, futureDay) which controls the style of the cell
	isSelected - an optional prop that is by default set to the first element of the second array
*/
class GridCell extends React.Component {
	renderEventDots = (numberOfEvents) => {
		if(numberOfEvents === 0) {
			return;
		}

		let eventDotsLabel = [];

		if(numberOfEvents > 5) {
			eventDotsLabel = [
				<span key='1' className='fa fa-circle'></span>, 
				<span key='2' className='fa fa-circle'></span>, 
				<span key='3' className='fa fa-circle'></span>, 
				<span key='4' className='fa fa-circle'></span>,
				<span key='5' className='fa fa-plus'></span>];
		} else {
			for(let x = 0; x < numberOfEvents; x++) {
				eventDotsLabel.push(<span className='fa fa-circle' key={x}></span>);
			}
		}

		return (
			<div className='event-dots'>{eventDotsLabel}</div>
			);
	}

	render() {
		let title=this.props.events.length+((this.props.events.length===1)?'event':' events');
		if(this.props.isSelected){
			title+=' today';
		}else if(this.props.dateStr!==null){
			title+=' '+this.props.dateStr;
		}
		return(
			<div className={`GridCell ${this.props.dayType} ${this.props.events.length>0?'event':''} ${this.props.isSelected ? 'selected' : ''}`} title={title}>
				<p className='grid-cell-text'>{this.props.text}</p>
				{this.renderEventDots(this.props.events.length)}
			</div>
		);
	}
}

GridCell.defaultProps = {
	text: '0',
	events: [],
	dayType: "pastDay",
	dateStr:null,
}

GridCell.propTypes = {
	text: PropTypes.string.isRequired,
	events: PropTypes.array.isRequired,
	dayType: PropTypes.string.isRequired,
	dateStr: PropTypes.string
}

export default GridCell;
