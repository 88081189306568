/*
QuickLinkBar - A Quicklink Bar consists of a title, a row of IconLinks and a link to a page that contains all of the Quick Links


*/

import React from 'react';
import PropTypes from 'prop-types';
//eslint-disable-next-line no-unused-vars
import {BrowserRouter as Router, Route, Link} from 'react-router-dom'; // Even tho Route and Router is not actually used a tag, it is needed for the component to show up.

import IconLink from './IconLink.jsx';
/*
props (component-level arguments):
	links: A array of link objects, pulled from the server
*/
class QuickLinkBar extends React.Component {
	render() {
		return(
			<div className={'QuickLinkBar'}>
				<div className='link-title-and-row'>
					<Link className='arrow-link title-text' to={'../quick-links'}>Quick Links</Link>
					<div className='link-row show-for-medium'>
						{this.props.links.map((link, index) => {
							return <IconLink key={index} title={link.title} url={link.url} target={link.target} />;
						})}
					</div>
				</div>
			</div>
		);
	}
}

QuickLinkBar.propTypes = {
	links: PropTypes.array.isRequired,
};

export default QuickLinkBar;
