/*
 FormEditView - the view of a form


*/

import React from 'react';
import InputFieldBlock from '../../../BasicInputs/InputFieldBlock';
import InputCheckbox from '../../../BasicInputs/InputCheckbox';
import { getHeaders } from '../../../../projlibs/HelperNetworkCalls';
import { xml_send_info } from '../../../../react-utils/src/libajax';
import { toast } from 'react-toastify';
import { SUCCESFULLY_UPDATED_FORM_EMAIL, ERROR_UPDATING_FORM_EMAIL } from '../../../../constants';
import styled from 'styled-components';
import IconButton from '../../../IconButton';
import { InputButtonWrapper } from './NewFormView';

/*
props (component-level arguments):
	formBeingViewed: the entire form response from the server
	
*/
class FormEditView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			labels: [],
			copy_form_taker:this.props.formBeingViewed.copy_form_taker,
			email: this.props.formBeingViewed.email,
		};
		this.submitForm = this.submitForm.bind(this);
	}
	

	componentDidMount() {
		this.renderQuestions();
	}

	handleInputChange = event => {
		let inputs = this.state.inputs;
		inputs[event.currentTarget.id] = event.currentTarget.value;
		this.setState({
			inputs: inputs
		});
	};

	renderQuestions = () => {
		const questions = this.props.formBeingViewed.questions;
		let labels = [];
		for (let q in questions) {
			const cur_q = questions[q];
			// if this question depends on a question then skip it because it needs to be rendered inside the question it is dependent on
			if (cur_q.depends_on !== null) {
				continue;
			}
			const q_type = cur_q.q_type;
			if (q_type === 'open_ended') {
				labels.push(
					<div className={`FieldQuestionLabel text`} key={`question-${q}-${questions[q].title}`}>
						<div className="field-label-content text">
							<div className="field-label-title">
								Text
								<i className="fas fa-text-width textarea"> </i>
							</div>
							<InputFieldBlock title="Question Title" fieldRequired={true} fieldType="text" fieldName={`question-${q}-${questions[q].title}`} disabled={true} fieldValue={cur_q.title} fieldHint={'Enter the title of this question...'} />
						</div>
						<div className="fa-label-container"></div>
					</div>
				);
			} else {
				let multi = cur_q.question_type === 'multi_select';
				labels.push(
					<div className={`FieldQuestionLabel single-select`} key={`question-${q}-${questions[q].title}`}>
						<div className="field-label-content text">
							<div className="field-label-title">
								{questions[q].question_type === 'dropdown'?
								'Dropdown': multi ? 'Multi-Select' : 'Single-select'}
								<i className="fas fa-text-width textarea"> </i>
							</div>
							<InputFieldBlock title="Question Title" fieldName={`${q}`} fieldType="text" id={`question-${cur_q.title}`} disabled={true} fieldValue={cur_q.title} fieldHint={'Enter the title of this question...'} />
							{this.renderOptions(cur_q, true)}
						</div>
					</div>
				);
			}
		}
		this.setState({ labels });
	};

	// this is used to render a follow up question
	renderSingleQuestion = question => {
		if (question === undefined || question === null) {
			return null;
		}
		if (question.question_type === 'open_ended') {
			return (
				<div className={`FieldQuestionLabel text`}>
					<div className="field-label-content text">
						<div className="field-label-title">
							Text
							<i className="fas fa-text-width textarea"> </i>
						</div>
						<InputFieldBlock
							title="Question Title"
							fieldRequired={true}
							fieldType="text"
							fieldName={`question-single-${question.title}`}
							disabled={true}
							fieldValue={question.title}
							fieldHint={'Enter the title of this question...'}
						/>
					</div>
					<div className="fa-label-container"></div>
				</div>
			);
		} else {
			let multi = question.question_type === 'multi_select';
			return (
				<div className={`FieldQuestionLabel single-select`}>
					<div className="field-label-content text">
						<div className="field-label-title">
							{multi ? 'Multi-Select' : 'Single-select'}
							<i className="fas fa-text-width textarea"> </i>
						</div>
						<InputFieldBlock
							title="Question Title"
							fieldRequired={true}
							fieldType="text"
							fieldName={`${question.title}`}
							disabled={true}
							fieldValue={question.title}
							fieldHint={'Enter the title of this question...'}
						/>
						{this.renderOptions(question, false)}
					</div>
					<div className="fa-label-container"></div>
				</div>
			);
		}
	};

	findDependentQuestion = (answer_option) => {
		let questions = this.props.formBeingViewed.questions;
		for (let i = 0; i < questions.length; i++) {
			if (questions[i].depends_on) {
				let keys = Object.keys(questions[i].depends_on);
				if (questions[i].depends_on[keys[0]] === answer_option) {
					return questions[i];
				}
			}
		}
		return null;
	};

	handleEmailInputChange = (event,index) => {
		let inputs = this.state.email;
		inputs[index] = event.currentTarget.value;
		this.setState({
			email:inputs
		});
	};
	addEmail=()=>{
		this.setState({email:[...this.state.email,'']});
	}
	removeEmail=(index)=>{
		let oldEmails = [...this.state.email];
		oldEmails.splice(index,1);
		this.setState({email:oldEmails});
	}

	renderOptions = (question, look_for_follow_up = true) => {
		let options = [];

		for (let option in question.answer_options) {
			let follow_up_question = null;
			if (look_for_follow_up) {
				// then this is not a follow up question and has another question depending on it
				let depends_on = this.findDependentQuestion(question.answer_options[option]); // find the question that depends on this question
				if (depends_on !== undefined && depends_on !== null) { // if we found that question
					// if found the dependent question
					follow_up_question = this.renderSingleQuestion(depends_on); // save it to render inside an option below
				}
			}
			options.push(
				<div className={'FieldSelectOption'} key={`option-${question.answer_options[option]}`}>
					<div className="grid-x option-row">
						<div className={'cell small-9'}>
							<InputFieldBlock
								fieldRequired={true}
								fieldType="text"
								title=""
								fieldName={`option-${question.answer_options[option]}`}
								disabled={true}
								fieldValue={question.answer_options[option]}
								inputBlockClass={'option'}
								fieldHint={'Enter answer option'}
							/>
						</div>
						<div className={'cell small-3 has-follow-up'}>
							{follow_up_question && (
								<InputCheckbox checked={true} disabled={true} onCheckChange={this.handleCheckBoxChange} required={false} inputId={`checkbox-${question.answer_options[option]}`} value="false" fieldName="
								" desc="Has follow-up question" />
							)}
						</div>
					</div>
					<div className={`AddFieldController follow-up`}>
						<div className="fields-container">{follow_up_question}</div>
					</div>
				</div>
			);
		}
		return options;
	};

	submitForm() {
		return event => {
			event.preventDefault();
			let data = {
				email: this.state.email,
				copy_form_taker: this.state.copy_form_taker
			};
			xml_send_info('/form/' + this.props.formBeingViewed.form_id, JSON.stringify(data), xhr => {
				toast.success(SUCCESFULLY_UPDATED_FORM_EMAIL);
				this.props.closeFlyoutCallback();
			}, 'PUT', getHeaders(), error => {
				toast.error(ERROR_UPDATING_FORM_EMAIL);
			});
		};
	}
	handleCheckboxChange=()=>{
		this.setState({copy_form_taker: !this.state.copy_form_taker});
	}

	render() {
		return (
			<div className={`NewFormView flyout-form`}>
				<form onSubmit={this.submitForm()}>
					<h2 className="form-title">View Form</h2>
					<div className="main-form-field-wrapper">
						<div className="grid-container full">
							<div className="grid-x">
								<div className="cell small-12">
									<InputFieldBlock title="Title your form" fieldName="title" fieldRequired={true} fieldType="text" fieldValue={this.props.formBeingViewed.title} id="title" disabled={true} />
								</div>
								<div className='email-container'>
									<div className="grid-x">
										<div className="cell small-6">
											<InputWrapper>
											{this.state.email.map((item,index)=>{
													return (
														<InputButtonWrapper key={`email-${index}`} >
															<InputFieldBlock inputBlockClass="email" title="Email results will get sent to" fieldName="email" fieldRequired={true} fieldType="email" callback={(e)=>this.handleEmailInputChange(e,index)} fieldValue={this.state.email[index]} />
															{index > 0 && 
																<IconButton
																	buttonColorType='color-blue'
																	foregroundIcon='fas fa-trash'
																	backgroundIcon='fa fa-square color-light-gray3'
																	extraClasses='icon-button-click'
																	callback={(e)=>{e.preventDefault();this.removeEmail(index);}}
																/>
															}
														</InputButtonWrapper>
													);
												})}
												
												<button className='button save-form' type='button' onClick={()=>this.addEmail()}>Add Email</button>
											</InputWrapper>
										</div>
										<div className="cell small-6">
											<div className="save-email-button-container">
												<div className='form-checkbox-container'>
													<input type='checkbox' name='target' id='input-target' checked={this.state.copy_form_taker} onChange={this.handleCheckboxChange} />
												<label htmlFor='input-target'>CC Form Taker On Submission</label>
												</div>
												<button className="button save-form email" type="submit">
													Save Form Settings
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className={`AddFieldController`}>
						<div className="fields-container"></div>
						{this.state.labels}
					</div>
				</form>
			</div>
		);
	}
}

export default FormEditView;

const InputWrapper = styled.div`
	button:last-child {
		margin-top:1rem;
	}
`;