/*
MainLogo - A MainLogo is the primary logo for the site. This usually appears somewhere in the header or footer on the page

A MainLogo is a top-level component that contains no sub-components
*/

import React from 'react';

const logo = require('../assets/primary.svg');

/*
props (component-level arguments):
	A Main Logo has no Props
*/
class MainLogo extends React.Component {
	render() {
		return(
			<div className={`MainLogo `}>
				{logo && <img src={logo} alt='logo' />}
			</div>
		);
	}
}

export default MainLogo;
