/*
CalendarMonthLabel - The name of the currently selected month.

A CalendarMonthLabel is a base level component, and contains no subcomponents
*/
import React from 'react';
import PropTypes from 'prop-types';
class CalendarMonthLabel extends React.Component {
	render() {
		return(
			<div className={`CalendarMonthLabel `}>{this.props.label}</div>
		);
	}
}

CalendarMonthLabel.defaultProps = {
	label: "Month"
}

CalendarMonthLabel.propTypes = {
	label: PropTypes.string.isRequired
}

export default CalendarMonthLabel;
