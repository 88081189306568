import {
    ERROR_SESSION_EXPIRED,
    AWS_TOKEN,
    AWS_IDENTITY_ID,
    SESSION_ID,
    EMAIL,
    FIRST_NAME,
    LAST_NAME,
    IS_ADMIN,
    USER_ID,
    DEPARTMENT,
    USER_IMAGE,
    RESET_PASS_ENDPOINT
} from '../constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { parse_cookies } from '../react-utils/src/libformat';
//this file consists of functions for managing cookies

//set the cookie for the / path on the current domain
//arguments:
//	cookie_name: the name of the cookie to set
//	cookie_value: the value to set the cookie to, as a string
//return:
//	none
//side-effects:
//	sets the given cookie in the user's browser to the given value
export function set_cookie(cookie_name,cookie_value){
	document.cookie=cookie_name+'='+cookie_value+';path=/;';
}

//delete the cookie for the / path on the current domain
//arguments:
//	cookie_name: the name of the cookie to delete
//return:
//	none
//side-effects:
//	sets the cookie to be expired in the user's browser
//	which is the javascript version of deleting it
export function delete_cookie(cookie_name){
	document.cookie=cookie_name+'=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
/*
This a helper file holding functions that are called throughout the project for session-management
*/
// This function will log the user out, clear all cookies, and then redirect the user to the login page
export function sessionLogout(){
	let login_cookie_names = [
		AWS_TOKEN,
		AWS_IDENTITY_ID,
		SESSION_ID,
		USER_ID,
		EMAIL,
		FIRST_NAME,
		LAST_NAME,
		IS_ADMIN,
		DEPARTMENT,
		USER_IMAGE
	];
	for (var i = 0; i < login_cookie_names.length; i++) {
		document.cookie = login_cookie_names[i] + '=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	}
	if(window.location.pathname === '/'+RESET_PASS_ENDPOINT){
		window.location.href='/reset'+window.location.search;
	}else{
		window.location.href = '/login';
	}
}
export function processError(error){
	if(error.status===401 || error.status===403){
		sessionLogout();
		toast.error(ERROR_SESSION_EXPIRED);
		return true;
	}
	return false;
}
export function hasSession(){
	let cookies=parse_cookies();
	if(!cookies.hasOwnProperty(SESSION_ID)){
		sessionLogout();
		toast.error(ERROR_SESSION_EXPIRED);
		return false;
	}
	return true;
}

