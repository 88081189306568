/*
 UploadWidget - a widget to upload files

*/

import React from 'react';
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '../IconButton';
import pdf_icon from '../../assets/icon_PDF_brand_gold.svg';
import { FILE_TYPE_SHORTNAMES } from '../../constants';
import config from '../../config';

/*
props (component-level arguments):
	fileId: the classname for the fileinput. must be unique as this is what's trigerring the upload for this component. if you want multiple upload widgets 
		then having it unique is a must
	acceptedFileTypes: an array of accepted file types (MIME types) based off their extensions; e.g. image/jpeg, image/png, image/*, application/pdf
	idUniqueIdentifier: the id for the drag/drop area, must be unique for same reasons as fileId
	classIdentifier: used to customize the widget css
	fileCategory: the text to display for the title. fileCategory=Photo would read as: Upload Photo
	saveFile: callback function, passes the file that was just selected up to whichever component is using this. all file manipulation aside from
		checking if the file extension is valid should be done in the parent in this callback
	showRequirements: boolean, true by default. if true it will show all file extensions allowed and max file size seperated by commas
	maxFileSize: integer, max file size in mbs
*/
function getMaxFileSize(isAvatar){
	if(isAvatar){
		if(config.maxAvatarFileSizeMB){
			return config.maxAvatarFileSizeMB;
		}else{
			return 0.5;
		}
	}else if(config.maxFileSizeMB){
		return config.maxFileSizeMB;
	}else{
		return 5;
	}
}
class UploadWidget extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dropAreaId: '',
			fileInputId: '',
			showPreview: false,
			file: null,
			allowableFileSize: this.props.allowableFileSize?this.props.allowableFileSize:getMaxFileSize(props.isAvatar)
		};
	}

	// This function is called when the upload button is pressed. This will click the invisible input with type-file to start the upload
	uploadFile = event => {
		event.preventDefault();
		document.getElementById(this.props.fileId).click();
	};
	// This is called by uploadFile and is what happens when the invisible input of type:file is clicked
	saveFile = event => {
		event.preventDefault();
		const file = event.target.files[0];
		if(!this.props.allowAllFileSizes && file && file.size>this.state.allowableFileSize*1048576){
			toast.error(`Error: ${file.name} is too large. Please upload a file with a size no greater than ${this.state.allowableFileSize}MB`);
			return false;
		}
		this.handleFile(file);
	};

	handleFile = file => {
		if (file) {
			if (this.props.acceptedFileTypes.includes(file.type)) {
				this.setState({ file });
				return this.props.saveFile(file);
			}
		}

		toast.error('Error: invalid file type, please upload one of the following: ' + this.renderAcceptedFileTypes());
	};

	setDropEvents = () => {
		const dropArea = document.getElementById(this.state.dropAreaId);

		['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
			dropArea.addEventListener(eventName, this.preventDefaults, false);
		});

		['dragenter', 'dragover'].forEach(eventName => {
			dropArea.addEventListener(eventName, this.highlight, this.preventDefaults, false);
		});

		['dragleave', 'drop'].forEach(eventName => {
			dropArea.addEventListener(eventName, this.unhighlight, false);
		});
		dropArea.addEventListener('drop', this.handleDrop, false);
	};

	highlight() {
		this.classList.add('highlight');
	}

	unhighlight() {
		this.classList.remove('highlight');
	}

	preventDefaults = e => {
		e.preventDefault();
		e.stopPropagation();
	};

	componentDidMount() {
		this.setState({
			dropAreaId: `dropArea_${this.props.idUniqueIdentifier}`,
			fileInputId: `fileInput_${this.props.idUniqueIdentifier}`
			
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.props.allowableFileSize && this.props.allowableFileSize!==prevProps.allowableFileSize && this.props.allowableFileSize!==this.state.allowableFileSize){
			this.setState({allowAllFileSizes:this.props.allowAllFileSizes});
		}
		if (prevState.dropAreaId !== this.state.dropAreaId) {
			this.setDropEvents();
		}
		if (prevProps.uploading && !this.props.uploading) {
			// show the preview
			this.setState({ showPreview: true });
		}
		else if(!prevProps.fileObject && this.props.fileObject){
			this.setState({ showPreview: true });
		}
		if((this.props.preview===null && prevProps.preview!==null) || (this.props.fileObject===null && prevProps.fileObject!==null)){
			this.setState({ showPreview: false });
		}
	}

	handleDrop = e => {
		/*
			The dataTransfer.files function is available in all browsers except Safari for IOS.
			If we find this to be an issue another approach might be warranted,
			but I don't think that anyone will be using this in a clinic on their iphone.
		*/

		//NOTE: the number of files is limited to 2-10 images or one or more zip files by logic in CreateCaseForm::handleFileUploadUpdate
		//which is triggered via callbacks
		const dt = e.dataTransfer;
		const file = dt.files;
		this.handleFile(file[0]);
	};

	renderAcceptedFileTypes = () => {
		return this.props.acceptedFileTypes.map(type => FILE_TYPE_SHORTNAMES[type]).join(', ');
	};

	removePreview = e => {
		e.preventDefault();
		this.setState({ showPreview: false });
		if(this.props.removeCallback){
			this.props.removeCallback();
		}
	};

	renderPdf=()=>{
		return(
			<div className='pdf-preview'>
				{pdf_icon && <img className='pdf-post' alt='pdf post' src={pdf_icon} />}
			</div>
		);
	}

	renderPreviewLogic=()=>{
		if(this.props.fileObject && this.props.fileObject.ContentType){
			if(this.props.fileObject.ContentType.includes('image')){
				return (
					<img className='preview-image' src={URL.createObjectURL(new Blob([this.props.fileObject.Body]))} alt={'preview'}/>
				);
			}
			else
				return (this.renderPdf());
		}
		if(this.state.file && this.state.file.type.includes('image')){
			return (
				<img className='preview-image' src={URL.createObjectURL(this.state.file)} alt={'preview'} />
			);
		}else{
			return (this.renderPdf());
		}
	}

	renderPreview = () => {
		return (
			<div className='drag-drop-files' id={this.state.dropAreaId}>
				<div className='preview'>
					{this.renderPreviewLogic()}
				</div>
				<div className='absolute-container'>
					<IconButton extraClasses='close' buttonColorType='red' foregroundIcon='fa fa-times' callback={e => this.removePreview(e)} />
				</div>
			</div>
		);
	};

	render() {
		return (
			<div className={`UploadWidget ${this.props.classIdentifier}`}>
				<div className='text-wrapper'>
					<h2 className='upload-type'>{this.props.includeUploadTitle?'Upload':''} {this.props.fileCategory}</h2>
					{this.props.acceptedFileTypes && this.props.showRequirements && (
						<div className='upload-requirements-wrapper'>
							<span className='upload-requirements'>Accepted file types: {this.renderAcceptedFileTypes()}</span>
							{!this.props.allowAllFileSizes && <span className='upload-requirements'>Maximum allowable file size is {this.state.allowableFileSize}MB</span>}
						</div>
					)}
				</div>
				{this.state.showPreview?
					this.renderPreview()
				: (
					<div className='drag-drop-files' id={this.state.dropAreaId}>
						{this.props.uploading && (
							<div className='loading-wrapper'>
								<CircularProgress id='network-loading' thickness={6} size={80} />
							</div>
						)}
						<div className='drag-drop-instr-text-wrapper'>
							<span className='fa-stack'>
								<i className='far fa-circle fa-stack-2x'></i>
								<i className={'fa fa-arrow-up fa-stack-1x'}></i>
							</span>
							<span className='drag-drop-instr-text'>drag & drop files here OR</span>
						</div>
						<input type='file' className='invisible-fileId' id={this.props.fileId} onChange={this.saveFile} accept={this.props.acceptedFileTypes.join(', ')} />
						<div className='button-title-row'>
							<button className='button upload-button' onClick={this.uploadFile}>
								Browse Files
							</button>
						</div>
					</div>
				)}
			</div>
		);
	}
}
UploadWidget.defaultProps = {
	fileId: 'invisibleInput',
	showRequirements: true,
	allowAllFileSizes:false,
	includeUploadTitle:true
};
export default UploadWidget;
