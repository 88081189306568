/*
TiledButton - a clickable div

*/
/*
states:
	has no states
props:
	faClass: the font awesome icon to display
	tileText: the text inside this div
	onClickEvent: callback function called when this div is clicked
*/

import React from 'react';
class TiledButton extends React.Component {
	render() {
		return (
			<div className={`tiled-button ${this.props.tileClass}`} onClick={typeof this.props.onClickEvent === 'function' ? this.props.onClickEvent : null}>
				<h3 className="tiled-button">
					<i className={this.props.faClass}></i>
					{this.props.tileText}
				</h3>
			</div>
		);
	}
}
export default TiledButton;
