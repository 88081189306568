//get the data within an html form element as a javascript object
export function get_form_data(form){
	var form_data={};
	//NOTE: the array slice here is used to convert an HTML collection into an array
	//because the HTML collection data type does not include a concatenation function
	var inputs=Array.prototype.slice.call(form.getElementsByTagName('INPUT'),0);
	inputs=inputs.concat(Array.prototype.slice.call(form.getElementsByTagName('SELECT'),0));
	inputs=inputs.concat(Array.prototype.slice.call(form.getElementsByTagName('TEXTAREA'),0));
	for(var i=0;i<inputs.length;i++){
		if(inputs[i].name!==undefined && inputs[i].name!==null && inputs[i].name!==''){
			if(inputs[i].getAttribute('type')==='datetime-local'){
				form_data[inputs[i].name]=(new Date(inputs[i].value).getTime() / 1000);
			}else{
				form_data[inputs[i].name]=inputs[i].value;
			}
		}
	}
	return form_data;
}

