/*
IconLink - An IconLink consists of a icon and a title and URL, the default icon is the link fa image
*/

import React from 'react';
import PropTypes from 'prop-types';

/*
props (component-level arguments):
	title: A title to show for the link
	url: the URL that the link will link to
	icon: the icon to show beside the link, default is fa-link
	target: the target of the link, default is _blank
*/
class IconLink extends React.Component {
	render() {
		return(
			<div className={'IconLink'}>
				<i className={`IconLink-icon ${this.props.icon}`}></i>
				<a href={this.props.url} target={this.props.target === '_blank' ? '_blank' : '_self'} rel='noopener noreferrer' className='icon-link'>{this.props.title}</a>
			</div>
		);
	}
}

IconLink.propTypes = {
	title: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	icon: PropTypes.string,
	target: PropTypes.string
};

IconLink.defaultProps = {
	icon: 'fa fa-link',
	target: '_blank'
};

export default IconLink;
