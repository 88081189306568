/*
InputDropdownBlock, a dropdown and its title

An InputDropdownBlock item consists of a title and a dropdown
*/

import React from 'react';
import InputDropdown from './InputDropdown';
import PropTypes from 'prop-types';

/*
props (component level arguments):
	title: the title of the dropdown
	titleClassName: an additional class for the title
	dropdownClass: the class of InputDropdown
	dropdownId: the id of the InputDropdown
	dropdownRequired: boolean value for 'required' attribute of InputDropdown
	optionData: data for InputDropdown in an array
	callback: The onchange event handler
	defaultValue: default value for this input field
*/

export class InputDropdownBlock extends React.Component {
	constructor(props) {
		super(props);
		this.changeEventHandler = this.changeEventHandler.bind(this);
	}

	changeEventHandler(event) {
		if(this.props.callback) {
			this.props.callback(event);
		}
	}

	render() {
		return (
			<div className="InputDropdownBlock">
				<label
					className={"input-title " + this.props.titleClassName}
					htmlFor={this.props.dropdownId}>{this.props.title}
				</label>
				<InputDropdown
					{...this.props}
					callback={this.changeEventHandler}/>
			</div>
		);
	}
}

InputDropdownBlock.defaultProps = {
	titleClassName: '',
	dropdownId: ''
};

InputDropdownBlock.propTypes = {
	title: PropTypes.string.isRequired,
	dropdownClass: PropTypes.string,
	dropdownId: PropTypes.string.isRequired,
	dropdownRequired: PropTypes.bool,
	optionData: PropTypes.array.isRequired,
	callback: PropTypes.func
};

export default InputDropdownBlock;
