import React, { useEffect, useState } from 'react';
import { FormTitle } from './FormTitle';
import { FormEmails } from './FormEmails';
import { Question } from './Question';
import { FormContext } from './FormContext';
import { AddQuestionMenu } from './AddQuestionMenu';
import { xml_send_info } from '../../../../../lib/networking/herdajax';
import {
	SUCCESFULLY_UPDATED_FORM_EMAIL,
	ERROR_UPDATING_FORM_EMAIL,
} from '../../../../../constants';
import { getHeaders, getTags } from '../../../../../projlibs/HelperNetworkCalls';
import { toast } from 'react-toastify';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Multiselect } from 'react-widgets';
export const FormViewManager = ({ form, closeFlyoutCallback, portal }) => {
	const [formTitle, setFormTitle] = useState('');
	const [emails, setEmails] = useState(['']);
	const [copyFormTaker, setCopyFormTaker] = useState(false);
	const [questions, setQuestions] = useState([]);
	const [completionTitle] = useState('Thank you for completing the form.');
	const [tags, setTags] = useState([]);
	const [selectedTags , setSelectedTags] = useState([]);
	const [, setReRender] = useState(false);
	useEffect(() => {
		getTags((data) => {
			setTags(data);
		},(err) => {
			toast.error('Error grabbing form tags.');
		},'form');
	}, []);

	useEffect(() => {
		if (form) {
			if(form.viewed){
				setQuestions(form.questions);
			}else{
				form.questions = form.questions.filter((element) => element.valid);
				let formattedQuestions = FormatQuestions([...form.questions]);
				setQuestions(formattedQuestions);
			}
			form.viewed=true;
			setFormTitle(form.title);
			setSelectedTags(form.tags);
			setCopyFormTaker(form.copy_form_taker);
			setEmails(form.email);
		}
	}, [form]);

	const handleCreateTag = tag => {
		let newTags = [tags];
		let newSelectedTags = [...selectedTags];
		newSelectedTags.push(tag);
		newTags.push(tag);
		setTags(newTags);
		setSelectedTags(newSelectedTags);
	};
	
	const resetFilters=()=>{
		let resetFilterButton=document.getElementById('reset-button');
		if(resetFilterButton){
			resetFilterButton.click();
		}
	};
	const submitForm = (e) => {
		e.preventDefault();
		let newQs = FormatForRequest(questions);
		let data = {
			title: formTitle,
			description: 'Form Description',
			completion_title: completionTitle,
			completion_subtitle: '',
			email: emails,
			copy_form_taker: copyFormTaker,
			questions: newQs,
			tags: selectedTags
		};
		if (form) {
			xml_send_info(
				'/form/' + form.form_id,
				JSON.stringify(data),
				(xhr) => {
					toast.success(SUCCESFULLY_UPDATED_FORM_EMAIL);
					closeFlyoutCallback();
					resetFilters();
				},
				'PUT',
				getHeaders(),
				(error) => {
					toast.error(ERROR_UPDATING_FORM_EMAIL);
				}
			);
		} else {
			xml_send_info(
				'/form',
				JSON.stringify(data),
				(xhr) => {
					toast.success(SUCCESFULLY_UPDATED_FORM_EMAIL);
					closeFlyoutCallback();
					resetFilters();
				},
				'POST',
				getHeaders(),
				(error) => {
					toast.error(ERROR_UPDATING_FORM_EMAIL);
				}
			);
		}
	};

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		// Update all position fields
		return result.map((question, index) => ({ ...question, position: index + 1 }));
	};

	const onReorder = (result) => {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}
		setQuestions(reorder(questions, result.source.index, result.destination.index));
	};
	return (
		<FormContext.Provider value={{ reRender: setReRender, setQuestions:setQuestions }}>
				<div className={'NewFormView flyout-form'}>
				<DragDropContext onDragEnd={onReorder}>
					<form onSubmit={submitForm}>
						<h2 className="flyout-title">{form?'Edit Form':'Create a New Form'}</h2>
						<SaveButton />
						<div className="main-form-field-wrapper">
							<div className="grid-container full">
								<div className="grid-x">
									<FormTitle
										title={formTitle}
										setTitle={setFormTitle}
									/>
									<FormEmails
										setEmails={setEmails}
										emails={emails}
										copyFormTaker={copyFormTaker}
										setCopyFormTaker={setCopyFormTaker}
									/>
									<div className="small-12 cell form-tag-wrapper">
										<label htmlFor="input-tags">Tags</label>
										<Multiselect data={tags} value={selectedTags} onChange={tags=>setSelectedTags(tags)} onCreate={tag => handleCreateTag(tag)} allowCreate="onFilter" textField="tags" placeholder="Select Tags..." />
									</div>
									<div className="add-form-fields">
										Add Questions Below
									</div>
								</div>
								<Droppable droppableId="droppable-tiles">
								{(provided) => (
									<div
										ref={provided.innerRef}
										{...provided.droppableProps}
									>
									<div className="fields-container">
										{questions.map((item, index) => (
											<Question
												portal={portal}
												key={`question-${item.title}`}
												questionIndex={index}
												question={item}
												isFollowUp={false}
												questions={questions}
											/>
										))}
									</div>
									{provided.placeholder}
									</div>
								)}
								</Droppable>
							</div>
						</div>
					</form>
					<AddQuestionMenu questions={questions} />
					</DragDropContext>
				</div>
			

		</FormContext.Provider>
	);
};

const SaveButton = () => (
	<div className="save-form-button">
		<button className="button save-form" type="submit">
			<i className="fa fa-check"></i>Save Form
		</button>
	</div>
);

const FormatForRequest = (questions) => {
	let newQuestions = [];
	for (let i = 0; i < questions.length; i++) {
		let q = questions[i];
		clearOpenEndedAnswerOptions(q);
		if (q.answer_options) {
			let followUpQs = [];
			for (let optionI in q.answer_options) {
				let option = q.answer_options[optionI];
				if (option.question) {
					option.question.parent = {
						questionTitle: q.title,
						optionTitle: option.title,
					};
					if (option.question.answer_options) {
						for (let followOption in option.question
							.answer_options) {
							option.question.answer_options[followOption] =
								option.question.answer_options[
									followOption
								].title;
						}
					}
					clearOpenEndedAnswerOptions(option.question);
					followUpQs.push(option.question);
				}
				q.answer_options[optionI] = option.title;
			}
			newQuestions.push(q, ...followUpQs);
		} else {
			newQuestions.push(q);
		}
	}
	for (let i = 0; i < newQuestions.length; i++) {
		if (newQuestions[i].parent) {
			for (let j = 0; j < newQuestions.length; j++) {
				if (
					newQuestions[j].title ===
					newQuestions[i].parent.questionTitle && newQuestions[j].valid
				) {
					newQuestions[i].depends_on = {
						[j]: newQuestions[i].parent.optionTitle,
					};
					break;
				}
			}
		}
	}
	return newQuestions;
};
const FormatQuestions = (formattedQuestions) => {
	for (let i = 0; i < formattedQuestions.length; i++) {
		let cur_q = formattedQuestions[i];
		clearOpenEndedAnswerOptions(cur_q);
		if (cur_q.answer_options) {
			for (let j = 0; j < cur_q.answer_options.length; j++) {
				cur_q.answer_options[j] = {
					title: cur_q.answer_options[j],
					question: null,
				};
			}
		}
	}
	for (let i = 0; i < formattedQuestions.length; i++) {
		let cur_q = formattedQuestions[i];
		
		if (cur_q.depends_on) {
			// then its a follow up question and should be inside an answer_option object
			const keys = Object.keys(cur_q.depends_on); // theres always 1 key (from server)
			const qIdxOwner = parseInt(keys[0]); // this question must be answered first
			cur_q.remove = true;
			if(qIdxOwner<formattedQuestions.length && formattedQuestions[qIdxOwner] && formattedQuestions[qIdxOwner].answer_options){
				const option = formattedQuestions[qIdxOwner].answer_options.find(
					(element) => element.title === cur_q.depends_on[qIdxOwner]
				);
				// this option holds the follow up question
				if(option){
					option.question = formattedQuestions[i]; // save this follow up question inside the option obj
					clearOpenEndedAnswerOptions(option.question);
				}
			}// the question could be deleted but not the follow up option.
			
		}
	}
	formattedQuestions = formattedQuestions.filter((q) => !q.remove);
	return formattedQuestions;
};

function clearOpenEndedAnswerOptions(q){
	if(q.question_type==='open_ended'){
		q.answer_options=null;
	}
}
