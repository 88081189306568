import styled, { css } from 'styled-components';

import React from 'react';
import { ScaleLoader } from 'react-spinners';
const SpinnerContainer = styled.div`
	display:flex;
	align-items:center;
	justify-content:center;
	${props=>css`
		position:${props.position};
	`};
`;
export const LoadingIndicator = props =>{
	return (<>
		{
			props.isLoading &&
			<SpinnerContainer {...props}>
				<ScaleLoader loading={props.isLoading} color={'#DFA429'} />
			</SpinnerContainer>
		}
	</>);
};