/*
EventPost - A EventPost page is a detaield view for a event article that uses the post ID params to get the information from an API

A EventPost Page consists of a Search and CatTagLabelList components
*/

import React from 'react';
import {Link} from 'react-router-dom';

import { xml_fetch_info } from '../../react-utils/src/libajax.js';
import { parse_cookies } from '../../react-utils/src/libformat';
import {ERROR_RETRIEVING_EVENTS, TRY_AGAIN_LATER, SESSION_ID} from '../../constants';
import {processError} from '../../projlibs/cookie-management';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

import CatTagLabelList from '../CatTagLabelList';
import DateTile from '../DateTile';
import MetaData from '../MetaData';

import S3Image from '../S3Image.jsx';

import config from '../../config';
import FileAttachment from '../PostComponents/FileAttachment.jsx';
import HerdRAAWSUtils from '../../projlibs/HerdRAAWSUtils';
/*
props (component-level arguments):
	No Props

params:
	event_id: The ID of the event, used to fetch from the API
*/
import { parseForInlineImages } from '../../projlibs/InlineImageFunctions';
class EventPost extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			event_id: 0,
			eventInfo: [],
			eventInfoContent:''
		};
		this.updateInfo = this.updateInfo.bind(this);
		this.aws = new HerdRAAWSUtils();
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.state.event_id !== this.props.match.params.event_id) {
			this.setState({
				event_id: this.props.match.params.event_id
			}, () => this.updateInfo());
		}
		if(prevState.eventInfoContent !== this.state.eventInfoContent){
			parseForInlineImages(this.aws);
		}
	}

	updateInfo() {
		const cookies = parse_cookies();
		const { match: { params } } = this.props;

		xml_fetch_info('/events/'+params.event_id,
			xhr => {
				const response = JSON.parse(xhr.response);
				let eventInfo=[response.Event][0];
				let eventInfoContent=eventInfo.content;
				if(eventInfo.file_s3_path){
					eventInfo.fileNames=[];
					for(let i=0;i<eventInfo.file_s3_path.length;i++){
						eventInfo.fileNames[i]={
							fileName:eventInfo.file_s3_path[i]
						};
						eventInfo.fileNames[i].getSignedUrlSuccess=(url)=>{
							eventInfo.fileNames[i].signedUrl=url;
							this.forceUpdate();
						};
					}

					eventInfo.fileName=eventInfo.file_s3_path;
					eventInfo.getSignedUrlError=function(error){
						console.log(error);
					};
					eventInfo.getSignedUrlSuccess=(url)=>{
						this.setState({fileAttachment:url});
					};
					for(let i=0;i<eventInfo.fileNames.length;i++){
						this.aws.getSignedUrl(config.bucket_name,eventInfo.fileNames[i].fileName,eventInfo.getSignedUrlError,eventInfo.fileNames[i].getSignedUrlSuccess);
					}
				}
				this.setState({eventInfo, eventInfoContent},() => {
					//log a view of this event which includes the title information for the event
					let ga_event={
						'event':'custom-pageview',
						'url':window.location.pathname,
						'title':'Event - '+(this.state?.eventInfo?.title),
						'category':'event',
					};
					window.dataLayer.push(ga_event);
				});
			},
			{
				'Content-Type': 'application/json',
				Authorization: cookies[SESSION_ID]
			},
			function (error) {
				if(processError(error)){
					return false;
				}else{
					toast.error(ERROR_RETRIEVING_EVENTS + ' ' + TRY_AGAIN_LATER);
				}
			}
		);
	}

	render() {
		const timeStartMoment = moment.unix(this.state.eventInfo.start);
		const timeEndMoment = moment.unix(this.state.eventInfo.end);
		let contactText = '';
		if(this.state.eventInfo.contact_name && this.state.eventInfo.contact_phone){
			contactText = `Contact ${this.state.eventInfo.contact_name} ${this.state.eventInfo.contact_phone}`;
		}else if(this.state.eventInfo.contact_name){
			contactText = `Contact ${this.state.eventInfo.contact_name}`;
		}else if(this.state.eventInfo.contact_phone){
			contactText = `${this.state.eventInfo.contact_phone}`;
		}
		return(
			<div className={'EventPost'}>
				<div className='meta-row'>
					<Link className={'caret-left-link'} to='/events'>Back to Events</Link>
					<CatTagLabelList category={this.state.eventInfo.category} tags={this.state.eventInfo.tags} />
				</div>
				<div className='post-content'>
					<header>
						<S3Image aws_object={this.aws} additionalClasses='full' s3_path={this.state.eventInfo.img_s3_path}/>
					</header>
					<div className='content-wrapper'>
						<div className='DateTile-wrapper'>
							{this.state.eventInfo.start && <DateTile endDate={this.state.eventInfo.end} date={this.state.eventInfo.start} />}
						</div>
						<div className='content-inner'>
							{this.state.eventInfo.title && <h1>{this.state.eventInfo.title}</h1>}
							{timeStartMoment && timeEndMoment && <MetaData additionalClass={'calendar'} icon='far fa-clock' text={`${timeStartMoment.format('hh:mma')} - ${timeEndMoment.format('hh:mma')}`} />}
							{this.state.eventInfo.address && <MetaData additionalClass={'calendar'} icon='fas fa-map-marker-alt' text={`${this.state.eventInfo.address}`} />}
							<MetaData additionalClass={'calendar'} icon='far fa-address-book' text={contactText} />
							{this.state.eventInfo.fileNames && this.state.eventInfo.fileNames.map( (item,index)=>{
								return <FileAttachment key={'event-file-'+this.state.eventInfo.post_id+'-'+index} fileName={item.fileName} fileAttachmentLink={item.signedUrl} />;
							})}
							{/* This might use something else, I'm not sure what author_id is actually going to present. */}
							{this.state.eventInfo.author_id && <MetaData icon='fas fa-user' text={this.state.eventInfo.author_id} />}
							{this.state.eventInfoContent &&
							<div>
								<hr />
								<div className='content' dangerouslySetInnerHTML={{__html: this.state.eventInfoContent}} />
							</div>
							}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default EventPost;
