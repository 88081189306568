/*
ImageCarousel - A ImageCarousel is a Slick Slider for large hero images and links to other pages.

A Image Carousel consists of an array of ImageSlide's 
*/

import React from 'react';
import PropTypes from 'prop-types';
import ImageSlide from './ImageSlide';
import Slider from 'react-slick';
import config from '../../config';

/*
props (component-level arguments):
	slideArray: An array of objects that consist of {slideLink, title, metaData, and ImageUrl}
*/
class ImageCarousel extends React.Component {

	showCarouselSlide=()=>{
		let carouselSlideArray = [];
		for (let index = 0; index < this.props.slideArray.length; index++) {
			let slideInfo = this.props.slideArray[index]?this.props.slideArray[index]:{};
			let newPath = undefined;
			if(slideInfo['slideLink']){
				if(this.props.dashboard){
					newPath = `${slideInfo['postType']}/${slideInfo['slideLink']}`;
				}else{
					newPath = `${slideInfo['slideLink']}`;
				}
			}
			carouselSlideArray.push(
				<ImageSlide newPath={newPath} aws_object={this.props.aws_object} key={index} imageUrl={slideInfo['imageUrl']} title={slideInfo['title']} metaData={slideInfo['metaData']} date={slideInfo['date']} />
			);
		}
		return <Slider {...this.props.carouselSettings}>{carouselSlideArray}</Slider>;
	}

	render() {
		return(
			<div className={'ImageCarousel'}>
				{this.showCarouselSlide()}
			</div>
		);
	}
}

ImageCarousel.propTypes = {
	slideArray: PropTypes.array,
	carouselSettings: PropTypes.object
};

ImageCarousel.defaultProps = {
	slideArray: [],
	carouselSettings: {
		dots: true,
		arrows: false,
		infinite: true,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
		lazyLoad: 'progressive',
		cssEase: 'linear',
		autoplay: true,
		autoplaySpeed: config.slider_speed_ms?config.slider_speed_ms:6000,
	}
};

export default ImageCarousel;
