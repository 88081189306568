/*
TagLabelList - a list of tags for an object

A tag label list consists of (from left to right and top to bottom)
	TagLabel - the label for a single tag
*/


import React from 'react';
import PropTypes from 'prop-types';

import TagLabel from './TagLabel.jsx';

/*
props (component-level arguments):
	tags: the tag list to display
*/
class TagLabelList extends React.Component {
	render() {
		return (
			<div className='TagLabelList'>
				{this.props.tags.map(function (tag,idx) {
					return <TagLabel key={'TagLabelList-item-'+idx} tag={tag} />
				})}
			</div>
		);
	}
}

TagLabelList.defaultProps={
	tags: [],
};

TagLabelList.propTypes = {
	tags: PropTypes.array.isRequired,
};

export default TagLabelList;

