/*
News - A News page is a parent archive for viewing and filtering news articles.

A News page consists of a Search, Image Carousel, Page Title, Filters, and Brief Views
*/

import React from 'react';
import {Link} from 'react-router-dom';

import { xml_fetch_info } from '../../react-utils/src/libajax.js';
import { parse_cookies } from '../../react-utils/src/libformat';
import {ERROR_RETRIEVING_NEWS, TRY_AGAIN_LATER, SESSION_ID, SECONDS_PER_MINUTE, MINUTES_PER_HOUR, HOURS_PER_DAY, DAYS_PER_WEEK} from '../../constants';
import {processError} from '../../projlibs/cookie-management';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ImageCarousel from '../ImageCarousel/ImageCarousel';
import BriefView from '../BriefView/BriefView';
import Filters from '../Filters.jsx';
import FilteredComponent from '../FilteredComponent.jsx';
import config from '../../config';
import {getFirstDayOfWeek, getFirstDayOfMonth, getFirstDayOfNextMonth} from '../../projlibs/DateCalculations.js';
import { openAlreadySignedURLFile } from '../../lib/libdownloadFiles';
import HerdRAAWSUtils from '../../projlibs/HerdRAAWSUtils';
import { PageController } from '../PageController';
import { LoadingIndicator } from '../LoadingIndicator';
import { fetchFeaturedPosts } from './Dashboard.jsx';

/*
props (component-level arguments):
	A News Page contians no props.
*/
class News extends FilteredComponent {
	constructor(props) {
		super(props);
		this.state = {
			filters:[],
			newsInfoUnFiltered:[],
			newsInfo: [],
			filteredMinTime: null,
			filteredMaxTime: null,
			carouselData:[],
			numPages:1,
			currentPage: 0,
			isLoading:true
		};

		this.aws = new HerdRAAWSUtils();

		this.handleTimeFilter=this.handleTimeFilter.bind(this);
		this.getFilteredData=this.getFilteredData.bind(this);
		this.netCallSuccess=this.netCallSuccess.bind(this);
		this.netCallFailure=this.netCallFailure.bind(this);
	}
	
	UNSAFE_componentWillMount(){
		let filters=this.state.filters;
		if(this.props.location.department) {
			filters['department']=this.props.location.department;
		} else {
			filters['department']='';
		}
		this.setState({filters:filters},() => {this.getFilteredData();});	
	}
	
	componentDidMount(){
		fetchFeaturedPosts(this,'news');
	}

	componentDidUpdate(prevProps,prevState){
		if(prevProps.location.department !== this.props.location.department){
			let filters=this.state.filters;
			if(this.props.location.department) {
				filters['department']=this.props.location.department;
			} else {
				filters['department']='';
			}
			this.setState({filters:filters},() => {this.getFilteredData();});
		}
		if(prevState.currentPage !== this.state.currentPage){
			this.getFilteredData();
		}
	}

	handleTimeFilter(filter_type){
		//calculate the time cutoff of when we want to get information from
		let min_ts=0;
		let max_ts=null;
		switch(filter_type){
			case 'this_week':
				min_ts=getFirstDayOfWeek();
				max_ts=min_ts+(SECONDS_PER_MINUTE*MINUTES_PER_HOUR*HOURS_PER_DAY*DAYS_PER_WEEK);
				break;
			case 'this_month':
				min_ts=getFirstDayOfMonth();
				max_ts=getFirstDayOfNextMonth();
				break;
			default:
				//NOTE: prior to this switch statement the cutoff_ts parameter is initialized to the "all" case
				break;
		}
		
		this.setState({
			filteredMinTime:min_ts,
			filteredMaxTime:max_ts,
			currentPage:0,
		},() => this.getFilteredData());
	}

	getFilteredData(){
		const min_ts=this.state.filteredMinTime;
		const max_ts=this.state.filteredMaxTime;
		const tag=this.state.filters.tags;
		const cat=this.state.filters.cats;
		//make a network request to get the time-filtered data
		const cookies = parse_cookies();
		let url_path='/search/?table=Post&type=post';
		
		if(min_ts!==null){
			url_path+='&min_time='+min_ts;
		}
		if(max_ts!==null){
			url_path+='&max_time='+max_ts;
		}

		if(cat!==''&&cat!==undefined){
			url_path+='&category='+cat;
		}
		if(tag!==''&&tag!==undefined){
			url_path+='&tag={'+tag+'}';
		}
		if(this.props.location.department){
			url_path+=`&department=${this.props.location.department}`;
		}
		url_path+=`&page=${this.state.currentPage}&page_size=10&order_by=published_at&order_dir=desc`;
		this.setState({isLoading:true},()=>{
			xml_fetch_info(url_path,
				this.netCallSuccess,
				{
					'Content-Type': 'application/json',
					Authorization: cookies[SESSION_ID]
				},
				this.netCallFailure
			);
		});

	}

	netCallSuccess(xhr){
		this.setState({isLoading:false});
		const response = JSON.parse(xhr.response);
		let newsInfo=[];
		if(response.hasOwnProperty('Post')){
			//store the news items even if AWS download links can't be generated
			for(let idx=0;idx<response.Post.length;idx++){
				let newsItem=response.Post[idx];
				if(newsItem.is_valid===false){
					continue;
				}
				if(newsItem.category===null){
					newsItem.category='';
				}
				if(newsItem.tags===null){
					newsItem.tags=[];
				}
				if(newsItem.direct_file && newsItem.file_s3_path!==undefined && newsItem.file_s3_path.length>0){
					newsItem.getSignedUrlError=function(error){
						console.log(error);
					};
					newsItem.getSignedUrlSuccess=function(url){
						newsItem.signed_PDF_link=url;
					};
					this.aws.getSignedUrl(config.bucket_name, newsItem.file_s3_path[0], newsItem.getSignedUrlError, newsItem.getSignedUrlSuccess);
				}
				newsInfo.push(newsItem);
			}
			if(this.state.newsInfoUnFiltered.length===0){
				this.setState({numPages: response.page_count, newsInfo,newsInfoUnFiltered:newsInfo});
			}else{
				this.setState({numPages: response.page_count, newsInfo});
			}

		}else{
			//if no results were found then there's nothing to display
			this.setState({
				newsInfo:[],
				numPages: response.page_count
			});
		}
	}
	
	netCallFailure(error){
		this.setState({isLoading:false});
		if(processError(error)){
			return false;
		}else{
			toast.error(ERROR_RETRIEVING_NEWS + ' ' + TRY_AGAIN_LATER);
		}
	}

	openPDF=(e,newsItem)=>{
		e.preventDefault();
		openAlreadySignedURLFile(newsItem.signed_PDF_link, newsItem.img_s3_path, newsItem.title);
	};

	showPosts() {
		const newsTagArray = [];
		for (let index = 0; index < this.state.newsInfo.length; index++) {
			if (this.state.newsInfo[index].direct_file) {
				newsTagArray.push(
				<div key={'news-link-'+this.state.newsInfo[index].post_id} className='news-single-link' onClick={e => this.openPDF(e, this.state.newsInfo[index])}>
					<BriefView
						aws_object={this.aws} display_type={'news_archive_pdf'} object={this.state.newsInfo[index]}/>
				</div>);
			} else {
				newsTagArray.push(<Link className='news-single-link' key={index}
										to={`/news/${this.state.newsInfo[index].post_id}`}><BriefView
					aws_object={this.aws} display_type={'news_archive'} object={this.state.newsInfo[index]}/></Link>);
			}
		}

		return newsTagArray;
	}

	filterData=(value,index)=>{
		let filters = this.state.filters;
		filters[index]=value;
		this.setState({currentPage:0,filters}, ()=>this.getFilteredData());
	};

	setCarouselDataHandler=(carouselData)=>{
		this.setState({carouselData});
	};

	render() {
		let filterOptions = null;
		if (this.props.cats && this.props.tags) {
			filterOptions = {
				tags: this.genFilterOptions(this.props.tags, 'Tags')
			};
		}

		const resetFilterData = () => {
			this.setState({
				filteredMinTime: null,
				filters: [],
				currentPage:0
			}, () => {
				this.getFilteredData();
			});
		};


		return(
			<div className={'News'}>
				<ImageCarousel aws_object={this.aws} slideArray={this.state.carouselData} />
				<h2 className='page-title'>Latest News</h2>
				<Filters
					filterChangeCallback={this.filterData}
					filterValues={[
						this.state.filters['cats'],
						this.state.filters['tags']
					]}
					filters={[
						{
							filterName:'tags',
							filterOptions:filterOptions.tags
						}
					]}
					callbackHandler={this.filterData}
					resetCallbackHandler={resetFilterData}
					dateButtonList={[
						{
							'text':'New',
							'callback': () => {this.handleTimeFilter('all');},
						},
						{
							'text':'This Week',
							'callback': () => {this.handleTimeFilter('this_week');},
						},
						{
							'text':'This Month',
							'callback': () => {this.handleTimeFilter('this_month');},
						}
					]}
				/>
				{this.showPosts()}
				<>{this.state.isLoading &&<LoadingIndicator isLoading={this.state.isLoading}/>}</>
				
				<PageController pageNumber={this.state.currentPage} setPageNumber={(page)=>{
					this.setState({currentPage:page});
				}} numPages={this.state.numPages} />
			</div>
		);
	}
}

export default News;
