import BaseModel from './BaseModel';
import moment from 'moment';

class Form extends BaseModel {
	constructor(createdAt){
		super(createdAt);
		this.completion_subtitle=null; // string
		this.completion_title=null;
		this.title=null;
		this.created_at=null; // timestamp
		this.desc=null; //string
		this.form_id=null; // int
		this.modified_at=null; //timestamp
		this.questions=[]; //array of objects
		this.action=null;
		this.stringColorPair = null;
		this.prettyCreatedDate = null;
		this.faIconName = 'fas fa-chevron-right';
		this.faIconNameBackground = '';
		this.iconColor = 'blue';
		this.valid=true;
		this.tags=[];
		this.copy_form_taker=false;
	}

	setValues(response){
		this.generic_setter(this, 'title', response.title, this.type_string());
		this.generic_setter(this, 'valid', response.valid, this.type_bool());
		this.generic_setter(this, 'copy_form_taker', response.copy_form_taker, this.type_bool());
		this.generic_setter(this, 'completion_subtitle', response.completion_subtitle, this.type_string());
		this.generic_setter(this, 'completion_title', response.completion_title, this.type_string());
		this.generic_setter(this, 'desc', response.desc, this.type_string());
		this.generic_setter(this, 'form_id', response.form_id, this.type_number());
		this.generic_setter(this, 'modified_at', response.modified_at, this.type_number());
		this.generic_setter(this, 'created_at', response.created_at, this.type_number());
		this.generic_setter(this, 'questions', response.questions, this.type_array());
		this.generic_setter(this, 'email', response.email, this.type_array());
		this.generic_setter(this, 'tags', response.tags, this.type_array());
		this.setPrettyCreatedDate(this.getDate(response.created_at));
		this['editIcon'] = 'fas fa-edit';
		this['deleteIcon'] = 'fas fa-trash';
		this['cssClasses'] = {
			formatted_name: 'name title title-circle-thumbnail',
			editIcon: 'no-circle-icon circle-border',
			deleteIcon: 'no-circle-icon',
			status: 'status-cell current'
		};
	}
	getDate(timestamp) {
		return moment.unix(timestamp).format('D MMM YYYY');
	}

	setPrettyCreatedDate(created_at) {
		return this.generic_setter(this, 'prettyCreatedDate', created_at, this.type_string());
	}

	getAction() {
		if (typeof this.action === 'function') {
			return this.action();
		}
		return null;
	}

	setAction(action) {
		return this.generic_setter(this, 'action', action, this.type_function());
	}
}
export default Form;
