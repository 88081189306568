/*
 FormQuestions - a list of all questions inside a form. with user inputs


*/

import React from 'react';
import InputFieldBlock from '../../BasicInputs/InputFieldBlock';
import QuestionContentOwner from './QuestionContentOwner';
import { submitForm } from '../../../projlibs/form-networking';
import { toast } from 'react-toastify';
import { Dropdown } from '../../../shared-components/src/components/Shared/Dropdown';
import { QuestionFileUpload } from './QuestionFileUpload';
import config from '../../../config';
import HerdRAAWSUtils from '../../../projlibs/HerdRAAWSUtils';
import { getAWSKey } from '../../../projlibs/HelperFunctions';
import { QUESTION_TYPE_FILE_UPLOAD } from '../../../constants';
import { LoadingIndicator } from '../../LoadingIndicator';

/*
props (component-level arguments):
    
*/
class FormQuestions extends React.Component {
	constructor(props) {
		super(props);
		this.aws = new HerdRAAWSUtils();
		this.state = {
			inputs: [],
			questions: [],
			answer_inputs: {},
			filtered_questions: [],
			submitting: false
		};
	} 

	handleLabelInputChange = event => {
		event.preventDefault();
		let answer_inputs = this.state.answer_inputs;
		answer_inputs[event.currentTarget.id.toString()] = [event.currentTarget.value];
		this.setState({ answer_inputs });
	};

	handleFileInputChange = (id, file) => {
		let answer_inputs = this.state.answer_inputs;
		answer_inputs[id.toString()] = file;
		this.setState({ answer_inputs });
	};

	clickRow = (title,question_object,selected,dependent_question_options,dependent_question_option) => {
		let questions = this.state.questions;
		let answer_inputs = this.state.answer_inputs;
		let inputs = this.state.inputs;
		for (let item in questions) {
			if (questions[item] === question_object) {
				let question = questions[item];
				if (question.question_type === 'single_select' || question.question_type==='dropdown') {
					if(question.question_type!=='dropdown'){
						if(title && question.selected_answer === title){
							question.selected_answer=null;
						}else{
							if (selected) {
								question.selected_answer = title;
							}
						}
					}else{
						question.selected_answer = title;
					}
					if (dependent_question_options) {// this will show/hide dependent questions
						for(let i in questions){
							for(let j=0;j<dependent_question_options.length;j++){
								// grabs reference of the dependent question from the array we are using to set state
								if(dependent_question_options[j].form_question_id === questions[i].form_question_id && selected){
									questions[i].hideInput=true;
									answer_inputs[questions[i].form_question_id.toString()] = null;
									if(questions[i].question_type==="dropdown"){
										// the dependent is set here
										if(!this.props.viewingResults){
											if(dependent_question_option){
												answer_inputs[questions[i].form_question_id.toString()] = [dependent_question_option?.answer_options[0]];
											}
										}else{
											answer_inputs[questions[i].form_question_id.toString()] = dependent_question_option?.selected_answer[0];
										}
									}
									if(!this.props.viewingResults){ // if they are not viewing results then clear the input.
										let input_label = document.getElementById(questions[i].form_question_id);
										if(input_label){
											input_label.value=null;
										}
									}
								}
							}
						}
					}
					if(dependent_question_option){
						for(let i=0;i<questions.length;i++){
							// need to grab reference of the question
							if(questions[i].form_question_id === dependent_question_option.form_question_id){
								// if the owner of this dependent question is not selected then hide the dependent question
								questions[i].hideInput=!selected;
							}
						}
					}
					for (let item in inputs) {
						if (inputs[item].form_question_id === question.form_question_id) {
							inputs[item] = question;
							break;
                        }
					}
					if(!this.props.viewingResults&&!question.hideInput){
						answer_inputs[question.form_question_id.toString()] = [question.selected_answer];
					}
				} else if (question.question_type === 'multi_select') {
					if (question.selected_answers === undefined) {
						question.selected_answers = [];
					}
					if(selected){
						if(!question.selected_answers.includes(title)){
							question.selected_answers.push(title);
						}	
					}else{
						if(question.selected_answers.includes(title)){
							const index = question.selected_answers.indexOf(title);
							question.selected_answers.splice(index, 1);
						}
					}
					if (dependent_question_option) {
						const keys = Object.keys(dependent_question_option.depends_on);
						let option_name = dependent_question_option.depends_on[keys[0]];
						dependent_question_option.hideInput = !selected;
						if(!question.selected_answers.includes(option_name)){
							let input_label = document.getElementById(dependent_question_option.form_question_id);
							if(input_label && !this.props.viewingResults){
								input_label.value=null;
							}
							answer_inputs[dependent_question_option.form_question_id]=null;
						}
					}
					for (let item in inputs) {
						if (inputs[item].form_question_id === question.form_question_id) {
							inputs[item] = question;
							break;
						}
                    }
                    answer_inputs[question.form_question_id.toString()] = question.selected_answers;
                }
			}
		}
		this.setState({ questions, answer_inputs, inputs });
	};

	renderForm = () => {
		let forms = this.props.forms;
		let answer_inputs = this.state.answer_inputs;
		let questions = forms.questions;
		let filtered_questions = questions.filter(question => !question.depends_on);
		let inputs = [];
		for (let i in questions) {
			if(!questions[i].valid){
				continue;
			}
			
			let idString = questions[i].form_question_id.toString();
			let question = questions[i];
			if(question.question_type === 'dropdown'){
				let defaultInput='';
				
				if(this.props.viewingResults && answer_inputs[idString] && Array.isArray(answer_inputs[idString])){
					defaultInput=answer_inputs[idString][0];
				}
				let formatted_options = {};
				question.answer_options.forEach(element => {
					formatted_options[element]=element;
				});
				question.selected_answer=formatted_options[0];
				question.inputField=(
					<Dropdown
						key={idString}
						title={question.title}
						disabled={this.props.viewingResults}
						options={formatted_options}
						iconUniCode={true}
						titleClass={'question-select'}
						fieldClass={'question-select'}
						selectedOption={defaultInput}
						fieldName={idString}
						onChange={this.props.viewingResults?()=>{}:(e)=>{
							e.preventDefault();
							if(e.currentTarget.value){
								this.clickRow(e.currentTarget.value,question);
							}
					}}
						fieldHint={'Enter your response...'}
					/>
				);
				question.label_html=(
					<label className="question-title">
						{question.title}
					</label>
				);
				question.hideInput = false;
				question.selected_answer=question.answer_options[0];
				if(question.depends_on){
					question.hideInput = true;
				}
			}
			else if (question.answer_options) {
                question.label_html=(
                    <label className="question-title">
                        {question.title}
                    </label>
				);
				question.hideInput = false;
				if(question.depends_on){
					question.hideInput = true;
				}
				question.inputField=(
					<QuestionContentOwner
						results={answer_inputs[idString]}
						viewingResults={this.props.viewingResults}
						filtered_questions={filtered_questions}
						handleLabelInputChange={this.handleLabelInputChange}
						clickCallback={this.clickRow}
						questions={questions}
						question_owner={question}
						titleClass={'question-select'}
						fieldClass={'question-select'}
						title={question.answer_options}
						question_index={i}
						multi_select={question.question_type==='multi_select'}
					/>
				);
			} else{ // text, file
                if(question.depends_on){
                    question.hideInput = true;
				}
				let defaultInput='';
				
				if(this.props.viewingResults && answer_inputs[idString] && Array.isArray(answer_inputs[idString])){
					defaultInput=answer_inputs[idString][0];
				}
				if(question.question_type===QUESTION_TYPE_FILE_UPLOAD){
					question.inputField=(
						<QuestionFileUpload viewingResults={this.props.viewingResults} response={answer_inputs[idString]?answer_inputs[idString].join(''):''} fileLabel={question.title} idString={idString} handleFileInputChange={this.handleFileInputChange}/>
					);
				}else{
					question.inputField = (
						<InputFieldBlock
							key={idString}
							title={question.title}
							disabled={this.props.viewingResults}
							fieldRequired={true}
							fieldType="text"
							titleClass={'question-select'}
							fieldClass={'question-select'}
							fieldValue={defaultInput}
							fieldName={idString}
							callback={this.handleLabelInputChange}
							fieldHint={'Enter your response...'}
						/>
					);
				}
			}
			inputs.push(question);
		}
		this.setState({ questions: questions, inputs, filtered_questions }, ()=>{
			this.state.questions.forEach((element)=>{
					if(element.question_type==='dropdown'){
						const defaultAnswer=this.state.answer_inputs[element.form_question_id.toString()]
						?
							this.state.answer_inputs[element.form_question_id.toString()]
						:
							element.answer_options[0]
						this.clickRow(defaultAnswer,element,true);
					}
				}
			);
		});
	};

	componentDidMount() {
		if(this.props.viewingResults){
			let responses = this.props.responses;
			var answer_inputs={};
			for(let i=0; i<responses.length; i++){
				answer_inputs[responses[i].form_question_id]=responses[i].response_value;
			}
			this.setState({ answer_inputs:answer_inputs},()=>this.renderForm());
		}else{
			this.renderForm();
		}
	}

	handleFormSubmit = () => {
		let data = { responses: [] };
		let keys = Object.keys(this.state.answer_inputs); // these are form question ids
		let promises = [];
		for (let item in keys) {
            if(this.state.answer_inputs[keys[item]]){
				if(typeof this.state.answer_inputs[keys[item]].name === 'string'){
					let file=this.state.answer_inputs[keys[item]];
					promises.push(new Promise((resolve,reject)=>{
						this.aws.uploadFile(config.bucket_name, file.name, file, err=>reject(err), success => {
							const key=getAWSKey(success);
							data.responses.push({
								response_value: key,
								form_question_id: keys[item]
							});
							resolve(key);
						});
					}));
				}else{
					data.responses.push({
						response_value: this.state.answer_inputs[keys[item]],
						form_question_id: keys[item]
					});
				}
            }
		}
		this.setState({submitting:true},()=>{
			if(promises.length){
				Promise.all(promises).then(()=>{
					submitForm(this.props.forms.form_id,JSON.stringify(data),response => {
						toast.success('Your responses have succesfully been saved');
						this.props.closeForm();
						this.setState({submitting:false});
					},response => {
						toast.error('Error: ' + JSON.stringify(response));
						this.setState({submitting:false});
					});
				}).catch(err=>{
					toast.error('Error uploading file');
					this.setState({submitting:false});
					console.log(err);
				});
			}else{
				submitForm(this.props.forms.form_id,JSON.stringify(data),response => {
					toast.success('Your responses have succesfully been saved');
					this.setState({submitting:false});
					this.props.closeForm();
				},response => {
					toast.error('Error: ' + JSON.stringify(response));
					this.setState({submitting:false});
				});
			}
		});
	};
	
	render() {
		return (
			<div className={'FormQuestions'}>
				<span className='info-title'>
					{this.props.forms.title}
            	</span>
				{this.state.submitting?<LoadingIndicator isLoading={this.state.submitting}/>:
				<form>
					{this.state.inputs.map((item, idx) => {
						if (item && (item.inputField)) {
							if (item.hideInput) {
								return (
									<div key={'question-' + idx} className="hidden">
										{item.inputField}
									</div>
								);
							}
							return (
								<div key={'question-' + idx}>
									{item.label_html?item.label_html:null} {item.inputField}
								</div>
							);
						} else {
							return item;
						}
					})}
					{!this.props.viewingResults && 
						<div className="small-12 cell button-holder">
							<button type="button" className="button save-form" onClick={this.handleFormSubmit}>Submit</button>
						</div>
					}
				</form>
				}
			</div>
		);
	}
}

export default FormQuestions;
