/*
Filters - Filters is a controller to decide what you want to view for the parent page.

A Filter consists of a label, Select Menus and Buttons 
*/

import React from 'react';
import PropTypes from 'prop-types';
import ButtonRow from './ButtonRow';

/*
props (component-level arguments):
	filterChangeCallback: A onClick handler for the change event of the select menu's.
	resetCallbackHandler: A callback for the reseting of the filters
	dateButtonList: a list of button information objects, each of which consists of the following:
		text: the title of the button
		callback: the function to call when the button is clicked
	filters: the list of filter options; each option consists of the following:
		filterName: the name of the filter
		filterOptions: a list of selectable key->value options for this filter
*/
class Filters extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			filterValues:[],
			timeSelectIndex: 0,
		};
		this.handleChangeTimeSelect = this.handleChangeTimeSelect.bind(this);
		this.resetFilters = this.resetFilters.bind(this);
	}

	UNSAFE_componentWillReceiveProps(newProps){
		if(newProps.hasOwnProperty('filterValues')){
			for(let filterIdx=0;filterIdx<newProps.filterValues.length;filterIdx++){
				if(filterIdx<this.props.filterValues.length){
					let oldFilterValue=this.props.filterValues[filterIdx];
					let newFilterValue=newProps.filterValues[filterIdx];
					if(oldFilterValue!==newFilterValue){
						if(newProps.filterChangeCallback!==undefined && newProps.filterChangeCallback!==null){
							newProps.filterChangeCallback(newFilterValue,filterIdx);
						}
					}
				}
			}
			this.setState({filterValues:newProps.filterValues});
		}
	}

	handleChange=(event, index)=>{
		let filters = this.props.filters;
		this.props.filterChangeCallback(event.target.value,filters[index].filterName);
		let filterValues=this.state.filterValues;
		filterValues[index]=event.target.value;
		this.setState({filterValues});
	}

	handleChangeTimeSelect(index) {
		this.setState({
			timeSelectIndex: index,
		});
	}

	resetFilters(event) {
		event.preventDefault();
		let filterValues=this.state.filterValues;
		for(let item in filterValues){
			filterValues[item]='';
		}
		this.setState({filterValues, timeSelectIndex:0});
		this.props.resetCallbackHandler();
		return false;
	}

	renderFilters = ()=>{
		let filters=[];
		for(let i=0, length=this.props.filters.length;i<length;i++){
			if(this.props.filters[i].filterOptions===undefined){
				continue;
			}
			
			let filterValue=this.state.filterValues.length>i?this.state.filterValues[i]:'';
			if(this.props.hasOwnProperty('filterValues')&&this.props.filterValues.length>i){
				filterValue=this.props.filterValues[i];
			}
			filters.push(
				<div className='select-wrapper' key={`filter-${i}`}>
					<p className='filter-label'>{this.props.filters[i].title?this.props.filters[i].title:'Filter: '}</p>
					<select id={this.props.filters[i].filterName} value={filterValue} onChange={e=>this.handleChange(e,i)}>
						
						{this.props.filters[i].filterOptions.map((x, key) => {
							return(<option key={key} value={Object.keys(x)}>{Object.values(x)}</option>);
						})}
					</select>
				</div>
			);
		}
		return filters;
	}

	render() {
		return(
			<div className={'Filters'}>
				{(this.props.filters) && 
					this.renderFilters()
				}
				{this.props.dateButtonList && 
					<div className='button-wrapper'>
						<ButtonRow buttonList={this.props.dateButtonList} selectedButtonIndex={this.state.timeSelectIndex} changeSelectCallback={this.handleChangeTimeSelect} /> 
					</div>
				}
				<button className='reset-button' onClick={this.resetFilters}><i className="fas fa-undo icon"></i><span className='button-text'>Reset Filters</span></button>
			</div>
		);
	}
}


Filters.defaultProps={
	resetDateValue:'All',
};

Filters.propTypes = {
	filterChangeCallback: PropTypes.func.isRequired,
	filters: PropTypes.array.isRequired,
	dateButtonList: PropTypes.array,
	resetDateValue: PropTypes.string,
	resetCallbackHandler: PropTypes.func,
	filterValues: PropTypes.array,
};

export default Filters;
