import BaseModel from './BaseModel.js';

class Location extends BaseModel {
	constructor(created_at) {
		super(created_at);
		
		//NOTE: the validity field for this class is different from the validity field for the base model
		//because the server names these validity fields inconsistently in its data model
		delete this.valid;
		this.is_valid=true;
		
		//NOTE: these internal variables must be in snake case and not lower camel case
		//because they are used as the serialization fields that get sent to the server
		
		this.name='';
		this.address='';
		this.city='';
		this.state_prov='';
		this.postal_code='';
		this.contact_phone='';
		this.contact_email='';
		this.contact_name='';
	}
	
	set_name(name) {
		return this.generic_setter(this,'name',name,this.type_string());
	}
	get_name() {
		return this.name;
	}
}
export default Location;

