/*
DateTile - A DateTile is a Day and Month in side a tile square.

A DateTile consists of no children components
*/

import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
/*
props (component-level arguments):
	Date: The date you want to display.
*/
import { checkSimiliarDay, checkSimiliarMonth } from '../projlibs/HelperFunctions';
class DateTile extends React.Component {

	showDate() {
		if(this.props.date) {
			const dateMoment = moment.unix(this.props.date);
			const dateMomentEnd = moment.unix(this.props.endDate);
			let startMonth=dateMoment.format('MMM');
			let endMonth=dateMomentEnd.format('MMM');
			let startDay=dateMoment.format('DD');
			let endDay=dateMomentEnd.format('DD');
			endMonth=checkSimiliarMonth(startMonth, endMonth);
			endDay=checkSimiliarDay(startDay, endDay, startMonth, endMonth);
			return (<time className='slide-date' dateTime={dateMoment.format('YYYY-MM-DD')}>
				<div className='month'>{startMonth}{endMonth!==''?'-'+endMonth:null}</div>
				<div className='day'>{startDay}{endDay!==''?'-'+endDay:null}</div>
			</time>);
		}
	}

	render() {
		return(
			<div className={`DateTile `}>
				{this.showDate()}
			</div>
		);
	}
}

DateTile.propTypes = {
	date: PropTypes.number.isRequired,
	endDate: PropTypes.number.isRequired,
};

export default DateTile;
