/*

    UserFormResults - a table view of all surveys the user has completed 

*/

import React from 'react';
import FlexTable from '../../../FlexTable';
import FormQuestions from '../../../Pages/FormTaker/FormQuestions';
/*

props (component-level arguments):
    userFormResults: a list of all forms a user has taken and their most recent responses
    
*/
const FORM_RESULT_HEADERS = [
	{ title: 'Form title', field: 'name' },
	{ title: 'Date Submitted', field: 'entryModifiedAt' },
	{ title: '', field: 'editIcon', onClick: 'editAction', sortable: false },
];
class UserFormResults extends React.Component {
 
    renderSingleForm=()=>{
        return(
            <>
                <div className="go-back-wrapper">
                    <span onClick={this.props.goBackCallback} className="go-back"><i className='fas fa-arrow-left'></i>Back To Forms</span>
                </div>
                <FormQuestions viewingResults={true} forms={this.props.selectedForm.form} responses={this.props.selectedForm.responses}/>
            </>
        );
    }

    renderFormTable=()=>{
        return(
            <div className={'UserFormResults'}>
                <FlexTable printable={true} tableData={{ headerData: FORM_RESULT_HEADERS, rows: this.props.userFormResults }} />
            </div>
        );
    }

    render() {
        if(this.props.selectedForm){
            return this.renderSingleForm();
        }else{
            return this.renderFormTable();
        }
    }
}

export default UserFormResults;