/*
TagLabel - a single label for a tag

A tag label is a base level component and contains no subcomponents
*/


import React from 'react';
import PropTypes from 'prop-types';

/*
props (component-level arguments):
	tag: the tag to display
*/
class TagLabel extends React.Component {
	render() {
		return (
			<div className='TagLabel'>
				<p className='taglabel-text'>
					{this.props.tag}
				</p>
			</div>
		);
	}
}

TagLabel.defaultProps={
	tag: '',
};

TagLabel.propTypes = {
	tag: PropTypes.string.isRequired,
};

export default TagLabel;

