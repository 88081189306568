
import { xml_rqst_info, xml_send_info } from '../react-utils/src/libajax';
import { getHeaders } from './HelperNetworkCalls';
import { FORM_URL, USER_ID } from '../constants';
import { parse_cookies } from '../react-utils/src/libformat';
import moment from 'moment';

export function getForms(successCallback, errorCallback, tag='', orderBy = ''){
    let url = FORM_URL;
    if(tag){
        url+= '?tag='+tag;
    }
    if(orderBy){
        url+= tag ? "&" : "?";
        url+= orderBy;
    }
    xml_rqst_info(
        url,xhr => {
            successCallback(xhr.response);
        },
        'GET',{},getHeaders(),error => {
            errorCallback(error);
        }
    );
}
export function submitForm(form_id, data, successCallback, errorCallback){
    let cookies=parse_cookies();
    xml_send_info(`${FORM_URL}/${form_id}/user/${cookies[USER_ID]}`,data, xhr=>{
        successCallback(xhr.response);
    },'POST',getHeaders(),error=>{
        errorCallback(error.response);
    });
}

export function getUserFormResults(user_id, successCallback, errorCallback, clickResultEvent, format_response=false){
    if(!user_id){
        return null;
    }
    xml_rqst_info(`/user/${user_id}/form`,xhr=>{
        if(format_response){
            formatUserFormResults(xhr.response,successCallback, clickResultEvent);
        }else{
            successCallback(xhr.response);
        }
    },'GET',{},getHeaders(),error=>{
        errorCallback(error);
    });
}

function formatUserFormResults(response,successCallback, clickResultEvent){
    // format response to fit inside flextable and send back up with successCallback
    let form_entries = JSON.parse(response)['Form_Entry'];
    let forms = [];
    for(let i=0; i<form_entries.length; i++){
        forms.push(
            {
                name: form_entries[i].Form.title,
                form: form_entries[i].Form,
                responses: form_entries[i].responses,
                entryModifiedAt: moment.unix(form_entries[i].modified_at).format('MMM. DD, YYYY'),
                editIcon: 'fas fa-angle-right',
                'editAction':() => {clickResultEvent(forms[i]);},
                cssClasses:{
                    editIcon: 'circle-border action-button secondary-color-icon'
                }
            }
        );
    }
    successCallback(forms);
}
