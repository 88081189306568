/*
DateView - an abbreviated display showing a calendar date (with no year)

A date view is a base-level component, and contains no subcomponents
*/


import React from 'react';
import PropTypes from 'prop-types';
import { checkSimiliarMonth, checkSimiliarDay } from '../projlibs/HelperFunctions';

/*
props (component-level arguments):
	date: the date to display, as a javascript Date object
*/
class DateView extends React.Component {
	render() {
		let endMonth='';
		let startMonth=this.props.date.toLocaleString('en-us', {month:'short'});
		let startDay=this.props.date.getDate();
		let endDay='';

		if(this.props.endDate){
			endMonth=this.props.endDate.toLocaleString('en-us', {month:'short'});
			endMonth=checkSimiliarMonth(startMonth, endMonth);
			endDay=checkSimiliarDay(startDay, endDay, startMonth, endMonth);
		}
		return (
			<div className='DateView'>
				<p className='dateview-month'>
					{startMonth}{endMonth!==''?'-'+endMonth:''}
				</p>
				<p className='dateview-day'>
					{startDay}{endDay!==''?'-'+endDay:''}
				</p>
			</div>
		);
	}
}

DateView.defaultProps={
	date: new Date(),
};

DateView.propTypes = {
	date: PropTypes.object.isRequired,
};

export default DateView;

