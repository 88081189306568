/*

    TabSelectView - switch between tabs and show different content.

*/

import React from 'react';

/*
props (component-level arguments):
    selected: the name of the selected tab
    tabs: an array of tab objects that have: name, clickCallback
*/
class TabSelectView extends React.Component {

    render() {
        return(
            <div className={'TabSelectView'}>
                {this.props.tabs.map((item,index)=>{
                    let selected=this.props.selected===item.name;
                    return (
                        <div key={`tab-${index}`} onClick={item.clickCallback?item.clickCallback:null} className={'tab-header'+(selected?' selected':'')}>
                            {item.name}
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default TabSelectView;