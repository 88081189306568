/*
 SelectFieldButton - the button that allows for question types to be selected
*/
import React from 'react';

/*
state (component-level variables):
	dropDownOpen: if true then display the custom dropdown menu showing all current label_types
props (component-level arguments):
	addLabelHandler: callback function that adds a FieldQuestionLabel component to the screen
	classIdentifier: if there are labels already then this is a class identifier to direct position to bottom left instead of mid center
*/
class SelectFieldButton extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dropDownOpen: false
		};
		this.addLabel = this.addLabel.bind(this);
	}

	openFieldTypeDropdown = () => {
		this.setState({ dropDownOpen: !this.state.dropDownOpen });
	};

	addLabel(event) {
		this.props.addLabelHandler(event.currentTarget.id);
		this.setState({ dropDownOpen: false });
	}

	renderFieldTypeDropDown = () => {
		return (
			<div className={`add-field-type-dropdown ${this.props.classIdentifier}`}>
				<div className="row-border">
					<div className="select-field-type-row">select question type</div>
				</div>
				<div className="row-border">
					<div id="open_ended" className="field-type-row" onClick={this.addLabel}>
						Text
					</div>
				</div>
				<div className="row-border">
					<div id="dropdown" className="field-type-row" onClick={this.addLabel}>
						dropdown
					</div>
				</div>
				<div className="row-border">
					<div id="single_select" className="field-type-row" onClick={this.addLabel}>
						single-select
					</div>
				</div>
				<div className="row-border">
					<div id="multi_select" className="field-type-row" onClick={this.addLabel}>
						multi-select
					</div>
				</div>
				<div className="row-border">
					<div id="file_upload" className="field-type-row" onClick={this.addLabel}>
						file-upload
					</div>
				</div>
			</div>
		);
	};

	render() {
		return (
			<div className={`SelectFieldButton ${this.props.classIdentifier}`}>
				<button type="button" className={`button hollow add-form-field ${this.props.classIdentifier}`} onClick={this.openFieldTypeDropdown}>
					<span className="fa fa-plus">
					add question
					</span>
				</button>
				{this.state.dropDownOpen && this.renderFieldTypeDropDown()}
			</div>
		);
	}
}

export default SelectFieldButton;
