import React from 'react';

class FilteredComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			filteredCat:'',
			filteredTag:''
		};
	}

	genFilterOptions(filterArray, filterType){
		if(filterArray) {
			return [{'':`All ${filterType}`}, ...filterArray.map(x => {return {[x]: [x]};})];
		}
	}
	
	//set filtered data for the given state variables
	//e.g. setFilteredData('newsInfoFiltered','newsInfo') -> setFilteredData() for News
	//e.g. setFilteredData('eventInfoFiltered','eventInfo') -> setFilteredData() for Events
	setFilteredData(filteredStateVariable,fullStateVariable) {
		let fullInfo=this.state[fullStateVariable];
		let newStateDelta={};
		
		if(this.state.filteredCat !== '' && this.state.filteredTag !== '') {
			newStateDelta[filteredStateVariable]=fullInfo.filter(row => {
				return (row.category.includes(this.state.filteredCat) && row.tags.includes(this.state.filteredTag));
			});
		} else if(this.state.filteredCat === '' && this.state.filteredTag !== '') {
			newStateDelta[filteredStateVariable]=fullInfo.filter(row => {
				return row.tags.includes(this.state.filteredTag);
			});
		} else if(this.state.filteredCat !== '' && this.state.filteredTag === '') {
			newStateDelta[filteredStateVariable]=fullInfo.filter(row => {
				return row.category.includes(this.state.filteredCat);
			});
		} else {
			newStateDelta[filteredStateVariable]=fullInfo;
		}
		
		this.setState(newStateDelta);
	}
};

export default FilteredComponent;

