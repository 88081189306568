import React from 'react';
import styled from 'styled-components';
import { InputFieldBlock } from '../../../../BasicInputs/InputFieldBlock';
import { InputButtonWrapper } from '../NewFormView';
import IconButton from '../../../../IconButton';

export const FormEmails = ({
	emails,
	setEmails,
	copyFormTaker,
	setCopyFormTaker,
}) => (
	<div className="email-container">
		<div className="grid-x">
			<div className="cell small-6">
				<InputWrapper>
					{emails.map((item, index) => {
						return (
							<InputButtonWrapper key={`email-${index}`}>
								<InputFieldBlock
									inputBlockClass="email"
									title="Email results will get sent to"
									fieldName="email"
									fieldType="email"
									callback={(e) => {
										const val = e.currentTarget.value;
										setEmails((prev) => {
											prev[index] = val;
											return [...prev];
										});
									}}
									fieldValue={emails[index]}
								/>
								{index > 0 && (
									<IconButton
										buttonColorType="color-blue"
										foregroundIcon="fas fa-trash"
										backgroundIcon="fa fa-square color-light-gray3"
										extraClasses="icon-button-click"
										callback={(e) =>
											setEmails((prev) =>
												prev.filter(
													(item, idx) => idx !== index
												)
											)
										}
									/>
								)}
							</InputButtonWrapper>
						);
					})}

					<button
						className="button save-form form-email"
						type="button"
						onClick={() => setEmails((prev) => [...prev, ''])}
					>
						Add Email
					</button>
				</InputWrapper>
			</div>
			<div className="cell small-6">
				<div className="save-email-button-container">
					<div className="form-checkbox-container">
						<input
							type="checkbox"
							name="target"
							id="input-target"
							checked={copyFormTaker}
							onChange={() => setCopyFormTaker(!copyFormTaker)}
						/>
						<label htmlFor="input-target">
							CC Form Taker On Submission
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>
);
const InputWrapper = styled.div`
	button:last-child {
		margin-top: 1rem;
	}
`;
