/*
BarStylePageMenu - 


*/

import React from 'react';

// eslint-disable-next-line
import {BrowserRouter as Route, Link} from 'react-router-dom'; // Even tho Route is not actually used a tag, it is needed for the Link to function.
import PropTypes from 'prop-types';

/*
props (component-level arguments):
	pages: A list of links to pages that you want this menu to be able to navigate to.
	active: Whether this link is the currently active page or not.
*/
class BarStylePageMenu extends React.Component {

	renderLinkPills() {
		return (
			this.props.pages.map((link, key) => (<Link key={key} className={`link-pill ${link.link===this.props.active ? 'is-active': ''} `} to={ {pathname: `/company-directory/${link.link}`, department: this.props.department} }>{`${link.title}`}</Link>)));
	}

	render() {
		return(
			<div className={'BarStylePageMenu'}>
				{this.renderLinkPills()}
			</div>
		);
	}
}

BarStylePageMenu.propTypes = {
	pages: PropTypes.array.isRequired,
	active: PropTypes.string
};

BarStylePageMenu.defaultProps = {
	active: 'false'
};

export default BarStylePageMenu;
