/*
Events - An event page shows Event Information
*/

import React from 'react';
import {Link} from 'react-router-dom';

import { xml_fetch_info } from '../../react-utils/src/libajax.js';
import { parse_cookies } from '../../react-utils/src/libformat';
import {ERROR_RETRIEVING_EVENTS, TRY_AGAIN_LATER, SESSION_ID, SECONDS_PER_MINUTE, MINUTES_PER_HOUR, HOURS_PER_DAY, DAYS_PER_WEEK } from '../../constants';
import {processError} from '../../projlibs/cookie-management';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ImageCarousel from '../ImageCarousel/ImageCarousel';
import BriefView from '../BriefView/BriefView';
import Filters from '../Filters.jsx';
import FilteredComponent from '../FilteredComponent.jsx';
import config from '../../config';
import { setCarouselData } from '../../projlibs/HelperNetworkCalls.js';
import {getFirstDayOfWeek, getFirstDayOfMonth, getFirstDayOfNextMonth} from '../../projlibs/DateCalculations.js';
import { openAlreadySignedURLFile } from '../../lib/libdownloadFiles';
import HerdRAAWSUtils from '../../projlibs/HerdRAAWSUtils';
import { PageController } from '../PageController';
import { LoadingIndicator } from '../LoadingIndicator';

export function getStartofDayTimeStamp(){
	let min_time=(new Date());
	min_time.setHours(0,0,0,0);
	return Math.floor(min_time.getTime()/1000);
}
class Events extends FilteredComponent {
	constructor(props) {
		super(props);
		this.state = {
			filters: [],
			eventsInfo: [],
			eventsInfoUnFiltered:[],
			filteredMinTime:  getStartofDayTimeStamp(),
			filteredMaxTime: null,
			carouselData:[],
			numPages:1,
			currentPage: 0,
			isLoading:true
		};
		this.aws = new HerdRAAWSUtils();
		this.handleTimeFilter=this.handleTimeFilter.bind(this);
		this.getFilteredData=this.getFilteredData.bind(this);
		this.netCallSuccess=this.netCallSuccess.bind(this);
		this.netCallFailure=this.netCallFailure.bind(this);
	}

	handleTimeFilter(filter_type){
		//calculate the time cutoff of when we want to get information from
		let now=new Date();
		const now_ts=Math.floor(now.getTime()/1000.0);
		let min_ts=getStartofDayTimeStamp();
		let max_ts=null;
		switch(filter_type){
			case 'this_week':
				min_ts=getFirstDayOfWeek();
				max_ts=min_ts+(SECONDS_PER_MINUTE*MINUTES_PER_HOUR*HOURS_PER_DAY*DAYS_PER_WEEK);
				break;
			case 'today':
				min_ts=now_ts-(SECONDS_PER_MINUTE*MINUTES_PER_HOUR*HOURS_PER_DAY);
				max_ts=now_ts+(SECONDS_PER_MINUTE*MINUTES_PER_HOUR*HOURS_PER_DAY);
				break;
			case 'this_month':
				min_ts=getFirstDayOfMonth();
				max_ts=getFirstDayOfNextMonth();
				break;
			case 'next_week':
				min_ts=getFirstDayOfWeek()+(SECONDS_PER_MINUTE*MINUTES_PER_HOUR*HOURS_PER_DAY*DAYS_PER_WEEK);
				max_ts=min_ts+(SECONDS_PER_MINUTE*MINUTES_PER_HOUR*HOURS_PER_DAY*DAYS_PER_WEEK);
				break;
			default:
				//NOTE: prior to this switch statement the min_ts and max_ts parameters are initialized to the "all" case
				break;
		}
		
		this.setState({
			filteredMinTime:min_ts,
			filteredMaxTime:max_ts,
			currentPage:0,
		},() => this.getFilteredData());
	}

	getFilteredData() {
		const min_ts=this.state.filteredMinTime;
		const max_ts=this.state.filteredMaxTime;
		const tag=this.state.filters.tags;
		const cat=this.state.filters.cats;
		
		//make a network request to get the time-filtered and category-filtered and tag-filtered data
		const cookies = parse_cookies();
		let url_path='/search/?table=Post&type=event';
		if(min_ts!==null){
			url_path+='&min_time='+min_ts;
		}
		if(max_ts!==null){
			url_path+='&max_time='+max_ts;
		}
		if(cat!==''&&cat!==undefined){
			url_path+='&category='+cat;
		}
		if(tag!==''&&tag!==undefined){
			url_path+='&tag={'+tag+'}';
		}
		url_path+=`&page=${this.state.currentPage}&page_size=10&order_by=start&order_dir=asc`;
		this.setState({isLoading:true},()=>{
			xml_fetch_info(url_path,
				this.netCallSuccess,
				{
					'Content-Type': 'application/json',
					Authorization: cookies[SESSION_ID]
				},
				this.netCallFailure
			);
		});
	}

	netCallSuccess(xhr){
		this.setState({isLoading:false});
		const response = JSON.parse(xhr.response);
		let eventsInfo=[];
		if(response.hasOwnProperty('Event')){
			const eventSorted = response.Event;
			for(let idx=0;idx<eventSorted.length;idx++){
				let eventsItem=eventSorted[idx];
				if(eventsItem.is_valid===false || eventsItem.start===undefined){
					continue;
				}
				//NOTE: we don't want to filter based on start or end time here
				//because the user might want to view events which are not available for the current time
				//but will become available in the future
				if(eventsItem.valid===false){
					continue;
				}
				if(eventsItem.category===null){
					eventsItem.category='';
				}
				if(eventsItem.tags===null){
					eventsItem.tags=[];
				}
				if(eventsItem.direct_file){
					eventsItem.getSignedUrlError=function(error){
						console.log(error);
					};
					eventsItem.getSignedUrlSuccess=function(url){
						eventsItem.signed_PDF_link=url;
					};
					this.aws.getSignedUrl(config.bucket_name,eventsItem.img_s3_path,eventsItem.getSignedUrlError,eventsItem.getSignedUrlSuccess);
				}
				eventsInfo.push(eventsItem);
			}
			if(this.state.eventsInfoUnFiltered.length===0){
				this.setState({numPages: response.page_count, eventsInfo:eventsInfo,eventsInfoUnFiltered:eventsInfo},()=>this.formatCarouselData(eventsInfo));
			}else{
				this.setState({numPages: response.page_count, eventsInfo:eventsInfo},()=>this.formatCarouselData(eventsInfo));
			}
		}else{
			//if no results were found then there's nothing to display
			this.setState({numPages: response.page_count, eventsInfo:[]});
		}
	}

	formatCarouselData=(eventsInfo)=>{
		if(this.state.eventsInfoUnFiltered.length>0){
			setCarouselData(this.state.eventsInfoUnFiltered, this.setCarouselDataHandler);
		}else{
			setCarouselData(eventsInfo, this.setCarouselDataHandler);
		}
	}

	setCarouselDataHandler=(carouselData)=>{
		this.setState({carouselData});
	}

	netCallFailure(error){
		this.setState({isLoading:false});
		if(processError(error)){
			return false;
		}else{
			toast.error(ERROR_RETRIEVING_EVENTS + ' ' + TRY_AGAIN_LATER);
		}
	}

	componentDidUpdate(prevProps, prevState){
		if(prevState.currentPage !== this.state.currentPage){
			this.getFilteredData();
		}
	}
	componentDidMount() {
		this.getFilteredData();
	}

	openPDF=(e,eventItem)=>{
		e.preventDefault();
		openAlreadySignedURLFile(eventItem.signed_PDF_link,eventItem.img_s3_path, eventItem.title);
	}

	showPosts() {
		const eventsTagArray = [];
		for(let index = 0; index < this.state.eventsInfo.length; index++) {
			if(this.state.eventsInfo[index].direct_file){
				eventsTagArray.push(<div key={'event-link-'+this.state.eventsInfo[index].post_id} className='event-single-link' onClick={e=>this.openPDF(e,this.state.eventsInfo[index],)}><BriefView aws_object={this.aws} display_type={'events_archive_pdf'} object={this.state.eventsInfo[index]} /> </div>);
			}else{
				eventsTagArray.push(<Link className='event-single-link' key={'event-link-'+this.state.eventsInfo[index].post_id} to={`/events/${this.state.eventsInfo[index].event_id}`}><BriefView aws_object={this.aws} display_type={'events_archive'} object={this.state.eventsInfo[index]} /></Link>);
			}
		}

		if(eventsTagArray.length>0) {
			return eventsTagArray;
		} else {
			return 'There are no Events that match these filters.';
		}
	}

	filterData=(value,index)=>{
		let filters = this.state.filters;
		filters[index]=value;
		this.setState({currentPage:0,filters}, ()=>this.getFilteredData());
	}

	render() {
		let filterOptions=null;
		if(this.props.cats && this.props.tags){
			filterOptions={cats: this.genFilterOptions(this.props.cats, 'Categories'), tags: this.genFilterOptions(this.props.tags, 'Tags')};
		}

		const resetFilterData = () => {
			this.setState({
				filteredCat: '',
				filteredTag: '',
				filteredMinTime: getStartofDayTimeStamp(),
				filteredMaxTime: null,
				currentPage:0
			},()=>{
				this.getFilteredData();
			});
		};
		return(
			<div className={'Events'}>
				<ImageCarousel aws_object={this.aws} slideArray={this.state.carouselData} />
				<h2 className='page-title'>Latest Events & Happenings</h2>
				<Filters
					filterChangeCallback={this.filterData}
					filters={[
						{
							filterName:'tags',
							filterOptions:filterOptions.tags
						}
					]}
					resetCallbackHandler={resetFilterData}
					dateButtonList={[
						{
							'text':'All',
							'callback': () => {this.handleTimeFilter('all');}
						},
						{
							'text':'Today',
							'callback': () => {this.handleTimeFilter('today');}
						},
						{
							'text':'This Week',
							'callback': () => {this.handleTimeFilter('this_week');}
						},
						{
							'text':'Next Week',
							'callback': () => {this.handleTimeFilter('next_week');}
						},
						{
							'text':'This Month',
							'callback': () => {this.handleTimeFilter('this_month');}
						}
					]}
				/>
				{this.showPosts()}
				<>{this.state.isLoading &&<LoadingIndicator isLoading={this.state.isLoading}/>}</>
				<PageController pageNumber={this.state.currentPage} setPageNumber={(page)=>{
					this.setState({currentPage:page});
				}} numPages={this.state.numPages} />
			</div>
		);
	}
}

export default Events;
