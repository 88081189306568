import { parse_cookies } from '../react-utils/src/libformat';
import { xml_fetch_info } from '../react-utils/src/libajax';
import { SESSION_ID } from '../constants';
import moment from 'moment';
export function getHeaders(content_type='application/json'){
	let cookies=parse_cookies();
	return {'Content-Type': content_type, Authorization: cookies[SESSION_ID]};
}

export function getTags(successCallback, failCallback, type) {
	let url = '/tags';
	if (type) {
		url += `?type=${type}`;
	}
	xml_fetch_info(
		url,
		(xhr) => {
			let response = JSON.parse(xhr.response).Tag;
			response = response.filter((tag) => tag !== '');
			successCallback(response);
		},
		getHeaders(),
		(error) => {
			failCallback(JSON.parse(error.response));
		}
	);
}
export function getCategories(successCallback,failCallback, cat_type=null){
	let url = '/categories/';
	if (cat_type){
		url+='?type='+cat_type;
	}
	xml_fetch_info(url,xhr=>{
		let response=JSON.parse(xhr.response).Category;
		response=response.filter(category=>category!=='');
		successCallback(response);
	},getHeaders(),error=>{
		failCallback(JSON.parse(error.response));
	});
}
export function getIndustries(successCallback,failCallback){
	let url ='/directory-filters';
	xml_fetch_info(url,xhr=>{
		let response=JSON.parse(xhr.response).Industry;
		let formattedIndustries = [];
		for(let i=0;i<response.length;i++){
			formattedIndustries.push({
				[response[i]]: response[i]
			});
		}
		successCallback(formattedIndustries);
	},getHeaders(),error=>{
		failCallback(JSON.parse(error.response));
	});
}

export function getDepartments(successCallback,failCallback){
	xml_fetch_info('/departments/',xhr=>{
		let response=JSON.parse(xhr.response);
		successCallback(response.Department);
	},getHeaders(),error=>{
		failCallback(JSON.parse(error.response));
	});
}
export function getLandingPages(successCallback,failCallback){
	xml_fetch_info('/landing_pages/',xhr=>{
		let response=JSON.parse(xhr.response);
		successCallback(response.landing_pages);
	},getHeaders(),error=>{
		failCallback(JSON.parse(error.response));
	});
}
export function getLocation(successCallback,failCallback, store_in_dictionary=false){
	xml_fetch_info('/directory/?type=location',xhr=>{
		let response=JSON.parse(xhr.response);
		let formattedData=null;
		let locations = response.Location.Company[0].locations;
		if(store_in_dictionary){
			formattedData = formatLocationInDictionary(locations);
		}else{
			formattedData = formatLocationInArray(locations);
		}
		successCallback(formattedData);
	},getHeaders(),error=>{
		failCallback(JSON.parse(error.response));
	});
}
function formatLocationInDictionary(locations){
	let formattedData={};
	for(let i=0;i<locations.length;i++){
		if(locations[i].is_valid){
			formattedData[locations[i].location_id] = locations[i].name;
		}
	}
	return formattedData;
}
function formatLocationInArray(locations){
	let formattedData=[];
	for(let i=0;i<locations.length;i++){
		if(locations[i].is_valid){
			formattedData.push({
				[locations[i].location_id] : locations[i].name
			});
		}
	}
	return formattedData;
}

export function checkkIfPdf(key){
	if(key){
		let split = key.split('.');
		if(split.length>0){
			let lastValue=split[split.length-1];
			if(typeof lastValue === 'string' || lastValue instanceof String){
				if(lastValue.toLowerCase()==='pdf'){
					return true;
				}
			}
		}
	}
	return false;
}

export function setCarouselData(carouselData,successCallback){
	if (carouselData) {
		var featuredData = [];
		let numFeatured=0;
		for(let i=0;i<carouselData.length;i++){
			if(carouselData[i].is_featured && (carouselData[i].is_valid||carouselData[i].valid)){
				numFeatured+=1;
			}
		}
		let acceptUnfeatured = numFeatured===0;
		let numUnfeaturedAccepted=0;
		for (let i = 0; i < carouselData.length; i++) {
			if (((carouselData[i].is_featured === true || acceptUnfeatured) && carouselData[i].is_valid)) {
				let imageUrl=carouselData[i].img_s3_path?carouselData[i].img_s3_path:'';
				
				if(checkkIfPdf(carouselData[i].img_s3_path)){
					if(carouselData[i].file_s3_path){
						
						imageUrl=carouselData[i].file_s3_path[0];
					}
				}
				let tmp = {
					title: carouselData[i].title,
					imageUrl: imageUrl,
					published_at: carouselData[i].start
						? carouselData[i].start
						: carouselData[i].published_at,
					slideLink:
						carouselData[i].type === 'post'
							? carouselData[i].post_id
							: carouselData[i].event_id,
					postType:
						carouselData[i].type === 'post' ? 'news' : 'events',
				};
				tmp.metaData=[{icon: 'far fa-calendar-alt', data: moment.unix(tmp.published_at).format('MMM. DD, YYYY')}];
				if(typeof carouselData[i].start !== 'undefined'){
					tmp.is_event = true;
				}
				featuredData.push(tmp);
				if(acceptUnfeatured){
					numUnfeaturedAccepted+=1;
					if(numUnfeaturedAccepted>=3){
						return successCallback(featuredData);
					}
				}
			}
		}
		return successCallback(featuredData);
	}
}

export function getEvents(successCallback,errorCallback, queryParams=null){
	let url='/events?limit=1000';
	if(queryParams){
		// params only work with the seach endpoint so we must over write url
		url=`/search/?table=Post&type=event&${queryParams}`;
	}
	xml_fetch_info(url,xhr=>{
		let response = JSON.parse(xhr.response);
		let eventsInfo=[];
		if(response.hasOwnProperty('Event')){
			for(let idx=0;idx<response.Event.length;idx++){
				let eventsItem=response.Event[idx];
				if(eventsItem.hasOwnProperty('is_valid') && eventsItem.is_valid===false){
					continue;
				}
				if(eventsItem.hasOwnProperty('valid')&&eventsItem.valid===false){
					continue;
				}
				if(eventsItem.category===null){
					eventsItem.category='';
				}
				if(eventsItem.tags===null){
					eventsItem.tags=[];
				}
				eventsInfo.push(eventsItem);
			}
			successCallback(eventsInfo);
		}else{
			//if no results were found then there's nothing to display
			successCallback([]);
		}
	},getHeaders(),error=>{
		errorCallback(JSON.parse(error.response));}
	);
}
