/*
InputDropdown, a dropdown for an array of options

An InputDropdown item is a base level component and has no subcomponents
*/

import React from 'react';
import PropTypes from 'prop-types';

/*
props (component level arguments):
	dropdownClass: the class select tag
	dropdownId: the id of the select tag
	dropdownRequired: boolean value for 'required' attribute in select tag
	optionData: data for options in an array
state
	value: the value of the selected row
*/

class InputDropdown extends React.Component {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event) {
		if(this.props.callback) {
			this.props.callback(event);
		}
	}

	static populateOptions(data,defaultText) {
		let array = [<option key={0} value=''>{defaultText ? defaultText:"- Select -"}</option>];
		for (let key in data) {
			if (data.hasOwnProperty(key)) {

				array.push(<option key={key+1} value={Object.keys(Object.values(data)[key])}>{Object.values(Object.values(data)[key])}</option>);
			}
		}
		return array;
	}

	render() {
		return (
			<select className={'InputDropdown ' + this.props.dropdownClass} id={this.props.dropdownId}
					required={this.props.dropdownRequired} value={this.props.value} onChange={this.handleChange} disabled={this.props.disabled}>
				{InputDropdown.populateOptions(this.props.optionData,this.props.defaultSelectText)}
			</select>
		);
	}
}

InputDropdown.defaultProps = {
	dropdownClass: '',
	dropdownId: '',
	value: ''
};

InputDropdown.propTypes = {
	value: PropTypes.string,
	dropdownClass: PropTypes.string,
	dropdownId: PropTypes.string,
	dropdownRequired: PropTypes.bool,
	optionData: PropTypes.array.isRequired,
	callback: PropTypes.func,
};

export default InputDropdown;
