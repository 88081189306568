import React from 'react';


export class SmallUploadPreview extends React.Component{
    
    onClick=()=>{
        this.props.onClickCallback(this.props.file);
    }
    render(){
        return(
            <div onClick={this.onClick} className="MiniUploadPreview">
                <img className="preview-image" src={this.props.image} alt={'preview'} />
            </div>
        );
    }
}
SmallUploadPreview.defaultProps={
    image:''
};