/*
	AdminQuickLinkManager - A Quick Link Manager allows an administrator to view, modify, and create Quick Links
*/

import React from 'react';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { get_form_data } from '../../../projlibs/libform.jsx';
import { xml_fetch_info, xml_send_info } from '../../../react-utils/src/libajax.js';
import { parse_cookies } from '../../../react-utils/src/libformat.js';

import { SESSION_ID, ERROR_RETRIEVING_POSTS, ERROR_QUICK_LINK_NOT_SAVED, TRY_AGAIN_LATER, SUCCESS_QUICK_LINK_SAVED, SUCCESS_QUICK_LINK_DELETED} from '../../../constants.js';

import FlexTable from '../../FlexTable.jsx';
import FlyoutMenu from '../../FlyoutMenu.jsx';
import FilteredComponent from '../../FilteredComponent.jsx';

import QuickLink from '../../../models/QuickLink.js';
import { sortLinks } from '../../Pages/QuickLink.jsx';

import { processError } from '../../../projlibs/cookie-management';
import { TrimInputFields } from '../../../projlibs/HelperFunctions.js';

/*
props (component-level arguments):
	none

state (component-global variables):
	quickLinkInfo: an ordered list of posts
	filteredquickLinkInfo: a subset of quickLinkInfo filtered based off of the set filter parameters
	filteredCat: the string to use to filter by category
	filteredTag: the string to use to filter by tag
	editQuickLinkOpen: whether or not the new/edit post menu is open
	editingQuickLink: the post being edited (null for a new post)
	editorContent: the WYSIWYG editor content field value
*/

class AdminQuickLinkManager extends FilteredComponent {
	constructor(props) {
		super(props);

		this.state = {
			date: new Date(),
			quickLinkInfo: [],
			editQuickLinkOpen: false,
			editingQuickLink: null,
			//NOTE: editorContent must be separate from editingQuickLink.content
			//to handle the case of a newly-created post
			editorContent: '',
			isTargetChecked: false,
			isFeatured: false
		};

		this.storeArrayWithDownloadLinks = this.storeArrayWithDownloadLinks.bind(this);
		this.genFormattedTableData = this.genFormattedTableData.bind(this);
		// this.stripPostUiFields = this.stripPostUiFields.bind(this);
		this.saveQuickLink = this.saveQuickLink.bind(this);
		this.deleteQuickLink = this.deleteQuickLink.bind(this);
		this.openEditForm = this.openEditForm.bind(this);
		this.closeEditForm = this.closeEditForm.bind(this);
		this.renderEditForm = this.renderEditForm.bind(this);
		this.renderTableTitle = this.renderTableTitle.bind(this);
		this.handleTargetCheckbox = this.handleTargetCheckbox.bind(this);
		this.addURLProtocol = this.addURLProtocol.bind(this);
	}

	componentDidMount() {
		this.fetchQuickLinkInfo();
	}

	componentDidUpdate(prevProps, prevState) {
		//then editing a new post
		if (!this.state.editingQuickLink && prevState.editingQuickLink) {
			this.setState({
				editQuickLinkOpen: false,
				editingQuickLink: null,
			});
		}
	}

	fetchQuickLinkInfo() {
		let cookies = parse_cookies();
		let url = '/link?limit=1000';
		xml_fetch_info(
			url,
			xhr => {
				let data = JSON.parse(xhr.responseText);
				let links = [];
				for (let idx = 0; idx < data['Link'].length; idx++) {
					if (data['Link'][idx].is_valid === false) {
						continue;
					}
					links.push(data['Link'][idx]);
				}
				if (data['Link'].length === undefined) {
					// then it is a single file
					if (data['Link'].is_valid) {
						links.push(data['Link']);
					}
				}

				this.setState({
					quickLinkInfo: links,
				});
			},
			{
				'Content-Type': 'application/json',
				Authorization: cookies[SESSION_ID]
			},
			function(error) {
				if (processError(error)) {
					return false;
				} else {
					toast.error(ERROR_RETRIEVING_POSTS + ' ' + TRY_AGAIN_LATER);
				}
			}
		);
	}

	storeArrayWithDownloadLinks(posts) {
		let quickLinkInfo = [];
		for (let idx = 0; idx < posts.length; idx++) {
			let newsItem = posts[idx];
			if (newsItem.is_valid === false) {
				continue;
			}
			if (newsItem.category === null) {
				newsItem.category = '';
			}
			if (newsItem.tags === null) {
				newsItem.tags = [];
			}
			quickLinkInfo.push(newsItem);
		}

		this.setState({
			quickLinkInfo,
		});
	}

	genFormattedTableData() {
		let tableData = {
			headerData: [
				{ title: 'Link Title', field: 'formattedTitle' },
				{ title: 'Link URL', field: 'formattedURL' },
				{ title: '', field: 'editIcon', sortable: false, onClick: 'editAction' },
				{ title: '', field: 'deleteIcon', sortable: false, onClick: 'deleteAction' }
			],
			rows: []
		};
		//sort quicklinks alphabetically
		sortLinks(this.state.quickLinkInfo);
		for (var quickLinkIdx = 0; quickLinkIdx < this.state.quickLinkInfo.length; quickLinkIdx++) {
			let quickLink = this.state.quickLinkInfo[quickLinkIdx];
			quickLink['formattedTitle'] = quickLink.title;
			quickLink['formattedURL'] = quickLink.url;
			quickLink['editIcon'] = 'fas fa-edit';
			quickLink['editAction'] = function() {
				this.openEditForm(quickLink);
			}.bind(this);
			quickLink['deleteIcon'] = 'fas fa-trash';
			quickLink['deleteAction'] = function() {
				this.deleteQuickLink(quickLink);
			}.bind(this);
			quickLink['cssClasses'] = {
				formattedURL: 'break-all',
				editIcon: 'circle-border action-button secondary-color-icon',
				deleteIcon: 'action-button'
			};
			tableData['rows'].push(quickLink);
		}
		return tableData;
	}

	// A precautionary backup in case someone removes the type="url" from the form input
	addURLProtocol(url) {
		if(!url.match(/http(s)?:\/\/.*/g)) {
			return 'http://'+url;
		}
		return url;
	}

	saveQuickLink(current_target) {
		if(!TrimInputFields('admin-manager-form')){
			return false;
		}
		let cookies = parse_cookies();

		let form_data = get_form_data(current_target);
		
		//NOTE: when editing existing posts there will be a post_id value set
		//when creating a new post there will not be a post_id value set
		//this is intentional and it allows us to distinguish those cases
		let isNewQuickLink = false;
		let quickLink = this.state.editingQuickLink;
		let url = '';
		if (quickLink === null) {
			quickLink = new QuickLink();
			url = '/link/';
			isNewQuickLink = true;
		} else {
			url = '/link/'+quickLink.link_id;
		}

		quickLink.title = form_data['title'];
		quickLink.url = this.addURLProtocol(form_data['url']);
		quickLink.target = this.state.isTargetChecked ? '_blank' : '_self';
		quickLink.is_featured = this.state.isFeatured;
		
		if(isNewQuickLink) {
			quickLink.prepareForNetworkRequest();
		}
		
		xml_send_info(
			url,
			JSON.stringify(quickLink),
			xhr => {
				let data = JSON.parse(xhr.responseText)['Link'];
				let quickLinkInfo = this.state.quickLinkInfo;

				//look through all existing posts to see if this is an update to a previously-existing post
				let idx = 0;
				for (idx = 0; idx < quickLinkInfo.length; idx++) {
					//if this is a match
					if (quickLinkInfo[idx]['link_id'] - 0 === data['link_id'] - 0) {
						//then update the existing data
						quickLinkInfo[idx] = data;
						//and stop looking
						break;
					}
				}
				//if we iterated through all existing posts and didn't find a matching id
				//then this is a new post and we should add it to the end of the list
				if (idx >= quickLinkInfo.length) {
					quickLinkInfo.push(data);
				}

				this.setState(
					{
						quickLinkInfo: quickLinkInfo,
						editQuickLinkOpen: false,
						editingQuickLink: null,
						editorContent: ''
					},
					() => {
						toast.success(SUCCESS_QUICK_LINK_SAVED);
					}
				);
			},
			quickLink.hasOwnProperty('link_id') ? 'PUT' : 'POST',
			{ Authorization: cookies[SESSION_ID] },
			xhr => {
				toast.error(ERROR_QUICK_LINK_NOT_SAVED + ' ' + TRY_AGAIN_LATER);
				
			}
		);
		return false;
	}

	deleteQuickLink(link) {
		let cookies = parse_cookies();

		let request_delete_data = { link_id: link.link_id, is_valid: false };
		xml_send_info(
			'/link/'+link.link_id,
			JSON.stringify(request_delete_data),
			xhr => {
				let data = JSON.parse(xhr.responseText)['Link'];
				let quickLinkInfo = this.state.quickLinkInfo;
				let newQuickLinkInfo = [];

				//look through all existing quick links to see if this is an update to a previously-existing quick links
				let idx = 0;
				for (idx = 0; idx < quickLinkInfo.length; idx++) {
					//if this is a match
					if (quickLinkInfo[idx]['link_id'] - 0 === data['link_id'] - 0) {
						//then update the existing data
						quickLinkInfo[idx] = data;
					}
					//if this is invalid (as the new link should be if it saved correctly)
					//then don't include it in the new list
					if (quickLinkInfo[idx].is_valid === false) {
						continue;
					}
					newQuickLinkInfo.push(quickLinkInfo[idx]);
				}

				this.setState(
					{
						quickLinkInfo: newQuickLinkInfo,
						editQuickLinkOpen: false,
						editingQuickLink: null,
						editorContent: ''
					},
					() => {
						toast.success(SUCCESS_QUICK_LINK_DELETED);
					}
				);
			},
			'PUT',
			{ Authorization: cookies[SESSION_ID] },
			xhr => {
				toast.error(ERROR_QUICK_LINK_NOT_SAVED + ' ' + TRY_AGAIN_LATER);
			}
		);
	}

	openEditForm(editingQuickLink = null) {
		this.setState({
			editQuickLinkOpen: true,
			editingQuickLink: editingQuickLink,
			editorContent: editingQuickLink !== null ? editingQuickLink.content : '',
			submitting: false,
			isFeatured: editingQuickLink? editingQuickLink.is_featured: false
		});

		if (editingQuickLink && editingQuickLink.target === '_blank') {
			this.setState({
				isTargetChecked: true,
			});
		} else if(editingQuickLink === null) {
			this.setState({
				isTargetChecked: true,
				isFeatured: false
			});
		} else {
			this.setState({
				isTargetChecked: false,
			});
		}
	}

	closeEditForm() {
		this.setState({
			editQuickLinkOpen: false,
			editingQuickLink: null,
			editorContent: ''
		});
	}

	handleDropdownChange = event => {
		event.preventDefault();
		let inputs = { ...this.state.inputs };
		if (typeof event !== 'undefined') {
			inputs[event.currentTarget.id] = event.currentTarget.value;
			this.setState({
				inputs: inputs
			});
		}
	};

	handleTargetCheckbox(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		this.setState({
			isTargetChecked: value
		});
	}

	handleFeatureCheckbox=(event)=> {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		this.setState({
			isFeatured: value
		});
	}

	renderEditForm() {
		let data = this.state.editingQuickLink !== null
			? this.state.editingQuickLink
			: {
				is_featured: false,
				target: '_blank',
				title: '',
				url: ''
			};

		return (
			<FlyoutMenu id='edit-post-flyout' menuOpen={this.state.editQuickLinkOpen} buttonText='New Quick Link' closeButtonText='Cancel' openMenu={this.openEditForm} closeMenu={this.closeEditForm} haveOpenButton={true}>
				{this.state.editQuickLinkOpen && (
					<form onSubmit={e=>{
						e.preventDefault();
						let current_target=e.currentTarget;
						this.setState({submitting:true},()=>{this.saveQuickLink(current_target);});
					}}id="admin-manager-form">
						<div className='grid-x grid-padding-x grid-padding-y'>
							<div className='small-12 cell title-cell'>
								<h2 className='flyout-title'>{this.state.editingQuickLink === null ? 'Create a New Post' : 'Editing ' + this.state.editingQuickLink.title}</h2>
							</div>
							<div className='small-12 cell content-cell'>
								<div className='grid-x grid-margin-x grid-padding-y'>
									<div className='small-12 cell title-cell'>
										<label htmlFor='input-title'>Title</label>
										<input type='text' name='title' id='input-title' required={true} defaultValue={data['title']} />
									</div>
									
									<div className='small-12 cell url-cell'>
										<label htmlFor='input-url'>Url</label>
										<input type='url' name='url' id='input-url' placeholder="https://example.com" required={true} defaultValue={data['url']} />
									</div>
									<div className='small-12 cell target-cell'>
										<input type='checkbox' name='is_featured' id='input-is-featured' checked={this.state.isFeatured} onChange={this.handleFeatureCheckbox} />
										<label htmlFor='input-is-featured'>Do you want this link to show up in the quick link bar?</label>
									</div>
									<div className='small-12 cell target-cell'>
										<input type='checkbox' name='target' id='input-target' checked={this.state.isTargetChecked} onChange={this.handleTargetCheckbox} />
										<label htmlFor='input-target'>Do you want this link to open in a new tab when clicked?</label>
									</div>
									
									<div className='small-12 cell'>
										<input disabled={this.state.submitting} className='show-for-medium' type='submit' value='Submit' />
									</div>
								</div>
							</div>
						</div>
					</form>
				)}
			</FlyoutMenu>
		);
	}

	renderTableTitle() {
		return (
			<div className='grid-x grid-padding-x'>
				<div className='small-12 medium-8 cell'>
					<h1 className='table-title'>Quick Links</h1>
				</div>
				<div className='small-12 medium-4 cell'>
					<div className='new-item-button-cont'>{this.renderEditForm()}</div>
				</div>
			</div>
		);
	}

	render() {
		let tableData = this.genFormattedTableData();

		return (
			<div className='AdminQuickLinkManager'>
				<FlexTable tableData={tableData} tableTitle={this.renderTableTitle()} />
			</div>
		);
	}
}

export default AdminQuickLinkManager;
