/*
Form Manager - allow a company to create forms
*/

import React from 'react';
import FlyoutMenu from '../../../FlyoutMenu.jsx';
import AdminManagerAbstract from '../../AdminManagerAbstract.jsx';
import FlexTable from '../../../FlexTable.jsx';
import { xml_rqst_info, xml_send_info } from '../../../../react-utils/src/libajax';
import {  SESSION_ID, TRY_AGAIN_LATER, ERROR_RETRIEVING_FORMS } from '../../../../constants';
import { parse_cookies } from '../../../../react-utils/src/libformat';
import { processError } from '../../../../projlibs/cookie-management';
import { toast } from 'react-toastify';
import Form from '../../../../models/Form';
/*
states (component-level states):
	edit_view_open: boolean; true if flyout menu is open false if closed
	forms: a list of all forms retrieved from the server
	formsFiltered: a list of all forms that have been filtered,
	formBeingViewed: the form being viewed by the user

props (component-level arguments):
	no props
*/
import { FormViewManager } from './FormViewManager/FormViewManager';
import { FormFilter } from '../../../FormFilter';
import {table_enums, updateSorting} from "../../../../projlibs/pagination-functions";
const portal = document.createElement('div');
document.body.appendChild(portal);
const FORM_MANAGER_TABLE_HEADERS = [
	{ title: 'Form title', field: 'title' },
	{ title: 'Date Created', field: 'prettyCreatedDate', sortField: 'created_at' },
	{ title: '', field: 'editIcon', onClick: 'editAction', sortable: false },
	{ title: '', field: 'deleteIcon', onClick: 'deleteAction', sortable: false }
];
class FormManager extends AdminManagerAbstract {
	constructor(props) {
		super(props);
		this.state = {
			edit_view_open: false,
			forms: [],
			table_rows:[],
			formsFiltered: [],
			formBeingViewed: null,
			order_by:'order_by=title&order_dir=asc'
		};
		this.closeFlyoutCallback = this.closeFlyoutCallback.bind(this);
		this.openFlyoutCallback = this.openFlyoutCallback.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.cellClickedEdit = this.cellClickedEdit.bind(this);
		this.populateTableNetworkRequest = this.populateTableNetworkRequest.bind(this);
	}

	componentDidMount() {
		this.populateTableNetworkRequest();
	}
	componentDidUpdate(prevProps, prevState) {
		if(prevState.order_by !== this.state.order_by){
			this.populateTableNetworkRequest();
		}
	}

	// --- get form information from server and populate table
	populateTableNetworkRequest(tag='') {
		const cookies = parse_cookies();
		let url_path = '/form';
		url_path+=`?${this.state.order_by}&tag=`+tag;
		xml_rqst_info(
			url_path,
			xhr => {
				this.populateTableSuccessHandler(xhr.response);
			},
			'GET',
			{},
			{ Authorization: cookies[SESSION_ID] },
			error => {
				this.populateTableErrorHandler(error);
			}
		);
	}

	populateTableSuccessHandler = response => {
		response = JSON.parse(response).Form;
		let forms = [];
		for (let item in response) {
			let form = new Form();
			form.setValues(response[item]);
			if(!form.valid){
				continue;
			}
			form['editAction'] = function() {
				this.actionEdit(form);
			}.bind(this);
			form['deleteAction'] = function() {
				this.deleteForm(form);
			}.bind(this);
			form['cssClasses'] = {
				// formattedTitle: 'title',
				editIcon: 'circle-border action-button secondary-color-icon',
				deleteIcon: 'action-button'
			};
			forms.push(form);
		}
		this.setState({ forms, formsFiltered: forms });
	};

	populateTableErrorHandler = error => {
		if (processError(error)) {
			return false;
		} else {
			toast.error(ERROR_RETRIEVING_FORMS + ' ' + TRY_AGAIN_LATER);
		}
	};

	actionEdit=(form)=>{
		this.setState({
			formBeingViewed: form,
			edit_view_open: true
		});
	}

	deleteForm=(form) =>{
		let cookies = parse_cookies();
		let data = {
			valid: false
		};
		xml_send_info(`/form/${form.form_id}`, JSON.stringify(data), this.successDelete, 'PUT', { Authorization: cookies[SESSION_ID] }, this.netActionFailure);
	}

	successDelete=(form_id)=>{
		let forms = this.state.forms;
		for(let i in forms){
			if(forms[i].form_id === form_id){
				forms.splice(i,1);
			}
		}
		this.setState({forms});
	}
	// --- customtable ---
	closeFlyoutCallback() {
		this.setState({ edit_view_open: false, formBeingViewed: null });
		this.populateTableNetworkRequest();
	}

	openFlyoutCallback() {
		this.setState({
			edit_view_open: true,
			formBeingViewed:null
		});
	}

	updateTableContent(component, array) {
		component.setState({
			formsFiltered: array
		});
	}

	// TODO take this function out and just add an object to the table instead of completely repopulating it
	handleSubmit() {
		this.populateTableNetworkRequest();
	}

	// when a user clicks the cell it will trigger the open/edit procedure screen
	cellClickedEdit(i){
		let form=this.state.formsFiltered[i];
		this.setState({
			formBeingViewed:form,
			edit_view_open:true
		});
	}

	renderEditForm() {
		return (
			<FlyoutMenu id="edit-user-flyout" menuOpen={this.state.edit_view_open} buttonText="New Form" closeButtonText="Cancel" openMenu={this.actionEdit} closeMenu={this.closeEditForm} haveOpenButton={true}>
				{this.state.edit_view_open && (
						<FormViewManager portal={portal} id='new-form-view' form={this.state.formBeingViewed} closeFlyoutCallback={this.closeFlyoutCallback} onSuccessCreate={this.handleSubmit} />
				)}
			</FlyoutMenu>
		);
	}

	renderTableTitle() {
		return (
			<div className="grid-x grid-padding-x">
				<div className="small-12 medium-8 cell">
					<p className="table-title">Forms</p>
				</div>
				<div className="small-12 medium-4 cell">
					<div className="new-item-button-cont">{this.renderEditForm()}</div>
				</div>
			</div>
		);
	}

	render() {
		return (
			<div className={'Forms'}>
				<FormFilter tagChangeCallback={this.populateTableNetworkRequest}/>
				<FlexTable tableData={{ headerData: FORM_MANAGER_TABLE_HEADERS, rows: this.state.forms }}
						   tableTitle={this.renderTableTitle()}
						   sort={(e, title) => updateSorting(e, title, this, table_enums.forms)}/>
			</div>
		);
	}
}

export default FormManager;
