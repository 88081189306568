/*
Greeting - A Greeting is a text of good morning, eventing or afternoon depending on the time of day.

A Greeting is a top-level component that contains no sub-components
*/

import React from 'react';

import { parse_cookies } from '../react-utils/src/libformat';
import { FIRST_NAME } from '../constants.js';

const elaine = require('../assets/elaine_dash.svg');

/*
props (component-level arguments):
	A Greeting has no Props.
*/
class Greeting extends React.Component {
	constructor(props){
		super(props);
		this.getUserFirstName=this.getUserFirstName.bind(this);
	}
	
	getUserFirstName() {
		//if user info was given as a prop, then use that
		if(this.props.userInfo!==undefined && this.props.userInfo!==null && this.props.userInfo.hasOwnProperty(FIRST_NAME)){
			return this.props.userInfo[FIRST_NAME];
		}
		
		//otherwise, check cookie info
		let cookies=parse_cookies();
		if(cookies.hasOwnProperty(FIRST_NAME)){
			return cookies[FIRST_NAME];
		}
		//otherwise fall back to the literal string "User"
		return 'User';
	}

	getGreetingBasedOnTime() {
		let today = new Date();
		let time = today.getHours();
		let timeOfDay = 'evening';
		if((time >= 6) && time < 12) {
			timeOfDay = 'morning';
		} else if((time >= 12) && time <= 18) {
			timeOfDay = 'afternoon';
		}
		return timeOfDay;
	}
	render() {
		return(
			<div className={`Greeting `}>
				<div className='welcome-wrapper'>
					<p className='time-of-day-greeting'>Good {this.getGreetingBasedOnTime()},</p>
					<h2 className='logged-in-user'>{this.getUserFirstName()}</h2>
				</div>
				{elaine && <img src={elaine} alt='Elaine Greeter Illustration'/>}
			</div>
		);
	}
}

export default Greeting;
