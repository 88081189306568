/*
AdminManagerAbstract - an abstract class meant to hold data and functions which are common to all directory manager views

*/

import React from 'react';

import FilteredComponent from '../FilteredComponent.jsx';

/*
props (component-level arguments):
	match: url path information for path-based routing arguments

state (component-level globals):
	edit_view_open: whether or not the add/edit user dialogue is open
	data_to_edit: the data being edited in the add/edit view (null for new items)
*/
class AdminManagerAbstract extends FilteredComponent {
	constructor(props) {
		super(props);
		this.state={
			edit_view_open:false,
			data_to_edit:null,
		};
		
		this.openEditForm=this.openEditForm.bind(this);
		this.closeEditForm=this.closeEditForm.bind(this);
	}

	openEditForm(data_to_edit=null) {
		this.setState({
			edit_view_open:true,
			data_to_edit:data_to_edit,
			submitting:false
		});
	}
	
	closeEditForm() {
		this.setState({
			edit_view_open:false,
			data_to_edit:null,
		});
	}
	
	render() {
		return(
			<div className='AdminManagerAbstract'>
			</div>
		);
	}
}
export default AdminManagerAbstract;

