/*
Calendar - an area showing a calendar and allows users to quickly see at a glance how many events they have on days.

A Calendar consists of (from left to right and top to bottom)
	CalendarMonthLabel - Shows the name of the month current selected month.
	CalendarArrows - Previous and Next Month controls to change the month.
	CalendarGrid - A 6 row calendar with a header displaying the days of the week from Sunday -> Saturday.
*/

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import CalendarGrid from './CalendarGrid.jsx';
import CalendarMonthLabel from './CalendarMonthLabel.jsx';
import CalendarArrows from './CalendarArrows.jsx';

import {DAY_SUNDAY,MONTH_NAMES} from '../../constants.js';

/*
props (component-level arguments)
	events: a hash table of events that might display on this calendar
		events is expected to be a javascript object, each key of which is a date in YYYY-MM-DD format and each entry of which is a list of events for that day

state (component-level globals)
	monthStartDate: the javascript Date() object representing the first day of the month
	weekStartsOn: the index of the day to use as the start-of-week day
	selectedDate: the date which is currently selected/highlighted/focused on the calendar
*/
class Calendar extends React.Component {
	constructor(props) {
		super(props);

		let now=new Date();
		let month_start=new Date(now.getFullYear(),now.getMonth(),1);
		
		this.state={
			monthStartDate:month_start,
			weekStartsOn:DAY_SUNDAY,
			selectedDate:now,
		};
		
		this.handleMonthChange=this.handleMonthChange.bind(this);
	}

	handleMonthChange(direction){
		let moment_month_start=moment(this.state.monthStartDate.toISOString());
		moment_month_start.add(direction,'months');
		this.setState({
			monthStartDate:new Date(moment_month_start.toISOString()),
		});
	}

	render() {
		return (
			<div className='Calendar'>
				<div className="calendar-control-row">
					{/* Eventually this will need to be a callback from the method that changes what day is selected */}
					<CalendarMonthLabel label={MONTH_NAMES[this.state.monthStartDate.getMonth()]+' '+this.state.monthStartDate.getFullYear()} />
					<CalendarArrows monthChangeCallback={this.handleMonthChange} />
				</div>
				<CalendarGrid
					monthStartDate={this.state.monthStartDate}
					weekStartsOn={this.state.weekStartsOn}
					selectedDate={this.state.selectedDate}
					events={this.props.events}
				/>
			</div>
		);
	}
}

Calendar.defaultProps = {
	events:{},
};

Calendar.propTypes = {
	events: PropTypes.object.isRequired,
};

export default Calendar;
