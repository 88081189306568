/*
QuickLink - QuickLink is a page that hosts various links to different locations that are important to the users.


*/

import React from 'react';
//eslint-disable-next-line no-unused-vars
import {BrowserRouter as Router, Link} from 'react-router-dom'; // Even tho Router is not actually used a tag, it is needed for the component to show up.

import { parse_cookies } from '../../react-utils/src/libformat';
import { xml_fetch_info } from '../../react-utils/src/libajax.js';
import {ERROR_RETRIEVING_LINKS, TRY_AGAIN_LATER, SESSION_ID} from '../../constants';
import {processError} from '../../projlibs/cookie-management';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import IconLink from './../IconLink.jsx';
/*
props (component-level arguments):
	QuickLink has no props.
*/
class QuickLink extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			linkInfo: [],
			editLinkInfo: [],
		};
		this.getQuickLinkData = this.getQuickLinkData.bind(this);
		this.netCallSuccess = this.netCallSuccess.bind(this);
		this.netCallFailure = this.netCallFailure.bind(this);
		this.showLinks = this.showLinks.bind(this);
		
	}

	componentDidMount() {
		this.getQuickLinkData();
	}

	getQuickLinkData() {
		const cookies = parse_cookies();

		xml_fetch_info('/link/',
			this.netCallSuccess,
			{
				'Content-Type': 'application/json',
				Authorization: cookies[SESSION_ID]
			},
			this.netCallFailure
		);
	}

	netCallSuccess(xhr){
		const response = JSON.parse(xhr.response);
		let linkInfo=[];
		if(response.hasOwnProperty('Link')){
			for(let idx=0;idx<response.Link.length;idx++){
				let linkItem=response.Link[idx];
				if(linkItem.is_valid===false){
					continue;
				}

				linkInfo.push(linkItem);
			}
			sortLinks(linkInfo);
			this.setState({
				linkInfo,
			});
		}else{
			//if no results were found then there's nothing to display
			this.setState({
				linkInfo:[],
			});
		}
	}
	
	netCallFailure(error){
		if(processError(error)){
			return false;
		}else{
			toast.error(ERROR_RETRIEVING_LINKS + ' ' + TRY_AGAIN_LATER);
		}
	}

	showLinks() {
		const linkArray = [];

		for(let index = 0; index < this.state.linkInfo.length; index++) {
			linkArray.push(<div className='cell small-12 medium-3 quick-link-list-item' key={index}><IconLink title={this.state.linkInfo[index].title} url={this.state.linkInfo[index].url} target={this.state.linkInfo[index].target} /></div>);
		}

		return <div className='grid-x quick-link-list'>{linkArray}</div>;
	}

	render() {
		return(
			<div className='QuickLink'>
				<Link className='back-link arrow-link arrow-link--back' to='/'>Back to Dashboard</Link>
				<div className='QuickLink-page'>
					<h2 className='page-title'>Quick Links</h2>
					{this.showLinks()}
				</div>
			</div>
		);
	}
}

export default QuickLink;


export function sortLinks(links){
	//sort modifies array
	links.sort((a,b)=> {
		if (a.title.toLowerCase() < b.title.toLowerCase())
			return -1;
		if (a.title.toLowerCase() > b.title.toLowerCase())
			return 1;
		return 0;
	});
}