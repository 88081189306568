/*
CategoryLabel - a label for an object's category

A category label is a base-level component, and contains no subcomponents
*/


import React from 'react';
import PropTypes from 'prop-types';

/*
props (component-level arguments):
	category: the category text to display
*/
class CategoryLabel extends React.Component {
	render() {
		return (
			<div className='CategoryLabel'>
				<p className='categorylabel-text'>{this.props.category}</p>
			</div>
		);
	}
}

CategoryLabel.defaultProps={
	category: '',
};

CategoryLabel.propTypes = {
	category: PropTypes.string.isRequired,
};

export default CategoryLabel;

