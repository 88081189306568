/*
InputFieldBlock, an input field and its title

An InputFieldBlock item consists of a title and an input field
*/

import React from 'react';
import PropTypes from 'prop-types';

/*
props (component level arguments):
	title: the title of the input field
	titleClass: an additional class for the title
	fieldClass: the class of the input tag
	fieldName: the for attribute of the label as well as the id and name of the input field
	fieldHint: the text placeholder of the input tag
	fieldType: the data type for the input tag
	fieldRequired: boolean to see if a user input is mandatory
	callback: The onchange event handler
	fieldValue: the initial value of the field
	disabled: whether or not this field is disabled
	onBlur: the onBlur event handler
*/

export class InputFieldBlock extends React.Component {
	render() {
		if(this.props.hidden){
			return null;
		}
		return (
			<div className={`InputFieldBlock ${this.props.inputBlockClass}`}>
				<label className={'input-title ' + this.props.titleClass} htmlFor={this.props.fieldName}>{this.props.title}</label>
				<input className={'input-field ' + this.props.fieldClass}
					id={this.props.fieldName}
					type={this.props.fieldType}
					name={this.props.fieldName}
					placeholder={this.props.fieldHint}
					required={this.props.fieldRequired}
					onChange={this.props.callback}
					onBlur={this.props.onBlur}
					defaultValue={this.props.fieldValue}
					disabled={this.props.disabled}
					max={this.props.max}
					min={this.props.min}
				/>
			</div>
		);
	}
}

InputFieldBlock.defaultProps = {
	titleClass:'',
	fieldHint: '...',
	fieldType: 'text',
	fieldClass: '',
	inputBlockClass: '',
	fieldRequired: false,
	fieldValue: '',
	disabled: false,
	onBlur: function () {},
};

InputFieldBlock.propTypes={
	fieldName:PropTypes.string.isRequired,
	callback:PropTypes.func,
	disabled: PropTypes.bool,
};

export default InputFieldBlock;
