import { Color,FontSize, FontWeight, FontFamily } from './shared-components/src/constants/style-constants';

// Themes used set colors and fontSizes in child components.
// Base themes that can be used to provide 2 themes.  i.e. dark and light themes.

export const MainTheme = {
	colors: {
		bodyTextDark: 'black',
		headingTextDark: 'black',
		tableBgLight: 'white',
		tableRowBgAlternate: Color.ghost,
		tableRowBorder: 'rgba(23,43,77,0.1)',
		tableActionIcon: Color.nile,
		tableHdg: Color.nile
	},
	table: {
		columnHeader: '#778996',
		theadBorderBottomColor: '#4F2683',
	},
	fontSize: {
		header1: FontSize.header1,
		header2: FontSize.header2,
		header3: FontSize.header3,
		header4: FontSize.header4,
		header5: FontSize.header5,
		body1: FontSize.body1,
		body2: FontSize.body2,
		caption1: FontSize.caption1,
		caption2: FontSize.caption2
	},

	fontWeight: {
		normal: FontWeight.normal,
		medium: FontWeight.medium,
		semibold: FontWeight.semibold,
		bold: FontWeight.bold
	},

	defaultFontFamily: FontFamily
};
