/*
Recent Messages - a feed of recent chat messages

A Recent Messages view consists of (from left to right and top to bottom)
	A title, directly in this component
	if recent messages exist
		one or more MessageSummary components
	if recent messages do not exist
		a "check back soon" type message
	A "view all" link, directly in this component
*/

import React from 'react';

import { getHeaders } from '../projlibs/HelperNetworkCalls';
import S3Image from './S3Image';
import HerdRAAWSUtils from '../projlibs/HerdRAAWSUtils';
import { PATH_COMPANY_DIRECTORY, MAX_FEATURED_COMPANIES } from '../constants';
import { xml_rqst_info } from '../react-utils/src/libajax';
//eslint-disable-next-line no-unused-vars
import { BrowserRouter as Route, withRouter } from 'react-router-dom'; // Even tho Router is not actually used a tag, it is needed for the component to show up.

/*
props (component-level arguments):
	msgs: a list of recent messages to be displayed
		each message is an object which is expected to consist of the fields described in MessageSummary
*/
class FeaturedCompanies extends React.Component {
	constructor(props){
		super(props);
		this.state={
			companies: [],
			loading:true
		};
		this.aws = new HerdRAAWSUtils();
	}

	componentDidMount(){
		xml_rqst_info(`/featured-companies?limit=${MAX_FEATURED_COMPANIES}`,xhr=>{
			let companies=JSON.parse(xhr.response).Company;
			this.setState({companies, loading:false});

		},'GET',{},getHeaders(),error=>{
			this.setState({loading:false});
		});
	}

	render() {
		let HAS_FEATURED_COMPANIES = this.state.companies.length>0;
		return (
			<div className='RecentMessages'>
				<div className='RecentMessages-content'>
					<h2 className='RecentMessages-title'>Featured Companies</h2>
					{HAS_FEATURED_COMPANIES
					?
					<>
						{this.state.companies.map((item, idx)=> {
							return (
								<div key={`featured-company-${idx}`}className='featured-company-wrapper' 
								onClick={e=>{e.preventDefault();this.props.history.push(`${PATH_COMPANY_DIRECTORY}${item.company_id}`);}}>
									<S3Image aws_object={this.aws} s3_path={item?.locations[0]?.photo_s3_path}/>
									{item.name}
								</div>
							);
						})}
					</>
					:
					<p className="no-recent-messages">{this.state.loading?'...':'No featured companies'}</p>
					}
				</div>
				<div className='view-all-cont'>
					{/* eslint-disable-next-line */}
					<a href={PATH_COMPANY_DIRECTORY} className='view-all-link'>View All</a>
				</div>
			</div>
		);
	}
}
export default withRouter(props => <FeaturedCompanies {...props} />);