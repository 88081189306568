/*
CalendarGrid - A grid containing days of the week in a row column layout based on how many rows are in the header

A Calendar Grid consists of (from left to right and top to bottom)
	CalendarGridHeader - A row of header information to print out the header. Also used to define the number of rows in the grid
	GridCell - A row of GridCell elements
*/

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { DAYS_PER_WEEK, DAY_SUNDAY, DAY_ABBREVIATIONS } from '../../constants';
import GridCell from './GridCell';
import CalendarGridHeader from './CalendarGridHeader';
import FlyoutMenu from '../FlyoutMenu';
import { Link } from 'react-router-dom';
import BriefView from '../BriefView/BriefView';
import HerdRAAWSUtils from '../../projlibs/HerdRAAWSUtils';
//eslint-disable-next-line no-unused-vars
import { BrowserRouter as Route, withRouter } from 'react-router-dom'; // Even tho Router is not actually used a tag, it is needed for the component to show up.

/*
props (component-level arguments)
	monthStartDate: A javascript Date object representing the first day of the current month
	weekStartsOn: The index of the day of the week to begin the display with
	selectedDate: The highlighted/selected date
*/
class CalendarGrid extends React.Component {
	constructor(props) {
		super(props);
		this.aws = new HerdRAAWSUtils();
		this.calcBoundDays = this.calcBoundDays.bind(this);
		this.getDayType = this.getDayType.bind(this);
		this.getEventsForISODate = this.getEventsForISODate.bind(this);
		this.renderGrid = this.renderGrid.bind(this);
		this.state = {
			eventsOpen: false,
			cur_events: []
		};
	}

	//get the grid start and end date values
	//so that a week of the previous month and a week of the following month always display
	calcBoundDays(month_start_date) {
		let moment_start_date = moment(month_start_date.toISOString()).subtract(7, 'days');
		while (moment_start_date.day() !== this.props.weekStartsOn) {
			moment_start_date.subtract(1, 'days');
		}
		let start_date = new Date(moment_start_date.toISOString());

		let moment_end_date = moment(month_start_date.toISOString()).add(1, 'month').add(7, 'days');
		while (moment_end_date.day() !== this.props.weekStartsOn) {
			moment_end_date.add(1, 'days');
		}
		moment_start_date.subtract(1, 'days');
		let end_date = new Date(moment_end_date.toISOString());

		return {
			'start': start_date,
			'end': end_date,
		};
	}

	getDayType(month_start_date, active_date, selected_date) {
		//default to pastDay
		let day_type = 'pastDay';

		//if we are displaying a date within the currently-viewed month
		if ((month_start_date.getFullYear() === active_date.getFullYear()) && (month_start_date.getMonth() === active_date.getMonth())) {
			//if the selected date is somewhere within that month
			if ((selected_date.getFullYear() === month_start_date.getFullYear()) && (selected_date.getMonth() === month_start_date.getMonth())) {
				//then by default we're in a day which is remaining in this month
				day_type = 'dayRemainingThisMonth';

				//if the current date is before the selected day
				if (active_date.getDate() < selected_date.getDate()) {
					//then this is a past day
					day_type = 'pastDay';
				}
				//if the selected date is NOT somewhere within that month
			} else {
				//then this is a remaining day in the month
				day_type = 'dayRemainingThisMonth';
			}
			//if we are displaying a date within the following month, it's a future date
		} else if (active_date > month_start_date) {
			day_type = 'futureDay';
		}

		return day_type;
	}

	getEventsForISODate(iso_date, event_list) {
		let day_events = [];
		if ((event_list !== undefined) && (event_list !== null) && (event_list.hasOwnProperty(iso_date))) {
			day_events = event_list[iso_date];
		}
		return day_events;
	}

	renderMenu = (event) => {
		this.setState({ cur_events: event }, () => {
			this.openEvents();
		});
	};

	renderGrid() {
		let display_grid = [];
		let grid_row = [];
		let grid_cell_idx = 0;
		let week_idx = 0;

		let month_start_date = this.props.monthStartDate;
		let selected_date = this.props.selectedDate;
		let selected_iso_date = (selected_date.getFullYear()) + '-' + (((selected_date.getMonth() + 1) + '').padStart(2, '0')) + '-' + ((selected_date.getDate() + '').padStart(2, '0'));

		let grid_bound_dates = this.calcBoundDays(month_start_date);
		let grid_start_date = grid_bound_dates['start'];
		let grid_end_date = grid_bound_dates['end'];

		//for every day in the list of days to display
		for (let active_date = grid_start_date; active_date <= grid_end_date; active_date = new Date(moment(active_date.toISOString()).add(1, 'days').toISOString()), grid_cell_idx++) {
			//get the day in ISO8601 format for easy handling
			let active_iso_date = (active_date.getFullYear()) + '-' + (((active_date.getMonth() + 1) + '').padStart(2, '0')) + '-' + ((active_date.getDate() + '').padStart(2, '0'));

			//get the type and selection status of the day being displayed
			let day_type = this.getDayType(month_start_date, active_date, selected_date);
			let is_selected = (active_iso_date === selected_iso_date);

			//display that day
			grid_row.push(<td onClick={e => { e.preventDefault(); this.renderMenu(this.getEventsForISODate(active_iso_date, this.props.events)); }} key={'cal-grid-cell-' + grid_cell_idx}>
				<GridCell
					text={active_date.getDate() + ''}
					/* include event list for this day based on server-provided data */
					events={this.getEventsForISODate(active_iso_date, this.props.events)}
					dayType={day_type}
					isSelected={is_selected}
					dateStr={active_iso_date}
				/>
			</td>);

			//if this is the last day of the week
			if (((active_date.getDay() + 1) % DAYS_PER_WEEK) === this.props.weekStartsOn) {
				//then end the current row and if necessary start a new one
				display_grid.push(<tr key={'cal-week-' + week_idx}>{grid_row}</tr>);
				grid_row = [];
				week_idx++;
			}
		}
		return display_grid;
	}

	openEvents = () => {
		this.setState({ eventsOpen: true });
	};
	closeEvents = () => {
		this.setState({ eventsOpen: false });
	};

	clickPost = (url) => {
		this.props.history.push(url);
		this.closeEvents();
	};

	showPosts() {
		const eventsTagArray = [];
		for (let index = 0; index < this.state.cur_events.length; index++) {
			if (this.state.cur_events[index].direct_file) {
				eventsTagArray.push(<div key={'event-link-' + this.state.cur_events[index].post_id} className='event-single-link' onClick={e => this.openPDF(e, this.state.cur_events[index],)}><BriefView aws_object={this.aws} display_type={'events_archive_pdf'} object={this.state.cur_events[index]} /> </div>);
			} else {
				eventsTagArray.push(<Link onClick={e => { e.preventDefault(); this.clickPost(`/events/${this.state.cur_events[index].event_id}`); }} className='event-single-link' key={'event-link-' + this.state.cur_events[index].post_id} to={`/events/${this.state.cur_events[index].event_id}`}><BriefView aws_object={this.aws} display_type={'events_archive'} object={this.state.cur_events[index]} /></Link>);
			}
		}

		if (eventsTagArray.length > 0) {
			return eventsTagArray;
		} else {
			return 'There are no Events that match these filters.';
		}
	}

	render() {
		let calendarHeaderData = [];
		for (let day_idx = this.props.weekStartsOn; day_idx < (this.props.weekStartsOn + DAYS_PER_WEEK); day_idx++) {
			let day_of_week = day_idx % DAYS_PER_WEEK;
			calendarHeaderData.push(DAY_ABBREVIATIONS[day_of_week]);
		}
		return (<>
			<FlyoutMenu id='calendar-event-pick' menuOpen={this.state.eventsOpen} closeButtonText='Close' openMenu={this.openEvents} closeMenu={this.closeEvents} cancelIcon='fa-caret-left'>
				{(this.state.eventsOpen) &&
					<>
						{this.showPosts()}
					</>
				}
			</FlyoutMenu>
			<table className='CalendarGrid'>
				<CalendarGridHeader calendarHeaderData={calendarHeaderData} />
				<tbody>
					{this.renderGrid()}
				</tbody>
			</table>
		</>);
	}
}

CalendarGrid.defaultProps = {
	monthStartDate: new Date(),
	weekStartsOn: DAY_SUNDAY,
	selectedDate: new Date(),
};

CalendarGrid.propTypes = {
	monthStartDate: PropTypes.object.isRequired,
	weekStartsOn: PropTypes.number.isRequired,
	selectedDate: PropTypes.object.isRequired,
};

export default withRouter(props => <CalendarGrid {...props} />);
