import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../config';
import HerdRAAWSUtils from '../projlibs/HerdRAAWSUtils';

export function signedUrlErrorCallback() {
	// TODO  fix this so it will still show search results if aws is not working for thumbnails
	toast.error('Could not generate file download links');
}

export function downloadLinkInit(downloadFiles, AWS, storeDownloadLinksCallback){
	//this should be called only when new downloadFiles information is received
	//for example in the success callback function from where the list of downloadFiles is retrieved
	
	if(!downloadFiles){
		storeDownloadLinksCallback([]);
		return null;
	}
	if(downloadFiles.length === undefined && downloadFiles){// for the case that there is only 1 file and it is not an array
		let file = downloadFiles;
		downloadFiles = [];
		downloadFiles.push(file); // dont have to heavily modify the library this way
	}
	if(downloadFiles.length) {
		for(let idx=0;idx<downloadFiles.length;idx++){
			let downloadInfo=downloadFiles[idx];
			getSignedUrl(downloadInfo, 's3_path', idx, downloadFiles, AWS, storeDownloadLinksCallback);
			getSignedUrl(downloadInfo, 'thumbnail_s3_path', idx, downloadFiles,AWS, storeDownloadLinksCallback);
			getSignedUrl(downloadInfo, 'file_s3_path', idx, downloadFiles,AWS, storeDownloadLinksCallback);
			getSignedUrl(downloadInfo, 'img_s3_path', idx, downloadFiles, AWS, storeDownloadLinksCallback);
			getSignedUrl(downloadInfo, 'photo_s3_path', idx, downloadFiles, AWS, storeDownloadLinksCallback);
			getSignedUrl(downloadInfo, 'photo_s3_path', idx, downloadFiles, AWS, storeDownloadLinksCallback);

			if(!downloadInfo.s3_path && !downloadInfo.thumbnail_s3_path && !downloadInfo.file_s3_path && !downloadInfo.img_s3_path && !downloadInfo.photo_s3_path) {
				storeDownloadLinksCallback(downloadFiles);
			}
		}
	}
}

export function getSignedUrl(downloadInfo, fieldName, idx, downloadFiles, AWS, storeDownloadLinksCallback) {
	if(downloadInfo[fieldName]) {
		AWS.getSignedUrl(config.bucket_name,
			downloadInfo[fieldName].replace(/^\/+/g, ''),
			signedUrlErrorCallback,
			signedUrlSuccessCallback(downloadInfo, downloadFiles, storeDownloadLinksCallback, fieldName, idx),
		);
	}
}

export function signedUrlSuccessCallback(file, downloadFiles, storeDownloadLinksCallback, pathType, idx=0){
	return function(url) {
		//find the location of the the element in the existing list
		//if the loop finished before the condition was false then the file was found
		if(idx<downloadFiles.length){
			//now that we know what file this was for, set the url info for that file
			if(pathType==='s3_path' && downloadFiles[idx].s3_path) {
				downloadFiles[idx].s3_path=url;
			}
			
			if(pathType==='thumbnail_s3_path' && downloadFiles[idx].thumbnail_s3_path) {
				downloadFiles[idx].thumbnail_s3_path=url;
			}
			if(pathType==='file_s3_path' && downloadFiles[idx].file_s3_path) {
				downloadFiles[idx].file_s3_path=url;
			}
			
			if(pathType==='img_s3_path' && downloadFiles[idx].img_s3_path) {
				downloadFiles[idx].img_s3_path=url;
			}

			if(pathType==='photo_s3_path' && downloadFiles[idx].photo_s3_path) {
				downloadFiles[idx].photo_s3_path=url;
			}
			
			//the setState will trigger a re-render which will show the files
			storeDownloadLinksCallback(downloadFiles);
		}
	};
}
export function openSignedURLFile(s3_path,postTitle) {
	let aws = new HerdRAAWSUtils();
	if(s3_path) {
		aws.getSignedUrl(config.bucket_name, s3_path, function(error) {
			console.log(`Error getting signed url. ${error}`);
		}, function(url) {
			let fileName=stripShortIDAndBucket(s3_path);
			if(postTitle===null){ // then its a file attachment that has no title just a file
				postTitle=fileName;
			}
			dataLayerPush(fileName,postTitle);
			window.open(url);
			
		});
	}
}
export function openAlreadySignedURLFile(signed_url,fileName,postTitle){
	fileName=stripShortIDAndBucket(fileName);
	if(postTitle===null){ // then its a file attachment that has no title just a file
		postTitle=fileName;
	}
	dataLayerPush(fileName,postTitle);
	window.open(signed_url);
}

export function stripShortIDAndBucket(fileName){
	if(!fileName){
		return '';
	}
	let fileNameSplit = fileName.split('/');
	if(fileNameSplit.length>1){
		fileName=fileNameSplit[fileNameSplit.length-1];
		let underIdx=fileName.indexOf('_');
		if(underIdx>0){
			return fileName.substring(underIdx+1);
		}
		//otherwise return the whole file name
		return fileName;
	}
	return fileName;
}

export function dataLayerPush(fileName, fileTitle){
	window.dataLayer.push({'event':'ga-event','category':'File Download','action':'Download','label': fileName});
}
