/*
Calendar Grid Header - Displays the header row for the grid

A CalendarGridHeader is a base level component, and contains no subcomponents
*/
import React from 'react';
import PropTypes from 'prop-types';

/*
props (component-level arguments):
	calendarHeaderData: An array of text elements to display as a header row
*/
class CalendarGridHeader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	renderHeader() {
		let headers = [];
		this.props.calendarHeaderData.forEach((element, key) => {
			headers.push(<th key={key}>{element}</th>)
		});
		
		return <tr>{headers}</tr>;
	}

	render() {
		return(
			<thead className='CalendarGridHeader'>
				{this.renderHeader()}
			</thead>
		);
	};
}

CalendarGridHeader.defaultProps = {
	calendarHeaderData: []
}

CalendarGridHeader.propTypes = {
	calendarHeaderData: PropTypes.array.isRequired
}

export default CalendarGridHeader;
