/*
	AdminFileManager - A File Manager allows an administrator to view, modify, and create files
*/

import React from 'react';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import config from '../../../config';

import FlexTable from '../../FlexTable.jsx';
import FlyoutMenu from '../../FlyoutMenu.jsx';
import FilteredComponent from '../../FilteredComponent.jsx';

import { get_form_data } from '../../../projlibs/libform.jsx';
import { xml_fetch_info, xml_send_info } from '../../../react-utils/src/libajax.js';
import { parse_cookies } from '../../../react-utils/src/libformat.js';

import { SESSION_ID, ERROR_RETRIEVING_POSTS, ERROR_POST_NOT_SAVED, TRY_AGAIN_LATER, SUCCESS_POST_SAVED, SUCCESS_POST_DELETED, GENERIC_UPLOAD_ERROR_MESSAGE, ACCEPTED_FILE_TYPE_ARRAY, LANDING_PAGES } from '../../../constants.js';
import Post from '../../../models/Post.js';
import UploadWidget from '../../UploadWidget/UploadWidget.jsx';

import 'react-widgets/dist/css/react-widgets.css';

import Multiselect from 'react-widgets/lib/Multiselect';
import { getTags, getCategories, getDepartments } from '../../../projlibs/HelperNetworkCalls.js';

import { processError } from '../../../projlibs/cookie-management';
import S3Image from '../../S3Image';
import { TrimInputFields, getAWSKey, removeDuplicateTags } from '../../../projlibs/HelperFunctions';
import HerdRAAWSUtils from '../../../projlibs/HerdRAAWSUtils';
import { PageController } from '../../PageController';
import { LoadingIndicator } from '../../LoadingIndicator';
import { table_enums, updateSorting } from '../../../projlibs/pagination-functions';
const imagePlaceholder = require('../../../assets/icon_image.svg');

/*
props (component-level arguments):
	none

state (component-global variables):
	postInfo: an ordered list of posts
	filteredPostInfo: a subset of postInfo filtered based off of the set filter parameters
	filteredCat: the string to use to filter by category
	filteredTag: the string to use to filter by tag
	editPostOpen: whether or not the new/edit post menu is open
	editingPost: the post being edited (null for a new post)
	editorContent: the WYSIWYG editor content field value
*/

class AdminFileManager extends FilteredComponent {
	constructor(props) {
		super(props);

		this.state = {
			date: new Date(),
			tags: [],
			selectedTags: [],
			departments: [],
			selectedDepartments: [],
			categories: [],
			selectedCategory: null,
			postInfo: [],
			filteredPostInfo: [],
			filteredCat: '',
			filteredTag: '',
			editPostOpen: false,
			editingPost: null,
			thumbnail: '',
			uploadingThumbnail: false,
			detailedPhoto: '',
			uploadingDetailedPhoto: false,
			showDatePicker: false,
			AWSrefreshed:false,
			submitting: false,
			inputs: {
				category: '',
				types: '',
				sendTimestamp: 0
			},
			numPages:1,
			currentPage: 0,
			isLoading:true,
			order_by:'&order_by=title&order_dir=asc',
			selectedLandingPages: [],
			landingPages:LANDING_PAGES,

			//NOTE: editorContent must be separate from editingPost.content
			//to handle the case of a newly-created post
			editorContent: ''
		};
		this.aws = new HerdRAAWSUtils();
		this.storeArrayWithDownloadLinks = this.storeArrayWithDownloadLinks.bind(this);
		this.setFilteredPostInfo = this.setFilteredPostInfo.bind(this);
		this.resetFilterPostInfo = this.resetFilterPostInfo.bind(this);
		this.filterData = this.filterData.bind(this);
		this.genFormattedTableData = this.genFormattedTableData.bind(this);
		this.handleWYSIWYGInputChange = this.handleWYSIWYGInputChange.bind(this);
		this.stripPostUiFields = this.stripPostUiFields.bind(this);
		this.savePost = this.savePost.bind(this);
		this.deletePost = this.deletePost.bind(this);
		this.openEditForm = this.openEditForm.bind(this);
		this.closeEditForm = this.closeEditForm.bind(this);
		this.renderEditForm = this.renderEditForm.bind(this);
		this.renderTableTitle = this.renderTableTitle.bind(this);
		this.renderImageLoader = this.renderImageLoader.bind(this);
	}

	getDetailedPhotoObjectSuccess = obj => {
		this.setState({ detailedPhotoObject: obj });
	};
	getObjectFail = err => {
		console.log(err);
	};

	componentDidMount() {
		this.fetchPostInfo();
		getTags(this.successGetTags, this.failGetTags, 'file');
		getCategories(this.successGetCategories, this.failGetTags, 'file');
		getDepartments(this.successGetDepartments, this.failGetTags);
	}


	componentDidUpdate(prevProps, prevState) {
		if (prevState.editingPost !== this.state.editingPost && this.state.editingPost) {
			let departments = [];
			for (let item in this.state.editingPost.departments) {
				if (this.state.editingPost.departments[item].name) {
					departments.push(this.state.editingPost.departments[item].name.toLowerCase());
				}
			}
			this.setState({
				selectedTags: this.state.editingPost.tags,
				selectedCategory: this.state.editingPost.category ? this.state.editingPost.category : null,
				selectedDepartments: departments,
				detailedPhoto: this.state.editingPost.s3_path
			});
			this.aws.getObject(config.bucket_name, this.state.editingPost.s3_path, this.getObjectFail, this.getDetailedPhotoObjectSuccess);
		} else if (!this.state.editingPost && prevState.editingPost) {
			this.setState({
				date: new Date(),
				selectedTags: [],
				selectedDepartments: [],
				selectedCategory: null,
				editPostOpen: false,
				editingPost: null,
				detailedPhoto: '',
				detailedPhotoObject:null,
				uploadingDetailedPhoto: false
			});
		}
		if(prevState.currentPage !== this.state.currentPage || prevState.order_by !== this.state.order_by){
			this.fetchPostInfo();
		}
	}

	successGetDepartments = departments => {
		this.setState({ departments });
	};

	successGetCategories = categories => {
		this.setState({ categories });
	};

	successGetTags = tags => {
		this.setState({ tags });
	};

	failGetTags = error => {
		toast.error(error);
	};

	fetchPostInfo() {
		let cookies = parse_cookies();
		//NOTE: although this says it's looking for "news" the server returns all post data of type 'post' at that endpoint
		//regardless of if it's in the News category or not
		let url='/search/?table=Post&type=file';
		if (this.props.match.params.file_id) {
			url = `/files/${this.props.match.params.file_id}`;
		}else{
			url+=`&page=${this.state.currentPage}&page_size=0&${this.state.order_by}`;
		}
		// this will return files that have no file attached. we only want this in the admin view
		url+='&full=true'; 
		this.setState({isLoading:true}, ()=>{
			xml_fetch_info(
				url,
				xhr => {
					let data = JSON.parse(xhr.responseText);
					let posts = [];
					for (let idx = 0; idx < data['File'].length; idx++) {
						if (data['File'][idx].is_valid === false) {
							continue;
						}
						posts.push(data['File'][idx]);
					}
					if (data['File'].length === undefined) {
						// then it is a single file
						if (data['File'].is_valid) {
							posts.push(data['File']);
						}
					}
					this.setState({
						postInfo: posts,
						filteredPostInfo: posts,
						filteredCat: '',
						filteredTag: '',
						numPages:data.page_count ,
						isLoading:false
					});
				},
				{
					'Content-Type': 'application/json',
					Authorization: cookies[SESSION_ID]
				},
				(error)=> {
					this.setState({isLoading:false});
					if (processError(error)) {
						return false;
					} else {
						toast.error(ERROR_RETRIEVING_POSTS + ' ' + TRY_AGAIN_LATER);
					}
				}
			);
		});
	}

	storeArrayWithDownloadLinks(posts) {
		let postInfo = [];
		for (let idx = 0; idx < posts.length; idx++) {
			let newsItem = posts[idx];
			if (newsItem.is_valid === false) {
				continue;
			}
			if (newsItem.category === null) {
				newsItem.category = '';
			}
			if (newsItem.tags === null) {
				newsItem.tags = [];
			}
			postInfo.push(newsItem);
		}

		this.setState({
			postInfo,
			filteredPostInfo: postInfo
		});
	}

	setFilteredPostInfo() {
		//start with all data
		let filteredPostInfo = this.state.postInfo;
		let filteredCat = this.state.filteredCat;
		let filteredTag = this.state.filteredTag;

		//apply any category filters that are present
		if (filteredCat !== '') {
			filteredPostInfo = filteredPostInfo.filter(post => post.category === filteredCat);
		}

		//apply any tag filters that are present
		if (filteredTag !== '') {
			filteredPostInfo = filteredPostInfo.filter(post => post.tags.includes(filteredTag));
		}

		//update the state to reflect the newly-applied filters
		this.setState({
			filteredPostInfo: filteredPostInfo
		});
	}

	resetFilterPostInfo() {
		this.setState(
			{
				filteredCat: '',
				filteredTag: '',
				filteredPostInfo: this.state.postInfo,
				currentPage:0
			},
			() => {
				if (this.props.match.params.file_id) {
					window.location.href = '/admin/post-manager';
				}
			}
		);
	}

	filterData(filterValue, filterKey) {
		if (filterKey === 'category') {
			this.setState(
				{
					filteredCat: filterValue,
					currentPage:0
				},
				() => this.setFilteredPostInfo()
			);
		} else if (filterKey === 'tags') {
			this.setState(
				{
					filteredTag: filterValue,
					currentPage:0
				},
				() => this.setFilteredPostInfo()
			);
		}
	}

	genFormattedTableData() {
		let tableData = {
			headerData: [
				{ title: 'Post Title', field: 'formattedTitle' },
				{ title: 'Date Posted', field: 'formattedPublishedAt' },
				{ title: 'Type', field: 'type', sortable: false },
				{ title: 'Category', field: 'category', sortable: false },
				{ title: 'Tags', field: 'tags', sortable: false },
				{ title: '', field: 'editIcon', sortable: false, onClick: 'editAction' },
				{ title: '', field: 'deleteIcon', sortable: false, onClick: 'deleteAction' }
			],
			rows: []
		};
		for (var postIdx = 0; postIdx < this.state.filteredPostInfo.length; postIdx++) {
			let post = this.state.filteredPostInfo[postIdx];
			post['formattedTitle'] = this.renderImageLoader(post.thumbnail_s3_path, `${post.title}`);
			post['formattedPublishedAt'] = new Date(post['published_at'] * 1000);
			post['formattedTags'] = post.tags === null ? '' : post.tags.join(', ');
			post['editIcon'] = 'fas fa-edit';
			post['editAction'] = function() {
				this.openEditForm(post);
			}.bind(this);
			post['deleteIcon'] = 'fas fa-trash';
			post['deleteAction'] = function() {
				this.deletePost(post);
			}.bind(this);
			post['cssClasses'] = {
				formattedTitle: 'title',
				formattedPublishedAt: 'iso-date',
				tags: 'tag-array',
				editIcon: 'circle-border action-button secondary-color-icon',
				deleteIcon: 'action-button'
			};
			tableData['rows'].push(post);
		}
		return tableData;
	}

	renderImageLoader(image, title) {
		return (
			<>
				<S3Image aws_object={this.aws} s3_path={image} placeholderImageURL={imagePlaceholder} />
				{title}
			</>
		);
	}

	handleWYSIWYGInputChange(content, editor) {
		this.setState({
			editorContent: content
		});
	}

	stripPostUiFields(post) {
		//remove any fields that are only valid in the front end
		let uiFields = ['formattedTitle', 'formattedTags', 'editIcon', 'editAction', 'deleteIcon', 'deleteAction', 'cssClasses'];
		for (let i = 0; i < uiFields.length; i++) {
			if (post.hasOwnProperty(uiFields[i])) {
				delete post[uiFields[i]];
			}
		}
		return post;
	}

	savePost(current_target) {
		if(!TrimInputFields('admin-manager-form')){
			return false;
		}
		let cookies = parse_cookies();
		let form_data = get_form_data(current_target);

		//NOTE: when editing existing posts there will be a post_id value set
		//when creating a new post there will not be a post_id value set
		//this is intentional and it allows us to distinguish those cases
		let post = this.state.editingPost;
		if (post === null) {
			post = new Post(null);
		}

		post.title = form_data['title'];
		post.category = form_data['category'];
		post.img_s3_path = this.state.thumbnail;
		post.s3_path = this.state.detailedPhoto;

		//NOTE: to generate the short description from the content we need to take out html tags.
		//because the WYSIWYG field allows them for content, but we can't allow them here
		//(if we did then they might have ending tags cut off due to the substring operation)
		let div_tmp = document.createElement('DIV');
		div_tmp.innerHTML = post.content;
		post.desc = div_tmp.innerText;

		//for the post object that goes over the network
		//we must remove any fields that are only valid in the front end
		//so do that here
		let netPost = this.stripPostUiFields(post);
		netPost.tags = this.state.selectedTags;
		netPost.tags = removeDuplicateTags(netPost.tags);
		netPost.category = this.state.selectedCategory;
		netPost['type'] = 'file';
		netPost.departments = this.state.selectedDepartments;
		netPost.landing_pages = this.state.selectedLandingPages;
		delete netPost.created_at;
		delete netPost.modified_at;
		delete netPost.published_at;
		let url = '/files';
		netPost.tags=removeDuplicateTags(netPost.tags);
		xml_send_info(
			url,
			JSON.stringify(netPost),
			xhr => {
				let data = JSON.parse(xhr.responseText)['File'];
				let postInfo = this.state.postInfo;

				//look through all existing posts to see if this is an update to a previously-existing post
				let idx = 0;
				for (idx = 0; idx < postInfo.length; idx++) {
					//if this is a match
					if (postInfo[idx]['post_id'] - 0 === data['post_id'] - 0) {
						//then update the existing data
						postInfo[idx] = data;
						//and stop looking
						break;
					}
				}
				//if we iterated through all existing posts and didn't find a matching id
				//then this is a new post and we should add it to the end of the list
				if (idx >= postInfo.length) {
					postInfo.push(data);
				}


				this.setState(
					{
						postInfo: postInfo,
						filteredPostInfo: postInfo,
						filteredTag: '',
						filteredCat: '',
						editPostOpen: false,
						editingPost: null,
						detailedPhotoObject:null,
						detailedPhoto:'',
						editorContent: '',
						selectedTags: [],
						selectedDepartments: [],
						selectedCategory: null,
						selectedLandingPages: []
					},
					() => {
						toast.success(SUCCESS_POST_SAVED);
					}
				);
			},
			post.hasOwnProperty('post_id') ? 'PUT' : 'POST',
			{ Authorization: cookies[SESSION_ID] },
			xhr => {
				this.setState({submitting:false});
				toast.error(ERROR_POST_NOT_SAVED + ' ' + TRY_AGAIN_LATER);
			}
		);
		return false;
	}

	deletePost(post) {
		let cookies = parse_cookies();

		//set the post to be invalid
		post.is_valid = false;

		//remove any UI fields from what we send over the network
		let netPost = this.stripPostUiFields(post);
		let request_delete_data = {
			file_id: netPost.file_id,
			post_id: netPost.post_id,
			is_valid: false
		};
		xml_send_info(
			'/files',
			JSON.stringify(request_delete_data),
			xhr => {
				let data = JSON.parse(xhr.responseText)['File'];
				let postInfo = this.state.postInfo;
				let newPostInfo = [];

				//look through all existing posts to see if this is an update to a previously-existing post
				let idx = 0;
				for (idx = 0; idx < postInfo.length; idx++) {
					//if this is a match
					if (postInfo[idx]['post_id'] - 0 === data['post_id'] - 0) {
						//then update the existing data
						postInfo[idx] = data;
					}
					//if this is invalid (as the new post should be if it saved correctly)
					//then don't include it in the new list
					if (postInfo[idx].is_valid === false) {
						continue;
					}
					newPostInfo.push(postInfo[idx]);
				}


				this.setState(
					{
						postInfo: newPostInfo,
						filteredPostInfo: newPostInfo,
						filteredTag: '',
						filteredCat: '',
						editPostOpen: false,
						editingPost: null,
						editorContent: ''
					},
					() => {
						toast.success(SUCCESS_POST_DELETED);
					}
				);
			},
			'PUT',
			{ Authorization: cookies[SESSION_ID] },
			xhr => {
				toast.error(ERROR_POST_NOT_SAVED + ' ' + TRY_AGAIN_LATER);
			}
		);
	}

	openEditForm(editingPost = null) {
		this.setState({
			editPostOpen: true,
			editingPost: editingPost,
			selectedLandingPages: editingPost?.landing_pages?editingPost?.landing_pages:[],
			editorContent: editingPost !== null ? editingPost.content : '',
			submitting:false
		});
	}

	closeEditForm() {
		this.setState({
			editPostOpen: false,
			editingPost: null,
			editorContent: '',
			selectedCategory: null,
			selectedDepartments: [],
			selectedTags: [],
			selectedLandingPages:[]
		});
	}

	uploadThumbnailError = err => {
		toast.error(GENERIC_UPLOAD_ERROR_MESSAGE + ' ' + TRY_AGAIN_LATER);
		this.setState({ uploadingThumbnail: false });
	};
	uploadDetailedPhotoError = err => {
		toast.error(GENERIC_UPLOAD_ERROR_MESSAGE + ' ' + TRY_AGAIN_LATER);
		this.setState({ uploadingDetailedPhoto: false });
	};
	setThumbnailSuccess = data => {
		let key = getAWSKey(data);
		this.setState({ thumbnail: key, uploadingThumbnail: false });
	};
	setDetailedPhotoSuccess = data => {
		let key = getAWSKey(data);
		this.setState({ detailedPhoto: key, uploadingDetailedPhoto: false });
	};
	saveThumbnail = file => {
		this.setState({ thumbnailObject: null, uploadingThumbnail: true }, () => this.aws.uploadFile(config.bucket_name, file.name, file, this.uploadThumbnailError, this.setThumbnailSuccess));
	};
	saveDetailedPhoto = file => {
		this.setState({ detailedPhotoObject: null, uploadingDetailedPhoto: true }, () => this.aws.uploadFile(config.bucket_name, file.name, file, this.uploadDetailedPhotoError, this.setDetailedPhotoSuccess));
	};
	onChangeDate = date => {
		let minutes = new Date(date);
		minutes = minutes.getMinutes();
		if (minutes < 1) {
			const minutesInput = document.getElementsByClassName('react-datetime-picker__inputGroup__minute')[0];
			minutesInput.value = 0;
		}
		this.setState({ date });
	};

	changeLandingPages=(value)=>{
		this.setState({ selectedLandingPages: value });
	}
	handleCreateLandingPage=(landingPage)=>{
		let landingPages = [...this.state.landingPages];
		let selectedLandingPages = [...this.state.selectedLandingPages];
		selectedLandingPages.push(landingPage);
		landingPages.push(landingPage);
		this.setState({ landingPages, selectedLandingPages });
	}

	changeDepartments = value => {
		this.setState({ selectedDepartments: value });
	};

	handleCreateCategory = category => {
		let categories = [...this.state.categories];
		categories.push(category);
		this.setState({ categories, selectedCategory: category });
	};

	changeCategory = value => {
		this.setState({ selectedCategory: value[value.length - 1] });
	};

	handleCreateDepartment = department => {
		let departments = [...this.state.departments];
		let selectedDepartments = [...this.state.selectedDepartments];
		selectedDepartments.push(department);
		departments.push(department);
		this.setState({ departments, selectedDepartments });
	};

	changeTags = value => {
		this.setState({ selectedTags: value });
	};
	handleCreateTag = tag => {
		let tags = [...this.state.tags];
		let selectedTags = [...this.state.selectedTags];
		selectedTags.push(tag);
		tags.push(tag);
		this.setState({ tags, selectedTags });
	};

	handleDropdownChange = event => {
		event.preventDefault();
		let inputs = { ...this.state.inputs };
		if (typeof event !== 'undefined') {
			inputs[event.currentTarget.id] = event.currentTarget.value;
			this.setState({
				inputs: inputs
			});
		}
	};

	removeFileCallback=()=>{
		this.setState({detailedPhoto:null});
	}

	renderEditForm() {
		let dflts =
			this.state.editingPost !== null
				? this.state.editingPost
				: {
						title: '',
						category: 'News',
						selectedTags: '',
						selectedDepartments: '',
						selectedCategory: ''
				  };
		let multiSelectCategory = [];
		if(this.state.selectedCategory){
			multiSelectCategory.push(this.state.selectedCategory);
		}
		return (
			<FlyoutMenu id="edit-post-flyout" menuOpen={this.state.editPostOpen} buttonText="New File" closeButtonText="Cancel" openMenu={this.openEditForm} closeMenu={this.closeEditForm} haveOpenButton={true}>
				{this.state.editPostOpen && (
					<form onSubmit={e=>{
						e.preventDefault();
						let current_target=e.currentTarget;
						this.setState({submitting:true},()=>{this.savePost(current_target);});
					}}id="admin-manager-form">
						<div className="grid-x grid-padding-x grid-padding-y">
							<div className="small-12 cell title-cell">
								<h2 className="flyout-title">{this.state.editingPost === null ? 'Create a New File' : 'Editing File ' + this.state.editingPost.title}</h2>
							</div>
							<div className="small-12 medium-8 large-7 cell content-cell">
								<div className="grid-x grid-margin-x grid-padding-y">
									<div className="small-12 medium-9 cell title-cell">
										<label htmlFor="input-title">Title</label>
										<input type="text" name="title" id="input-title" placeholder="..." required={true} defaultValue={dflts['title']} />
									</div>
									<div className="small-12 cell">
										<label htmlFor="input-tags">Tags</label>
										<Multiselect data={this.state.tags} value={this.state.selectedTags} onChange={this.changeTags} onCreate={tag => this.handleCreateTag(tag)} allowCreate="onFilter" textField="tags" placeholder="Select Tags..." />
									</div>

									<div className="cell small-12 medium-6 large-6">
										<label htmlFor="input-title">Category</label>
										<Multiselect
											data={this.state.categories}
											value={multiSelectCategory}
											onChange={this.changeCategory}
											onCreate={category => this.handleCreateCategory(category)}
											allowCreate="onFilter"
											textField="Category"
											placeholder="Select Category"
										/>
									</div>
									<div className="small-12 cell">
										<label htmlFor="input-tags">Departments</label>
										<Multiselect
											data={this.state.departments}
											value={this.state.selectedDepartments}
											onChange={this.changeDepartments}
											onCreate={department => this.handleCreateDepartment(department)}
											allowCreate="onFilter"
											textField="tags"
											placeholder="Select Departments..."
										/>
									</div>
									<div className="small-12 cell">
										<label htmlFor="input-tags">Landing Page</label>
										<Multiselect
											data={this.state.landingPages}
											value={this.state.selectedLandingPages}
											onChange={this.changeLandingPages}
											allowCreate={false}
											textField="landing page"
											placeholder="Select Landing Pages..."
										/>
									</div>

									<div className="small-12 cell">
										<input disabled={this.state.uploadingDetailedPhoto || this.state.submitting} className="show-for-medium" type="submit" value="Submit" />
									</div>
								</div>
							</div>
							<div className="small-12 medium-4 cell upload-cell">
								<div className="upload-widget-container">
									<UploadWidget
										fileCategory="File"
										acceptedFileTypes={ACCEPTED_FILE_TYPE_ARRAY}
										saveFile={this.saveDetailedPhoto}
										fileObject={this.state.detailedPhotoObject}
										idUniqueIdentifier={'detailed-photo-photobnail'}
										classIdentifier={'upload-detailed-photo'}
										fileId={'upload-detailed-photo'}
										uploading={this.state.uploadingDetailedPhoto}
										preview={this.state.detailedPhoto}
										removeCallback={this.removeFileCallback}
									/>
								</div>
								<input disabled={this.state.uploadingDetailedPhoto || this.state.submitting} className="hide-for-medium" type="submit" value="Submit" />
							</div>
						</div>
					</form>
				)}
			</FlyoutMenu>
		);
	}

	renderTableTitle() {
		return (
			<div className="grid-x grid-padding-x">
				<div className="small-12 medium-8 cell">
					<h1 className="table-title">File Manager</h1>
				</div>
				<div className="small-12 medium-4 cell">
					<div className="new-item-button-cont">{this.renderEditForm()}</div>
				</div>
			</div>
		);
	}

	render() {
		let tableData = this.genFormattedTableData();

		return (
			<div className="AdminPostManager">
				<FlexTable tableData={tableData} 
				tableTitle={this.renderTableTitle()}
				sort={(e, title) => updateSorting(e, title, this, table_enums.files)}
				/>
				
				<>{this.state.isLoading &&<LoadingIndicator isLoading={this.state.isLoading}/>}</>
				
				<PageController pageNumber={this.state.currentPage} setPageNumber={(page)=>{
					this.setState({currentPage:page});
				}} numPages={this.state.numPages} />
			</div>
		);
	}
}

export default AdminFileManager;