/*
 FieldText - the text display when no questions are added to a form or follow-up question
*/

import React from 'react';

/*
state (component-level states):
	title: the title to display - changes if this is a follow up question title
	subtitle: the subtitle to display
props (component-level arguments):
	follow_up_enabled: if true then this is a follow up question
*/
class FieldText extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			title: 'Please add some questions to your form',
			subtitle: 'Click the button below to get started. You will have a variety of question types you may choose from'
		};
	}

	componentDidMount() {
		if (this.props.follow_up_enabled) {
			this.setState({
				title: 'Please add some questions to your follow-up question.'
			});
		}
	}

	render() {
		return (
			<div className={'FieldText'}>
				<this.props.titleTag className="title">{this.state.title} </this.props.titleTag>
				<this.props.subtitleTag className="subtitle">{this.state.subtitle}</this.props.subtitleTag>
			</div>
		);
	}
}
FieldText.defaultProps = {
	titleTag: 'h3',
	subtitleTag:'h4'
};
export default FieldText;