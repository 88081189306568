import React, { useState } from 'react';
import { useEffect } from 'react';
import { getTags } from '../projlibs/HelperNetworkCalls';
import { Dropdown } from '../shared-components/src/components/Shared/Dropdown';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { LoadingIndicator } from './LoadingIndicator';

export const FormFilter = ({tagChangeCallback}) => {
	const [tags, setTags] = useState([]);
	const [curTag, setCurTag] = useState('');
	const [loading, setLoading] = useState(true);
	useEffect(()=>{
		tagChangeCallback(curTag);
	}, [curTag, tagChangeCallback]);
	useEffect(() => {
		getTags((data) => {
			let formattedValues = { '': 'No Filter' };
			data.forEach((element) => {
				formattedValues[element] = element;
			});
			setTags(formattedValues);
			setLoading(false);
		},(err) => {
			toast.error('Error grabbing form tags.');
			setLoading(false);
		},'form');
	}, []);
	return (
		<Wrapper>
			{loading?<LoadingIndicator isLoading={loading}/>:<>
			<Label>Tag Filter: </Label>
			<Dropdown
				key={'form-filter'}
				title={'Filter by tag'}
				options={tags}
				id={'form-filter'}
				defaultValue={curTag}
				onChange={(e) => {
					setCurTag(e.target.value);
				}}
			/>
			<button id='reset-button' className='reset-button' onClick={()=>{
				setCurTag('');
				const element=document.getElementById('form-filter');
				const select = element?.children[0]?.children[0];// avoid controlled/uncontrolled component warning
				if(select){
					select.value='';
				}
			}}><i className="fas fa-undo icon"></i><span className='button-text'>Reset Filters</span></button>
			</>}
		</Wrapper>
	);
};
const Label = styled.label`
	margin-bottom: 0;
	margin-right: 1rem;
	font-weight: 700;
	letter-spacing: 0.44px;
	font-size: 0.875rem;
	line-height: 1.2142857143;
	color: 778996;
`;
const Wrapper = styled.div`
	display:flex;
	align-items:center;
	padding: 8px;
	.reset-button{
		margin-left:0;
	}
	#form-filter{
		select {
			text-transform: capitalize;
			margin-bottom: 0;
			font-size: 1rem;
			width: fit-content;
			background-color: transparent;
			border: none;
			font-weight: 600;
			color: #55636F;
			padding-right: 3rem;
			background-position: right -2.5rem center;
			&:hover, &:active, &:focus {
				box-shadow: none;
			}
		}
			option {
				text-transform:capitalize;
			}
		}
	}
`;
