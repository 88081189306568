import moment from 'moment';
import {DAY_SUNDAY} from '../constants.js';

const WEEK_START_DAY=DAY_SUNDAY;

export function getFirstDayOfWeek(startDate=new Date()) {
	let moment_start_date=moment(startDate.toISOString());
	while(moment_start_date.day()>WEEK_START_DAY){
		moment_start_date.subtract(1,'days');
	}
	startDate=new Date(moment_start_date.toISOString());
	return Math.floor(startDate.getTime()/1000);
}
export function getFirstDayOfMonth(startDate=new Date()){
	return Math.floor(new Date(startDate.getFullYear(), startDate.getMonth(), 1).getTime()/1000);
}
export function getLastDayOfMonth(startDate=new Date()){
	return Math.floor(new Date(startDate.getFullYear(), startDate.getMonth()+1, 0).getTime()/1000.0);
}
export function getFirstDayOfNextMonth(startDate=new Date()){
	return Math.floor(new Date(startDate.getFullYear(), startDate.getMonth()+1, 1).getTime()/1000.0);
}
export function getLastDayOfNextMonth(startDate=new Date()){
	return Math.floor(new Date(startDate.getFullYear(), startDate.getMonth()+2, 0).getTime()/1000.0);
}

