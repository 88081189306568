import {ERROR_RETRIEVING_TAGS, TAG_URL, TRY_AGAIN_LATER} from "../../constants";
import {processError} from "../../projlibs/cookie-management";
import {toast} from "react-toastify";
import {fetch_info} from "./herdajax";

export function getTagsForPostType(post_type, successCallback, errorCallback = null) {
    if (errorCallback === undefined || errorCallback === null) {
        errorCallback = defaultErrorCallback;
    }

    fetch_info(TAG_URL + '?type=' + post_type, successCallback, errorCallback, {});
}

// Likely should be overridden.
export function defaultErrorCallback(error) {
    if (processError(error)) {
        return false;
    } else {
        toast.error(ERROR_RETRIEVING_TAGS + ' ' + TRY_AGAIN_LATER);
    }
}