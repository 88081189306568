//get generic information from the back-end
//this requires CORS to be configured on the back-end server
import {get_backend_host, xml_rqst_info, xml_fetch_info} from "../../react-utils/src/libajax";
import {AUTHORIZATION_HEADER, CONTENT_TYPE_HEADER, SESSION_ID, CONTENT_TYPE_HEADER_JSON} from "../../constants";
import {parse_cookies} from "../../react-utils/src/libformat";

export function fetch_info(path, have_data_callback, error_callback = function (xhr) {}, headers = {}, no_data_callback = function (xhr) {}, upload_progress_callback = function (e) {}) {
    let cookies = parse_cookies();
    if (!(AUTHORIZATION_HEADER in headers))
        headers[AUTHORIZATION_HEADER] = cookies[SESSION_ID];

    if (!(CONTENT_TYPE_HEADER in headers))
        headers[CONTENT_TYPE_HEADER] = CONTENT_TYPE_HEADER_JSON;

    return xml_fetch_info(path, have_data_callback, headers, error_callback, no_data_callback, upload_progress_callback, get_backend_host());
}

export function add_query_parameters(base_url, params) {
    let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    return base_url + '?' + queryString;
}

//send generic information to the back-end
//this requires CORS to be configured on the back-end server
export function xml_send_info(path, body_data, have_data_callback, method = 'POST', headers = {}, error_callback = function (xhr) {}, no_data_callback = function (xhr) {}, upload_progress_callback = function (e) {}) {
    return xml_rqst_info(path, have_data_callback, method, body_data, headers, error_callback, no_data_callback, upload_progress_callback, get_backend_host());
}