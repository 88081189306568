/*
Latest News - a feed of recent news summaries

A Latest News view consists of (from left to right and top to bottom)
	A title, directly in this component
	if news items exist
		One or more BriefView components; one for each news item
	if news items do not exist
		a "check back soon" type message

*/

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import BriefView from './BriefView/BriefView.jsx';
import { openAlreadySignedURLFile } from '../lib/libdownloadFiles.jsx';

const dummy_excerpt='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque et tellus vitae elit consectetur accumsan. Integer fermentum augue et massa dictum, eget ullamcorper ex feugiat. Integer eros ipsum, porta ac tempus ut, vehicula egestas turpis. Aenean sed maximus ipsum. Donec id egestas quam. Integer lectus sem, porttitor eu fermentum nec, mollis id magna. Curabitur libero eros, dapibus a facilisis in, euismod sed massa. ';

/*
props (component-level arguments):
	news_items: a list of news objects to be displayed as brief views
*/
class LatestNews extends React.Component {
	openPDF=(e,newsItem)=>{
		e.preventDefault();
		openAlreadySignedURLFile(newsItem.signed_PDF_link,newsItem.img_s3_path, newsItem.title);
	}
	render() {
		return (
			<div className='LatestNews'>
				<div className='header-row'>
					<h2>Latest News</h2>
					<div className='view-all-cont'>
						<Link to='/news' className='view-all-link'>View All</Link>
					</div>
				</div>
				{this.props.newsInfo.map((object, idx) =>{
					if(object.direct_file){
						return(
							<div key={idx} onClick={e=>this.openPDF(e,object)}>
								<BriefView aws_object={this.props.aws_object} object={object} display_type='regular' />
							</div>
						);
					}

					return (
						<Link to={`/news/${object.post_id}`} key={idx}>
							<BriefView aws_object={this.props.aws_object} object={object} display_type='regular' />
						</Link>
					);
				})}
			</div>
		);
	}
}

LatestNews.defaultProps={
	news_items:[],
};

LatestNews.propTypes={
	news_items:PropTypes.array,
};



export {LatestNews, dummy_excerpt};

