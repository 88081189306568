import RAAWSUtils from "../react-utils/src/RAAWSUtils";
import {parse_cookies} from "../react-utils/src/libformat";
import {
    AUTHORIZATION_HEADER,
    CONTENT_TYPE_HEADER,
    CONTENT_TYPE_HEADER_JSON,
    SESSION_ID,
    SHORT_ID_CHARACTERS
} from '../constants';
import config from '../config';

const shortid = require('shortid');
shortid.characters(SHORT_ID_CHARACTERS); //  remove _ because it was making it hard to parse

class HerdRAAWSUtils extends RAAWSUtils {

    // Override parent refreshToken() function which by default logs the user out.
    // We can provide a better implementation if we have knowledge about the application.
    // (which generic submodules should not)
    async handleCredentialRenewalError() {
        let cookies = parse_cookies();

        let url = config.backend_host + '/aws-refresh';
        let headers = {};
        headers[AUTHORIZATION_HEADER] = cookies[SESSION_ID];
        headers[CONTENT_TYPE_HEADER] = CONTENT_TYPE_HEADER_JSON;

        const refreshReq = await fetch(url, {headers: headers});
        const respJson = await refreshReq.json();
        this.setToken(respJson.AWSToken.Token);
        this.setIdentityId(respJson.AWSToken.IdentityId);

        // TODO: investigate further why this.getCredentials() fails but initAWSInstance does not.
        return await this.initAWSInstance(this.getIdentityId(), this.getToken(), config.aws_region_id, config.identity_pool_id);
    }

    uploadFile(bucketName, key, file, errorCallback, successCallback, flushing = false ){
        key = `1/company/1/files/${shortid.generate()}_${key}`;
        super.uploadFile(bucketName, key, file, errorCallback, successCallback, flushing);
    }
    uploadFileRawKey(bucketName, key, file, errorCallback, successCallback, flushing = false ){
        super.uploadFile(bucketName, key, file, errorCallback, successCallback, flushing);
    }
}

export default HerdRAAWSUtils;