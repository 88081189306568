/*

    QuestionContentOwner - a question that owns a variable amount of QuestionContentRow components

*/

import React from 'react';
import QuestionContentRow from './QuestionContentRow';

/*
props (component-level arguments):
    question_owner: the question that is being asked
    filtered_questions: a list of all questions that are not follow-up questions
    clickCallback: a clickcallback that selects an option once clicked
    questions: a list of all questions including follow-up questions
    question_idx: the index in questions of question_owner
*/
class QuestionContentOwner extends React.Component {
    constructor(props){
        super(props);
        this.state={
            rows:[],
            selected_row:null,
            selected_rows:[]//for multi-select
        };
    }


    componentDidMount(){
        if(this.props.viewingResults && this.props.results){
            let results = this.props.results;
            let multiSelect=this.props.question_owner.question_type==='multi_select';
            if(results){
                for(let i=0;i<this.props.question_owner.answer_options.length;i++){
                    for(let j=0,len=results.length;j<len;j++){
                        if(results[j] === this.props.question_owner.answer_options[i]){
                            if(multiSelect){
                                let selected_rows = this.state.selected_rows;
                                selected_rows.push(i);
                                this.setState({selected_rows});
                            }else{
                                this.setState({selected_row:i});
                            }
                        }
                    }
                }
            }
        }
    }

    clickRowHandler=(row_number,multi_select=false)=>{
        if(multi_select){
            let selected_rows=this.state.selected_rows;
            if(selected_rows.includes(row_number)){
                const indexOf=selected_rows.indexOf(row_number);
                selected_rows.splice(indexOf,1);
            }else{
                selected_rows.push(row_number);
            }
            this.setState({selected_rows});
        }else{
            this.setState({selected_row:row_number});
        }
    }

    render() {
        return(
            <div className={'QuestionContentOwner'}>
                {this.props.question_owner.answer_options.map((item,idx)=>{
                    const multi_select=this.props.question_owner.question_type==='multi_select';
                    let selected_rows='';
                    if(multi_select){
                        if(this.state.selected_rows.length>0){
                            selected_rows=this.state.selected_rows.join();
                        }
                    }
                    return(
                        <QuestionContentRow
                            key={`question-${this.props.question_owner.form_question_id}-display-${idx}`}
                            disabled={this.props.viewingResults===true}
                            filtered_questions={this.props.filtered_questions}
                            handleLabelInputChange={this.handleLabelInputChange}
                            clickCallback={this.clickRowHandler}
                            questionChangeCallback={this.props.clickCallback}
                            questions={this.props.questions}
                            question_owner={this.props.question_owner}
                            display_order={idx}
                            selected_row={multi_select?selected_rows:this.state.selected_row}
                            titleClass={'question-select'}
                            fieldClass={'question-select'}
                            title={item}
                            question_index={this.props.question_index}
                            multi_select={multi_select}
                        />
                    );
                })}
            </div>
        );
    }
}
export default QuestionContentOwner;