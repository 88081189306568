// these definitions aren't used in this file
// they are defined here only for use in other files
// therefore the eslint no-unused-vars warning does not make sense here

/* eslint-disable no-unused-vars */

//unit conversion constants
// aws strings
export const GENERIC_UPLOAD_ERROR_MESSAGE = 'There was an error uploading to Amazon Web Services.';
export const AWS_REFRESH_ERROR='There was an error refreshing your AWS credentials';
//conversion constants
export const MS_PER_SECOND=1000;
export const SECONDS_PER_MINUTE=60;
export const MINUTES_PER_HOUR=60;
export const HOURS_PER_DAY=24;
export const DAYS_PER_WEEK=7;
export const DAYS_PER_MONTH=30;

export const MAX_SUMMARY_LENGTH=128;
export const MAX_RECENT_MESSAGE_LENGTH=40;
//datetime constants
export const DAY_SUNDAY=0;
export const DAY_MONDAY=1;
export const DAY_TUESDAY=2;
export const DAY_WEDNESDAY=3;
export const DAY_THURSDAY=4;
export const DAY_FRIDAY=5;
export const DAY_SATURDAY=6;
export const DAY_NAMES=[
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
];
export const DAY_ABBREVIATIONS=[
	'S',
	'M',
	'T',
	'W',
	'T',
	'F',
	'S',
];
export const MONTH_NAMES=[
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

export const MAX_FEATURED_COMPANIES = 8;

export const INLINE_IMAGE_CLASSNAME='custom-inline-image';
// window size constants
export const BREAKPOINT_LARGE = 1024;

// COOKIE STRINGS
export const AWS_IDENTITY_ID = 'AWSToken.IdentityId';
export const AWS_TOKEN = 'AWSToken.Token';
export const SESSION_ID = 'session_id';
export const USER_ID = 'user_id';
export const EMAIL = 'email';
export const FIRST_NAME = 'first_name';
export const LAST_NAME = 'last_name';
export const LOCATION_NAME = 'location_name';
export const IS_ADMIN = 'is_admin';
export const DEPARTMENT = 'department';
export const SENDBIRD_SESSION_TOKEN = 'sendbird_session_token';
export const SENDBIRD_SESSION_EXPIRES_AT = 'sendbird_session_expires_at';
export const SENDBIRD_USER_ID = 'sendbird_user_id';
export const USER_IMAGE = 'photo_s3_path';

//profile strings
export const SAVE_AVATAR_SUCCESS='Succesfully updated avatar.';
export const SAVE_AVATAR_FAIL='Error: could not update avatar.';
// reset password strings
export const MIN_PASSWORD_LENGTH=10;
export const ERROR_PASSWORDS_DONT_MATCH='Error: Password values must match.';
export const PASSWORD_TOO_SHORT=`Password must be at least ${MIN_PASSWORD_LENGTH} characters.`;
export const INVALID_RESET_LINK='This reset link is no longer valid. Please use the most recent reset password link.';
export const SUCCESSFULLY_CHANGED_PASSWORD ='Succesfully changed password, redirecting to login page...';
export const RESET_PASS_EMAIL_SENT='An email has been sent with instructions on how to reset your password.';
export const ACCOUNT_DISABLED='Your account is not active.  Please ask your administrator to enable your account.';
export const NEW_PASSWORD_INVALID='Password does not meet minimum requirements.';
export const EMAIL_DOES_NOT_EXIST='This email is not associated with any account, please enter a different email.';
// GENERIC STRINGS
export const ERROR_NOT_LOGGED_IN = 'Please sign in.';
export const ERROR_LOAD_FAILURE = 'Some data could not be loaded.';
export const RESET_PASS_ENDPOINT = 'reset';

// this is usually going to be used when server is not working as intended
export const TRY_AGAIN_LATER = 'Please try again later.';

// DASHBOARD STRINGS
export const ERROR_RETRIEVING_NEWS = 'Error: cannot retrieve news.';
export const ERROR_RETRIEVING_POSTS = 'Error: cannot retrieve posts.';

// EVENTS STRINGS
export const ERROR_RETRIEVING_EVENTS = 'Error: cannot retrieve events.';

// FILES STRINGS
export const ERROR_RETRIEVING_FILES = 'Error: cannot retrieve files.';
export const MAX_FILES_IN_CONTAIER = 5;

// TAGS STRINGS
export const ERROR_RETRIEVING_TAGS = 'Error: cannot retrieve tags.';

// CATEGORY STRINGS
export const ERROR_RETRIEVING_CATEGORIES = 'Error: cannot retrieve categories.';

// POSITION STRINGS
export const ERROR_RETRIEVING_POSITIONS = 'Error: cannot retrieve positions.';

// DEPARTMENT STRINGS
export const ERROR_RETRIEVING_DEPARTMENTS = 'Error: cannot retrieve departments.';

// DIRECTORY STRINGS
export const ERROR_RETRIEVING_DIRECTORY = 'Error: cannot retrieve directory.';
export const ERROR_RETRIEVING_EMPLOYEES = 'Error: cannot retrieve employee information.';
export const EMPLOYEE_INFORMATION = 'Employee Information';
export const EMPLOYEE_FORM_RESULTS = 'View Form Results';
export const ERROR_RETRIEVING_LOCATIONS = 'Error: cannot retrieve location information.';
export const ERROR_RETRIEVING_COMPANY = 'Error: cannot retrieve company information.';

// QUICKLINK STRINGS
export const ERROR_RETRIEVING_LINKS = 'Error: cannot retrieve quick links.';


// SESSION STRINGS
export const ERROR_SESSION_EXPIRED = 'Session invalid. Please sign in.';
export const ERROR_INVALID_USER_OR_PASS = 'Invalid username or password. Please try again.';
export const ERROR_SIGNING_IN = 'Error signing in. Please try again later.';

// POST EDITOR STRINGS
export const ERROR_POST_NOT_SAVED = 'Your Post could not be saved at this time.';
export const SUCCESS_POST_SAVED = 'Your Post was saved!';
export const SUCCESS_POST_DELETED = 'The Post was deleted.';
export const ERROR_SAME_EMAIL = 'Error: This email already belongs to an employee. Please use a unique email for every employee.';

// USER EDITOR STRINGS
export const ERROR_USER_NOT_SAVED = 'The User could not be saved at this time.';
export const SUCCESS_USER_SAVED = 'The User was saved!';
export const SUCCESS_USER_DELETED = 'The User was deleted.';

// LOCATION EDITOR STRINGS
export const ERROR_LOCATION_NOT_SAVED = 'The Location could not be saved at this time.';
export const SUCCESS_LOCATION_SAVED = 'The Location was saved!';
export const SUCCESS_LOCATION_DELETED = 'The Location was deleted.';

// FORM MANAGER STRINGS
export const ERROR_RETRIEVING_FORMS = 'Error retrieving forms from database.';
export const SUCCESFULLY_UPDATED_FORM_EMAIL='Successfully updated form.';
export const ERROR_UPDATING_FORM_EMAIL='There was an error updating form.';
export const ERROR_DELETING_FORM = 'Error deleting form.';

// COMPANY EDITOR STRINGS
export const ERROR_COMPANY_NOT_SAVED = 'The Company could not be saved at this time.';
export const SUCCESS_COMPANY_SAVED = 'The Company was saved!';
export const SUCCESS_COMPANY_DELETED = 'The Company was deleted.';

// CHAT VIEW STRINGS
export const ERROR_MESSAGE_NOT_SENT = 'The message could not be sent. '+TRY_AGAIN_LATER;
export const ERROR_INVALID_CHAT_USER_ID = 'This user does not have a valid chat user id, and so a chat cannot be made for them.';
export const CHAT_EXPIRY_TIME_MS =  SECONDS_PER_MINUTE * MINUTES_PER_HOUR * HOURS_PER_DAY * MS_PER_SECOND;

// QUICK_LINK EDITOR STRINGS
export const ERROR_QUICK_LINK_NOT_SAVED = 'Your Quick Link could not be saved at this time.';
export const SUCCESS_QUICK_LINK_SAVED = 'Your Quick Link was saved!';
export const SUCCESS_QUICK_LINK_DELETED = 'The Quick Link was deleted.';

// NAV STRINGS
export const PATH_NEWS = /^\/news(?:\/[0-9]*)?$/g;
export const PATH_EVENTS = /^\/events(?:\/[0-9]*)?$/g;
export const PATH_DOWNLOADS = /^\/downloads(?:\/[0-9]*)?$/g;
export const PATH_DOWNLOADS_HR = '/downloads/HR/?';
export const PATH_DOWNLOADS_ACCOUNTING = '/downloads/Accounting/?';
export const PATH_DOWNLOADS_TRAINING_DEVELOPMENT = '/downloads/Training & Development/?';
export const PATH_DOWNLOADS_HEALTH_DEVELOPMENT = '/downloads/Health & Safety/?';
export const PATH_DOWNLOADS_COMMUNICATIONS = '/downloads/Communications/?';
export const PATH_DOWNLOADS_IT = '/downloads/IT/?';
export const PATH_EMPLOYEE_SINGLE_DIRECTORY = /^.*\/company-directory\/employee\/?.*?$/g;
export const PATH_EMPLOYEE_DIRECTORY = '/company-directory/employee/';
export const PATH_LOCATION_SINGLE_DIRECTORY = /^.*\/company-directory\/location\/?.*?$/g;
export const PATH_LOCATION_DIRECTORY = '/company-directory/location/';
export const PATH_COMPANY_SINGLE_DIRECTORY = /^.*\/company-directory\/company\/?.*?$/g;
export const PATH_COMPANY_DIRECTORY = '/company-directory/company/';
export const PATH_SEARCH = '/search/';
export const PATH_CHAT = /\/chat\/?/g;
export const PATH_FORMS = /^\/forms\/?/g;
export const PATH_ADMIN = /\/admin\/?$/g;

export const NAV_CLIPS_CALCULATOR = 'clips';
export const NAV_NEWS='news';
export const NAV_EVENTS='events';
export const NAV_DOWNLOADS='downloads';
export const NAV_ADMIN='admin';

export const NAV_COMPANY_DIR='company-directory';
export const NAV_LOGIN='login';
export const NAV_RESET='reset';
export const NAV_CHAT='chat';
export const NAV_FORMS = 'forms';
export const NAV_QUICK_LINK='quick-links';

export const PATH_ADMIN_DIR_MANAGER_EMPLOYEE = '/admin/company-directory/employee';
export const PATH_ADMIN_DIR_MANAGER_LOCATION = '/admin/company-directory/location';
export const PATH_ADMIN_DIR_MANAGER_COMPANY = '/admin/company-directory/company';
export const PATH_ADMIN_DIR_MANAGER_EMPLOYEE_REGEX = /^\/admin\/company-directory\/employee(?:\/[0-9]*)?$/g;
export const PATH_ADMIN_DIR_MANAGER_LOCATION_REGEX = /^\/admin\/company-directory\/location(?:\/[0-9]*)?$/g;
export const PATH_ADMIN_DIR_MANAGER_COMPANY_REGEX = /^\/admin\/company-directory\/company(?:\/[0-9]*)?$/g;
export const PATH_ADMIN_NEWS_MANAGER = /^\/admin\/news(?:\/[0-9]*)?$/g;
export const PATH_ADMIN_EVENTS_MANAGER = /^\/admin\/events(?:\/[0-9]*)?$/g;
export const PATH_ADMIN_FILE_MANAGER = /^\/admin\/downloads(?:\/[0-9]*)?$/g;
export const PATH_ADMIN_FORM_MANAGER = /^\/admin\/forms(?:\/[0-9]*)?$/g;
export const PATH_ADMIN_QUICK_LINK_MANAGER = '/admin/quick-links/';

export const QUICK_LINK_DATA = [
	{title: 'ADP Workforce', link: 'https://workforcenow.adp.com/workforcenow/login.html'},
	{title: 'Manulife', link: 'https://www.manulife.ca/personal/sign-in.html'},
	{title: 'Website', link: 'https://www.mcfarlanrowlands.com/'},
	{title: 'MyMcFarlan', link: 'https://www.portal.yovu.ca/portal'}
];
export const UPLOAD_IMAGE_FILE_TYPES = ['image/jpeg', 'image/svg+xml', 'image/png'];
export const UPLOAD_PDF_FILE_TYPE = ['application/pdf'];
export const FILE_TYPE_SHORTNAMES = {
	'image/jpeg': 'JPG',
	'image/png': 'PNG',
	'application/pdf': 'PDF',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
	'application/msword': 'DOC',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
	'application/vnd.ms-excel': 'XLS',
	'image/svg+xml' : 'SVG/XML',
	'application/vnd.ms-powerpoint': 'PPT',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
	'application/postscript': 'EPS'
};


export const ACCEPTED_FILE_TYPE_ARRAY = ['image/jpeg', 'image/png', 'image/svg+xml', 'application/pdf', 'application/vnd.ms-excel','application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/postscript'];
// Headers
export const AUTHORIZATION_HEADER = 'Authorization';
export const CONTENT_TYPE_HEADER = 'Content-Type';
export const CONTENT_TYPE_HEADER_JSON = 'Application/json';

// ENDPOINTS
export const TAG_URL = '/tags';
export const CAT_URL = '/categories';
export const EVENT_URL = '/events';
export const DEPARTMENT_URL = '/departments';
export const FORM_URL = '/form';

// POST TYPES
export const POST_TYPE_NEWS = 'post';
export const POST_TYPE_EVENTS = 'event';
export const POST_TYPE_FILES = 'file';
export const POST_TYPE_LOCATION = 'location';

// SEARCH PARAMS
export const SEARCH_TABLE_POST = 'Post';
export const SEARCH_TABLE_EVENT = 'Event';
export const SEARCH_TABLE_USER = 'User';
export const SEARCH_TABLE_LOCATION = 'Location';
export const SEARCH_TABLE_COMPANY = 'Company';
export const SEARCH_CATEGORY_NEWS = 'News';
export const SEARCH_CATEGORY_EVENT = 'Event';
export const SEARCH_CATEGORY_FILE = 'File';

export const SEARCH_PLACEHOLDER_NEWS = 'news';
export const SEARCH_PLACEHOLDER_FILES = 'files & downloads';
export const SEARCH_PLACEHOLDER_EMPLOYEES = 'employees';
export const SEARCH_PLACEHOLDER_LOCATION = 'branch locations';
export const SEARCH_PLACEHOLDER_COMPANIES = 'companies';
export const SEARCH_PLACEHOLDER_CHAT = 'chat';
export const SEARCH_PLACEHOLDER_EVERYTHING = 'news, events, and files';

// department strings - used for searching
export const DEPARTMENT_HR = 'HR';
export const DEPARTMENT_HEALTH_SAFETY = 'Health & Safety';
export const DEPARTMENT_TRAINING_DEVELOPMENT = 'Training & Development';
export const DEPARTMENT_RECEPTION = 'Reception';
export const DEPARTMENT_COMMUNICATIONS = 'Communications';
export const DEPARTMENT_ACCOUNTING = 'Accounting';
export const DEPARTMENT_IT = 'IT';
export const DEPARTMENT_GROUP_OPERATIONS = 'Group Operations';
export const DEPARTMENT_CLAIMS = 'Claims';
export const DEPARTMENT_PERSONAL_LINES = 'Personal Lines';
export const DEPARTMENT_COMMERCIAL_LINES = 'Commercial Lines';
export const DEPARTMENT_LIFE = 'Life';

// FILTER STRINGS
export const FILTER_DEPARTMENT = 'department';

//tiny mce strings
export const TINY_MCE_NAME = 'content';
export const TINY_MCE_ID = 'input-content';
export const TINY_MCE_BASE_PLUGINS = 'link image advcode powerpaste media mediaembed formatpainter';

// short id strings
export const SHORT_ID_CHARACTERS='0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!-';

export const LANDING_PAGES = [
	'HR',
	'Health & Safety',
	'Training & Development',
	'Accounting',
	'Communications',
	'IT'
];

export const QUESTION_TYPE_FILE_UPLOAD = 'file_upload';
