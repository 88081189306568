import React from 'react';
import { InputFieldBlock } from '../../../../BasicInputs/InputFieldBlock';
import { ListOfOptions } from './ListOfOptions';
export const OptionsQuestion = ({
	questions,
	question,
	questionIndex,
	isFollowUp,
	removeQuestion,
}) => (
	<div className={`FieldQuestionLabel single-select ${isFollowUp?'follow-up':''}`}>
		<div className="field-label-content text">
			<div className="field-label-title">
				{formatFieldType(question.question_type)}
				<i className="fas fa-list textarea"> </i>
			</div>
			<InputFieldBlock
				title="Question Title"
				fieldRequired={true}
				fieldType="text"
				fieldName={`select-question-${questionIndex}-options`}
				callback={(e) => (question.title = e.currentTarget.value)}
				fieldValue={question.title}
				fieldHint={'Enter the title of this question...'}
			/>
			<ListOfOptions
				questions={questions}
				isFollowUp={isFollowUp}
				question={question}
				questionIndex={questionIndex}
			/>
		</div>
		<div className="fa-label-container">
			<i
				className="far fa-trash-alt"
				onClick={() => removeQuestion()}
			></i>
		</div>
	</div>
);

function formatFieldType(fieldType) {
	switch (fieldType) {
		case 'single_select':
			return 'single-select';
		case 'multi_select':
			return 'multi-select';
		default:
			return fieldType;
	}
}
