/*

AdminCompanyManager - the admin page for viewing, modifying, creating, and deleting company information. upon creation of a company a location is created as well.
	but this location does not display in branch locations because it is not an Intranet company
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { get_form_data } from '../../../projlibs/libform.jsx';
import { xml_fetch_info, xml_send_info } from '../../../react-utils/src/libajax.js';
import config from '../../../config';
import { parse_cookies } from '../../../react-utils/src/libformat.js';
import { ERROR_RETRIEVING_COMPANY, ERROR_COMPANY_NOT_SAVED, SUCCESS_COMPANY_SAVED, TRY_AGAIN_LATER, SESSION_ID, GENERIC_UPLOAD_ERROR_MESSAGE, UPLOAD_IMAGE_FILE_TYPES } from '../../../constants.js';

import { processError } from '../../../projlibs/cookie-management.js';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import FlyoutMenu from '../../FlyoutMenu.jsx';
import FlexTable from '../../FlexTable.jsx';
import S3Image from '../../S3Image.jsx';
import AdminManagerAbstract from '../AdminManagerAbstract.jsx';
import { getIndustries } from '../../../projlibs/HelperNetworkCalls.js';
import UploadWidget from '../../UploadWidget/UploadWidget.jsx';
import InputDropdownBlock from '../../BasicInputs/InputDropdownBlock.jsx';
import { TrimInputFields } from '../../../projlibs/HelperFunctions.js';
import HerdRAAWSUtils from '../../../projlibs/HerdRAAWSUtils';
import { TINY_MCE_BASE_PLUGINS, PATH_SEARCH } from '../../../constants';
import { getAWSKey } from '../../../projlibs/HelperFunctions';
import { LoadingIndicator } from '../../LoadingIndicator';
import { PageController } from '../../PageController';
import { table_enums, updateSorting } from '../../../projlibs/pagination-functions.js';
const IMG_PLACEHOLDER = require('../../../assets/icon_image.svg');

const CLIENTS_TABLE_HEADERS = [
	{ title: 'Company Name', field: 'formatted_name' },
	{ title: 'Address', field: 'formatted_address', sortable: false },
	{ title: 'Phone #', field: 'phone', sortable: false },
	{ title: 'Industry', field: 'industry', sortable: false },
	{ title: '', field: 'editIcon', onClick: 'editAction', sortable: false },
	{ title: '', field: 'deleteIcon', onClick: 'deleteAction', sortable: false }
];

/*
props (component-level arguments):
	positions: a list of positions for filtering purposes
	match: url path information for path-based routing arguments

state (component-level globals):
	table_rows: an ordered list of data for display in a FlexTable
	filtered_city: the city to filter on
	edit_view_open, data_to_edit: see AdminManagerAbstract.jsx
*/
class AdminCompanyManager extends AdminManagerAbstract {
	constructor(props) {
		super(props);
		this.state = {
			table_rows: [],
			filtered_city: '',
			edit_view_open: false,
			data_to_edit: null,
			editorContent: '',
			uploadingDetailedPhoto: null,
			detailedPhotoObject: null,
			detailedPhoto: null,
			formattedIndustries: [],
			company_id: '',
			submitting: false,
			inputs: {
				industry: '',
			},
			is_featured:false,
			numPages:1,
			currentPage: 0,
			isLoading:true,
			order_by:'&order_by=created_at&order_dir=desc'
		};
		this.aws = new HerdRAAWSUtils();

		this.getFilteredCompanyInfo = this.getFilteredCompanyInfo.bind(this);
		this.formatCompanyInfo = this.formatCompanyInfo.bind(this);
		this.netCallSuccess = this.netCallSuccess.bind(this);
		this.netCallFailure = this.netCallFailure.bind(this);
		this.netActionSuccess = this.netActionSuccess.bind(this);
		this.netActionFailure = this.netActionFailure.bind(this);

		this.stripLocUiFields = this.stripLocUiFields.bind(this);
		this.saveItem = this.saveItem.bind(this);
		this.deleteLoc = this.deleteLoc.bind(this);

		this.renderEditForm = this.renderEditForm.bind(this);
		this.renderTableTitle = this.renderTableTitle.bind(this);
		this.renderImageLoader = this.renderImageLoader.bind(this);
		this.resetFormState=this.resetFormState.bind(this);
	}

	componentDidMount() {
		getIndustries( (res)=>{this.setState({formattedIndustries:res});}, this.failGetTags);
	}
	
	getDetailedPhotoObjectSuccess = obj => {
		this.setState({ detailedPhotoObject: obj });
	};
	
	getObjectFail = err => {
		console.log(err);
	};

	resetFormState(){
		let inputs=this.state.inputs;
		inputs['industry'] = '';
		this.setState({
			filtered_city: '',
			edit_view_open: false,
			data_to_edit: null,
			editorContent: '',
			uploadingDetailedPhoto: null,
			detailedPhotoObject: null,
			detailedPhoto: null,
			inputs: inputs,
			is_featured:false
		});
	}

	componentDidUpdate(prevProps, prevState) {

		if(prevState.company_id !== prevProps.match.params.company_id) {
			this.setState({
				company_id: prevProps.match.params.company_id
			}, () => this.getFilteredCompanyInfo());
		}
		if(prevState.currentPage !== this.state.currentPage || prevState.order_by !== this.state.order_by){
			this.getFilteredCompanyInfo();
		}

		//then editing a new post
		if (this.state.data_to_edit && prevState.data_to_edit !== this.state.data_to_edit) {
			let inputs = this.state.inputs;
			inputs['industry']=this.state.data_to_edit.industry;
			this.setState({
				detailedPhoto: this.state.data_to_edit.photo_s3_path,
				editorContent: this.state.data_to_edit.description,
				is_featured: this.state.data_to_edit.is_featured,
				inputs: inputs
			});
			this.aws.getObject(config.bucket_name, this.state.data_to_edit.photo_s3_path, this.getObjectFail, this.getDetailedPhotoObjectSuccess);
		} else if (!this.state.data_to_edit && prevState.data_to_edit) {
			this.resetFormState();
		}
	}

	failGetTags = error => {
		toast.error(error);
	};
	formatCompanyInfo(loc_info) {
		loc_info['formatted_name'] = this.renderImageLoader(loc_info.photo_s3_path, loc_info.name);
		loc_info['formatted_address'] = this.formatAddress(loc_info);
		loc_info['editIcon'] = 'fas fa-edit';
		loc_info['deleteIcon'] = 'fas fa-trash';
		loc_info['editAction'] = function() {
			this.openEditForm(loc_info);
		}.bind(this);
		loc_info['deleteAction'] = function() {
			this.deleteLoc(loc_info);
		}.bind(this);
		loc_info['cssClasses'] = {
			formatted_name: 'name title title-circle-thumbnail',
			editIcon: 'circle-border action-button secondary-color-icon',
			deleteIcon: 'action-button',
			status: 'status-cell current'
		};

		return loc_info;
	}

	formatAddress(directoryItem) {
		if(directoryItem.address) {
			let formattedAddress = [directoryItem.address, directoryItem.city, directoryItem.state_prov, directoryItem.postal_code, directoryItem.country].filter(elm=>(elm !== null && elm !== undefined && elm !== '')).join(', ');
			return formattedAddress;
		}

		return '';
	}

	

	getFilteredCompanyInfo() {
		const cookies = parse_cookies();

		let url_path = PATH_SEARCH + '?table=Company&company_type=vendor';
		url_path+=`&page=${this.state.currentPage}&page_size=0&${this.state.order_by}`;
		this.setState({isLoading:true}, ()=>{
			xml_fetch_info(
				url_path,
				this.netCallSuccess,
				{
					'Content-Type': 'application/json',
					Authorization: cookies[SESSION_ID]
				},
				this.netCallFailure
			);
		});

	}

	handleWYSIWYGInputChange = (content, editor) => {
		this.setState({
			editorContent: content
		});
	};

	netCallSuccess(xhr) {
		this.setState({isLoading:false});
		const response = JSON.parse(xhr.response);
		let info = [];
		if (response.hasOwnProperty('Company')) {
			let directory_info = response.Company;
			if(this.state.company_id) {
				directory_info = directory_info.filter(data => data.company_id === parseInt(this.state.company_id));
			}
			for (let parent_idx = 0; parent_idx < directory_info.length; parent_idx++) {
				const directoryParent = directory_info[parent_idx];
				if (directoryParent.valid === false) {
					continue;
				}
				const directoryChildren = directoryParent.locations;
				for (let child_idx = 0; child_idx < directoryChildren.length; child_idx++) {
					const directoryItem = directoryChildren[child_idx];
					if (!directoryChildren[child_idx].is_primary) {
						continue;
					}
					// this is taken from the company directory on the dashboard and because of how locations are set up.
					const companyInfo = {
						name: directoryItem.name,
						photo_s3_path: directoryItem.photo_s3_path,
						address: directoryItem.address,
						phone: directoryItem.contact_phone,
						email: directoryItem.contact_email,
						phone_fax: directoryItem.phone_fax,
						public_website: directoryParent.public_website,
						portal_url: directoryParent.portal_url,
						description: directoryParent.desc,
						supplyType: directoryParent.supply_type,
						city: directoryItem.city,
						state_prov: directoryItem.state_prov,
						postal_code: directoryItem.postal_code,
						contact_name: directoryItem.contact_name,
						company_id: directoryParent.company_id,
						valid: directoryParent.valid,
						industry: directoryParent.industry,
						is_featured: directoryParent.is_featured
					};
					if (companyInfo.valid) {
						info.push(this.formatCompanyInfo(companyInfo));
					}
				}
			}
			
			this.setState({
				table_rows: info,
				numPages: response.page_count
			},()=>this.resetFormState());
		} else {
			this.setState({
				table_rows: [],
				numPages: response.page_count
			});
		}
	}

	uploadDetailedPhotoError = err => {
		toast.error(GENERIC_UPLOAD_ERROR_MESSAGE + ' ' + TRY_AGAIN_LATER);
		this.setState({ uploadingDetailedPhoto: false });
	};
	setDetailedPhotoSuccess = data => {
		let key = getAWSKey(data);
		this.setState({ detailedPhoto: key, uploadingDetailedPhoto: false });
	};

	saveDetailedPhoto = file => {
		this.setState({ detailedPhotoObject: null, uploadingDetailedPhoto: true }, () => this.aws.uploadFile(config.bucket_name, file.name, file, this.uploadDetailedPhotoError, this.setDetailedPhotoSuccess));
	};
	netCallFailure(error) {
		this.setState({isLoading:false});
		if (processError(error)) {
			return false;
		} else {
			toast.error(ERROR_RETRIEVING_COMPANY + ' ' + TRY_AGAIN_LATER);
		}
	}

	netActionSuccess(xhr) {
		let data = this.rebuildCompany(xhr);
		let table_rows = this.state.table_rows;
		//look through all existing users to see if this is an update to a previously-existing user
		let idx = 0;
		for (idx = 0; idx < table_rows.length; idx++) {
			//if this is a match
			if (table_rows[idx]['company_id'] - 0 === data['company_id'] - 0) {
				//then update the existing data
				if (data.valid === false) {
					table_rows.splice(idx, 1);
					continue;
				}
				table_rows[idx] = this.formatCompanyInfo(data);
				//and stop looking
				break;
			}
		}
		//if we iterated through all existing posts and didn't find a matching id
		//then this is a new user and we should add it to the end of the list
		if (idx >= table_rows.length) {
			if (data.valid === true || data.valid === undefined) {
				table_rows.push(this.formatCompanyInfo(data));
			}
		}

		//sort by date, newest first
		table_rows.sort((a, b) => (a['modified_at'] < b['modified_at'] ? 1 : a['modified_at'] > b['modified_at'] ? -1 : 0));

		this.resetFormState();
		toast.success(SUCCESS_COMPANY_SAVED);
	}

	netActionFailure(xhr) {
		this.setState({submitting: false});
		toast.error(ERROR_COMPANY_NOT_SAVED + ' ' + TRY_AGAIN_LATER);
	}

	stripLocUiFields(user) {
		//remove any fields that are only valid in the front end
		let uiFields = ['formatted_name', 'formatted_address', 'editIcon', 'editAction', 'deleteIcon', 'deleteAction', 'cssClasses'];
		for (let i = 0; i < uiFields.length; i++) {
			if (user.hasOwnProperty(uiFields[i])) {
				delete user[uiFields[i]];
			}
		}
		return user;
	}

	saveItem(current_target) {
		if(!TrimInputFields('admin-manager-form')){
			return false;
		}
		let cookies = parse_cookies();

		let form_data = get_form_data(current_target);

		//NOTE: when editing existing locationss there will be a company_id value set
		//when creating a new user there will not be a user_id value set
		//this is intentional and it allows us to distinguish those cases
		let item = this.state.data_to_edit;
		if (item === null) {
			item = {};
		}

		item.name = form_data['name'];
		item.address = form_data['address'];
		item.industry = this.state.inputs.industry;
		item.status = 'Current';
		item.phone = form_data['phone'];
		item.email = '';
		item.contact_name = '';
		item.postal_code = form_data['postal_code'];
		item.state_prov = form_data['state_prov'];
		item.city = form_data['city'];
		item.manager_name = form_data['manager_name'];
		item.company_type = 'vendor';
		item.public_website = form_data['public_website'];
		item.portal_url = form_data['portal_url'];
		item.phone_fax = form_data['phone_fax'];
		item.is_featured = this.state.is_featured;
		//for the user object that goes over the network
		//we must remove any fields that are only valid in the front end
		//so do that here
		let netItem = this.stripLocUiFields(item);
		delete netItem.created_at;
		delete netItem.modified_at;
		netItem.desc = this.state.editorContent;

		if (this.state.detailedPhoto) {
			netItem.photo_s3_path = this.state.detailedPhoto;
		}
		xml_send_info('/company', JSON.stringify(netItem), this.getFilteredCompanyInfo, netItem.hasOwnProperty('company_id') ? 'PUT' : 'POST', { Authorization: cookies[SESSION_ID] }, this.netActionFailure);

		return false;
	}

	deleteLoc(company) {
		let cookies = parse_cookies();
		let data = {
			company_id: company.company_id,
			valid: false
		};
		xml_send_info('/company', JSON.stringify(data), this.getFilteredCompanyInfo, 'PUT', { Authorization: cookies[SESSION_ID] }, this.netActionFailure);
	}

	handleDropdownChange = event => {
		event.preventDefault();
		let inputs = { ...this.state.inputs };
		if (typeof event !== 'undefined') {
			inputs[event.currentTarget.id] = event.currentTarget.value;
			this.setState({
				inputs: inputs
			});
		}
	};

	closeEditForm(){
		this.resetFormState();
	}
	
	handleFeaturedCheck=()=>{
		this.setState({is_featured: !this.state.is_featured});
	}

	renderEditForm() {
		let dflts =
			this.state.data_to_edit !== null
				? this.state.data_to_edit
				: {
						name: '',
						address: '',
						city: '',
						state_prov: '',
						postal_code: '',
						phone: '',
						public_website:'',
						email: '',
						contact_name: ''
				  };
		return (
			<FlyoutMenu id="edit-user-flyout" menuOpen={this.state.edit_view_open} buttonText="New Company" closeButtonText="Cancel" openMenu={this.openEditForm} closeMenu={this.closeEditForm} haveOpenButton={true}>
				{this.state.edit_view_open && (
					<form onSubmit={e=>{
						e.preventDefault();
						let current_target=e.currentTarget;
						this.setState({submitting: true},()=>{this.saveItem(current_target);});
					}}id="admin-manager-form">
						<div className="grid-x grid-padding-x grid-padding-y">
							<div className="small-12 cell">
								<h2 className="flyout-title">{this.state.data_to_edit === null ? 'Create a New Company' : 'Editing Company ' + this.state.data_to_edit.name}</h2>
							</div>
							<div className="small-12 medium-8 large-7 cell">
								<div className="grid-x grid-padding-x grid-padding-y">

									<div className="small-6 cell is-featured-cell">
										<label className="input-checkbox-cont" htmlFor="input-is-featured">
											<input type="checkbox" id="input-is-featured" name="is_featured" onChange={this.handleFeaturedCheck} checked={this.state.is_featured} />
											Featured Company
										</label>
									</div>
									<div className="small-12 medium-12 cell">
										<label htmlFor="input-name">Company Name</label>
										<input type="text" name="name" id="input-name" placeholder="..." required={true} defaultValue={dflts['name']} />
									</div>
									<div className="small-12 medium-12 cell">
										<label htmlFor="input-address">Address</label>
										<textarea name="address" id="input-address" placeholder="..." required={false} defaultValue={dflts['address']} />
									</div>
									<div className="small-12 medium-6 cell">
										<label htmlFor="input-city">City</label>
										<textarea name="city" id="input-city" placeholder="..." required={false} defaultValue={dflts['city']} />
									</div>

									<div className="small-12 medium-6 cell">
										<label htmlFor="input-state_prov">State/Province</label>
										<textarea name="state_prov" id="input-state_prov" placeholder="..." required={false} defaultValue={dflts['state_prov']} />
									</div>
									<div className="small-12 medium-6 cell">
										<label htmlFor="input-postal_code">Postal Code</label>
										<textarea name="postal_code" id="input-postal_code" placeholder="..." required={false} defaultValue={dflts['postal_code']} />
									</div>
									<div className="small-12 medium-6 cell">
										<label htmlFor="input-phone">Phone #</label>
										<input type="text" name="phone" id="input-phone" placeholder="..." required={false} defaultValue={dflts['phone']} />
									</div>
									<div className="small-12 medium-6 cell">
										<label htmlFor="input-phone_fax">Fax #</label>
										<input type="text" name="phone_fax" id="input-phone_fax" placeholder="..." required={false} defaultValue={dflts['phone_fax']} />
									</div>
									<div className="small-12 medium-6 cell">
										<InputDropdownBlock
											value={this.state.inputs.industry}
											dropdownId={'industry'}
											title="Industries"
											callback={this.handleDropdownChange}
											defaultSelectText="- Select -"
											optionData={this.state.formattedIndustries}
											fieldName="industry"
											key="industry"
										/>
									</div>
									<div className="small-12 medium-6 cell">
										<label htmlFor="input-public_website">Website</label>
										<input type="text" name="public_website" id="input-public_website" placeholder="..." required={false} defaultValue={dflts['public_website']} />
									</div>
									<div className="small-12 medium-6 cell">
										<label htmlFor="input-portal_url">Portal</label>
										<input type="text" name="portal_url" id="input-portal_url" placeholder="..." required={false} defaultValue={dflts['portal_url']} />
									</div>
									<div className="small-12 cell">
										<label htmlFor="input-content">Company Description</label>
										<Editor
											apiKey={config.tinymce_key}
											value={this.state.editorContent}
											id="input-content"
											name="content"
											init={{
												plugins: TINY_MCE_BASE_PLUGINS,
												height: 256,
												toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code formatpainter'
											}}
											onEditorChange={this.handleWYSIWYGInputChange}
										/>
									</div>
									<div className="small-12 cell">
										<input disabled={this.state.uploadingDetailedPhoto || this.state.submitting} className="show-for-medium" type="submit" value="Submit" />
									</div>
								</div>
							</div>
							<div className="upload-widget-container">
								<UploadWidget
									fileCategory="Detailed Photo"
									acceptedFileTypes={UPLOAD_IMAGE_FILE_TYPES}
									saveFile={this.saveDetailedPhoto}
									idUniqueIdentifier={'detailed-photo'}
									classIdentifier={'upload-detailed-photo'}
									showRequirements={false}
									fileObject={this.state.detailedPhotoObject}
									fileId={'upload-detailed-photo'}
									uploading={this.state.uploadingDetailedPhoto}
									preview={this.state.detailedPhoto}
								/>
							</div>
							<div className="small-12 medium-4 large-5 cell">
								<input disabled={this.state.uploadingDetailedPhoto || this.state.submitting} className="hide-for-medium" type="submit" value="Submit" />
							</div>
						</div>
					</form>
				)}
			</FlyoutMenu>
		);
	}

	renderTableTitle() {
		return (
			<div className="grid-x grid-padding-x">
				<div className="small-12 medium-8 cell">
					<h1 className="table-title">Companies</h1>
				</div>
				<div className="small-12 medium-4 cell">
					<div className="new-item-button-cont">{this.renderEditForm()}</div>
				</div>
			</div>
		);
	}

	renderImageLoader(image, title) {
		return (
			<>
				<S3Image aws_object={this.aws} s3_path={image} placeholderImageURL={IMG_PLACEHOLDER} />
				{title}
			</>
		);
	}


	render() {
		return (
			<div className="AdminCompanyManager">
				<FlexTable tableData={{ headerData: CLIENTS_TABLE_HEADERS, rows: this.state.table_rows }} tableTitle={this.renderTableTitle()}
					sort={(e, title) => updateSorting(e, title, this, table_enums.company)}
				/>
				<>{this.state.isLoading &&<LoadingIndicator isLoading={this.state.isLoading}/>}</>
				
				<PageController pageNumber={this.state.currentPage} setPageNumber={(page)=>{
					this.setState({currentPage:page});
				}} numPages={this.state.numPages} />
			</div>
		);
	}
}

AdminCompanyManager.propTypes = {
	cities: PropTypes.array
};

export default AdminCompanyManager;






