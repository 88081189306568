import {CAT_URL, ERROR_RETRIEVING_CATEGORIES, TRY_AGAIN_LATER} from "../../constants";
import {processError} from "../../projlibs/cookie-management";
import {toast} from "react-toastify";
import {fetch_info} from "./herdajax";

export function getCatsForPostType(post_type, successCallback, errorCallback = null) {
    if (errorCallback === null || errorCallback === undefined){
        errorCallback = defaultErrorCallback;
    }
    fetch_info(CAT_URL + '?type=' + post_type, successCallback, errorCallback, {});
}

// Likely should be overridden.
export function defaultErrorCallback(error) {
    if (processError(error)) {
        return false;
    } else {
        toast.error(ERROR_RETRIEVING_CATEGORIES + ' ' + TRY_AGAIN_LATER);
    }
}