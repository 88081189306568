/*
Excerpt - a short excerpt from a post object (such as a news object or event object) to display on the BriefView

An excerpt is a base level component, and contains no subcomponents
*/

import React from 'react';
import PropTypes from 'prop-types';

/*
props (component-level arguments):
	text: the text to render, aka the excerpt to display
*/
class Excerpt extends React.Component {
	render() {
		return (
			<div className='Excerpt'>
				<p>{this.props.text}</p>
			</div>
		);
	}
}

Excerpt.defaultProps={
	text:'',
};

Excerpt.propTypes={
	text:PropTypes.string.isRequired,
};

export default Excerpt;

