import React from 'react';

export default class ClipWeek extends React.Component {
    render() {
        return (
            <div className='single-date-wrapper'>
                <p className='step-x-text'>
                    {`Step ${this.props.step} Abeyance Day (${this.props.weeks} Weeks)`}:
                </p>
                <span className='step-x-date'>{this.props.stepDate?this.props.stepDate:'--'}</span>
            </div>
        );
    }
}
ClipWeek.defaultProps={
    step:'1',
    weeks:'1',
};