/*
GlobalSearch - A search bar for everything
*/

import React from 'react';
import PropTypes from 'prop-types';
import { xml_fetch_info } from '../react-utils/src/libajax.js';
import { parse_cookies } from '../react-utils/src/libformat';
import {
    ERROR_RETRIEVING_NEWS,
    TRY_AGAIN_LATER,
    SESSION_ID,
    PATH_NEWS,
    PATH_EVENTS,
    PATH_DOWNLOADS,
    PATH_EMPLOYEE_SINGLE_DIRECTORY,
    SEARCH_PLACEHOLDER_EVERYTHING,
    PATH_LOCATION_SINGLE_DIRECTORY,
    PATH_COMPANY_SINGLE_DIRECTORY,
    PATH_CHAT,
    SEARCH_TABLE_POST,
    SEARCH_CATEGORY_EVENT,
    SEARCH_CATEGORY_FILE,
    SEARCH_TABLE_USER,
    SEARCH_TABLE_LOCATION,
    SEARCH_TABLE_COMPANY,
    POST_TYPE_NEWS,
    POST_TYPE_EVENTS,
    POST_TYPE_FILES,
    POST_TYPE_LOCATION,
    PATH_ADMIN_FILE_MANAGER,
    PATH_ADMIN_EVENTS_MANAGER,
    PATH_ADMIN_NEWS_MANAGER,
    PATH_ADMIN_DIR_MANAGER_EMPLOYEE_REGEX,
    PATH_ADMIN_DIR_MANAGER_LOCATION_REGEX,
    PATH_ADMIN_DIR_MANAGER_COMPANY_REGEX,
    PATH_DOWNLOADS_HR,
    PATH_DOWNLOADS_HEALTH_DEVELOPMENT,
    PATH_DOWNLOADS_TRAINING_DEVELOPMENT,
    PATH_DOWNLOADS_ACCOUNTING,
    PATH_DOWNLOADS_COMMUNICATIONS,
    PATH_DOWNLOADS_IT,
    DEPARTMENT_HR,
    DEPARTMENT_HEALTH_SAFETY,
    DEPARTMENT_TRAINING_DEVELOPMENT,
    DEPARTMENT_ACCOUNTING,
    DEPARTMENT_COMMUNICATIONS,
    DEPARTMENT_IT
} from '../constants';
import {processError} from '../projlibs/cookie-management';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {openSignedURLFile} from '../lib/libdownloadFiles';
import { getImageBasedOnMimeType } from './../lib/libformat';
import S3Image from './S3Image.jsx';
import HerdRAAWSUtils from '../projlibs/HerdRAAWSUtils';
/*
props (component-level arguments):
	searchParam: A text label for the bar and what will be searched with this component
*/
import { Button } from '../shared-components/src/components/Shared/Button';

const search_list_class = 'search-result-list';
const search_list_item_link_class= 'search-result-list-item-link';
const search_list_item_class = 'search-result-list-item';

class GlobalSearch extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			queryText: '',
			searchResults: [],
			numPages:1,
			showResults: false,
			searchFocus: false,
			searchProperty: '',
			isAdminView: false,
			page:0
		};
		this.aws = new HerdRAAWSUtils();
		this.handleSearchChange = this.handleSearchChange.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.clearQuery = this.clearQuery.bind(this);
		this.closeSearch = this.closeSearch.bind(this);
		this.showSearchResults = this.showSearchResults.bind(this);
		this.handleFocusChange = this.handleFocusChange.bind(this);
		this.handleBlurChange = this.handleBlurChange.bind(this);
		this.getSearchResults = this.getSearchResults.bind(this);
		this.isAdminView = this.isAdminView.bind(this);
		this.searchInput = React.createRef();
	}

	componentDidMount() {
		this.isAdminView();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			this.isAdminView();
		}
		if(this.state.page !== prevState.page){
			if(this.state.page>0){
				this.handleSearch(null,true);
			}
		}
	}


	handleFocusChange() {
		this.setState({searchFocus: true});
		this.props.focusCallback(true);
	}

	handleBlurChange(e){
		if(e.relatedTarget){
			if(e.relatedTarget.type==='button'){
				return null;
			}
			let classListArray = [search_list_item_class, search_list_item_link_class, search_list_class];
			for(let x = 0; x < classListArray.length; x++) {
				if(e.relatedTarget.className.includes(classListArray[x])) {
					return null;
				}
			}
		}
		this.setState({page:0, searchFocus: false, searchResults: [], queryText: ''});
		this.props.focusCallback(false);
	}

	showSearchResults() {
		let searchResultData = [];
		if(this.state.showResults && Array.isArray(this.state.searchResults)) {
			searchResultData = this.state.searchResults.filter(res => {
				if(this.state.searchProperty===SEARCH_TABLE_LOCATION){
					return res.company_id===1 && (res.is_valid || res.valid || res.is_enabled);
				}else{
					return res.is_valid || res.valid || res.is_enabled;
				}
			}).map((res, key) =>{
				let id = '';
				let title = res.title;
				let link = '';
				let thumbnail=res.photo_s3_path;
				let res_is_file = false;
				let fileLink = res.s3_path;
				if(this.props.searchParam === SEARCH_PLACEHOLDER_EVERYTHING) {
					switch(res.type){
						case POST_TYPE_NEWS:
							id=res.post_id;
							thumbnail = res.img_s3_path;
							if(res.direct_file) {
								res_is_file = true;
								fileLink = res.img_s3_path;
							} else {
								link = 'news';
							}
							break;
						case POST_TYPE_EVENTS:
							id=res.event_id;
							link = 'events';
							thumbnail = res.img_s3_path;
							break;
						case POST_TYPE_FILES:
							id=res.file_id;
							link = 'downloads';
							thumbnail=null;
							res_is_file = true;
							break;
						default:
							return null;
					}
				} else {
					switch(this.state.searchProperty){
						case SEARCH_TABLE_POST:
							id=res.post_id;
							thumbnail = res.img_s3_path;
							if(res.direct_file) {
								res_is_file = true;
								fileLink = res.img_s3_path;
							} else {
								link = 'news';
							}
							break;
						case SEARCH_CATEGORY_EVENT:
							id=res.event_id;
							link = 'events';
							thumbnail = res.img_s3_path;
							break;
						case SEARCH_CATEGORY_FILE:
							id=res.file_id;
							link = 'downloads';
							thumbnail=null;
							res_is_file = true;
							break;
						case SEARCH_TABLE_USER:
							id=res.user_id;
							title = `${res.first_name} ${res.last_name}`;
							link = 'company-directory/employee';
							break;
						case SEARCH_TABLE_LOCATION:
							id=res.location_id;
							link=POST_TYPE_LOCATION;
							title = res.name;
							link = 'company-directory/location';
							break;
						case SEARCH_TABLE_COMPANY:
							id=res.company_id;
							title = res.name;
							thumbnail=res.locations[0].photo_s3_path;
							link = 'company-directory/company';
							break;
						default:
							return null;
					}
				}

				if(this.state.isAdminView) {
					link = 'admin/'+link;
				}
				if(!id){
					return null;
				}
				if(res_is_file) {
					return <li className={search_list_item_class} key={key} onClick={this.handleFocusChange}>
						<button className={`${search_list_item_link_class} search-list-button`} onClick={() => openSignedURLFile(fileLink,res.title)}>
							<div className='search-img-and-title'>
								{res.mime_type && <S3Image aws_object={this.aws} additionalClasses={`${res.img_s3_path ? '' : 'default-backup-image' }`} s3_path={res.img_s3_path} placeholderImageURL={getImageBasedOnMimeType(res.mime_type)} altText={res.title} />}
								<p className="search-title">{title}</p>
							</div>
							<i className='fas fa-arrow-down download-arrow-icon'></i>
						</button>
					</li>;
				}
				return <li className={search_list_item_class} key={key} onClick={this.handleFocusChange}>
					<a className={search_list_item_link_class} href={`/${link}/${id}`}>
						{thumbnail && <S3Image aws_object={this.aws} additionalClasses='search-result-list-item-thumbnail' s3_path={thumbnail} alt={res.title} />}
						<p className="search-title">{title}</p>
					</a>
				</li>;
			});
			return this.state.searchResults.length>0?(
			<ul className={search_list_class}>{searchResultData}
			<Button onClick={()=>{
				if(this.state.page<this.state.numPages){
					this.setState({page:this.state.page+1});
				}
			}}
			width='100%'
			marginBottom='8px'backgroundColor='transparent' visibility={this.state.page + 1 === this.state.numPages ? 'hidden' : 'inherit'} title='Load More'/>
			 </ul>
			):null;
		}
	}

	isAdminView() {
		let urlArray = this.props.location.pathname.split('/');
		this.setState({isAdminView: urlArray[1] === 'admin'});
	}

	getSearchResults=(response,property, new_page)=>{
		let searchResults = this.state.searchResults;
		if(new_page){
			searchResults.push(...response[property]);
		}else{
			searchResults=response[property];
		}
		this.setState({numPages: response.page_count, searchResults: searchResults, searchProperty: property});
	}

	getSearchParams() {
		let cur_nav = this.props.selectedNavItem;
		if(cur_nav.match(PATH_NEWS) || cur_nav.match(PATH_ADMIN_NEWS_MANAGER)) {
			return {url: `/search?table=${SEARCH_TABLE_POST}&type=${POST_TYPE_NEWS}&search=${this.state.queryText}`, property: SEARCH_TABLE_POST};
		} else if(cur_nav.match(PATH_EVENTS) || cur_nav.match(PATH_ADMIN_EVENTS_MANAGER)) {
			return {url: `/search?table=${SEARCH_TABLE_POST}&type=${POST_TYPE_EVENTS}&search=${this.state.queryText}`, property: SEARCH_CATEGORY_EVENT};
		} else if(cur_nav.match(PATH_DOWNLOADS) || cur_nav.match(PATH_ADMIN_FILE_MANAGER)) {
			return {url: `/search?table=${SEARCH_TABLE_POST}&type=${POST_TYPE_FILES}&search=${this.state.queryText}`, property: SEARCH_CATEGORY_FILE};
		} else if(cur_nav.match(PATH_EMPLOYEE_SINGLE_DIRECTORY) || cur_nav.match(PATH_ADMIN_DIR_MANAGER_EMPLOYEE_REGEX)) {
			return {url: `/search?table=${SEARCH_TABLE_USER}&search=${this.state.queryText}`, property: SEARCH_TABLE_USER};
		} else if(cur_nav.match(PATH_LOCATION_SINGLE_DIRECTORY) || cur_nav.match(PATH_ADMIN_DIR_MANAGER_LOCATION_REGEX)) {
			return {url: `/search?table=${SEARCH_TABLE_LOCATION}&search=${this.state.queryText}`, property: SEARCH_TABLE_LOCATION};
		} else if(cur_nav.match(PATH_COMPANY_SINGLE_DIRECTORY) || cur_nav.match(PATH_ADMIN_DIR_MANAGER_COMPANY_REGEX)) {
			return {url: `/search?table=${SEARCH_TABLE_COMPANY}&company_type=vendor&search=${this.state.queryText}`, property: SEARCH_TABLE_COMPANY};
		} else if(cur_nav.match(PATH_CHAT)) {
			return {url: `/search?table=${SEARCH_TABLE_USER}&search=${this.state.queryText}`, property: SEARCH_TABLE_USER};
		} else if(cur_nav.match(PATH_DOWNLOADS_HR)){
			return {url: `/search?table=${SEARCH_TABLE_POST}&type=${POST_TYPE_FILES}&landing_page=${DEPARTMENT_HR}&search=${this.state.queryText}`, property: SEARCH_CATEGORY_FILE};
		}else if(cur_nav.match(PATH_DOWNLOADS_HEALTH_DEVELOPMENT)){
			return {url: `/search?table=${SEARCH_TABLE_POST}&type=${POST_TYPE_FILES}&landing_page=${encodeURIComponent(DEPARTMENT_HEALTH_SAFETY)}&search=${this.state.queryText}`, property: SEARCH_CATEGORY_FILE};
		}else if(cur_nav.match(PATH_DOWNLOADS_TRAINING_DEVELOPMENT)){
			return {url: `/search?table=${SEARCH_TABLE_POST}&type=${POST_TYPE_FILES}&landing_page=${encodeURIComponent(DEPARTMENT_TRAINING_DEVELOPMENT)}&search=${this.state.queryText}`, property: SEARCH_CATEGORY_FILE};
		}else if(cur_nav.match(PATH_DOWNLOADS_ACCOUNTING)){
			return {url: `/search?table=${SEARCH_TABLE_POST}&type=${POST_TYPE_FILES}&landing_page=${DEPARTMENT_ACCOUNTING}&search=${this.state.queryText}`, property: SEARCH_CATEGORY_FILE};
		}else if(cur_nav.match(PATH_DOWNLOADS_COMMUNICATIONS)){
			return {url: `/search?table=${SEARCH_TABLE_POST}&type=${POST_TYPE_FILES}&landing_page=${DEPARTMENT_COMMUNICATIONS}&search=${this.state.queryText}`, property: SEARCH_CATEGORY_FILE};
		}else if(cur_nav.match(PATH_DOWNLOADS_IT)){
			return {url: `/search?table=${SEARCH_TABLE_POST}&type=${POST_TYPE_FILES}&landing_page=${DEPARTMENT_IT}&search=${this.state.queryText}`, property: SEARCH_CATEGORY_FILE};
		}else {
			return {url: `/search?search=${this.state.queryText}`, property: SEARCH_TABLE_POST};
		}
	}

	handleSearch(event, new_page=false) {
		if(event){
			event.preventDefault();
		}
		this.setState({showResults:true},
			function() {
				const cookies = parse_cookies();
				// TODO: Will async load an array of search params
				let searchParams = this.getSearchParams();
				searchParams.url+=`&page_size=10&page=${this.state.page}`;
				xml_fetch_info(searchParams.url,
					xhr => {
						let response=JSON.parse(xhr.response);
						if(response) {
							if(this.state.queryText){
								this.setState({
									showResults:true
								},()=>this.getSearchResults(response, searchParams.property, new_page));
							}
						}
					},
					{
						'Content-Type': 'application/json',
						Authorization: cookies[SESSION_ID]
					},
					function (error) {
						if(processError(error)){
							return false;
						}else{
							toast.error(ERROR_RETRIEVING_NEWS + ' ' + TRY_AGAIN_LATER);
						}
					}
				);
			}
		);
	}

	handleSearchChange(event) {
		this.setState({
			queryText: event.target.value,
			showResults: false
		});
	}

	clearQuery(event) {
		event.preventDefault();
		this.setState({queryText: '', showResults: false, searchResults: []}, () => this.searchInput.current.focus());
	}

	closeSearch(event) {
		event.preventDefault();
		this.setState({queryText: '', showResults: false, searchResults: [], searchFocus: false}, () => this.props.focusCallback(false));
	}

	render() {
		return(
			<div className={'GlobalSearch'}>
				<form className={'global-search-form'} onSubmit={this.handleSearch}>
					<button className='submit-button'><i className="fas fa-search"></i></button>
					<input className={`search-field ${this.state.searchFocus ? 'faux-focus' : ''}`}
						ref={this.searchInput}
						onBlur={this.handleBlurChange}
						onFocus={this.handleFocusChange}
						onChange={this.handleSearchChange}
						value={this.state.queryText}
						placeholder={`Search ${this.state.isAdminView ? 'administrator ' : ''}${this.props.searchParam}...`} />
					<button className={`clear-query-button ${this.state.queryText ? 'shown' : 'hidden'}`} onClick={this.clearQuery}>Clear Query</button>
					{this.state.searchFocus && <button className='search-close-button' onClick={this.closeSearch}><i className="fas fa-times"></i></button>}
				</form>
				<div className='form-search-results'>
					{this.showSearchResults()}
				</div>
			</div>
		);
	}
}

GlobalSearch.propTypes = {
	searchParam: PropTypes.string
};

GlobalSearch.defaultProps = {
	searchParam: 'news, events, and files'
};

export default GlobalSearch;
